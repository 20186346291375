<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import {
    getTestCase as aquaGetTestCase,
    getRequirements,
    getRelations,
    deleteRelation,
    createRelation,
    getAquaBase,
} from "@/common/aqua/AquaService"
import HeaderComponent from "@/common/aqua/HeaderComponent.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { useRoute, useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()

const filters = ref<any>({})
const aquaUrl = ref("")
const aqua = ref<any>({
    projectId: "",
    testCaseId: "",
    testCase: {},
    requirements: [],
    relations: [],
    folders: {},
})

onMounted(async () => {
    await reload()
    aquaUrl.value = await getAquaBase()
    filters.value[aqua.value.testCase.Location.FolderId] = true
})

const folders = computed(() => {
    return Object.keys(aqua.value.folders).sort()
})

const aquaTestUrl = computed(() => {
    return aquaUrl.value + "/TestCase/" + aqua.value.testCase.Id
})

const mainMenu = computed(() => {
    return [
        {
            iconClass: ["fas", "fa-sync-alt"],
            text: "Reload",
            callback: reload,
        },
        {
            iconClass: ["fas", "fa-cog"],
            text: "Configure",
            callback: configure,
        },
    ]
})

async function reload() {
    aqua.value.testCaseId = route.params.aquaId
    aqua.value.testCase = (await aquaGetTestCase(aqua.value.testCaseId)).data
    aqua.value.projectId = aqua.value.testCase.Location.ProjectId
    await Promise.all([
        getRequirements(aqua.value.projectId).then(
            (x) => (aqua.value.requirements = (x.data as any).Items.filter((y: any) => y.Type == "Requirement"))
        ),
        getRelations(aqua.value.testCase.Id).then((x) => (aqua.value.relations = (x.data as any).Relations)),
    ])
    aqua.value.requirements.forEach((x: any) => {
        aqua.value.folders[x.Location.FolderId] = x.Location.Path.map((x: any) => x.Name).join("/") || "/"
        if (isLinked(x.FormattedId)) filters.value[x.Location.FolderId] = true
    })
}

async function exit() {
    await router.push(`/ext/aqua/sync/${route.query.testupId}`)
}

async function toggleLink(requirement: any) {
    const link = aqua.value.relations.find((r: any) => r.OtherItem.Id == requirement.Id)
    if (link) {
        aqua.value.relations = aqua.value.relations.filter((r: any) => r != link)
        await deleteRelation(aqua.value.testCaseId, link.Id).then(reload)
    } else {
        aqua.value.relations.push({
            OtherItem: requirement,
        })
        const relation = {
            OtherItemId: requirement.Id,
            OtherItemType: "Requirement",
            Type: "RelatedTo",
        }
        await createRelation(aqua.value.testCaseId, relation).then(reload)
    }
}

function isLinked(otherItemId: any) {
    return !!aqua.value.relations.find((r: any) => r.OtherItem.FormattedId == otherItemId)
}

function reqLink(requirement: any) {
    return aquaUrl.value + "/Requirement/" + requirement.Id
}

function configure() {
    router.push("/aqua/configure")
}
</script>

<template lang="pug">
.sync-test-case-view.container
    header-component
    .main.mb-4
        .mb-2(style="display: grid; place-items: end")
            v-action-button(
                main="Done",
                @main="exit",
                :mainClass="null",
                :mainIconClass="['fas', 'fa-sign-out-alt']",
                :dropDownItems="mainMenu"
            )

        div
            a.tu-link(:href="aquaTestUrl", target="_blank")
                h2.mb-3 {{ aqua.testCase.FormattedId }} {{ aqua.testCase.Name }}
            h4 Folders
            .mb-3
                .folder.btn(
                    v-for="key in folders",
                    :class="{ selected: filters[key] }",
                    @click="filters[key] = !filters[key]"
                ) {{ aqua.folders[key] }}
            h4 Select requirements
            .tu-card(
                v-for="r in aqua.requirements",
                :class="{ selected: isLinked(r.FormattedId) }",
                v-show="filters[r.Location.FolderId]",
                @click="toggleLink(r)"
            )
                .card-header
                    span(v-if="isLinked(r.FormattedId)") [Linked]
                    span(v-else)
                    span(:href="reqLink(r)", target="_blank") &nbsp; {{ r.FormattedId }} {{ r.Name }}
</template>

<style lang="css" scoped>
h1,
h2 {
    text-align: center;
}
.main {
    margin-top: 100px;
}
.logo {
    height: 80px;
    place-self: center;
}
.tu-link {
    color: black;
}
.folder {
    margin: 2px;
    padding: 2px;
    display: inline-grid;
    border: 1px solid lightgray;
    border-radius: 5px;
}
.selected {
    background-color: var(--bright-green) !important;
    color: white;
}
.selected:hover {
    background-color: var(--green) !important;
    color: white;
}
</style>
