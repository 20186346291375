/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationResult = {
    channel: NotificationResult.channel;
    hook: string;
    errorMsg?: string;
    time: string;
};

export namespace NotificationResult {

    export enum channel {
        EMAIL = 'EMAIL',
        SLACK = 'SLACK',
        MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
        DISCORD = 'DISCORD',
    }


}

