<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { getUser } from "@/services/ProjectService"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DataTable from "@/common/components/DataTable.vue"
import ProjectTeamsPermissions from "@/modules/project/components/ProjectTeamsPermissions.vue"
import type { CurrentUserResponse } from "@/types/gen"

interface Props {
    project?: any
}

defineProps<Props>()

const user = ref<CurrentUserResponse>()
const APIKeys = ref<any[]>([])

onMounted(async () => {
    await reloadUser()
})

async function reloadUser(regenerate = false) {
    user.value = await getUser(regenerate)
    APIKeys.value = []
    APIKeys.value.push(user.value)
}

async function regenerate() {
    await reloadUser(true)
}
</script>

<template lang="pug">
div
    project-teams-permissions(v-if="user", :userData="user", :project="project")
    hr.divider
    data-table.mt-4(
        title="API Keys",
        :columnNames="['User', 'Key']",
        :dataKeys="['email', 'apiKey']",
        :dataRows="APIKeys",
        :hidden="['apiKey']"
    )
    .button-row.mt-5.mb-5
        div
        v-action-button(main="Regenerate", :mainIconClass="['fas', 'fa-sync-alt']", @main="regenerate")
</template>

<style lang="css" scoped>
.button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.divider {
    background-color: var(--black);
}
</style>
