<script lang="ts" setup>
import { useStore } from "@/store/useStore"
import { ref } from "vue"
import { saveConfig } from "@/services/ProjectService"
import TestcaseSettingsData from "@/modules/testcase/components/TestcaseSettingsData.vue"
import { DeviceState } from "@/services/BackendService";

interface Props {
    mode: string
    device?: DeviceState
    variables: any
    testcase?: any
    metaData?: any
    showSkipButtons?: boolean
    viewerMode?: boolean
    showUseVariable?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(["skipForward", "skipBackward", "imageLoaded", "save", "useVariable"])
defineExpose({
  addVariable,
  syncAqua
})

const testCaseSettingsData = ref<any>(null)

const { runLocked } = useStore()

function skipForward() {
    emit("skipForward")
}

function skipBackward() {
    emit("skipBackward")
}

function imageLoaded() {
    emit("imageLoaded")
}

function save(data: any) {
    emit("save", data)
}

function addVariable(data: any = {}) {
    if (testCaseSettingsData.value) testCaseSettingsData.value.addVariable({hidden: data.hidden})
}

function syncAqua(message:any) {
    if (testCaseSettingsData.value) testCaseSettingsData.value.syncAqua(message)
}

</script>

<template lang="pug">
.page
    .header
        slot(name="header-layout")
    .main(v-show="mode!='data'")
        slot(name="main-layout")
    #side-panel.side
        slot(name="side-layout")
    .variables(v-if="mode=='data'")
        testcase-settings-data.tu-tab-area(
            ref="testCaseSettingsData",
            :variables="variables",
            :device="device",
            :testcase="testcase",
            :viewerMode="viewerMode"
            :metaData="metaData"
            :showUseVariable="showUseVariable",
            @useVariable="emit('useVariable', $event)"
            @save="save"
        )
    .footer
        slot(name="footer-layout")
</template>

<style lang="css" scoped>
.page {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    overflow: hidden;
}
.header {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-row: 1;
    grid-column: 1 /5;
}
.main, .variables {
    overflow: hidden;
    grid-column: 1 / 4;
    grid-row: 2;
    grid-row-start: 2;
    grid-row-end: auto;
    object-fit: contain;
    margin: 0;
    border-style: none;
    display: grid;
    height: 100%;
}
.variables {
    overflow-y: auto;
    height: 90vh;
}
.side {
    grid-column: 4;
    width: fit-content;
    overflow-y: scroll;
    max-height: 100%;
    grid-row: 2/4;
}
.footer {
    grid-row: 3;
    grid-column: 1/4;
}
</style>
