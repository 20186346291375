import axios from "axios"
import type {
    ActionCreateRequest,
    ActionDTO,
    ActionMatcher,
    AddComplaintRequest,
    AnnotationUpstreamDTO,
    Complaint,
    CreateProjectRequest,
    CreateScheduleRequest,
    CurrentUserResponse,
    DeviceSystemConfigurationProperties,
    ExecutionConfiguration,
    ExecutionDTO,
    GetProjectsProject,
    MatcherOnArea,
    ProjectDTO,
    RecordingDTO,
    RecordingsWithLinkedEntitiesDTO,
    ScheduleDTO,
    SplitTestCaseActionsRequest,
    TagDTO,
    TeamProjectPermissions,
    TestCaseCreateRequest,
    TestCaseDTO,
    TotalBilling,
    UpdateExecutionRequest, UpdateTestCaseRequest,
    UserVariable,
    VariableSource,
    TestupTeam,
    AnnotationDTO,
    ActivityLogDTO
} from "@/types/gen/"

const baseUrl = `/api/v1`
const getData = (response: any) => response?.data

export function createProject(project: CreateProjectRequest): Promise<ProjectDTO> {
    return axios.post(`${baseUrl}/project`, project).then(getData)
}

export function saveProject(project: ProjectDTO): Promise<ProjectDTO> {
    return axios.put(`${baseUrl}/project/${project.id}`, project).then(getData)
}

export function getProject(projectId: number): Promise<ProjectDTO> {
    return axios.get(`${baseUrl}/project/${projectId}`).then(getData)
}

export function deleteProject(project: ProjectDTO): Promise<void> {
    return axios.delete(`${baseUrl}/project/${project.id}`).then(getData)
}

export function listProjects(): Promise<GetProjectsProject[]> {
    return axios.get(`${baseUrl}/project`).then(getData)
}

export function duplicateProject(projectId: number): Promise<ProjectDTO> {
    return axios.post(`${baseUrl}/project/${projectId}/duplicate`).then(getData)
}

export function getProjectEvents(projectId: number, pageSize: number = 5, pageNumber: number = 0): Promise<ActivityLogDTO[]> {
    return axios.get(`${baseUrl}/project/${projectId}/activities?size=${pageSize}&page=${pageNumber}`).then(getData)
}

export function getTags(projectId: number): Promise<TagDTO[]> {
    return axios.get(`${baseUrl}/project/${projectId}/tags`).then(getData)
}

export function createSchedule(schedule: CreateScheduleRequest): Promise<ScheduleDTO> {
    return axios.post(`${baseUrl}/schedules`, schedule).then(getData)
}

export function listSchedules(projectId: number): Promise<ScheduleDTO[]> {
    return axios.get(`${baseUrl}/project/${projectId}/schedules`).then(getData)
}

export function getSchedule(scheduleId: number): Promise<ScheduleDTO> {
    return axios.get(`${baseUrl}/schedules/${scheduleId}`).then(getData)
}

export function saveSchedule(schedule: ScheduleDTO): Promise<ScheduleDTO> {
    return axios.put(`${baseUrl}/schedules`, schedule).then(getData)
}

export function deleteSchedule(schedule: ScheduleDTO): Promise<void> {
    return axios.delete(`${baseUrl}/schedules/${schedule.id}`).then(getData)
}

export function getScheduleRecordings(scheduleId: number, n: number): Promise<RecordingsWithLinkedEntitiesDTO> {
    return axios.get(`${baseUrl}/schedules/${scheduleId}/recordings`, {
        params: {
            executionsCount: n
        }
    }).then(getData)
}

export function runTestcases(project: ProjectDTO, comment = "", testIds: number[] | number | null = null): Promise<ExecutionDTO> {
    const queryParams = []
    if (testIds != null) queryParams.push(`testIds=${testIds}`)
    const queryStr = queryParams.length ? "?" + queryParams.join("&") : ""
    return axios.post(`${baseUrl}/project/${project.id}/run-all${queryStr}`, {message: comment} as ExecutionConfiguration).then(getData)
}

export function runSchedule(schedule: ScheduleDTO, comment = ""): Promise<ExecutionDTO> {
    return axios.post(`${baseUrl}/schedules/${schedule.id}/run`, {message: comment} as ExecutionConfiguration).then(getData)
}

export function stopAllProjectTestcases(project: ProjectDTO): Promise<void> {
    return axios.post(`${baseUrl}/project/${project.id}/stop-all`).then(getData)
}

export function getTests(project: {id: number}): Promise<TestCaseDTO[]> {
    return axios.get(`${baseUrl}/project/${project.id}/tests`).then(getData)
}

export function getTestCase(testCaseId: number): Promise<TestCaseDTO> {
    return axios.get(`${baseUrl}/testCase/${testCaseId}`).then(getData)
}

export function createTestCase(testCase: TestCaseCreateRequest): Promise<TestCaseDTO> {
    return axios.post(`${baseUrl}/testCase`, testCase).then(getData)
}

export function duplicateTestCase(testCaseId: number, withDescendants: boolean = true): Promise<TestCaseDTO> {
    return axios.post(`${baseUrl}/testCase/${testCaseId}/duplicate?withDescendants=${withDescendants}`).then(getData)
}

export function moveTestCase(testCaseId: number, projectId: number, withDescendants: boolean = false): Promise<void> {
    return axios.put(`${baseUrl}/testCase/move/${testCaseId}/toproject/${projectId}?withDescendants=${withDescendants}`).then(getData)
}

export function splitTestCase(testCaseId: number, pivotActionId: number): Promise<void> {
    return axios.post(`${baseUrl}/testCase/${testCaseId}/split`, {
        pivotActionId
    } as SplitTestCaseActionsRequest).then(getData)
}

export function inlineTestCase(testCaseId: number): Promise<void> {
    return axios.post(`${baseUrl}/testCase/${testCaseId}/inline`).then(getData)
}

export function deleteTestCase(testCase: TestCaseDTO, withDescendants: boolean = false): Promise<void>  {
    return axios.delete(`${baseUrl}/testCase/${testCase.id}?withDescendants=${withDescendants}`).then(getData)
}

export function getTestCaseRecordings(testCaseId: number, page = 0, size = 5): Promise<RecordingsWithLinkedEntitiesDTO> {
    return axios.get(`${baseUrl}/testCase/${testCaseId}/recordings?page=${page}&size=${size}`)
        .then(getData)
}

export function getRecording(recordingId: number, filterOutFramesCreatedBeforeTime: string = ""): Promise<RecordingDTO> {
    if (filterOutFramesCreatedBeforeTime) {
        const filterOutFramesCreatedBeforeTimeEncoded = encodeURIComponent(filterOutFramesCreatedBeforeTime)
        return axios.get(`${baseUrl}/recordings/${recordingId}?filterOutFramesCreatedBeforeTime=${filterOutFramesCreatedBeforeTimeEncoded}`)
            .then(getData)
    }
    return axios.get(`${baseUrl}/recordings/${recordingId}`).then(getData)
}

export function getLatestPassedRecording(testcaseId: number): Promise<RecordingDTO> {
    return axios.get(`${baseUrl}/testCase/${testcaseId}/latest-passed-recording`).then(getData)
}

export function saveRecording(recordingId: number, updateOptions: any): Promise<void> {
    return axios.put(`${baseUrl}/recordings/${recordingId}`, updateOptions).then(getData)
}

export function deleteRecording(recording: RecordingDTO): Promise<void> {
    return axios.delete(`${baseUrl}/recordings/${recording.id}`).then(getData)
}

export function stopRecording(recording: RecordingDTO): Promise<void> {
    return axios.post(`${baseUrl}/recordings/${recording.id}/stop`)
}

export function getActions(testCaseId: number): Promise<ActionDTO[]> {
    return axios.get(`${baseUrl}/testCase/${testCaseId}/actions`).then(getData)
}

export function getFailedActions(testCaseId: number): Promise<ActionDTO[]> {
    return axios.get(`${baseUrl}/testCase/${testCaseId}/failedActions`).then(getData)
}

export function createAction(testCaseId: number, beforeActionId: number, type: string, imageUrl?: string): Promise<ActionDTO> {
    return axios.post(`${baseUrl}/action`, {
        testCaseId,
        beforeActionId,
        type,
        imageUrl
    } as ActionCreateRequest).then(getData)
}

export function getAction(actionId: any): Promise<ActionDTO> {
    return axios.get(`${baseUrl}/action/${actionId}`).then(getData)
}


export function saveAction(action: ActionDTO): Promise<ActionDTO> {
    return axios.put(`${baseUrl}/action/${action.id}`, action).then(getData)
}

export function deleteAction(action: ActionDTO): Promise<void> {
    return axios.delete(`${baseUrl}/action/${action.id}`).then(getData)
}

export function saveConfig(testCaseId: number, config: any): Promise<void> {
    return axios.put(`${baseUrl}/testCase/${testCaseId}/config`, config).then(getData)
}

export function updateTestCase(testCaseId: number, request: UpdateTestCaseRequest): Promise<void> {
    return axios.put(`${baseUrl}/testCase/${testCaseId}`, request).then(getData)
}

export function getUser(regenerateApiKey: any = false): Promise<CurrentUserResponse> {
    const queryParam = regenerateApiKey ? `?regenerateApiKey=true` : ""
    return axios.get(`${baseUrl}/user/current${queryParam}`).then(getData)
}

export function getUserTeams(): Promise<TestupTeam[]> {
    return axios.get(`${baseUrl}/user/teams`).then(getData)
}

export function getProjectTeamsPermissions(projectId: number): Promise<TeamProjectPermissions[]> {
    return axios.get(`${baseUrl}/project/${projectId}/permissions`).then(getData)
}

export function updateProjectTeamsPermissions(projectId: number, teamsPermissions: TeamProjectPermissions[]): Promise<TeamProjectPermissions[]> {
    return axios.put(`${baseUrl}/project/${projectId}/permissions`, teamsPermissions).then(getData)
}

export function getDeviceDimensions(): any {
    return axios.get("/deviceDimensions.json").then(getData)
}

export function getAvailableDeviceSystems(): Promise<DeviceSystemConfigurationProperties[]> {
    return axios.get(`/api/v1/configuration/available-systems`).then(getData)
}

export function getExecutionsWithRecordings(projectId: number, page: number, size: number): Promise<ExecutionDTO[]> {
    return axios.get(`${baseUrl}/project/${projectId}/executions?page=${page}&size=${size}&sort=id,desc`).then(getData)
}

export function getExecution(executionId: number): Promise<ExecutionDTO> {
    return axios.get(`${baseUrl}/execution/${executionId}`).then(getData)
}

export function getExecutionRecordings(executionId: number): Promise<RecordingsWithLinkedEntitiesDTO> {
    return axios.get(`${baseUrl}/execution/${executionId}/recordings`)
        .then(getData)
}

export function notifyExecution(executionId: number): Promise<void> {
    return axios.post(`${baseUrl}/execution/${executionId}/notify`).then(getData)
}

export function saveExecution(executionId: number, updateOptions: UpdateExecutionRequest): Promise<ExecutionDTO> {
    return axios.put(`${baseUrl}/execution/${executionId}`, updateOptions).then(getData)
}

export function deleteExecution(execution: ExecutionDTO): Promise<void> {
    return axios.delete(`${baseUrl}/execution/${execution.id}`).then(getData)
}

export function addComplaint(imageUrl: string, actionId: number, label: boolean): Promise<Complaint> {
    return axios.post(`/api/v1/complaint/addcomplaint`, {
        imageUrl,
        label,
        actionId
    } as AddComplaintRequest).then(getData)
}

export function getVariables(prefix: string = ""): Promise<UserVariable[]> {
    return axios.get(`/api/v1/user/variables?prefix=${prefix}`).then(getData)
}

export function saveVariables(variables: UserVariable[]): Promise<void> {
    return axios.post(`/api/v1/user/variables`, variables).then(getData)
}

export function setMatcherNoDevice(matcher: MatcherOnArea, actionId: number): Promise<ActionMatcher> {
    return axios.put(`/api/v1/action/${actionId}/set-matcher`, matcher).then(getData)
}

export function getBillingRecords(year: number, month: number, userId?: string): Promise<TotalBilling> {
    return axios.get('/api/v1/user/billing', {
        params: {year, month, userId}
    }).then(getData)
}

export function addAnnotation(recording: RecordingDTO, annotation: AnnotationUpstreamDTO): Promise<AnnotationDTO> {
    return axios.post(`/api/v1/recordings/${recording.id}/annotation`, annotation).then(getData)
}

export function updateAnnotation(annotation: AnnotationDTO): Promise<AnnotationDTO> {
    return axios
    .put(`/api/v1/recordings/${annotation.recordingId}/annotation/${annotation.id}`,
        { content: annotation.content }
    ).then(getData)
}

export function deleteAnnotation(annotation: AnnotationDTO): Promise<AnnotationDTO> {
    return axios
    .delete(`/api/v1/recordings/${annotation.recordingId}/annotation/${annotation.id}`)
    .then(getData)
}

export function getDeviceVariables(deviceId: String) : Promise<VariableSource[]> {
    return axios.post(`/api/v1/device/get-variables`, {deviceId}).then(getData)
}
