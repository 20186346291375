<script lang="ts" setup>
import { computed, ref, watch } from "vue"
import AquaDataImporter from "@/common/aqua/AquaDataImporterComponent.vue"
import AquaDataExporter from "@/common/aqua/AquaDataExporterComponent.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { useStore } from "@/store/useStore"
import { getImportFromAquaId } from "@/common/aqua/TestupWrapper"
import { getAquaBase } from "@/common/aqua/AquaService"
import { config } from "process"

interface Props {
    config: any // Configuration type
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "save"): void
    (event: "addVariable", data: any): void
}>()
defineExpose({
  openImportDialog,
  openExportDialog,
  openExtern
})

const itemImportOpen = ref(false)
const itemExportOpen = ref(false)

const { runLocked } = useStore()

const importFromAquaId = computed(() => getImportFromAquaId(props.config))

function openImportDialog() {
    itemImportOpen.value = true
}

function openExportDialog() {
    itemExportOpen.value = true
}

async function openExtern() {
    const aquaUrl = await getAquaBase()
    window.open(
        aquaUrl + "/TestCase/" + importFromAquaId.value.replace(/^TC/, ""), "aqua")
}

function itemSelectionMenuChanged() {
    itemImportOpen.value = !itemImportOpen.value
}

function itemExporterMenuChanged() {
    itemExportOpen.value = !itemExportOpen.value
}


function setVariable(name: string, value: string) {
    emit("addVariable", { name, value, allowDuplicate: false, shouldSave: false })
}

function importItem(importItem: any) {
    itemImportOpen.value = false
    setVariable("_Wertesatz", importItem.name)
    for (let variable of importItem.variables) {
        const { name, value } = variable
        setVariable(name, value)
    }
    change()
}

function nameMissing(name:string) {
    itemExportOpen.value = false
    emit("addVariable", { name, value:"", allowDuplicate: false, shouldSave: true })
}

function exportComplete() {
    itemExportOpen.value = false
}

function change() {
    emit("save")
}
</script>

<template lang="pug">
aqua-data-importer(
    v-if="itemImportOpen",
    :importFromAquaId="importFromAquaId",
    @showMenuToggle="itemSelectionMenuChanged",
    @importItem="importItem($event)"
)
aqua-data-exporter(
    v-if="itemExportOpen"
    :exportToAquaId="importFromAquaId",
    :config="props.config",
    @showMenuToggle="itemExporterMenuChanged",
    @exportItem="exportComplete($event)"
    @nameMissing="nameMissing"
)
</template>

<style lang="css" scoped></style>
