<script lang="ts" setup>
import { ref, watch } from "vue"

interface Props {
    modelValue: number
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "input", data: number): void
    (event: "update:modelValue", data: number): void
    (event: "leaveFocus", data: Event): void
}>()

const v = ref(props.modelValue)
const t = ref<number | null>(null)

watch(
    () => props.modelValue,
    () => {
        v.value = props.modelValue
    }
)

function start() {
    let value = 0
    emit("input", 0.1)
    t.value = setInterval(() => {
        v.value = Number(((value += 1) / 5).toFixed(1))
    }, 100) as unknown as number
}

function stop() {
    if (t.value) {
        clearInterval(t.value)
        emit("update:modelValue", v.value)
        t.value = null
    }
}

function update() {
    emit("update:modelValue", v.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}
</script>

<template lang="pug">
.input-group.input-group-sm
    input.form-control(ref="input", v-model="v", @change="update", @keydown.enter="leaveFocus", @keydown.stop="")
    .input-group-append(@mousedown="start", @mouseup="stop", @mouseout="stop")
        span.input-group-text
            i.far.fa-clock
</template>

<style lang="css" scoped></style>
