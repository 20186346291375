<script lang="ts" setup>
import { ref } from "vue"

interface Props {
    confirmModeOnInit?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "itemDeleted"): void
    (event: "itemDeletionCanceled"): void
}>()

const confirmMode = ref(!!props.confirmModeOnInit)

function askConfirm() {
    confirmMode.value = true
}

function deleteItem() {
    confirmMode.value = false
    emit("itemDeleted")
}

function cancel() {
    confirmMode.value = false
    emit("itemDeletionCanceled")
}
</script>

<template lang="pug">
.content
    button.btn.btn-danger.h-100(v-if="!confirmMode", @click.stop="askConfirm")
        i.d-inline.fa.fa-times-circle.mr-2
        span Delete
    .action-buttons.bg-secondary(v-if="confirmMode")
        button.btn.btn-light(@click.prevent.stop="cancel") Cancel
        button.btn.btn-danger(@click.prevent.stop="deleteItem")
            i.d-inline.fas.fa-trash.mr-2
            span Really&nbsp;Delete
</template>

<style lang="css" scoped>
.content {
    display: inline;
}

.action-buttons {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-gap: 15px;
    padding: 10px;
}
</style>
