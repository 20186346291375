<script lang="ts" setup>
import { PivotAggregator, AggregatorConfig, VariableValueAggregator } from "@/common/pivottable/PivotDataAggregators"
import { DynamicPivotVariable } from "@/common/pivottable/PivotVariables"
import { onMounted, computed, ref } from "vue"

interface Props {
    aggregator: PivotAggregator
    dynamicVars: DynamicPivotVariable[]
}
const props = defineProps<Props>()
const showGraph = ref(false)
const config = computed(() => props.aggregator.getConfig())

onMounted(() => {
    if (config.value.aggregatedVariable?.name == "") {
        config.value.aggregatedVariable = props.dynamicVars[0]
    }
})

function updateShowGraph(evt : MouseEvent) {
   config.value.showGraph =  (evt?.target as HTMLInputElement)?.checked
}

function canShowAsGraph() {
    return config.value.showGraph !== undefined
}

function canChooseDynamicVariable() {
    return config.value.aggregatedVariable !== undefined
}
</script>

<template lang="pug">
.aggregator-config
    label(v-if="canShowAsGraph()")
        input.form-control(
            v-model="config.showGraph"
            type="checkbox")
        span.tu-small &nbsp; Show graph
        select.form-control(
            v-if="canChooseDynamicVariable()"
            v-model="config.aggregatedVariable")
            option(v-for="variable in dynamicVars" :value="variable") {{ variable.name }}

</template>

<style lang="css" scoped>
.aggregator-config {
    padding: 2px;
}
</style>
