<script lang="ts" setup>
import ExecutionTime from "@/common/components/ExecutionTime.vue";
import type { RecordingDTO } from "@/types/gen";
import { onMounted, ref } from 'vue';

interface Props {
    recording: RecordingDTO,
    testCaseName: string,
    executionName: string,
    height: number,
    width: number
}

const props = defineProps<Props>()

const recordingRef = ref<HTMLElement>()
const dropdownRef = ref<HTMLElement>()

function getRecordingStatusClass(recording: RecordingDTO) {
    return (recording ? recording.status.toLowerCase() : "not-run") + (recording.isBaseline ? " baseline" : "")
}

function executionProgress(r: RecordingDTO) {
    return r.executionProgress * 100
}

onMounted(() => {
    recordingRef.value.addEventListener('mouseenter', () => {
        const dropdown = dropdownRef.value;

        const dropdownRect = dropdown.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - dropdownRect.bottom - 10;
        const spaceAbove = dropdownRect.top;

        if (spaceBelow < dropdownRect.height && spaceAbove >= dropdownRect.height) {
            dropdown.style.top = `-${dropdownRect.height}px`;
        } else {
            dropdown.style.top = '0px';
        }
    });
});

</script>
 
<template lang="pug">
.recording-cell-with-hover-panel
    .recording(ref="recordingRef" :class="getRecordingStatusClass(recording)" :style="{height: height + 'px', width: width + 'px'}")
        .icon-container(v-if="getRecordingStatusClass(recording) == 'failed' && recording.meta.framesCount === 0 && width > 5")
            i.fa.fa-bolt
        .icon-container(v-if="executionName != '' && recording.annotations?.length > 0 && width > 5")
            i.far.fa-comment-dots
        .progress(
            v-if="getRecordingStatusClass(recording) == 'running'",
            :style="{ width: `${executionProgress(recording)}%` }",
            role="progressbar"
        )
        .tu-card.dropdown(ref="dropdownRef")
            li Test name: {{ testCaseName }}
            li Execution: {{ executionName }}
            li(v-if="recording.meta.lastPlayedTag") Last played tag: {{ recording.meta.lastPlayedTag }}
            li(v-if="getRecordingStatusClass(recording) == 'failed'") Failed at step {{ recording.meta.actionsPlayedCount }}
            li(v-if="getRecordingStatusClass(recording) == 'passed'") Steps played: {{ recording.meta.actionsPlayedCount }}
            li(v-if="getRecordingStatusClass(recording) == 'running'") Duration of execution: Running...
            template(v-else)
                execution-time(fontSize="medium" marginRight="medium" :recording="recording")
            template(v-if="recording.annotations?.length > 0") Annotations
                li(v-for="annotation in recording.annotations") {{ annotation.content.text }}
                
</template>

<style lang="css">
.recording-cell-with-hover-panel .recording .dropdown {
    margin-left: 10px;
    margin-top: 10px;
    min-width: 300px;
    width: fit-content;
    font-size: 0.75em;
    display: none;
    position: absolute;
    z-index: 999;
    left: 0;
    padding: 10px;
    pointer-events: none;
    box-shadow: 0 0 10px grey;
}

.recording-cell-with-hover-panel .recording:hover .dropdown {
    display: block;
}

.recording-cell-with-hover-panel .recording {
    position: relative;
    margin: 3px;
    border-radius: 5px;
}

.recording-cell-with-hover-panel .recording.passed {
    background-color: var(--bright-green);
}
.recording-cell-with-hover-panel .recording.failed {
    background-color: var(--red);
}
.recording-cell-with-hover-panel .recording.running {
    background-color: var(--gray);
}
.recording-cell-with-hover-panel .recording.new {
    background-color: var(--lightgray);
}
.recording-cell-with-hover-panel .recording .fa, .recording-cell-with-hover-panel .recording .far {
    color: var(--white);
    font-size: 21px !important;
}
.recording-cell-with-hover-panel .recording .fa-comment-dots {
    padding-top: 2px;
    padding-left: 2px;
}
.recording-cell-with-hover-panel .recording .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.recording-cell-with-hover-panel .progress {
    height: 100%;
    background-color: var(--bright-orange);
    color: white;
    border-radius: 5px !important;
}
.recording-cell-with-hover-panel .progress:hover {
    background-color: var(--orange);
}
</style>