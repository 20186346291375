/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionInput } from './ActionInput';

export type ActionUpdateRequest = {
    type: ActionUpdateRequest.type;
    inputs: Array<ActionInput>;
    disabled: boolean;
    timeout: number;
    imageUrl?: string;
    matcherId?: number;
    branches: string;
};

export namespace ActionUpdateRequest {

    export enum type {
        POINTER = 'POINTER',
        TEXT = 'TEXT',
        MARKER = 'MARKER',
        CONTROL = 'CONTROL',
        PROMPT = 'PROMPT',
    }


}

