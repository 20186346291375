<script lang="ts" setup>
import {computed, onMounted, ref, watch} from "vue"
import {getFolders, getProjects} from "@/common/aqua/AquaService";

const emit = defineEmits<{
    (event: "select", data: any): void
    (event: "cancel"): void
}>()

const projects = ref<any[]>([{Name:"Loading ..."}])
const folders = ref<any[]>([])
const selectedItems = ref<any[]>([])
const show = ref(false)

defineExpose({
    openDialog
})

onMounted(async () => {
})

async function openDialog() {
    show.value = true
    selectedItems.value = []
    const f = (await getProjects()).data
    if (f.Message) throw { header:f.Type, message: f.FurtherInfo || f.Message };
    projects.value = f
}

function isOpen(item: any, level: number = 0) {
    return selectedItems.value.length > level &&
        selectedItems.value[level].Id == item.Id
}

function isSelected(item: any, level: number=0) {
    return isOpen(item, level) && (level == 0 || level == selectedItems.value.length -1)
}

async function selectItem(item: any, level: number=0) {
    if (item.Id == undefined) return
    const wasOpen = isOpen(item, level)
    const wasSelected = isSelected(item, level)
    selectedItems.value.splice(level, 100)
    if (wasSelected) return
    selectedItems.value[level] = item
    if (wasOpen) return
    if (level == 0 || item.HasSubfolders) {
        const projectId = selectedItems.value[0].Id
        folders.value[level] = [{Name: "Loading...."}]
        const parentId = level > 0 ? item.Id : 0
        const f = (await getFolders(projectId, parentId)).data
        if (f.Message) throw { header:f.Type, message: f.FurtherInfo || f.Message };
        folders.value[level] = f.filter((x: any) => x.ParentFolderId == parentId)
    }
}

function close() {
    show.value = false
    emit('cancel')
}

function select() {
    show.value = false
    const project = selectedItems.value[0]
    const folder = selectedItems.value[selectedItems.value.length-1]
    emit('select', {project, folder})
}

function isComplete() {
    return selectedItems.value.length > 1
}

</script>

<template lang="pug">
.modal-backdrop(v-if="show" style="opacity:0.4" @click="cancel")
.modal.show(v-if="show")
    .modal-dialog.modal-dialog-centered
        .modal-content
            .modal-header
                h4.modal-title Select aqua project and folder
                button.close(@click="close()") &times;
            .modal-body
                div(v-for="project in projects")
                    .item(:class="{ selected : isSelected(project) }" @click="selectItem(project)") {{ project.Name }}
                    ul(v-if="isOpen(project)")
                        li(v-for="folder1 in folders[0]")
                            .item(:class="{ selected : isSelected(folder1, 1) }"  @click="selectItem(folder1, 1)") {{ folder1.Name }}
                            ul(v-if="isOpen(folder1, 1)")
                                li(v-for="folder2 in folders[1]")
                                    .item(:class="{ selected : isSelected(folder2, 2) }" @click="selectItem(folder2, 2)") {{ folder2.Name }}
                                    ul(v-if="isOpen(folder2, 2)")
                                        li(v-for="folder3 in folders[2]")
                                            .item(:class="{ selected : isSelected(folder3, 3) }" @click="selectItem(folder3, 3)") {{ folder3.Name }}
                                            ul(v-if="isOpen(folder3, 3)")
                                                li(v-for="folder4 in folders[3]")
                                                    .item(:class="{ selected : isSelected(folder4, 4) }" @click="selectItem(folder4, 4)") {{ folder4.Name }}
            .modal-footer
                button.btn.btn-success(@click="select" :class="{disabled: !isComplete()}") Select Folder

</template>

<style lang="css" scoped>
.item {
    margin: 1px 1px 1px 5px;
    border-radius: 2px;
}
.item:hover {
    background-color: lightgray;
}
.item.selected {
    background-color: var(--bright-green);
}
.item.selected:hover {
    background-color: var(--green);
}

.modal-body {
    height: 70vh;
    overflow: scroll;
}
.modal-footer {
    text-align: right;
    margin: 5px;
}
</style>
