<script lang="ts" setup>
/**
 * Inspired by:
 * Magnifier glass example (https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp)
 */

import { getImageCoords, getScreenCoordinates } from "@/services/DomHelperFunctions"
import { storeToRefs } from "pinia"
import { useStore } from "@/store/useStore"

import {
computed,
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "vue"

interface Props {
    img: HTMLImageElement,
    zoom?: number,
    scaleCoeff?: number,
    svgOffset?: number,
    glassHidden?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    zoom: 2,
    scaleCoeff: 1,
    svgOffset: 0,
    glassHidden: false
})

const { glassVisible, glassPosition } = storeToRefs(useStore())

const magnifiable = ref<HTMLElement[]>([])
const w = ref(50)
const h = ref(50)
const borderWidth = ref(3)
const delayTimer = ref<NodeJS.Timer | null>(null)
const imgUrl = ref("")



onMounted(async () => {
    magnifiable.value = [...document.querySelectorAll('.magnifiable')] as HTMLElement[]
    await nextTick()
    magnify()
})

onUnmounted(() => {
    document.removeEventListener("mousedown", mouseDownHandler)
})

function magnify() {
    props.img.addEventListener('load', () => {
        imgUrl.value = props.img?.src
    })

    /* Execute a function when user moves the magnifier glass over the image: */
    magnifiable.value.forEach(m => m.removeEventListener("mousedown", mouseDownHandler))
    magnifiable.value.forEach(m => m.addEventListener("mousedown", mouseDownHandler))
}

function mouseDownHandler(event: any) {
    if (props.glassHidden) return
    delayTimer.value = setTimeout(() => mouseDownHandlerDelayed(event), 300)
    document.addEventListener("mouseup", mouseUpHandler)
}

function mouseDownHandlerDelayed(event: any) {
    glassVisible.value = true
    document.body.style.cursor = "crosshair"
    magnifiable.value.forEach(m => m.addEventListener("mousemove", moveMagnifier))

    moveMagnifier(event)
    moveMagnifier(event)
}

function mouseUpHandler(event: any) {
    if (delayTimer.value) clearTimeout(delayTimer.value)
    glassVisible.value = false
    document.body.style.cursor = ""
    document.removeEventListener("mouseup", mouseUpHandler)
    magnifiable.value.forEach(m => m.removeEventListener("mousemove", moveMagnifier))
}

function moveMagnifier(event: any) {
    event.preventDefault()
    glassPosition.value = getScreenCoordinates(event)
}

const getGlassStyle = computed(() => {
    const pos = getImageCoords(props.img, glassPosition.value!)
    return { backgroundPosition: `${-pos.x * props.zoom + w.value - borderWidth.value}px `+
        `${-pos.y * props.zoom + h.value - borderWidth.value}px`,
       left: (glassPosition.value!.x - w.value) + "px",
       top: (glassPosition.value!.y - h.value) + "px",
       "background-image": "url('" + props.img.src + "')",
       'background-size':  (props.img.naturalWidth * props.zoom) + "px " + (props.img.naturalHeight * props.zoom) + "px"
    }
})

</script>

<template lang="pug">
.img-magnifier-glass(ref="glass"
    v-if="glassVisible"
    :style="getGlassStyle")
</template>

<style lang="css" scoped>
* {box-sizing: border-box;}

.img-magnifier-glass {
    position: fixed;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: crosshair;
    width: 100px;
    height: 100px;
    z-index: 1000;
    background-repeat: no-repeat;
}

</style>
