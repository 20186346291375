<script lang="ts" setup>
import { ref, onMounted, computed } from "vue"
import {
    getProject,
    getTests,
    getExecution,
    notifyExecution,
    saveExecution,
    deleteExecution,
    saveRecording, getExecutionRecordings,
} from "@/services/ProjectService"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import VHeader from "@/common/components/layout/VHeader.vue"
import ViewerRecordingPreview from "@/common/components/viewer/ViewerRecordingPreview.vue"
import ProjectSelection from "@/common/components/ProjectSelection.vue"
import VTestStatus from "@/common/components/form/VTestStatus.vue"
import VEditorOverlay from "@/common/components/layout/VEditorOverlay.vue"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import { useRoute, useRouter } from "vue-router"
import { dateDiff, formatDateInterval, formatDate, formatDateTime } from "@/common/util"
import VStatusItemList from "@/common/components/layout/VStatusItemList.vue"
import type {ExecutionDTO, ProjectDTO, RecordingDTO, TestCaseDTO} from "@/types/gen"
import NotificationResults from "@/modules/execution/components/NotificationResults.vue"
import VMultilevelMenu from "@/common/components/menu/VMultilevelMenu.vue"


interface Props {
    testFlatten: any,
    countItemsByPredecessor: number,
    dropDownItems: any[],
    parentTest?: any,
    project: ProjectDTO
}

const props = defineProps<Props>()

const route = useRoute()
const router = useRouter()
const collapsedItems = ref<any>()

const emit = defineEmits<{
    (event: "moveToProject", data: {testcase: TestCaseDTO, project: ProjectDTO}): void
}>()


function isTopLevelItem(item: any) {
    return !item.depth
}

function numberOfLines(testFlatten: any) {
    return Array(testFlatten.depth)
        .fill(undefined)
        .map((item, index) => index)
}

function toggleCollapsedItem(item: any) {
    item.isCollapsed = !item.isCollapsed
    collapsedItems.value = collapsedItems.value || {}
    collapsedItems.value[item.testcase.id] = item.isCollapsed
    saveCollapsedItems()
}

function toggleCollapsedParentItem(item: any, levelsUp: number) {
    while (levelsUp != 0) {
        item = props.parentTest
        levelsUp--
    }
    toggleCollapsedItem(item)
}

function saveCollapsedItems() {
    localStorage.setItem("collapsedItems", JSON.stringify(collapsedItems.value))
}

function moveToProject(data: any) {
    const [ testcase, project ] = arguments
    testcase.move = false
    emit("moveToProject", { testcase, project })
}

function moveToProjectMenuChanged(testcase: any, showMenu: boolean) {
    testcase.move = showMenu
}

</script>

<template lang="pug">
.testname-header
    .top-item-arrow(
        v-if="isTopLevelItem(testFlatten)",
        :class="{ 'item-arrow-collapsed': testFlatten.isCollapsed }"
    )
        .arrow(
            v-if="testFlatten.testcase.isPredecessor",
            :class="testFlatten.isCollapsed ? 'arrow-right' : 'arrow-down'",
            @click="toggleCollapsedItem(testFlatten)"
        )
    div(v-else) &nbsp;&nbsp;&nbsp;
    template(v-for="(numberLines, numberLinesIndex) in numberOfLines(testFlatten)")
        .vertical-dependency-separator-open(v-if="testFlatten.pattern[numberLines] == 'O'")
        .vertical-dependency-separator-closed(v-if="testFlatten.pattern[numberLines] == 'C'")
        .vertical-dependency-icon
            .arrow(
                v-if="testFlatten.testcase.isPredecessor && numberOfLines(testFlatten).length - numberLinesIndex == 1",
                :class="testFlatten.isCollapsed ? 'arrow-right item-arrow-collapsed' : 'arrow-down'",
                @click.stop="toggleCollapsedItem(testFlatten)"
            )
            i.center.fas.fa-square(
                v-else,
                :class="{ 'square-for-collapsed': testFlatten.isCollapsed, 'square-second': numberLinesIndex > 0 }",
                @click.stop="toggleCollapsedParentItem(testFlatten, numberOfLines(testFlatten).length - numberLinesIndex)"
            )
        .vertical-separator

    div(v-if="isTopLevelItem(testFlatten) && !countItemsByPredecessor") &nbsp;&nbsp;&nbsp;
    .test-item
        router-link.tu-card(:to="`/testcase/${testFlatten.testcase.id}`")
            .card-header.reverse-on-hover
                h5.execution-name {{ testFlatten.testcase.name }}
                v-action-button(
                    v-if="testFlatten.testcase.move",
                    :expandOnInit="true",
                    :hideMenuButton="true"
                )
                    project-selection(
                        :projectId="project.id",
                        :testcase="testFlatten.testcase",
                        :expandOnInit="true",
                        @moveToProject="moveToProject",
                        @showMenuChanged="moveToProjectMenuChanged(testFlatten.testcase, $event)"
                    )
                v-multilevel-menu.action-button.invisible-in-print.tu-align-items-center.bg-clr.pr-1(
                    v-if="testFlatten.testcase.isPredecessor"
                    :dropDownItems="dropDownItems",
                    style="margin-right: -15px"
                )
                v-action-button.action-button.invisible-in-print.tu-align-items-center.bg-clr.pr-1(
                    v-else
                    :dropDownItems="dropDownItems",
                    style="margin-right: -15px"
                )
        .testcases-collapsed.span-sm(
            v-if="testFlatten.isCollapsed && countItemsByPredecessor",
            @click="toggleCollapsedItem(testFlatten)"
        ) {{ countItemsByPredecessor }} Testcases collapsed
</template>
<style type="css">
.testname-header .arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 14px solid var(--light-grey);
    cursor: pointer;
    position: relative;
}

.testname-header .arrow-right {
    transform: rotate(90deg);
    left: -1px;
}

.testname-header .arrow-down {
    transform: rotate(180deg);
    left: -2px;
}

.testname-header .top-item-arrow {
    display: flex;
    align-items: center;
    position: relative;
    top: -3px;
    left: -10px;
}

.testname-header .item-arrow-collapsed {
    top: -15px;
}

.testname-header .square-for-collapsed {
    position: relative;
    top: -15px;
}

.testname-header .checkbox-collapsed span {
    position: relative;
    top: -11px;
}

.testname-header .square-second {
    position: relative;
    left: 1px;
}

.testname-header .test-item {
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
}

.testname-header .testcases-collapsed {
    margin-top: -7px;
    padding: 7px 0 7px 20px;
    cursor: pointer;
}

.testname-header .status-icons {
    display: grid;
    grid-template-columns: auto auto;
    margin-right: 10px;
    padding-top: 2px;
}

.testname-header .status-icons > div {
    display: grid;
    justify-content: center;
}

.testname-header .status-icons i {
    margin-right: 2px;
}

.testname-header {
    grid-column: 1;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    min-width: 250px;
    max-width: 50vw;
}

.testname-header .button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.testname-header .card-header {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
}

.testname-header .result {
    margin: 4px;
    height: 30px;
    border-radius: 5px;
    vertical-align: middle;
}

.testname-header .vertical-time-separator {
    width: var(--thin-line);
    background-color: var(--tu-border-lightgray);
    margin-left: auto;
    margin-right: auto;
}
.testname-header .vertical-dependency-separator-open {
    min-width: var(--thin-line);
    width: var(--thin-line);
    height: calc(100%);
    min-height: 100%;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    margin-left: 6px;
    background-color: var(--tu-border-lightgray);
}
.testname-header .vertical-dependency-separator-closed {
    min-width: var(--thin-line);
    width: var(--thin-line);
    height: calc(50%);
    margin-left: 6px;
    background-color: var(--tu-border-lightgray);
    display: inline-flex;
}
.testname-header .vertical-separator {
    width: 18px;
    min-width: 18px;
    height: calc(100%);
    background-color: var(--white);
    display: inline-flex;
}
.testname-header .execution-name {
    padding-right: 2em;
    text-decoration: none;
    margin-bottom: auto;
    margin-top: auto;
}
.testname-header .vertical-dependency-icon {
    margin-top: auto;
    margin-bottom: auto;
    transform: translate(-7px, 0);
    color: var(--light-grey);
    cursor: pointer;
}
</style>