/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VariableInfo } from './VariableInfo';

export type VariableSource = {
    type: VariableSource.type;
    variables: Array<VariableInfo>;
};

export namespace VariableSource {

    export enum type {
        TEST_CASE = 'TEST_CASE',
        PREDECESSOR = 'PREDECESSOR',
        RECORDING = 'RECORDING',
        EXECUTION_CONFIGURATION = 'EXECUTION_CONFIGURATION',
        SCHEDULE = 'SCHEDULE',
    }


}

