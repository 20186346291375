<script lang="ts">
const expandedAction = ref(0)
</script>

<script lang="ts" setup>
import { computed, inject, nextTick, ref, watch } from "vue"
import ActionContextMenu from "@/common/components/action/ActionContextMenu.vue"
import VPassword from "@/common/components/form/VPassword.vue"
import VTag from "@/common/components/form/VTag.vue"
import VText from "@/common/components/form/VText.vue"
import VTimeout from "@/common/components/form/VTimeout.vue"
import VUrl from "@/common/components/form/VUrl.vue"
import VWaitTime from "@/common/components/form/VWaitTime.vue"
import type { ActionDTO } from "@/types/gen"
import { actionVisibleInBranch } from "@/services/actionUtils"
import { TestcaseService } from "@/services/TestcaseService"

interface Props {
    a: ActionDTO
    verb: any
    deviceConnected: boolean
    deviceStatus: any
    isCurrentAction: boolean
    canEdit?: any
    justInserted?: number
    actionAnnotations: any
    isInPredecessor?: boolean
    canPlay: any
    mode: string
    actionIndex: number,
    testCaseService: TestcaseService
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "saveAction", data?: any): void
    (event: "play"): void
    (event: "delete"): void
    (event: "playtohere"): void
    (event: "openActionInEditor"): void
    (event: "jump"): void
    (event: "splitTestCase"): void
    (event: "disableAction"): void
    (event: "enableAction"): void
    (event: "open"): void
    (event: "complain"): void
}>()

const branch = inject('branch', '')
const showMe= computed(() => expandedAction.value == props.a.id || (props.isCurrentAction && props.mode=="edit"))

const timeOut = ref<InstanceType<typeof VTimeout>>()

const isCurrentActionAndEdit = computed(() => props.isCurrentAction && props.mode == "edit")

const actionRequiresInputField = computed(
    () =>
        (props.verb == "ENTER" && props.a.inputs[0].type == "ENTER") ||
        ["LOAD", "EVAL", "EQUALS", "SCROLL"].includes(props.verb)
)

const isInBranch = computed(() => actionVisibleInBranch(props.a, branch.value))

const hashTagNames = computed(() => props.testCaseService.getAllHashTagNames(branch.value))
const hashTagActions = computed(() => props.testCaseService.getAllHashTagActionInputs(branch.value))

watch(
    () => props.a.showTimeout,
    async () => {
        if (props.a.showTimeout) {
            if (props.a.timeout == null || props.a.timeout == 0) {
                emit("saveAction", {
                    ...props.a,
                    timeout: 10,
                })
            }
            await nextTick()
            await timeOut.value?.showTimeout()
            emit("saveAction", {
                ...props.a,
                showTimeout: false,
            })
        }
    }
)

function matchImage(a: any) {
    return a.matcherId ? `/api/v1/matcher/${a.matcherId}/image.png` : ""
}

async function leaveFocus(e: Event) {
    ;(e.currentTarget as HTMLElement).blur()
    await nextTick()
    if (props.justInserted) emit("play")
}

function isJumpType(verb: string) {
    const jumpArr = ["JUMP", "JUMP (T)", "JUMP (F)"]
    return jumpArr.includes(verb)
}

function fixSyntaxAndSaveAction(action: any) {
    if (action.inputs[0].type === "EVAL") {
        let actionText = action.inputs[0].text
        if (actionText) {
            try {
                const pattern = new RegExp('\\${([^"]+?[^"])}', "g")
                action.inputs[0].text = actionText.replaceAll(pattern, '${"$1"}')
            } catch (e) {
                console.warn("failed to correct EVAL syntax:", e)
            }
        }
    }
    emit("saveAction")
}

function getKeySequenceText(action: any) {
    if (action.inputs[0].type == 'KEY_SEQUENCE') {
        const keySequence = JSON.parse(action.inputs[0].text)
        const keySequenceFiltered = keySequence.filter((key: any) => key.action != 'UP')
        return keySequenceFiltered.map((key: any) => key.key).join(" + ")
    }
    else {
        return action.inputs[0].text
    }
}

function listItemClass(action: any) {
    return {
        newAction: action.newAction,
        'out-of-branch': showMe.value && !isInBranch.value,
        'mark-lastFailed': props.actionAnnotations.hasFailed,
        'mark-pass': isCurrentActionAndEdit.value && props.canPlay,
        'mark-fail': isCurrentActionAndEdit.value && !props.canPlay,
        'lastFailed-correct': isCurrentActionAndEdit.value && props.canPlay && props.actionAnnotations.hasFailed
    }
}

const prompts = computed(() => {
    return props.deviceStatus.promptsPerAction.get(props.a.id)
})

</script>

<template lang="pug">
.card-container(@mouseover="expandedAction=a.id")
    div(v-if="!a.disabled")
    .red-cross(v-else) &times;
    .tu-card.card-mg(:class="{ 'disabled-action': a.disabled || !actionAnnotations.wasPlayed }")
        .card-header.out-of-branch(
            v-if="!isInBranch && !showMe"
        )
            .action-index {{actionIndex + 1}} {{ a.branches ? "- "+a.branches : ""}}
        .card-header(
            v-else
            :style="'background-image:url(' + matchImage(a) + ')'",
            style="position: relative",
            :class="listItemClass(a)"
        )
            .action-index.absolute {{actionIndex + 1}} {{ a.branches ? "- "+a.branches : ""}}
            h5.tu-align-self-center(v-if="verb != 'TAG' && verb != 'PROMPT'") {{ verb != "WRONG_ACTION" ? verb : "" }}
            v-tag(
                v-if="verb == 'TAG'",
                v-model="a.inputs[0].text",
                :hashTagActions="hashTagActions",
                :currentInput="a.inputs[0]"
                @update:modelValue="$emit('saveAction')",
                @leaveFocus="leaveFocus"
            )
            v-text(
                v-if="verb == 'PROMPT'",
                v-model="a.inputs[0].text",
                @update:modelValue="$emit('saveAction')",
                @leaveFocus="leaveFocus"
                @pressEnter="leaveFocus"
            )
            .editable-action-name.name-closed(:class="{ 'text-action': a.type == 'TEXT' }")
                span.shown-text.nowrap(v-if="verb == 'PRESS'") {{ getKeySequenceText(a) }}
                .input-group.input-group-sm(v-if="actionRequiresInputField")
                    input.form-control(
                        v-model="a.inputs[0].text",
                        @change="fixSyntaxAndSaveAction(a)",
                        @keydown.enter="leaveFocus",
                        @keydown.stop=""
                    )
                .input-group.input-group-sm(v-if="isJumpType(verb)")
                    .input-group-prepend
                        span.input-group-text
                            .icon-large.fas.fa-hashtag
                    input.form-control(
                        v-if="isJumpType(verb)",
                        v-model="a.inputs[0].text",
                        list="tag-names-list"
                        @change="$emit('saveAction')",
                        @keydown.enter="leaveFocus",
                        @keydown.stop=""
                    )
                    datalist(id="tag-names-list")
                        option(v-for="tagName in hashTagNames") {{ tagName.substring(1) }}
                v-password(
                    v-if="verb == 'ENTER' && a.inputs[0].type == 'PASSWORD'",
                    v-model="a.inputs[0].text",
                    @update:modelValue="$emit('saveAction')",
                    @leaveFocus="leaveFocus"
                )
                v-wait-time(
                    v-if="verb == 'WAIT'",
                    v-model.number="a.inputs[0].wait",
                    @update:modelValue="$emit('saveAction')",
                    @leaveFocus="leaveFocus"
                )
                v-url(
                    v-if="verb == 'OPEN'",
                    v-model="a.inputs[0].text",
                    @update:modelValue="$emit('saveAction')",
                    @leaveFocus="leaveFocus"
                )
            .failIndicator
                span.indicator-pos(v-if="actionAnnotations['hasFailed']") Failed on last run
            v-timeout(
                ref="timeOut",
                v-if="(!!isCurrentAction && a.type == 'POINTER') || a.timeout > 0",
                v-model="a.timeout",
                @update:modelValue="$emit('saveAction')",
                @leaveFocus="leaveFocus",
                :isTimeOutSet="a.timeout > 0"
            )
            action-context-menu.menu(
                :canEdit="canEdit",
                :deviceConnected="deviceConnected",
                :deviceStatus="deviceStatus",
                :isCurrentAction="isCurrentAction",
                :actionDisabled="a.disabled",
                :isInPredecessor="isInPredecessor",
                @delete="$emit('delete')",
                @playtohere="$emit('playtohere')",
                @openActionInEditor="$emit('openActionInEditor')",
                @jump="$emit('jump')",
                @splitTestCase="$emit('splitTestCase')",
                @disableAction="$emit('disableAction')",
                @enableAction="$emit('enableAction')",
                @open="$emit('open')",
                @complain="$emit('complain')"
            )
            div(v-if="prompts && verb == 'PROMPT'")
            div(v-if="prompts && verb == 'PROMPT'")
                div( v-for="prompt in prompts")
                    b {{ prompt?.action }} &nbsp;
                    span {{ prompt?.description }}<br />

</template>

<style lang="css" scoped>
.card-mg {
    margin: 0.5ex 1ex 0.5ex 0.5em;
}
.tu-card:hover {
    background-color: inherit !important;
    color: inherit !important;
}
.card-container {
    width: 100%;
    display: grid;
    grid-template-columns: 14px 1fr;
}
.card-header {
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr) max-content min-content min-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 110px;
    padding-left: 10px;
    padding-right: 5px;
    align-items: center;
}
.disabled-action {
    background-color: #ccc;
}
.red-cross {
    align-self: center;
    user-select: none;
    font-size: 1.32em;
    font-weight: 500;
    color: red;
    position: relative;
    left: -2px;
    top: -1px;
}
.btn.name-closed {
    text-align: right;
    cursor: default;
}
.btn.text-action {
    text-align: left;
}
.out-of-branch > :not(.menu) {
    opacity: 0.5
}
.mark-lastFailed {
    outline: 2px solid orange;
}
.mark-pass {
    outline: 2px solid forestgreen;
}
.mark-fail {
    outline: 2px solid red;
}
.lastFailed-correct {
    outline: 2px solid forestgreen;
}
.mark-lastFailed .failIndicator {
    position: relative;
}
.indicator-pos {
    position: absolute;
    width: max-content;
    margin: -1.9rem 0 0 -11rem;
    background-color: var(--bright-orange);
}
.action-index {
    left: 5px;
    top: 3px;
    font-size: 8px;
}
.absolute {
    position: absolute;
}
</style>
