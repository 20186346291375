<script lang="ts" setup>
import { ref } from "vue"

interface Props {
    title: string
    titleClass?: string
    columnNames: string[]
    dataKeys: string[]
    dataRows: Record<string, any>[]
    hidden?: string[]
}

defineProps<Props>()
const shown = ref(new Map<number, boolean>())

function toggle(index:number) {
    shown.value.set(index, !shown.value.get(index))
}
</script>

<template lang="pug">
.data-table-component
    .tu-small
        th(v-if="title") {{ title }}
        table.data-table
            tr
                th(v-for="columnName in columnNames") {{ columnName }}
            tr(v-for="row, index in dataRows")
                td.pr-3(v-for="k in dataKeys")
                    template(v-if="! hidden?.includes(k)") {{ row[k] }}
                    template(v-else)
                        span(v-if="shown.get(index)") {{  row[k] }}
                        .tu-link(v-else @click="toggle(index)") *** show ***
</template>

<style lang="css" scoped>
.data-table {
    width: 100%;
}

th,
td {
    height: 30px;
    width: 25%;
}
</style>
