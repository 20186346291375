<script lang="ts" setup>
import {computed, onMounted, ref, watch} from "vue"

interface Props {
    deviceStatus: any
    currentActionExact: any
    isEnd?: boolean
    canPlay: boolean
    mode: string
    isRoot?: boolean
    justInserted?: number | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "activated", data: HTMLElement): void
    (event: "retakeImage"): void
    (event: "setMode", mode: string): void
    (event: "record"): void
    (event: "play"): void
    (event: "stop"): void
}>()

const reportFailurePopupShown = ref(false)

const el = ref<HTMLElement>()

onMounted(() => {
    if (el.value) emit("activated", el.value)
})

const currentActionText = computed(() => {
    if (!props.deviceStatus.isDummy) {
        if (props.mode == "play" && !props.currentActionExact)
            return "PLAYING NEXT:"
        if (props.mode == "edit")
            return props.isRoot
                ? "EDITING NOW:"
                : "PLAYING NEXT"
    }

    if (props.currentActionExact) {
        return !props.canPlay
            ? "FAILED"
            : "CURRENT:"
    }
    else {
        return "IN PROGRESS:"
    }
})

const currentActionClass = computed(() => {
    return props.deviceStatus.isDummy && !props.currentActionExact
        ? ["tu-bg-running", "tu-border-running", "show-white"]
        : !props.canPlay
        ? ["btn-failure"]
        : ["btn-success"]
})

watch(
    () => props.isEnd,
    (newVal) => {
        if (newVal) emit("record")
    },
    { immediate: true }
)

function toggleReportFailurePopup() {
    reportFailurePopupShown.value = !reportFailurePopupShown.value
}

function hideReportFailurePopup() {
    reportFailurePopupShown.value = false
}

function retakeImage() {
    emit("retakeImage")
    hideReportFailurePopup()
}

function setMode(mode: string) {
    emit("setMode", mode)
}
</script>

<template lang="pug">
.content(ref="el", @click.stop="hideReportFailurePopup")
    .control-component
        .not-playing.w100(v-if="!deviceStatus.playing")
            div
                button.btn.btn-danger(
                    v-if="!canPlay && !deviceStatus.isDummy",
                    @click.stop="toggleReportFailurePopup",
                    style="margin: 2px 0 0 10px"
                )
                    div(v-if="mode == 'play'")
                        i.fas.fa-exclamation-triangle
                        span= " NOT FOUND"
                    div(v-else)
                        span= "EDITING NOW"
                button.btn(
                    v-if="canPlay || deviceStatus.isDummy",
                    :class="currentActionClass",
                    @click="$emit('play')",
                    style="margin: 2px 0 0 10px"
                ) {{ currentActionText }}
            .insert-style(
                v-if="(mode == 'play' || mode == 'edit') && isRoot && !justInserted",
                @click="setMode('insert-default')"
            )
                .line
                span Insert action
                .line
        .is-playing.w100(v-if="deviceStatus.playing")
            button.btn.tu-bg-running.tu-border-running.playButton-mg.stop-play.show-white(@click="$emit('stop')")
                .spinner-border
                span PLAYING
    .report-failure-popup(v-if="reportFailurePopupShown", @click.stop="")
        .report-failure-popup-text
        span(@click.stop="retakeImage")
            .dropdown-item-icon
                i.fas.fa-image
            span Retake image
        hr
        span(@click.stop="hideReportFailurePopup")
            .dropdown-item-icon
                i.fas.fa-times
            span Cancel
</template>

<style lang="css" scoped>
.control-component {
    margin-top: -8px;
    position: relative;
    top: 8px;
    z-index: 10;
}
.not-playing {
    display: grid;
    grid-template-columns: max-content auto;
}
.w100 {
    width: 100%;
}
.content {
    position: relative;
}

.report-failure-popup {
    position: absolute;
    background-color: white;
    margin-top: 23px;
    top: 100%;
    width: 320px;
    height: 120px;
    border: 1px solid grey;
    border-radius: 6px;
    z-index: 20;
    padding: 20px;
    box-shadow: 5px 5px 5px 0px rgba(170, 170, 170, 1);
}

.report-failure-popup .report-failure-popup-text::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 8%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent grey transparent;
}

.report-failure-popup > hr {
    margin-top: 12px;
    margin-bottom: 12px;
}

.report-failure-popup span {
    cursor: pointer;
    user-select: none;
}
.stop-play {
    margin-left: 10px;
    width: 145px;
}
.insert-style {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto max-content auto;
    line-height: 0.1em;
    color: var(--bright-orange);
    margin-top: 12px;
}
.line {
    border-top: 2px solid var(--bright-orange);
    margin-left: 5px;
    margin-right: 5px;
}
.insert-style:hover .line {
    border-top: 3px solid var(--bright-orange);
}
.show-white {
    color: white;
}

.btn-failure {
    color: white;
    background-color: var(--red);
}
</style>
