<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';


interface Props {
    x: any
    y: any
    follow?: boolean
}

const props= defineProps<Props>()
const btn = ref<HTMLElement>()

onMounted(() => {
    setPosition()
})

watch(() => props.x, (old, now) => {
    if (props.follow) setPosition()
})

function setPosition() {
    btn.value!.style.top = props.y
    btn.value!.style.left = props.x
}
</script>

<template lang="pug">
.overlayBtn(ref="btn" v-movable @mousedown.stop.prevent="")
    slot
</template>

<style scoped>
.overlayBtn {
    position: absolute;
    z-index: 500;
}
</style>
