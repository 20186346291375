<script lang="ts" setup>
import { dateDiff, formatDate, formatDateTime } from "@/common/util"

interface Props {
    recording: any
    fontSize?: string
    marginRight?: string
    hideDate?: boolean
}
 
const props = withDefaults(defineProps<Props>(), {
    fontSize: "small",
    marginRight: "large"
})

function getDateDiff(startTime: string, endTime: string) {
    return (startTime && endTime) ? dateDiff(startTime, endTime) : "Waiting..."
}

</script>

<template lang="pug">
.execution-time
    span(v-if="recording.startTime && !hideDate")
        i.far.fa-calendar
        span.exec-time-text.span-sm(:class="[`font-size-${fontSize}`, `margin-right-${marginRight}`]") {{ formatDate(recording.startTime) }}
    span(v-if="recording.startTime")
        i.far.fa-clock
        span.exec-time-text.span-sm(:class="[`font-size-${fontSize}`, `margin-right-${marginRight}`]") {{ formatDateTime(recording.startTime, "HH:mm") }}
    span
        i.far.fa-hourglass
        span.exec-time-text.span-sm(:class="[`font-size-${fontSize}`, `margin-right-${marginRight}`]") {{ getDateDiff(recording.startTime, recording.endTime) }}   
</template>

<style lang="css" scoped>
.execution-time, .execution-time > span > span {
    display: inline-block;
}

.execution-time i {
    color: var(--black) !important;
}

.exec-time-text {
    padding-left: 0.25rem;
}

.font-size-medium {
    font-size: 12px;
    font-weight: 400;
}

.margin-right-medium {
    margin-right: 0.5rem;
}

.margin-right-large {
    margin-right: 1rem;
}


</style>
