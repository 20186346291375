<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import {
    getAvailableDeviceSystems,
    getDeviceDimensions,
    getTests,
    updateTestCase,
} from "@/services/ProjectService"
import {listOfReorderedTestcase} from "@/services/TestcaseHelperFunction.js"
import {useStore} from "@/store/useStore"
import VActionButton from "@/common/components/form/VActionButton.vue"
import VUrl from "@/common/components/form/VUrl.vue"
import type {
    DeviceSystemConfigurationProperties,
    TestCaseConfigurationDTO,
    TestCaseConfigurationOptionDTO,
    TestCaseDTO,
    UpdateRequestPartBoolean,
    UpdateRequestPartLong,
    UpdateTestCaseRequest
} from "@/types/gen"

interface Props {
    modelValue: TestCaseConfigurationDTO
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: any): void
    (event: "runTest"): void
}>()

const config = ref(props.modelValue)
const editMode = ref(false)

const deviceDimensions = ref<any[]>([])
const deviceSystems = ref<DeviceSystemConfigurationProperties[]>([])
const selectedDimension = ref("")
const selectedDeviceSystemName = ref("")
const selectDeviceTypes = ref([
    { value: "Playground", text: "Play with Demo" },
    { value: "Website", text: "Open Browser" },
    { value: "Desktop", text: "Open Desktop" },
    { value: "Mobile", text: "Open Mobile" },
])
const selectedDeviceType = computed(() => props.modelValue?.systemName?.replace(/^(\S+).*$/, "$1"))
const selectedMobileOsType = computed(() => props.modelValue?.systemName?.includes('Android') ? "Android" : "iOS")
const file = ref<File | null>(null)

const { runLocked } = useStore()

const appetizeAppPublicKey = 'AppetizeAppPublicKey'
const hiddenOptionNames = [appetizeAppPublicKey]

const deviceUnavailable= computed(() =>
    deviceSystems.value?.length &&
    !deviceSystems.value.map(x=> x.name).includes(props.modelValue.systemName))

onMounted(async () => {
    await getDimensions()
    await getDeviceSystems()
    setDefaultOptions()
})

const showDimensionsSection = computed(() =>
    selectedDeviceType.value != 'Mobile' &&
    !props.modelValue.systemName.match(/Windows/)
)

const browserTypes = computed(() => {
    return getOptionsByType("Website")
})

const desktopOsTypes = computed(() => {
    return getOptionsByType("Desktop")
})

const mobileOsTypes = computed(() => {
    return getOptionsByType("Mobile")
})

const currentFullDeviceName = computed(() => {
    return props.modelValue.systemName
})

watch(
    () => props.modelValue,
    (value) => {
        config.value = value
    }
)

const appetizeAppPublicKeyOption = computed(() => {
    return (config.value.options || []).filter((opt: any) => opt.name === appetizeAppPublicKey)[0]
})

function setDefaultOptions() {
    if (!props.modelValue.systemName) {
        emit("update:modelValue", {
            ...props.modelValue,
            systemName: "Playground",
        })
    }
}

function setTestCaseType(type: string) {
    const firstMatchingSystem = deviceSystems.value.find(sys => sys.name.startsWith(type))
    props.modelValue.systemName = firstMatchingSystem?.name ?? "Playground"
    setDefaultDeviceSystemResolution()
    updateSelectedDimension()
    change()
}

function getDefaultOption(typeArray: any[]) {
    if (!typeArray.length) return ""
    return typeArray.map((type) => type.value).includes(props.modelValue.systemName)
        ? props.modelValue.systemName
        : typeArray[0].value
}

function switchEditMode() {
    editMode.value = !editMode.value
}


async function getDeviceSystems() {
    deviceSystems.value = await getAvailableDeviceSystems()

    if (location.host.match(/demo/)) {
        deviceSystems.value.sort(function(x,y){ return x.displayName === 'Chrome (Selenium)' ? -1 : 0; });
    }

    let selected = deviceSystems.value.filter((ds) => ds.name === config.value.systemName)[0]
    if (!selected) {
        selected = deviceSystems.value.filter((ds) => ds.default)[0]
    }
    selectedDeviceSystemName.value = selected.name
    return deviceSystems.value
}

async function getDimensions() {
    const data = await getDeviceDimensions()
    deviceDimensions.value = data.table
    for (let item of deviceDimensions.value) {
        item.displayName = `${item.name} (${item.width}x${item.height})`
    }
    updateSelectedDimension()
    return deviceDimensions.value
}

function setDefaultDimension() {
    selectedDimension.value = "Custom Resolution"
}

function updateApptizerOption(apptizerOptionValue: string) {
    const apptizerOption = appetizeAppPublicKeyOption.value
    if (!!apptizerOption) {
        apptizerOption.value = apptizerOptionValue
    } else {
        config.value.options.push({ name: appetizeAppPublicKey, value: apptizerOptionValue } as TestCaseConfigurationOptionDTO)
    }
}

function changeAppetizeAppPublicKey(newValue: string) {
    updateApptizerOption(newValue)
    change()
}

async function change() {
    if (currentFullDeviceName.value) config.value.systemName = currentFullDeviceName.value
    emit("update:modelValue", config.value)
    setDefaultOptions()
}

async function changeMobileOs() {
    file.value = null
    setDefaultDeviceSystemResolution()
    await change()
}
async function changeDesktopOs() {
    setDefaultDeviceSystemResolution();
    await change()
}

function setDefaultDeviceSystemResolution() {
    const firstMatchingDesktopSystem = deviceSystems.value.find(sys => sys.name.startsWith(props.modelValue.systemName))
    if (firstMatchingDesktopSystem) {
        props.modelValue.width = firstMatchingDesktopSystem?.resolutionWidth ?? props.modelValue.width
        props.modelValue.height = firstMatchingDesktopSystem?.resolutionHeight ?? props.modelValue.height
    }
}

function addOption() {
    config.value.options.push({
        name: "",
        value: "",
    } as TestCaseConfigurationOptionDTO)
    change()
}

function deleteOption(option: any) {
    config.value.options.splice(config.value.options.indexOf(option), 1)
    change()
}

function updateDeviceDimension() {
    const selected = deviceDimensions.value.find((item) => item.displayName === selectedDimension.value)
    if (selected != null) {
        config.value.width = selected.width
        config.value.height = selected.height
        change()
    }
}

function updateDeviceSystem() {
    const selected = deviceSystems.value.find((item) => item.name === selectedDeviceSystemName.value)
    if (selected != null) {
        config.value.width = selected.resolutionWidth
        config.value.height = selected.resolutionHeight
        change()
    }
}

function updateSelectedDimension() {
    change()
    for (let item of deviceDimensions.value) {
        if (config.value.width == item.width && config.value.height == item.height) {
            selectedDimension.value = item.displayName
            return
        }
    }
    setDefaultDimension()
}

function getOptionsByType(deviceType: string) {
    return deviceSystems.value
        .filter(sys => sys.name.startsWith(deviceType))
        .map(sys => ({name: sys.name, displayName: sys.displayName, value: sys.name}))
}

function handleFileUpload(event: Event) {
    const target = event.target as HTMLInputElement
    file.value = (target.files as FileList)[0]
}


function getTestCaseButtonClass(types: string[]) {
    return types?.includes(selectedDeviceType.value) ? ['tu-btn', 'btn-outline-secondary', 'tu-toggle-button', 'enabled'] : null
}

function runTest() {
    emit('runTest')
}

</script>

<template lang="pug">
.config-container.tu-tab-area

    .device-types-section
        v-action-button.invisible-in-print(
            v-if="browserTypes.length > 0",
            main="Browser",
            :mainIconClass="['fas', 'fa-globe']",
            :mainClass="getTestCaseButtonClass(['Website'])"
            @main="setTestCaseType('Website')",
            style=""
        )

        v-action-button.invisible-in-print(
            v-if="desktopOsTypes.length > 0",
            main="Desktop",
            :mainIconClass="['fas', 'fa-desktop']",
            :mainClass="getTestCaseButtonClass(['Desktop'])"
            @main="setTestCaseType('Desktop')",
            style=""
        )

        v-action-button.invisible-in-print(
            v-if="mobileOsTypes.length > 0",
            main="Mobile",
            :mainIconClass="['fas', 'fa-mobile-alt']",
            :mainClass="getTestCaseButtonClass(['Mobile'])"
            @main="setTestCaseType('Mobile')",
            style=""
        )

        v-action-button.invisible-in-print(
            main="Demo",
            :mainIconClass="['fas', 'fa-dice']",
            :mainClass="getTestCaseButtonClass(['Playground'])"
            @main="setTestCaseType('Playground')",
            style=""
        )


    .device-types-section.mt-2(v-if="selectedDeviceType == 'Mobile'")

        v-action-button.invisible-in-print(
            main="Android",
            :mainIconClass="['fab', 'fa-android']",
            :mainClass="selectedMobileOsType=='Android' ? getTestCaseButtonClass(['Mobile']) : null"
            @main="setTestCaseType('Mobile - Android')",
            style=""
        )

        v-action-button.invisible-in-print(
            main="iOS",
            :mainIconClass="['fab', 'fa-apple']",
            :mainClass="selectedMobileOsType=='iOS' ? getTestCaseButtonClass(['Mobile']) : null"
            @main="setTestCaseType('Mobile - iOS')",
            style=""
        )
        .empty
        .empty

    .alert.alert-danger.mt-3(v-if="deviceUnavailable")
        | Device type no longer supported
        | : {{ modelValue.systemName }}

    .settings-container
        .space
        .settings-section-name.section-name
            span.tu-heading Settings
        .settings-section
            .settings-section-demo(v-if="selectedDeviceType == 'Playground'")
                span Good choice! Start the demo project to get familiar with the basic steps in the Testup editor.
                button.button.start-demo-button.mt-3(@click="runTest") Start demo
            .settings-section-browser(v-if="selectedDeviceType == 'Website'")
                template(v-if="true")
                    label.m-0
                        span
                            span Url
                    v-url(v-model="config.url", @update:modelValue="change")
                    .empty
                    label.m-0
                        span
                            span Browser
                    select.custom-select(v-model="modelValue.systemName", @change="change")
                        option(v-for="browser in browserTypes", :value="browser.value", :key="browser.name") {{ browser.displayName }}
                    v-action-button.ml-4(
                        main="Start Editing",
                        :mainIconClass="['fas', 'fa-edit']",
                        :mainDisabled="!config.url",
                        @main="runTest"
                    )
            .settings-section-browser(v-if="selectedDeviceType == 'Desktop'")
                template(v-if="true")
                    label.m-0
                        span System
                    select.custom-select(v-model="modelValue.systemName", @change="changeDesktopOs")
                        option(
                            v-for="desktopOsType in desktopOsTypes",
                            :value="desktopOsType.value",
                            :key="desktopOsType.name"
                        ) {{ desktopOsType.displayName }}
                    v-action-button.ml-4(
                        main="Start Editing",
                        :mainIconClass="['fas', 'fa-edit']",
                        :mainDisabled="false",
                        @main="runTest"
                    )
            .settings-section-browser(v-if="selectedDeviceType == 'Mobile'")
                template(v-if="true")
                    label.m-0
                        span System
                    select.custom-select(v-model="modelValue.systemName", @change="changeMobileOs")
                        template(v-for="mobileOsType in mobileOsTypes")
                            option(
                                v-if="(selectedMobileOsType=='Android' && mobileOsType.value.includes('Android')) || (selectedMobileOsType=='iOS' && mobileOsType.value.includes('iOS'))"
                                :value="mobileOsType.value",
                                :key="mobileOsType.name"
                            ) {{ mobileOsType.displayName }}
                    v-action-button.ml-4(
                        main="Start Editing",
                        :mainIconClass="['fas', 'fa-edit']",
                        @main="runTest"
                    )
                    template(v-if="selectedMobileOsType.includes('Android')")
                            label.m-0(style="align-self: start; padding-top: 5px;")
                                span APK
                            div()
                                div
                                    a.tu-link.span-sm(
                                        href="https://testup.io/test-an-android-app/",
                                        target="_blank"
                                    )
                                        | How to upload your APK
                                input.form-control(type="text"
                                        placeholder="AppetizeAppPublicKey"
                                        :value="appetizeAppPublicKeyOption?.value"
                                        @input="changeAppetizeAppPublicKey($event.target.value)"
                                        style="margin-top: 5px")

                    template(v-else)
                            label.m-0(style="align-self: start; padding-top: 5px;")
                                span TAR
                            div
                                div
                                    a.tu-link.span-sm(
                                        href="https://testup.io/test-an-ios-app-2/",
                                        target="_blank"
                                    )
                                        | How to upload your IPA
                                input.form-control(type="text"
                                        placeholder="AppetizeAppPublicKey"
                                        :value="appetizeAppPublicKeyOption?.value"
                                        @input="changeAppetizeAppPublicKey($event.target.value)"
                                        style="margin-top: 5px")

                    .empty

        .space
        template(v-if="selectedDeviceType && selectedDeviceType != 'Playground' && selectedDeviceType != 'Folder'")
            .advanced-section-name.section-name
                span.tu-heading Advanced
            .advanced-section
                template(v-if="showDimensionsSection")
                    label
                        span Dimensions
                    select.custom-select(v-model="selectedDimension", @change="updateDeviceDimension")
                        option Custom Resolution
                        option(v-if="deviceDimensions", v-for="dimension in deviceDimensions", :key="dimension.name") {{ dimension.displayName }}
                    .empty
                    label
                        span Width
                    input.form-control(v-model="config.width", type="number", @change="updateSelectedDimension")
                    .empty
                    label
                        span Height
                    input.form-control(v-model="config.height", type="number", @change="updateSelectedDimension")
                    .empty
                    .empty
                    .empty
                    .empty
                template(v-for="option of config.options", :key="option.id")
                    template(v-if="!hiddenOptionNames.includes(option.name)")
                        input.form-control(v-model="option.name", @change="change")
                        div
                            input.form-control.d-inline(v-model="option.value", @change="change")
                        div
                            .deleteBtn(@click="deleteOption(option)")
                                i.fas.fa-trash
                i.fas.fa-plus.mt-3(style="cursor: pointer", @click="addOption")
                .empty
</template>

<style lang="css" scoped>
.header-container-with-button {
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 5px;
}
.form-group {
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-row-gap: 1ex;
}
.deleteBtn {
    cursor: pointer;
    width: fit-content;
    padding-left: 6px;
    padding-right: 6px;
}

.config-container {
    margin-top: 20px;
}

.settings-container {
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-template-rows: repeat(3, max-content);
    grid-gap: 10px;
    padding-bottom: 50px;
}

.testcase-type-buttons {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 20px 10px;
    margin-top: 20px;
}

.space {
    grid-column: 1/3;
    height: 50px;
}

@media (max-width: 940px) {
    .settings-container {
        grid-template-columns: 1fr;
    }
    .space {
        grid-column: 1/2;
    }
}

.select-device-section {
    margin-top: 5px;
}

.select-device-section > .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.settings-section-browser,
.advanced-section {
    display: grid;
    grid-template-columns: 20% 1fr 220px;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
}

.options-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
}

.start-demo-button {
    border-radius: 1em;
    color: white;
    font-size: 2rem;
    text-align: center;
    display: block;
    border-radius: 12px;
    border: 3px outset var(--bright-orange);
    background: linear-gradient(160deg, var(--bright-orange), var(--orange));
    text-shadow: 0px 0px 2px rgb(0 0 0 / 80%);
    width: 140px;
    height: 60px;
}

.select-device-section > .form-check > input {
    margin-top: 0;
}
</style>
