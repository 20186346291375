<script lang="ts" setup>
import { PivotAggregator, CustomAggregator } from "@/common/pivottable/PivotDataAggregators";
import { PivotCellData } from "@/common/pivottable/PivotDataHelpers";
import RecordingCellWithHoverPanel from "@/common/pivottable/RecordingCellWithHoverPanel.vue";
import { onMounted, computed, ref } from "vue"
import type { LinkedEntitiesDTO } from "@/types/gen";
import { useRouter } from "vue-router"
import { getScreenCoordinates } from "@/services/DomHelperFunctions";

interface Props {
    cell: PivotCellData
    aggregator: PivotAggregator
    linkedData?: LinkedEntitiesDTO
}
const props = defineProps<Props>()
const router = useRouter()
const isCustom = computed(() => props.aggregator instanceof CustomAggregator)
const numPassed = computed(() => countByStatus('PASSED'))
const numFailed = computed(() => countByStatus('FAILED'))
const numRunning = computed(() => countByStatus('RUNNING') + countByStatus('NEW'))

function countByStatus(status:string) : number {
    return props.cell.data.filter(rec => rec.status == status).length
}

function getTestCase(id:number) {
    return props.linkedData?.testCases?.find(test => test.id === id)
}

function getExecution(id:number) {
    return props.linkedData?.executions?.find(execution => execution.id === id)
}

function openViewer(recording: any) {
    let newPath = router.currentRoute.value.path + `/view/${recording.id}`
    const point = getScreenCoordinates(event)
    sessionStorage.setItem('animate', JSON.stringify([point.x, point.y]))
    router.push({ path: newPath })
}

const textAggregate = computed(() =>  props.aggregator.aggregate(props.cell.data))

</script>

<template lang="pug">
.pivot-cell(:class="{textcell: !isCustom}") {{ textAggregate }}
    template(v-if="isCustom")
        template(v-if="aggregator.type == 'all-recordings'")
            template(v-if="cell.isSummary")
                span(style="color: var(--green)") {{ numPassed ? numPassed : '-'}}/
                span(style="color: var(--light-grey)" v-if="numRunning") {{ numRunning }}/
                span(style="color: var(--red)") {{ numFailed ? numFailed : '-' }}
            template(v-else)
                template(v-for="recording in cell.data")
                    recording-cell-with-hover-panel.show-inline(
                        :recording="recording"
                        :testCaseName="getTestCase(recording.testCaseId)?.name"
                        :executionName="getExecution(recording.executionId)?.name"
                        :height="25"
                        :width="25"
                        @click="openViewer(recording, $event)"
                    )
</template>

<style lang="css">
.pivot-cell.textcell {
    padding: 2px 3px 2px 3px;
    text-align: right;
}
.show-inline {
    display: inline-block;
}
</style>
