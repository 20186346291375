<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch, provide } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
    getRecording,
    saveRecording,
    deleteRecording,
    getActions,
    saveAction,
    addComplaint,
    getTestCase
} from "@/services/ProjectService"
import { syntheticRecording } from "@/services/RecordingService"
import { DummyDeviceService, PendingDeviceService } from "@/services/BackendService"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { augArr, levJoin } from "@/services/levenshtein"
import { RecordingUpdater } from "@/services/RecordingService"
import { disableScrolling, enableScrolling } from "@/common/util"
import VEditAndView from "@/common/components/layout/VEditAndView.vue"
import ActionListMain from "@/common/components/action/ActionListMain.vue"
import Viewer from "@/common/components/viewer/Viewer.vue"
import ViewerHeader from "@/common/components/viewer/ViewerHeader.vue"
import ViewerSlider from "@/common/components/viewer/ViewerSlider.vue"
import type { ActionDTO, RecordingDTO, TestCaseDTO } from "@/types/gen"
import { TestcaseService } from "@/services/TestcaseService"
import { getVariableFromRecording } from "@/services/RecordingService"

const emit = defineEmits<{
    (event: "recordingUpdated", data: boolean): void
    (event: "closeModal"): void
}>()

const route = useRoute()
const router = useRouter()

const device= ref(new PendingDeviceService())
const compareDevice= ref(new PendingDeviceService())

const mode = ref("frames")
const frameIndex = ref<number>(parseInt(route.query.n as string) || 0)
const recording = ref<RecordingDTO>()
const testCaseService = ref(new TestcaseService())
const dontJump = ref(route.query.n != null || route.query.autoplay != null || route.query.start != null)
const running = ref(false)
const programmode = ref("view")
const onLatest = ref(false)
const sidePanel = ref(false)
const compareRec = ref<any>({})
const flicker = ref(false)
const showActions = ref(true)
const showControls = ref(false)
const showAnimation = ref(true)
const currentAction = ref(-1)
const currentActionExact = ref(false)
const actions = ref<ActionDTO[]>([])
const playing = ref(false)
const actionsLoaded = ref(false)
const updater = ref<any>(null)
const compareTimer = ref<NodeJS.Timer | null>(null)
const recordingWasChanged = ref(false)

provide('branch', computed(() => getVariableFromRecording(recording.value, 'BRANCH')))

const viewer = ref<InstanceType<typeof Viewer>>()
const viewer2 = ref<InstanceType<typeof Viewer>>()
const viewerHeader = ref<InstanceType<typeof ViewerHeader>>()

onMounted(async () => {
    await resetRecording(true)
    if (running.value) {
        frameIndex.value = recording.value!.frames.length - 1
        onLatest.value = true
    }

    disableScrolling()
})

onUnmounted(() => {
    if (updater.value) updater.value.destroy()
    if (compareTimer.value) {
        clearTimeout(compareTimer.value)
    }

    enableScrolling()
})

const testcase = computed(()=> testCaseService.value.getTestCase(false))
const modalOverlay = computed(() => route.params.recordingId != null)

const notPlayed = computed(() => {
    if (recording.value!.frames.length >= 0) {
        const thisActs = recording.value?.frames.filter((f: any) => f?.actionId !== null)
        const not_played = actions.value.filter(
            (el: any) => thisActs!?.findIndex((ele: any) => ele?.actionId === el.id) < 0
        )
        return not_played
    } else {
        return []
    }
})

const failedActions = computed(() => {
    if (recording.value!.frames.length) {
        const thisActs = recording.value?.frames.filter((f: any) => f.actionId !== null)
        const failedActs = thisActs?.filter((el: any) => el.success === false)
        return failedActs
    } else {
        return []
    }
})

const variables = computed(() => {
    return recording.value?.meta?.variables || []
})

const metaData = computed(() => {
    const start = recording.value?.startTime
    const timeDiff = (a:string, b:string) => ((new Date(a)).getTime() - (new Date(b)).getTime()) / 1000
    return {
        start: start,
        duration: timeDiff(recording.value?.endTime!, start!),
        time: timeDiff(recording.value?.frames[frameIndex.value]?.time!, start!),
        meta: recording.value?.frames[frameIndex.value]?.meta
    }
})

watch(
    () => recording.value,
    (_, oldValue) => {
        if (oldValue?.status == "RUNNING" &&
            recording.value!.frames.length != 0 && onLatest.value) {
            frameIndex.value = recording.value!.frames.length - 1
        }
        setFrameActionData()
    },
    { deep: true }
)

watch(
    () => frameIndex.value,
    () => {
        setFrameActionData()
    },
    { immediate: true }
)

watch(
    () => route.query.n,
    (n) => {
        let m = parseInt(n as string) || 0
        fixFrameNumber(m)
    }
)

function resetUpdater() {
    if (updater.value) updater.value.destroy()
    updater.value = new RecordingUpdater(true)
}

async function resetRecording(first_call = false) {
    resetUpdater()
    const recordingId = parseInt(route.params.recordingId as string)
    let rec

    if (!recordingId) {
        // Navigation changed
        return
    }
    try {
        rec = await getRecording(recordingId)
        device.value = new DummyDeviceService(rec)
    }
    catch (e: any) {
        recordingWasChanged.value = true
        if (e.response?.status == 404) {
            console.error(e)
            throw new Error(`Sorry, recording with id ${recordingId} has been removed`)
        }
        else {
            throw(e)
        }
    }
    recording.value = rec

    if (!dontJump.value) {
        frameIndex.value = getFirstError()
    }
    running.value = isRunning(recording.value)
    updater.value.keepUpdated(recording.value)
    await resetActions()
    actionsLoaded.value = true
}

function runTestCase(data: any) {
    const query = {useRecordingData: recording.value!.id} as any
    if (data.playTo) {
        query.playTo = data.playTo
        query.imageUrl = recording.value!.frames[frameIndex.value].imageUrl
    }
    router.push({ path: `/run/${data.testCaseId}`, query })
}

async function resetActions() {
    await testCaseService.value.loadTestCase(recording.value!.testCaseId)
    actions.value = testCaseService.value.actions
}

async function doDeleteRecording() {
    await deleteRecording(recording.value!)
    emit("recordingUpdated", true)
    emit("closeModal")
}

function openTest() {
    if (recordingWasChanged.value) {
        emit("recordingUpdated", true)
    }
    emit("closeModal")
}

function iframeUrl(latest: any) {
    if (latest)
        return (
            window.location.origin +
            `/viewer/latest/${recording.value!.testCaseId}?start=0&end=${recording.value!.frames.length - 1}&autoplay=true`
        )
    else
        return (
            window.location.origin +
            `/viewer/${recording.value!.id}?start=0&end=${recording.value!.frames.length - 1}&autoplay=true`
        )
}

function updateFrameUrl(index: number) {
    frameIndex.value = index
    router.replace({ query: { n: getFrameNumber() } })
    onLatest.value = index === recording.value!.frames.length - 1
}

function getFrameNumber() {
    const index = frameIndex.value
    if (programmode.value == 'compare') {
        return recording.value!.frames[index]?.source || index
    } else {
        return index
    }
}

function fixFrameNumber(sourceIndex: number) {
    if (!recording.value) return

    frameIndex.value =
        Math.max(0, Math.min(recording.value!.frames.length-1, sourceIndex))
    if (programmode.value == 'compare') {
        for (let i=0; i<recording.value!.frames.length; ++i) {
            if (recording.value!.frames[i]?.source >= sourceIndex) {
                frameIndex.value = i
                break
            }
        }
    }
}

async function saveBaseline() {
    await saveRecording(recording.value!.id, { isBaseline: recording.value!.isBaseline })
    emit("recordingUpdated", true)
}

async function doSaveAction(action: any) {
    await saveAction(action).catch(() => resetRecording(true))
}

function getFirstError() {
    for (let i = 0; i < recording.value!.frames.length; i++) {
        if (!recording.value!.frames[i].success) {
            return i
        }
    }
    return frameIndex.value
}

function isRunning(recording: any) {
    return recording.status == "RUNNING"
}

function setMode(mode: string) {
    programmode.value = mode
}

function next() {
    viewer.value?.next()
}

function prev() {
    viewer.value?.prev()
}

function first() {
    viewer.value?.first()
}

function nextIssue() {
    for (let i = frameIndex.value + 1; i < recording.value!.frames.length; i++) {
        if (!recording.value!.frames[i].success) {
            viewer.value?.setCurrentFrame(i)
            return
        }
    }
    return frameIndex.value
}

function prevIssue() {
    for (let i = frameIndex.value - 1; i > 0; i--) {
        if (!recording.value!.frames[i].success) {
            viewer.value?.setCurrentFrame(i)
            return
        }
    }
    return frameIndex.value
}

async function setCompareMode(msg: any) {
    const n = getFrameNumber()
    const frame = recording.value!.frames[frameIndex.value]
    if (msg.on_load && !frame?.mismatchUrl) {
        msg.mode = "view"
        msg.rec = {}
    }
    setMode(msg.mode)
    const r = msg.rec || {}
    if (compareTimer.value) clearTimeout(compareTimer.value)
    if (Object.keys(r).length) {
        try {
            if (r.id < 0) {
                compareRec.value = syntheticRecording(recording.value!!, testCaseService.value)
            } else {
                compareRec.value = await getRecording(r.id)
            }
            compareDevice.value = new DummyDeviceService(compareRec)
        }
        catch (e: any) {
            if (e.response?.status == 404) {
                console.error(e)
                throw new Error(`Sorry, recording with id ${r.id} has been removed`)
            }
            else {
                throw(e)
            }
        }
        if (msg.on_load && !compareRec.value.frames.find((f:any) => f.actionId == frame?.actionId)) {
            setMode("view")
            return
        }

        actionsOnly()
        if (msg.on_load) {
            setToDivergence()
        }
        if (showAnimation.value) compareRecordings()
        showControls.value = true
    } else {
        compareRec.value = {}
        await resetRecording()
        flicker.value = false
        showControls.value = false
    }
    fixFrameNumber(n)
}

function actionsOnly() {
    let thisActs = recording.value!.frames
        .map((x:any, i:number) => ({...x, source: i}))
        .filter((f: any) => f.actionId !== null)
    let compActs = compareRec.value.frames.filter((f: any) => f.actionId !== null)

    let joinedActs = levJoin(thisActs, compActs, "actionId")

    let firstActs = augArr(thisActs, joinedActs, "actionId")
    let secondActs = augArr(compActs, joinedActs, "actionId")

    recording.value.frames = firstActs
    compareRec.value.frames = secondActs
    if (frameIndex.value > recording.value!.frames.length) frameIndex.value = recording.value!.frames.length - 1
}

function setToDivergence() {
    if (recording.value!.status === "FAILED") {
        const index = recording.value!.frames.findIndex((el: any, i: number) => {
            return recording.value!.frames[i].success !== compareRec.value.frames[i]?.success
        })
        if (index >= 0) {
            frameIndex.value = index
            updateFrameUrl(frameIndex.value)
        }
    }
}

function compareRecordings() {
    setCompareTimeout()
}

function setCompareTimeout() {
    if (compareTimer.value) clearTimeout(compareTimer.value)
    const timeoutInterval = flicker.value ? 600 : 1400
    compareTimer.value = setTimeout(() => {
        flicker.value = !flicker.value
        setCompareTimeout()
    }, timeoutInterval)
}

function setAnimate(val: any) {
    if (val) {
        showAnimation.value = true
        if (Object.keys(compareRec.value).length) compareRecordings()
    } else {
        showAnimation.value = false
        if (compareTimer.value) clearTimeout(compareTimer.value)
    }
}

function showComp(val: any) {
    flicker.value = !!val
}

function actionClicked(a: any) {
    currentAction.value = a.id
    currentActionExact.value = true
    for (let i = 0; i < recording.value!.frames.length; i++) {
        if (recording.value!.frames[i].actionId == a.id) {
            updateFrameUrl(i)
            return
        }
    }
}

function setFrameActionData(next = false) {
    if (!recording.value) return false
    let frames = recording.value.frames
    let from = next ? frameIndex.value + 1 : 0
    for (let i = from; i < frames.length; i++) {
        if (frames[i].actionId) {
            currentAction.value = frames[i].actionId!
            if (next) frameIndex.value = i
            currentActionExact.value = i == frameIndex.value
            if (i >= frameIndex.value) break
        }
    }
    return
}

function play() {
    playing.value = true
}

function stop() {
    playing.value = false
}

function hasFailed(action: any) {
    return (
        !!action &&
        !!failedActions.value &&
        !!failedActions.value.find((failedAction: any) => failedAction.actionId === action.id)
    )
}

async function complain(action: ActionDTO) {
    let imagelabel = currentActionExact.value ? hasFailed(action) : true
    await addComplaint(recording.value!.frames[frameIndex.value].imageUrl!, action.id, imagelabel)
}

</script>

<template lang="pug">
v-edit-and-view(
    v-if="recording",
    :mode="programmode"
    :device="device.deviceStatus"
    :variables="variables"
    :testcase="testcase",
    :metaData="metaData"
    :viewerMode="true",
)
    template(#header-layout)
        viewer-header(
            ref="viewerHeader",
            :mode="programmode",
            :recording="recording",
            v-model:showActions="showActions",
            :activeRec="!flicker",
            :frameIndex="frameIndex",
            @setCompareMode="setCompareMode",
            @iframeCode="mode = 'html'",
            @deleteRecording="doDeleteRecording",
            @nextFrame="next",
            @prevFrame="prev",
            @firstFrame="first",
            @nextIssue="nextIssue",
            @prevIssue="prevIssue",
            @saveBaseline="saveBaseline",
            @showComp="showComp",du
            @setAnimate="setAnimate",
            @retakeRecording="runTestCase({testCaseId: recording.testCaseId})",
            @playRec="play",
            @stopRec="stop"
        )
            v-action-button(main="Done", :mainIconClass="['fas', 'fa-sign-out-alt']", @main="openTest")
    template(#main-layout)
        viewer.viewer-component-wrapper(
            ref="viewer",
            v-if="mode == 'frames' && recording",
            :style="{opacity: flicker ? 0 : 1}",
            :mode="programmode",
            :device="device"
            :recording="recording",
            :initialFrame="frameIndex",
            :navigation="showControls",
            :autoplay="playing",
            :key="playing.toString()",
            @currentFrame="updateFrameUrl",
            @resetActions="resetActions"
        )
        viewer.viewer-component-wrapper(
            ref="viewer2",
            v-if="mode == 'frames' && Object.keys(compareRec).length",
            :style="{opacity: flicker ? 1 : 0}",
            :mode="programmode",
            :device="compareDevice"
            :recording="compareRec",
            :initialFrame="frameIndex",
            :navigation="showControls",
            :autoplay="playing",
            :key="playing.toString()",
            @currentFrame="updateFrameUrl"
        )
        .content(v-if="mode == 'html'")
            .container.m-4
                p To embed this recording into an iframe use these as src links.
                p For the latest successful recording of this test use:
                pre {{ iframeUrl(true) }}
                p For this specific recording use this:
                pre {{ iframeUrl(false) }}
                button.btn.btn-primary(@click="mode = 'frames'") Ok
        .left-box(v-if="showControls", @click="prev")
            .left-control
                a.control_prev ❮❮
        .right-box(v-if="showControls", @click="next")
            .right-control
                a.control_next ❯❯
    template(#side-layout)
        .action-view(v-if="showActions")
            .spinner-container(v-if="!actionsLoaded")
                .spinner
                    .spinner-item
                    .spinner-item
                    .spinner-item
            .card-header.tu-card-header.ml-4.tu-sticky-header
                .span-sm Recorded actions from
                h5.wrapText {{ testcase?.name }}
            action-list-main(
                v-if="testCaseService?.isLoaded"
                :testCaseService="testCaseService"
                :device="device"
                :failedActions="failedActions",
                :noDeviceMode="true",
                :mode="mode",
                :currentAction="currentAction",
                :currentActionExact="currentActionExact",
                :notPlayed="notPlayed",
                @play="setFrameActionData(true)",
                @actionClicked="actionClicked",
                @complain="complain"
                @openTestCaseInEditor="runTestCase"
                @update="doSaveAction"
            )
    template(#footer-layout)
        viewer-slider(
            v-if="recording && programmode!='data'",
            :currentIndex="frameIndex",
            :maxIndex="recording.frames.length - 1",
            :status="recording.status",
            @currentFrame="updateFrameUrl"
        )
</template>

<style lang="css" scoped>
.recording-content {
    height: 100vh;
    display: grid;
    grid-template-rows: min-content 1fr;
}

.actions {
    display: none;
}

.action-view {
    width: 40vw;
    margin-top: 80px;
    max-width: 609px;
    margin-bottom: 200px;
}

a.control_prev,
a.control_next {
    z-index: 999;
    display: block;
    padding: 12px 23px;
    background: #2a2a2a;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    opacity: 0.5;
    user-select: none;
    width: fit-content;
}

.left-control {
    justify-self: left;
}

.right-control {
    justify-self: right;
}

.left-box {
    opacity: 0.5;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
}

.right-box {
    opacity: 0.5;
    grid-column: 3;
    grid-row: 1;
    display: grid;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
}

.left-box:hover,
.right-box:hover {
    opacity: 0.9;
    transition: all 0.2s ease;
    color: white;
    cursor: pointer;
}

a.control_prev.disabled,
a.control_next.disabled {
    opacity: 0.4;
    cursor: default;
}

a.control_prev {
    border-radius: 25px 0 0 25px;
    padding-right: 15px;
}

a.control_next {
    border-radius: 0 25px 25px 0;
    padding-left: 15px;
}
.wrapText {
    overflow: hidden;
    word-break: break-all;
}
</style>
