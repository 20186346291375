import axios from "axios"
import { reactive } from "vue"
import {getMatchImageLink} from "@/common/aqua/TestupWrapper";

function convertId(id) {
    if (typeof id == "string") return parseInt(id.replace(/^TC/, ""))
    return id
}

axios.interceptors.response.use(function interceptError(response) {
    if (response.data && response.data.ArgumentErrorType) {
        console.error(response.data.Message)
        throw new Error("Error contacting Aqua: " + response.data.Message)
    }
    return response
})

export function getAquaBase() {
    return axios.get("/aqua/base_url").then((res) => res.data.aqua_base)
}

export function getProjects() {
    return axios.get(`/aqua/api/Project`)
}

export function getProject(id) {
    return axios.get(`/aqua/api/Project/${id}`)
}

export function getFolders(projectId, folderId=0) {
    return axios.get(`/aqua/api/Project/${projectId}/Folder/${folderId}/SubFolderPath`)
}

export function getRequirements(projectId, folderId = 0) {
    return axios.get(`/aqua/api/Project/${projectId}/Item?Type=Requirement&folderId=${folderId}`)
}

export function getRelations(testCaseId) {
    return axios.get(`/aqua/api/TestCase/${testCaseId}/Relation`)
}

export function deleteRelation(testCaseId, relationId) {
    return axios.delete(`/aqua/api/TestCase/${testCaseId}/Relation/${relationId}`)
}

export function createRelation(testCaseId, relation) {
    return axios.post(`/aqua/api/TestCase/${testCaseId}/Relation/`, relation)
}

export function getTestCases(projectId) {
    return axios.get(`/aqua/api/TestCase/ItemList?projectId=${projectId}`)
}

export function getTestCase(testCaseId) {
    return axios.get(`/aqua/api/TestCase/${convertId(testCaseId)}`)
}

export function createTestCase(data) {
    return axios.post(`/aqua/api/TestCase?applyDefaultValues=true`, data)
}

export function updateTestCase(id, data) {
    return axios.put(`/aqua/api/TestCase/${id}`, data)
}

export function deleteTestCase(testCaseId) {
    return axios.delete(`/aqua/api/TestCase/${convertId(testCaseId)}`)
}

export function getTestSteps(testCaseId) {
    return axios.get(`/aqua/api/TestCase/${convertId(testCaseId)}/TestStep`)
}

export function getTestExecutions(testCaseId, max = 20) {
    return axios.get(`/aqua/api/TestCase/${convertId(testCaseId)}/Executions/${max}`)
}

export function saveTestExecution(execution) {
    return axios.post(`/aqua/api/TestExecution/`, execution)
}

export function getTestData(testCaseId) {
    return axios.get(`/aqua/api/TestCase/${testCaseId}/TestData?include=ValueSets,Variables,Values,IsReferenced`)
}

export function uploadImage(imageUrl) {
    return axios.post("/aqua/upload_image", {imageUrl})
}

function maskHtml(text) {
    return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
}
function imageHtml(imgLink) {
    return uploadImage(imgLink)
        .catch((retry) => uploadImage(imgLink))
        .then((response) => `<img src="${response.data.Url.replace(/\?.*/, '')}"/>`)
        .catch(() => {
            throw Error(`Failed to upload image ${imgLink}`)
        })
}

export class StepBuilder {
    constructor(name, autogenerated) {
        this.name = name
        this.autogenerated = autogenerated
        this.actions = []
        this.expectedImage = null
        this.observedImage = null
        this.status = "Pass"
    }

    hasContent() {
        return this.actions.length > 0 || !this.autogenerated
    }

    addTextInstruction(verb, arg) {
        this.actions.push(() =>
            Promise.resolve({
                verb: maskHtml(verb),
                arg: maskHtml(arg),
            })
        )
    }

    addPointerInstruction(verb, imgLink) {
        this.actions.push(() =>
            imageHtml(imgLink)
                .then((html) => ({
                    verb: verb,
                    arg: "<br/>" + html,
                }))
                .catch((e) => {
                    throw Error(`In step ${name}: Faild to link the image ${imgLink}`)
                })
        )
    }

    setExpectedImage(imgLink) {
        this.expectedImage = imgLink
    }

    setObservedImage(imgLink) {
        this.observedImage = imgLink
    }

    async getInstructionHtml() {
        const actions = await Promise.all(this.actions.map(f => f()))
        return (
            '<ol style="font-family: sans-serif">\n' +
            actions.map((a) => `<li>${a.verb}: ${a.arg}</li>`).join("\n") +
            "</ol>"
        )
    }

    async getExpectedHtml() {
        if (this.expectedImage) return await imageHtml(this.expectedImage)
        else return ""
    }

    async getObservedHtml() {
        if (this.observedImage) {
            return  (await imageHtml(this.observedImage))+"<p>Actual screenshot</p>"
        } else {
            return "None"
        }
    }

    async getAllHtml() {
        return [
            await this.getInstructionHtml(),
            await this.getExpectedHtml(),
            await this.getObservedHtml()]
    }

    fillInstructionFromAction(action) {
        if (!action) {
            this.addTextInstruction("---missing---", "")
        } else if (action.matcherId) {
            const inputs = action.inputs
            let verb = "CHECK"
            if (inputs.length > 0) {
                verb = inputs[inputs.length - 1].type
            }
            this.addPointerInstruction(verb, getMatchImageLink(action.matcherId))
        } else {
            const input = action.inputs[0]
            if (input.type == "EVAL") {
                this.addTextInstruction(input.type, input.text.replace(/\$\{"([^"}']*)"\}/, '\${$1}'))
            } else if (input.type.match(/^(PRESS|ENTER|JUMP|KEY).*/)) {
                this.addTextInstruction(input.type, input.text)
            } else if (input.type.match(/PASSWORD/)) {
                this.addTextInstruction("ENTER (Secured)", "*******")
            } else if (input.type == "TAG") {
                this.addTextInstruction("Tagged location", input.text)
            }
        }
    }
}

export class AquaTestCaseBuilder {
    constructor() {
        this.template = {
            Details: [
                {
                    FieldId: "Name",
                    Value: "",
                },
            ],
            TestSteps: [],
            Location: {
                ProjectId: 0,
                FolderId: 0,
            },
        }
        this.promise = Promise.resolve()
        this.stepBuilder = new StepBuilder("Initialize", true)
        this.progress = reactive({
            step: 0,
            total: 1,
        })
    }

    setName(name) {
        this.setField("Name", name)
    }

    getField(field) {
        return this.template.Details.find((a) => a.FieldId == field).Value
    }

    setField(field, value) {
        this.template.Details.find((a) => a.FieldId == field).Value = value
    }

    lastStep() {
        return this.template.TestSteps[this.template.TestSteps.length - 1]
    }

    async queueStep() {
        const stepBuilder = this.stepBuilder
        if (stepBuilder.hasContent()) {
            this.progress.total += 1
            this.promise = this.promise.then(async () => {
                const html = await stepBuilder.getAllHtml()
                this.template.TestSteps.push({
                    Name: stepBuilder.name,
                    Index: this.template.TestSteps.length + 1,
                    Description: {
                        Html: html[0],
                        IncompatibleRichTextFeatures: false,
                    },
                    ExpectedResult: {
                        Html: html[1],
                        IncompatibleRichTextFeatures: false,
                    },
                    StepType: "Step",
                })
                this.progress.step += 1
            })
        }
        return this.promise
    }

    addTestStep(name) {
        this.queueStep()
        this.stepBuilder = new StepBuilder(name)
    }

    setProject(projectId) {
        this.template.Location.ProjectId = projectId
    }

    setFolder(folderId) {
        this.template.Location.FolderId = folderId
    }

    async save() {
        await this.queueStep()
        const response = await createTestCase(this.template)
        return {
            formattedId: response.data.FormattedId,
            projectId: this.template.Location.ProjectId,
        }
    }

    async synchronize(aquaTestCase, testSteps) {
        await this.queueStep()
        const newLen = this.template.TestSteps.length
        const oldLen = testSteps.length
        const body = {}
        body.Details = this.template.Details
        body.TestSteps = {
            Added: this.template.TestSteps.slice(oldLen),
            Removed: testSteps.slice(newLen).map((x) => x.Id),
            Modified: testSteps.slice(0, newLen).map((item, index) => ({ ...item, ...this.template.TestSteps[index] })),
        }
        updateTestCase(aquaTestCase.Id, body)
    }
}

export class AquaExecutionBuilder {
    constructor(testCase) {
        this.template = {
            TestCaseId: testCase.Id,
            Steps: [],
            AttachedFiles: [],
            ExecutionDuration: {
                FieldValueType: "TimeSpan",
                Unit: "Second",
                Value: 0
            },
            Finalize: false,
            ValueSetName: undefined
        }
        this.promise = Promise.resolve()
        this.stepBuilder = new StepBuilder("Initialize", true)
        this.progress = reactive({
            step: 0,
            total: 1,
        })
    }

    setValueSetName(name) {
        this.template.ValueSetName = name
    }

    setDuration(startTime, endTime) {
        const seconds = Math.round((new Date(endTime) - new Date(startTime))/1000)
        this.template.ExecutionDuration.Value= seconds
    }

    lastStep() {
        return this.template.Steps[this.template.Steps.length - 1]
    }

    queueStep(name) {
        const stepBuilder = this.stepBuilder
        if (stepBuilder.hasContent()) {
            this.progress.total += 1
            this.promise = this.promise.then(async () => {
                console.log("Uploading images for Step " + this.template.Steps.length + "/" + this.progress.total)
                const html = await stepBuilder.getAllHtml()
                this.template.Steps.push({
                    Index: this.template.Steps.length + 1,
                    Name: stepBuilder.name,
                    StepType: "Step",
                    Status: stepBuilder.status,
                    Description: {
                        Html: html[0],
                        IncompatibleRichTextFeatures: false,
                    },
                    ExpectedResults: {
                        Html: html[1],
                        IncompatibleRichTextFeatures: false,
                    },
                    ActualResults: {
                        Html: html[2],
                        IncompatibleRichTextFeatures: false,
                    },
                })
                this.progress.step += 1
            })
        }
        return this.promise
    }

    addTestStep(name) {
        this.queueStep()
        this.stepBuilder = new StepBuilder(name)
    }

    async save() {
        await this.queueStep()
        return await saveTestExecution([this.template])
    }
}
