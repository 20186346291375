<script lang="ts" setup>
import {PivotAggregator} from "@/common/pivottable/PivotDataAggregators";
import { PivotTree, PivotHeader, PivotCellData } from "@/common/pivottable/PivotDataHelpers";
import { PivotVariable } from "@/common/pivottable/PivotVariables"
import { router } from "@/router";
import PivotCellDisplay from "@/common/pivottable/PivotCellDisplay.vue";
import { onMounted, computed, ref, watch } from "vue"
import type { LinkedEntitiesDTO } from "@/types/gen";
import Plotly from "plotly.js-basic-dist";

interface Props {
    rows: PivotVariable[]
    columns: PivotVariable[]
    aggregator: PivotAggregator
    data: any[]
    linkedData?: LinkedEntitiesDTO
}
const props = defineProps<Props>()
const plotElement = ref<HTMLElement>()

onMounted(() => { updatePlot() })
watch(() => props.columns, updatePlot)
watch(() => props.rows, updatePlot)
watch(() => props.data, updatePlot)

function build_xaxis(): PivotHeader[] {
    if (props.columns.length == 0) {
        return []
    }
    const variable = props.columns[0]
    const headers = variable.sortedHeaders(props.data)
    return headers
}

function build_groups() {
    if (props.rows.length == 0) {
        return []
    }
    const variable=props.rows[0]
    const headers = variable.sortedHeaders(props.data)
    return headers

}

function extract_from_group(group_key: PivotHeader, xaxis: PivotHeader[]) {
    const group_data = props.data.filter(row => props.rows[0].extractKey(row) == group_key.key)
    return extract_dataset(group_data, group_key.html, xaxis)
}

function extract_dataset(group_data: any[], group_name:string, xaxis: PivotHeader[]) {
    if (xaxis.length == 0) {
        return {
            x: [0],
            y: [props.aggregator.aggregate(group_data)],
            name: group_name
        }
    } else {
        const y = xaxis.map(x =>
            props.aggregator.aggregate(group_data.filter(row => props.columns[0].extractKey(row) == x.key)))
        return {
            x: xaxis.map(x => x.html),
            y: y,
            name: group_name
        }
    }
}

function updatePlot() {
    const xaxis = build_xaxis()
    const groups = build_groups()
    const data = groups.length>0 ? groups.map(g => extract_from_group(g, xaxis)) : [extract_dataset(props.data, "All", xaxis)]
    Plotly.newPlot(
        plotElement.value,
        data,
        {
            margin: { t: 0 }
        }
    )
}
</script>

<template lang="pug">
.pivottableplotly
    .plot(ref="plotElement")
</template>

<style lang="css" scoped>
.pivottableplotly {
    margin: 2px;
    width: 100%;
    display: grid;
}
.plot {
    width: 100%;
    height: 100%;
}
</style>
