<script lang="ts" setup>
import { computed, watch, ref, onMounted } from "vue"
import { saveRecording } from "@/services/ProjectService"
import ViewerRecordingPreview from "@/common/components/viewer/ViewerRecordingPreview.vue"
import VTestStatus from "@/common/components/form/VTestStatus.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { recordingService } from "@/services/RecordingService"
import { useRoute, useRouter } from "vue-router"
import VStatusItemList from "@/common/components/layout/VStatusItemList.vue"
import ExecutionTime from "@/common/components/ExecutionTime.vue"

interface Props {
    recordings: any[]
    actionButtonMode: string
    recordingsLoaded: boolean
    enableDownloadMore: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "recordingUpdated", data: any): void
    (event: "deleteRecording", data: { recording: any }): void
    (
        event: "colorClassChanged",
        data: {
            frameBorderClass: string
            bgClass: string
        }
    ): void,
    (event: "loadNextPage"): void
    (event: "reloadRequired"): void
}>()

const route = useRoute()
const router = useRouter()

const downloadMore = ref<HTMLElement>()

const frameBorderClass = computed(() => {
    const status = props.recordings[0] ? props.recordings[0].status.toLowerCase() : "norun"
    return `tu-border-${status}`
})

const testcaseRoute = computed(() => {
    return "testCase/" + route.params.testcaseId
})

watch(
    frameBorderClass,
    (value) => {
        let bgClass = value.replace("tu-border", "tu-bg")
        emit("colorClassChanged", {
            frameBorderClass: value,
            bgClass,
        })
    },
    { immediate: true }
)

const observer = new IntersectionObserver(
    (entries) => {
        const downloadMoreEntry = entries[0]
        if (downloadMoreEntry['isIntersecting']) {
            emit('loadNextPage')
        }
    }
)

onMounted(async () => {
    observer.observe(downloadMore.value!!)
})

function recordingUpdated(payload: any) {
    emit("recordingUpdated", payload)
}

function deleteRecording(recording: any) {
    emit("deleteRecording", {
        recording,
    })
}

async function saveBaseline(recording: any) {
    await saveRecording(recording.id, {
        isBaseline: recording.isBaseline,
    })
}

function borderClass(recording: any) {
    const status = recording ? recording.status.toLowerCase() : "norun"
    return `tu-border-${status}`
}

function resolveName(recording: any) {
    return recordingService.resolveName(recording)
}

function openExecution(recording: any) {
    router.push(`/execution/${recording.executionId}`)
}

</script>

<template lang="pug">
.container-block.recording-list.pt-4.avoid-print-break
    v-status-item-list(:items="recordings")
        template(#progressBar="{ listItem: recording }")
            v-test-status(:recording="recording", :noProgressLeftRadius="true")

        template(#execTime="{ listItem: recording }")
            .tu-align-self-center.pl-2.d-inline-block
                execution-time.tu-align-self-center(:recording="recording")

        template(#itemName="{ listItem: recording }")
            .tu-align-self-center.pl-2.tu-link.test-name {{ resolveName(recording) }}

        template(#actionButton="{ listItem: recording }", v-if="actionButtonMode == 'testCaseMode'")
            .pr-0
                div
                    i.fas.fa-star(v-if="recording.isBaseline")
                    v-action-button.float-right.tu-align-items-center.bg-clr.invisible-in-print
                        .dropdown-item.bg-white.text-dark(@click.stop="")
                            label
                                input(
                                    type="checkbox",
                                    v-model="recording.isBaseline",
                                    @change="saveBaseline(recording)"
                                )
                                span Set as Baseline
                        a.dropdown-item(:class="{ disabled: !recording.executionId }", @click="openExecution(recording)")
                            .dropdown-item-icon
                                i.fas.fa-external-link-alt
                            span Open Execution
                        a.dropdown-item(
                            :class="{ disabled: !!recording.isBaseline }",
                            @click="deleteRecording(recording)"
                        )
                            .dropdown-item-icon
                                i.fas.fa-trash
                            span Delete

        template(#recordingPreview="{ listItem: recording }")
            viewer-recording-preview(:recording="recording", @recordingUpdated="recordingUpdated", @reloadRequired="emit('reloadRequired')")
    div.load-more(ref="downloadMore")
    .spinner-container(v-if="!recordingsLoaded")
        .spinner
            .spinner-item
            .spinner-item
            .spinner-item
</template>

<style lang="css" scoped>
.card-header a {
    color: var(--black);
    text-decoration: none;
}

.row {
    align-items: center;
}

.align-grp {
    display: contents;
}

.dropdown-item.disabled {
    opacity: 0.5;
}

.spinner-container {
    position: static;
    left: 0;
    top: 0;
    margin-top: 40px;
    margin-bottom: 30px;
}

.load-more {
    height: 10px;
}
</style>
