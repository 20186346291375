<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { getProjectEvents } from "@/services/ProjectService"
import type { ActivityLogDTO, ProjectDTO } from "@/types/gen"
import ProjectEvent from "./ProjectEvent.vue"

interface Props {
    project: ProjectDTO
}

const props = defineProps<Props>()

const activities = ref<ActivityLogDTO[]>([])
const downloadMore = ref<HTMLElement>()
const downloadMoreAvailable = ref(true)
const currentPage = ref<number>(-1)
const pageSize = 50;


watch(currentPage, async () => {
    const loaded = await getProjectEvents(props.project.id, pageSize, currentPage.value)
    downloadMoreAvailable.value = pageSize <= loaded.length
    activities.value = [...activities.value, ...loaded]
})

const observer = new IntersectionObserver(
        (entries) => {
            const downloadMoreEntry = entries[0]
            if (downloadMoreAvailable.value && downloadMoreEntry['isIntersecting']) {
                nextPage()
            }
        }
    )

onMounted(async () => {
    observer.observe(downloadMore.value!!)
})

function nextPage() {
    currentPage.value = currentPage.value + 1
}
</script>

<template lang="pug">
div
    project-event(
        v-for="activity in activities" key="id"
        :activityLog="activity"
    )
    div.download-more(ref="downloadMore" @click="nextPage")
</template>

<style lang="css" scoped>
.download-more {
    width: 100%;
    height: 10px;    
}
</style>
