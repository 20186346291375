<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from "vue"

interface Props {
    modelValue: string
    showText?: boolean
    noIcon?: boolean
    nowrap?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: string): void
}>()

const text = ref(props.modelValue)
const origText = ref(props.modelValue)
const editMode = ref(false)

const input = ref<HTMLInputElement>()

onMounted(() => {
    if (text.value === "Project name" || text.value === "Name of testcase") editMode.value = true
})

watch(
    () => props.modelValue,
    () => {
        text.value = props.modelValue
        origText.value = props.modelValue
    }
)

watch(editMode, async (newValue) => {
    if (newValue) {
        await nextTick()

        if (!input.value) return
        input.value.focus()
        input.value.select()
    }
})

function update() {
    if (!editMode.value) return
    let newText = text.value.trim()
    if (origText.value != newText) {
        emit("update:modelValue", newText)
    }
    editMode.value = false
}
</script>

<template lang="pug">
.editable-text
    .group(v-if="editMode")
        input.form-control(
            ref="input",
            v-model="text",
            @blur="update",
            @keydown.enter="update",
            @keydown.stop="",
            @click.stop="",
            autofocus
        )
        .bar
        .input-group-append(@click.stop="update")
    .shown-text(v-if="!editMode", :class="{ nowrap: nowrap }", @click.stop="editMode = true") {{ showText || text }}
        span.btn.hide-in-iframe(v-if="!noIcon", @click.stop="editMode = !editMode")
            i.fas.fa-pencil-alt
</template>

<style lang="css" scoped>
.editable-text {
    display: grid;
    overflow: hidden;
}
.shown-text {
    cursor: pointer;
}
.nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.form-control:focus {
    outline: none;
    border: 0;
    box-shadow: none;
    padding: unset;
}
::selection {
    background: var(--bright-green);
    color: white;
}
.bar {
    position: relative;
    display: block;
}
.bar:before,
.bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: var(--bright-green);
}
input:focus ~ .bar:before,
input:focus ~ .bar:after {
    width: 100%;
}
</style>
