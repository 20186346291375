<script lang="ts" setup>
import { onMounted, ref, watch } from "vue"
import { listProjects } from "@/services/ProjectService"

interface Props {
    projectId: string | number
    testcase: any
    expandOnInit: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "showMenuChanged", data: boolean): void
    (event: "moveToProject", arg1: any, arg2: any): void
}>()

const showMenu = ref(props.expandOnInit)
const projects = ref<any[]>([])
const destinationProject = ref<any>(null)

onMounted(async () => {
    projects.value = await listProjects()
})

watch(showMenu, (newValue) => {
    emit("showMenuChanged", newValue)
})

function toggleMenu() {
    showMenu.value = !showMenu.value
}

function moveProject() {
    toggleMenu()
    if (destinationProject.value != null) emit("moveToProject", props.testcase, destinationProject.value)
}
</script>

<template lang="pug">
.modal.show
    .modal-dialog.modal-dialog-centered
        .modal-content
            .modal-header
                h4.modal-title Select a destination project
                button.close(@click="toggleMenu") &times;
            .modal-body
                select.project-select(size="10")
                    option(
                        v-for="projectItem in projects",
                        :class="{ disabledOption: projectId == projectItem.id }",
                        @click.prevent.stop="destinationProject = projectItem"
                    ) {{ projectItem.name }}
            .modal-footer
                button.btn.btn-success(@click="moveProject") Move
    .modal-backdrop.show(style="z-index: -1", @click="toggleMenu")
</template>

<style scoped>

.project-select {
    width: 100%;
}
.disabledOption {
    opacity: 0.4;
}
</style>
