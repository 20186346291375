<script lang="ts" setup>
import { ref, watch } from "vue"
import videoData from "@/assets/videoData.json"

interface Props {
    open: boolean
    videoId: string
}

const props = defineProps<Props>()

const showVideo = ref(false)
const video = videoData.find((v: any) => v.id == props.videoId)

watch(
    () => props.open,
    (value) => {
        if (value && location.hostname != "testup-qa") showVideo.value = true
    }
)

function close() {
    showVideo.value = false
}
</script>

<template lang="pug">
.video-content(v-if="showVideo")
    .video-container
        .video-title
            span {{ video.title }}
            span.text-right.mb-3(style="width: 100%; cursor: pointer", @click="close") X
        div
            iframe.d-block(
                width="640",
                height="320",
                :src="video.url",
                frameborder="0",
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen
            )
</template>

<style lang="css">
.video-content {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 40px;
    margin-bottom: 20px;
}

.video-title {
    display: grid;
    grid-template-columns: 1fr max-content;
}
</style>
