<script lang="ts" setup>
import { PivotDataFilter } from "@/common/pivottable/PivotDataHelpers";
import { PivotVariable, DynamicPivotVariable } from "@/common/pivottable/PivotVariables"
import { onMounted, computed, ref } from "vue"

interface Props {
    variable: PivotVariable
    filter: PivotDataFilter
}
const props = defineProps<Props>()
const showMenu = ref(false)
const dragged = ref(false)

function toggleMenu() {
    showMenu.value = !showMenu.value
    if (showMenu.value) props.filter.update()
}

function dragStart(evt: DragEvent) {
    if (showMenu.value) {
        evt.preventDefault()
    } else {
        dragged.value = true
    }
}

function bgColor() {
    if (props.variable instanceof DynamicPivotVariable)
        switch (props.variable.metaKey) {
            case "variables": return "#b2c9cd";
            case "runtimeVariables": return "#b2b6cd";
            default: return "#cdb2c7"
        }
    return "lightgray"
}

function getOpacity() {
    return dragged.value ? 0.5 : null
}
</script>

<template lang="pug">
.variable(
        :style="{'background-color': bgColor(), opacity: getOpacity()}"
        @dragstart="dragStart"
        @dragend="dragged = false"
    ) {{ variable.name}}
    .icon(@click="toggleMenu")
        i.fa.fa-caret-down
    .modal-backdrop(v-if="showMenu", @click.prevent.stop="toggleMenu")
    .btn-group.drop-right
        .dropdown-menu.p-2(:class="{show:showMenu}")
            div(style="display:inline") Filter
            .icon(@click.prevent="toggleMenu") &times;
            hr
            div(v-for="level in filter.levels.values()")
                label
                    input.mr-2.form-control(type="checkbox" v-model="level.enabled")
                    .key {{ " " }} {{ level.header.html }}
</template>

<style lang="css" scoped>
.variable {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    border-radius: 5px;
    padding: 1ex;
    margin: 2px;
}

.modal-backdrop {
    z-index: 500;
    opacity: 0.05;
}
.icon {
    float: right;
    padding: 3px;
    margin: -3px -3px -3px 3px;
    cursor: pointer;
}

.key {
    white-space: nowrap;
}
</style>
