import { App } from "vue"

const layouts = import.meta.globEager("../layouts/*.vue")

export default {
    install: (app: App) => {
        for (const layout of Object.keys(layouts)) {
            const name = layout.replace("../layouts/", "").replace(".vue", "")
            const component = layouts[layout].default

            app.component(name, component)
        }
    },
}
