<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { getRecording } from "@/services/ProjectService"
import { RecordingUpdater } from "@/services/RecordingService"
import { useRoute, useRouter } from "vue-router"
import useEventListener from "@/composables/useEventListener"
import type { RecordingDTO } from "@/types/gen"

interface Props {
    recording: any,
    previewHeight?: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (
        event: "recordingUpdated",
        data: {
            recording: any
            finished: boolean
        }
    ): void,
    (event: "reloadRequired"): void
}>()

const route = useRoute()
const router = useRouter()

const frames = ref<any[]>([])
const running = ref(isRunning(props.recording))
const height = ref(50)
const width = ref(500)
const enlarge = ref(false)
const enlargeNo = ref(0)
const isInView = ref(false)
const timer = ref<number | null>(null)
const updater = ref<any>(null)
const reloadRequestSent = ref(false)

const el = ref<HTMLElement>()
const preview = ref<HTMLInputElement>()

useEventListener(window, "resize", resize)
useEventListener(window, "resize", loadIfInView)
useEventListener(window, "scroll", loadIfInView)
useEventListener(document.querySelector(".scroll-event-hook"), "scroll", loadIfInView)

onMounted(async () => {
    if (running.value) {
        isInView.value = true
        await reload()
    }
    await loadIfInView()
    resize()
})

onUnmounted(() => {
    if (timer.value) clearTimeout(timer.value)
    if (updater.value) updater.value.destroy()
})

const selectedFrames = computed(() => {
    let len = frames.value.length
    let n = Math.min(Math.ceil(width.value / height.value), len)
    let newFrames: any[] = []
    for (let i = 0; i < n; ++i) {
        newFrames.push(frames.value[Math.ceil((i * len) / n)])
    }
    return newFrames
})

const routeElementWithView = computed(() => {
    return `${route.path}/view/${props.recording.id}`
})

watch(
    () => props.recording,
    async () => {
        if (timer.value) clearTimeout(timer.value)
        if (isRunning(props.recording)) {
            running.value = true
            isInView.value = true
        }
        await reload()
    }
)

async function reload() {
    if (!isInView.value || reloadRequestSent.value) return
    let recording: RecordingDTO
    try {
        recording = await getRecording(props.recording.id)
    } catch (e) {
        console.error(e)
        reloadRequestSent.value = true
        emit("reloadRequired")
        return
    }
    frames.value = []
    processRecording(recording)
    if (updater.value) updater.value.destroy()
    updater.value = new RecordingUpdater()
    updater.value.setRecordingUpdateCallback(async (r: RecordingDTO, recording: RecordingDTO) => {
        processRecording(recording)
    })
    updater.value.keepUpdated(recording)
}

function processRecording(recording: RecordingDTO) {
    if (recording) {
        frames.value = frames.value.concat(recording.frames)
        emit("recordingUpdated", { recording, finished: running.value && !isRunning(recording) })
        running.value = isRunning(recording)
    } else {
        reloadRequestSent.value = true
        emit("reloadRequired")
    }
}

function resize() {
    if (!el.value) return
    width.value = el.value.offsetWidth
    height.value = el.value.offsetHeight
}

function image(frame: any) {
    return {
        "background-image": frame && `url(${frame.thumbnailUrl})`,
    }
}

function showPreview(event: any) {
    if (timer.value) clearTimeout(timer.value)
    if (!enlarge.value) timer.value = setTimeout(() => (enlarge.value = true), 300) as unknown as number
    const x = event.clientX
    if (!el.value) return
    const rect = el.value.getBoundingClientRect()
    enlargeNo.value = Math.floor(((x - rect.x) / rect.width) * frames.value.length)
    if (!preview.value) return
    preview.value.style.left = x - rect.x - 100 + "px"
    preview.value.style.top = el.value.offsetTop - 25 + "px"
}

function stopPreview() {
    if (preview.value) {
        preview.value.style.left = "0"
    }
    if (timer.value) clearTimeout(timer.value)
    timer.value = setTimeout(() => (enlarge.value = false), 100) as unknown as number
}

function clicked() {
    router.push({ path: routeElementWithView.value, query: { n: enlargeNo.value } })
}

function isRunning(recording: any) {
    return recording.status == "RUNNING"
}

async function loadIfInView() {
    if (!el.value) return
    if (isInView.value) return
    const rect = el.value.getBoundingClientRect()
    isInView.value = rect.top < window.innerHeight && rect.bottom >= 0
    await reload()
}

</script>

<template lang="pug">
.recording-preview(ref="el" :style="{height: (previewHeight || 50) + 'px'}" @mouseout="stopPreview" @mousemove="showPreview" @click.stop="clicked")
    .frame(v-for="frame in selectedFrames" :style="image(frame)")
    .preview(ref="preview")
        .preview-image(v-if="enlarge" :style="image(frames[enlargeNo])")
</template>

<style lang="css" scoped>
.recording-preview {
    display: grid;
    grid-auto-flow: column;
    height: 50px;
}
.frame {
    background-size: cover;
    background-position: center;
}
.preview {
    pointer-events: none;
    height: 100px;
    width: 200px;
    position: absolute;
    z-index: 100;
    display: grid;
}
.preview-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
