import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomePage from "@/modules/home/HomePage.vue"
import ProjectPage from "@/modules/project/ProjectPage.vue"
import TestcasePage from "@/modules/testcase/TestcasePage.vue"
import ExecutionPage from "@/modules/execution/ExecutionPage.vue"
import RunPage from "@/modules/run/RunPage.vue"
import SchedulePage from "@/modules/schedule/SchedulePage.vue"
import ViewerPage from "@/modules/viewer/ViewerPage.vue"

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: HomePage,
        meta: {
            auth: true,
            breadcrumb: [
                {
                    text: "Testup",
                },
            ],
        },
    },
    {
        path: "/project/:projectId/:recordingId?",
        alias: ["/project/:projectId/view/:recordingId?"],
        component: ProjectPage,
        meta: {
            auth: true,
            breadcrumb: [
                {
                    path: "/",
                    text: "Testup",
                },
                {
                    text: "{{projectName}}",
                },
            ],
        },
    },
    {
        path: "/testcase/:testcaseId/:recordingId?",
        alias: ["/testcase/:testcaseId/view/:recordingId?"],
        component: TestcasePage,
        meta: {
            auth: true,
            breadcrumb: [
                {
                    path: "/",
                    text: "Testup",
                },
                {
                    path: "/project/{{projectId}}",
                    text: "{{projectName}}",
                },
                {
                    text: "{{testcaseName}}",
                },
            ],
        },
    },
    {
        path: "/execution/:executionId/:recordingId?",
        alias: ["/execution/:executionId/view/:recordingId?"],
        component: ExecutionPage,
        meta: {
            auth: true,
            breadcrumb: [
                {
                    path: "/",
                    text: "Testup",
                },
                {
                    path: "/project/{{projectId}}",
                    text: "{{projectName}}",
                },
                {
                    text: "{{executionName}}",
                },
            ],
        },
    },
    {
        path: "/run/:testId",
        name: "run",
        component: RunPage,
        meta: {
            layout: "empty",
            auth: true,
            breadcrumb: [
                {
                    path: "/",
                    text: "Testup",
                },
                {
                    path: "/project/{{projectId}}",
                    text: "{{projectName}}",
                },
                {
                    path: "/testcase/{{testcaseId}}",
                    text: "{{testcaseName}}",
                },
                {
                    text: "Editor",
                },
            ],
        },
    },
    {
        path: "/schedule/:scheduleId/:recordingId?",
        alias: ["/schedule/:scheduleId/view/:recordingId?"],
        component: SchedulePage,
        meta: {
            auth: true,
            breadcrumb: [
                {
                    path: "/",
                    text: "Testup",
                },
                {
                    path: "/project/{{projectId}}",
                    text: "{{projectName}}",
                },
                {
                    text: "{{scheduleName}}",
                },
            ],
        },
    },
    {
        path: "/viewer/:recordingId",
        component: ViewerPage,
        meta: {
            auth: false,
            hideSidePane: true,
        },
    },
    {
        path: "/viewer/latest/:testcaseId",
        component: ViewerPage,
        meta: {
            auth: false,
            hideSidePane: true,
        },
    },
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
})
