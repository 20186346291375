<script lang="ts" setup>
import { computed, ref } from "vue"
import useEventListener from "@/composables/useEventListener"
import { setAuthToken, isAuthorized } from "@/services/AuthService"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"
import { useRoute } from "vue-router"

const wordpressBase = WORDPRESS_BASE
const route = useRoute()
const showMenu = ref(false)

const form = ref<HTMLFormElement>()

const store = useStore()
const { toggleSidebar } = store
const { sidebarShown } = storeToRefs(store)

useEventListener(document, "click", closeMenu)

const isAuth = computed(isAuthorized)

const hideSidePane = computed(
    () => route.meta?.hideSidePane == true
)

function toggleMenu() {
    showMenu.value = !showMenu.value
}

function closeMenu() {
    if (showMenu.value) showMenu.value = false
}

function signoutButtonPressed() {
    setAuthToken(null)
    form.value?.submit()
}
</script>

<template lang="pug">
.header-component.invisible-in-print
    .header.pl-4.pr-4(v-if="!hideSidePane" :class="{ 'd-none': !sidebarShown }")
        .logo
            router-link.link(to="/")
                img(
                    src="@/assets/images/testup-icon-rahmenlos-300x220.png"
                )
        div
        a.link.documentation.invisible-in-print.header-nav.noUnderline(
            :href="`${wordpressBase}/documentation`",
            target="_blank"
        )
            i.fas.fa-question.fa-2x
            .span-sm.mt-2 Help
        div
        router-link.link.login.invisible-in-print(v-if="!isAuth", to="/autologin")
            h5 Login
        a.link.invisible-in-print.header-nav(v-if="isAuth", :href="`${wordpressBase}/my-account`")
            i.fas.fa-user.fa-2x
            .span-sm.mt-2 Account
        a.link.invisible-in-print.header-nav(v-if="isAuth", href="#", @click.prevent.stop="signoutButtonPressed")
            i.fas.fa-sign-out-alt.fa-2x
            .span-sm.mt-2 Logout
        form.d-none(ref="form", action="https://testup.io/en/my-account/customer-logout", method="post")
        .collapse-handle(@click="toggleSidebar")
            .arrow
    div(v-else)
    .collapse-handle.collapsed-handle(v-if="!sidebarShown && !hideSidePane", @click="toggleSidebar")
        .arrow
</template>

<style lang="css" scoped>
.header {
    position: absolute;
    padding: 30px;
    display: grid;
    grid-template-rows: min-content 1fr min-content min-content;
    align-items: center;
    justify-items: center;
    height: 100vh;
    background-color: white;
}

.collapse-handle {
    position: absolute;
    top: calc(50vh - 30px);
    right: 0;
    z-index: 100;
    width: 25px;
    height: 60px;
    background-color: var(--bright-green);
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrow {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    margin-left: 7px;
}

.collapsed-handle {
    transform: scaleX(-1);
    left: 0px;
    box-shadow: -5px 0 5px -2px #888;
}

@media (min-width: 790px) {
    .header {
        display: grid !important;
        position: static;
    }

    .collapse-handle {
        display: none !important;
    }
}

@media (max-width: 789px) {
    .header {
        border-right: 1px solid #333;
        box-shadow: 5px 0 5px -2px #888;
    }
}

.bcrumb {
    justify-self: start;
}

.link {
    color: var(--black);
}

.link:hover {
    color: rgb(17, 140, 24);
}

.logo,
.bcrumb,
.documentation {
    padding: 5px;
}

.logo img {
    width: 50px;
}

.gradient-bar {
    background-color: var(--black);
    background-image: linear-gradient(160deg, rgb(17, 140, 24) 0%, rgb(242, 116, 5) 100%);
    height: 5px;
}

.login {
    margin-left: 20px;
    margin-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.header-component {
    background-color: white;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: grid;
    position: var(--sibebar-position);
    grid-column: var(--layout-header-col);
    grid-row: var(--layout-header-row);
    max-width: 150px;
    border-right: 1px solid var(--light-grey);
}

.header-nav {
    display: grid;
    place-items: center;
    margin-bottom: 30px;
    text-decoration: none !important;
}
.fa-2x {
    font-size: 25px !important;
}
</style>
