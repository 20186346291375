<script lang="ts" setup>
import {PivotAggregator} from "@/common/pivottable/PivotDataAggregators";
import { PivotTree, PivotHeader, PivotCellData } from "@/common/pivottable/PivotDataHelpers";
import { PivotVariable } from "@/common/pivottable/PivotVariables"
import { router } from "@/router";
import PivotCellDisplay from "@/common/pivottable/PivotCellDisplay.vue";
import { onMounted, computed, ref } from "vue"
import type { LinkedEntitiesDTO } from "@/types/gen";
import { updateHighlight } from "@/services/DomHelperFunctions";

interface Props {
    rows: PivotVariable[]
    columns: PivotVariable[]
    aggregator: PivotAggregator
    data: any[]
    linkedData?: LinkedEntitiesDTO
}
const props = defineProps<Props>()
const tableRoot = ref<HTMLElement>()

onMounted(() => {
})

const rowLabels = computed<PivotHeader[]>(() => build_categories(props.data, props.rows, "_row", 0))
const columnLabels= computed<PivotHeader[]>(() => build_categories(props.data, props.columns, "_col", 0))
const cellData= computed<PivotCellData[]>(() => collateData())

function build_categories(data: any[], variables:PivotVariable[], field:string, yoff:number) : PivotHeader[]{
    if (variables.length==0) {
        for (let row of data) {
            row[field] = yoff
        }
        return []
    } else {
        const allheaders = []
        const classes = new Map<string, PivotTree>()
        const headers = variables[0].sortedHeaders(data)
        for (let header of headers) {
            classes.set(header.key, new PivotTree(variables[0], header.key))
        }
        for (let row of data) {
            const key:string = variables[0].extractKey(row)
            classes.get(key)!.push(row)
        }
        for (let header of headers) {
            const pivotClass = classes.get(header.key)
            const data = pivotClass!.data
            const subHeaders = build_categories(data, variables.slice(1), field, yoff)
            header.y1 = yoff
            for (let sh of subHeaders) {
                sh.x ++
                allheaders.push(sh)
            }
            yoff = subHeaders.length ? subHeaders[subHeaders.length-1]?.y2 : yoff + 1
            header.y2 = yoff
            allheaders.push(header)
        }
        return allheaders
    }
}

function collateData() : PivotCellData[] {
    const data:PivotCellData[] = []
    const numRows = props.data.reduce((b:number,a:any) => Math.max(a._row, b), 0) + 1
    const numCols = props.data.reduce((b:number,a:any) => Math.max(a._col, b), 0) + 1

    for (let i=0; i<numRows; i++) {
        const filtered1 = props.data.filter((x:any) => x._row == i)
        for (let j=0; j<numCols; j++) {
            const filtered2 = filtered1.filter((x:any) => x._col == j)
            data.push(new PivotCellData(j,i,filtered2))
        }
        if (numCols>1)
            data.push(new PivotCellData(numCols, i, filtered1, true))
    }
    if (numRows>1) {
        for (let j=0; j<numCols; j++) {
            const filtered2 = props.data.filter((x:any) => x._col == j)
            data.push(new PivotCellData(j,numRows+1,filtered2, true))
        }
    }
    return data
}

function cellStyle(cell: PivotCellData) {
    const xoff = props.rows.length + 1
    const yoff = props.columns.length + 2
    return `grid-area: ${cell.y + yoff} / ${cell.x + xoff};` + props.aggregator.getStyle(cell.data)
}

function rowLabelStyle(header: PivotHeader) {
    const yoff = props.columns.length + 2
    return `grid-column: ${header.x + 1}; grid-row: ${header.y1 + yoff}/${header.y2+yoff};`
}

function columnLabelStyle(header: PivotHeader) {
    const xoff = props.rows.length + 1
    return `grid-column: ${header.y1 + xoff}/${header.y2+xoff}; grid-row: ${header.x + 1};`
}

function openLink(link:string) {
    if (link)
        window.open(link, '_blank')
}

</script>

<template lang="pug">
.pivottabledata(ref="tableRoot" @mousemove="updateHighlight($event)")
    .header.no-y(v-for="label in columnLabels"
        :style="columnLabelStyle(label)"
        :class="label.style"
        @click="openLink(label.link)"
        v-html="label.html")
    .h-line
    .header.no-x(v-for="label in rowLabels"
        :style="rowLabelStyle(label)"
        :class="label.style"
        @click="openLink(label.link)"
        v-html="label.html")
    .h-line
    pivot-cell-display(v-for="cell in cellData"
        :style="cellStyle(cell)"
        :aggregator="aggregator" :cell="cell" :linkedData="linkedData")
    .cell.no-wrap.p-4(v-if="!data.length") No data
</template>

<style lang="css" scoped>
.pivottabledata {
    display: grid;
    padding: 3px;
    grid-template-columns: repeat(auto-fit, min-content);
}
.header {
    padding: 3px;
}
.h-line {
    background-color: #ccc;
    height: 1px;
    grid-column: 1/ 100;
}
.highlighted {
    background-color: rgba(0,0,0,0.1);
}
.no-wrap {
    white-space: nowrap;
}

.header-rotated {
    writing-mode: vertical-lr;
    transform: rotate(190deg);
    margin-bottom: 0.5ex;
}
</style>
