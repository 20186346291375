<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { getTestData } from "@/common/aqua/AquaService"

interface Props {
    importFromAquaId: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "showMenuToggle"): void
    (event: "importItem", data: any): void
}>()

const items = ref<any[]>([])
const selectedItem = ref(null)

onMounted(async () => await loadItems())

async function loadItems() {
    const aquaTestCaseId = props.importFromAquaId.replace(/^TC/, "")
    const data = (await getTestData(aquaTestCaseId)).data as any
    if (data.Message)
        throw new Error(data.Message)
    items.value = data.ValueSets && data.ValueSets.map((valueSet: any, i: number) => ({
        name: valueSet.Name,
        variables: data["Variables"].map((variable: any, j: number) => ({
            name: variable.Name,
            value: data["Values"][i][j],
        })),
    }))
}

function toggleMenu() {
    emit("showMenuToggle")
}

function importItem() {
    emit("importItem", selectedItem.value)
}
</script>

<template lang="pug">
.modal.show
    .modal-dialog.modal-dialog-centered
        .modal-content
            .modal-header
                h4.modal-title Select an item
                button.close(@click="toggleMenu") &times;
            .modal-body
                select.project-select(v-model="selectedItem" size="10")
                    option(v-for="item in items", :value="item") {{ item.name }}
            .modal-footer
                button.btn.btn-success(@click="importItem", :class="{ disabled: !selectedItem }") Import
    .modal-backdrop.show(style="z-index: -1", @click="toggleMenu")
</template>

<style scoped>

.project-select {
    width: 100%;
}

.disabledOption {
    opacity: 0.4;
}

.disabled {
    pointer-events: none;
}
</style>
