/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AnnotationDTO } from './AnnotationDTO';
import type { FrameDTO } from './FrameDTO';
import type { RecordingMetaDTO } from './RecordingMetaDTO';

export type RecordingDTO = {
    id: number;
    testCaseId: number;
    executionId?: number;
    executionName?: string;
    testCaseName?: string;
    testCasePredecessorId?: number;
    startTime?: string;
    endTime?: string;
    isBaseline: boolean;
    status: RecordingDTO.status;
    executionProgress: number;
    interactive: boolean;
    meta?: RecordingMetaDTO;
    frames: Array<FrameDTO>;
    lastFrameCreationTime?: string;
    annotations: Array<AnnotationDTO>;
};

export namespace RecordingDTO {

    export enum status {
        RUNNING = 'RUNNING',
        FAILED = 'FAILED',
        PASSED = 'PASSED',
    }


}

