<script lang="ts" setup>
import { computed, onMounted, ref, useSlots, watch } from "vue"
import { useRoute } from "vue-router"

interface Props {
    emptyMode?: boolean
    projectId?: number
    projectName?: string
    testcaseName?: string
    testcaseId?: string
    executionName?: string
    scheduleName?: string
    recordingIdDate?: string
    simpleMode?: boolean
}

const props = defineProps<Props>()
const route = useRoute()
const slots = useSlots()

const breadcrumbs = ref<any>([])
const lastBreadcrumb = ref<any>({})
const separator = "&nbsp;/&nbsp;"

onMounted(() => {
    if (!props.emptyMode) _prepareBreadcrumb()
})

const hasSlot = computed(() => !!slots.default)

const extraTag = computed(() => {
    return !!props.testcaseId || !!props.testcaseName
        ? "Test Case"
        : props.executionName
        ? "Execution"
        : props.scheduleName
        ? "Schedule"
        : !!props.projectId || !!props.projectName
        ? "Project"
        : " "
})

watch(
    [
        () => props.projectId,
        () => props.projectName,
        () => props.testcaseName,
        () => props.testcaseId,
        () => props.executionName,
        () => props.scheduleName,
        () => props.recordingIdDate,
        () => props.projectId,
    ],
    _prepareBreadcrumb
)

function _prepareBreadcrumb() {
    const bcrumbs = route.meta.breadcrumb ? (route.meta.breadcrumb as any[]).slice(0) : []
    breadcrumbs.value = bcrumbs.map((item) => {
        return _replaceBreadcrumb(item, {
            projectId: props.projectId,
            projectName: props.projectName,
            testcaseName: props.testcaseName,
            testcaseId: props.testcaseId,
            executionName: props.executionName,
            scheduleName: props.scheduleName,
            recordingIdDate: props.recordingIdDate,
        })
    })
    lastBreadcrumb.value = breadcrumbs.value.length ? breadcrumbs.value.pop() : {}
    breadcrumbs.value.shift()
}

function _replaceBreadcrumb(item: any, dataObj: any) {
    const newItem: any = {}
    if (item.path) {
        newItem.path = _replaceSubitem(item.path, dataObj)
    }
    newItem.text = _replaceSubitem(item.text, dataObj)
    return newItem
}

function _replaceSubitem(subitem: string, dataObj: any) {
    return subitem.replace(/\{\{(.*?)\}\}/g, (fullMatch, m1) => {
        return dataObj[m1] ? dataObj[m1] : m1
    })
}
</script>

<template lang="pug">
h5.bcrumb(:class="[simpleMode ? 'd-inline-flex' : 'breadcrumb', { simple: simpleMode }]")
    .link-container(v-for="breadcrumb in breadcrumbs")
        router-link(:to="breadcrumb.path")
            span.span-sm {{ breadcrumb.text }}
        span.span-sm(v-html="separator")
    span.span-sm.mt-1(v-if="!emptyMode") {{ extraTag }}
    span.span-sm(v-else) &nbsp;
    .right(v-if="hasSlot")
        slot
</template>

<style lang="css" scoped>
.right {
    flex-grow: 10;
}
.right * {
    float: right;
}
.bcrumb {
    min-height: 1em;
    justify-self: left;
}
.bcrumb.simple .router-link,
.bcrumb.simple .router-link-active,
.bcrumb.simple a {
    color: var(--black);
}

.bcrumb.simple .router-link:hover,
.bcrumb.simple .router-link-active:hover,
.bcrumb.simple a:hover {
    color: rgb(17, 140, 24);
}
</style>
