<script lang="ts" setup>
import { computed, ref, onMounted } from "vue"
import PivotTableData  from "@/common/pivottable/PivotTableData.vue"
import PivotTablePlotly from "@/common/pivottable/PivotTablePlotly.vue";
import PivotVariableDisplay from "@/common/pivottable/PivotVariableDisplay.vue";
import AggregatorConfigDisplay from "@/common/pivottable/AggregatorConfigDisplay.vue";
import { PivotVariable, DynamicPivotVariable } from "@/common/pivottable/PivotVariables";
import { AggregatorConfig, PivotAggregator } from "@/common/pivottable/PivotDataAggregators";
import { PivotDataFilter } from "@/common/pivottable/PivotDataHelpers";
import type { LinkedEntitiesDTO } from "@/types/gen";

interface Props {
    showControls: boolean
    variables: PivotVariable[]
    useDynamicVars?: boolean
    aggregators: { name: string; aggregator: PivotAggregator}[]
    data: any[]
    linkedData?: LinkedEntitiesDTO
}

const props = defineProps<Props>()
const dragged = ref<any>(null)
const openMoreVars = ref(false)
const aggregator = ref(props.aggregators[0].name)
const allVariables = ref<PivotVariable[]>([])
const allFilters = ref(new Map<string, PivotDataFilter>())

onMounted(() => {
    allVariables.value = [...props.variables]
})

const dynamicVars = computed(() => extractDynamicVars(props.data))
const filteredData = computed(() => allVariables.value.reduce(
    (filtered: any[], variable:PivotVariable) => allFilters.value.get(variable.key)?.filter(variable, filtered) ?? filtered, props.data))
const currentAggregator = computed(() => props.aggregators.find(x => x.name == aggregator.value)?.aggregator)

function variablesInScope(scope: number) {
    return allVariables.value.filter(
        (v:any) => v.role == scope
    ).sort((x:any, y:any) => x.index - y.index)
}

function extractDynamicVars(data: any[]) : DynamicPivotVariable[] {
    const extracted = new Set<string>()
    const result: DynamicPivotVariable[] = []
    for (let row of data) {
        // run time variables of recordings
        for (let group of ['variables','runtimeVariables']) {
            for (let v of row.meta?.[group] ?? []) {
                if (!extracted.has(group + v.name)) {
                    extracted.add(group + v.name)
                    result.push(new DynamicPivotVariable(v.name, group))
                }
            }
        }
    }
    const allKeys = allVariables.value.map(x => x.key)
    return result.filter(x => !allKeys.includes(x.key))
}

function getFilterForVariable(variable: PivotVariable) {
    if (!allFilters.value.has(variable.key)) {
        allFilters.value.set(variable.key, new PivotDataFilter(() => variable.sortedHeaders(props.data)))
    }
    return allFilters.value.get(variable.key)
}

function startDrag(evt:DragEvent, item:any): void {
    if (evt.dataTransfer) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        dragged.value = item
    }
}

function onDrop(role: string) {
    if (!props.variables.includes(dragged.value)) {
        // dynamically instantiated variable
        if (dragged.value instanceof DynamicPivotVariable) {
            if (role == 'unused') {
                allVariables.value = allVariables.value.filter(x=> x != dragged.value)
            } else if (dragged.value.role == 'unused') {
                allVariables.value.push(dragged.value)
            }
        }
    }
    dragged.value.role = role
    if (dragged.value.indexBefore == null) {
        const draggedIndex = dragged.value.index
        for (let v of allVariables.value) {
            if (v.index >= draggedIndex)
                dragged.value.index = Math.max(dragged.value.index, v.index)
                v.index -= 1
        }
    } else {
        const indexBefore = dragged.value.indexBefore
        for (let v of allVariables.value) {
            if (v.index >= indexBefore) v.index += 1
        }
        dragged.value.index = indexBefore
    }
    dragged.value = null
}

function dragOver(evt:DragEvent, beforeItem:any) {
    if (beforeItem) {
        dragged.value.indexBefore = beforeItem?.index
    } else {
        if (!(evt.target as Element).getAttribute('draggable')) {
            dragged.value.indexBefore = null
        }
    }
}

</script>

<template lang="pug">
.pivottable
    .aggregator(v-if="showControls")
        select.form-control(v-model="aggregator")
            option(v-for="value in aggregators" :value="value.name") {{ value.name }}
        Aggregator-config-display(
            :aggregator="currentAggregator"
            :dynamicVars="dynamicVars"
        )
    .row-first.variable-container.unused-variables-popup(v-if="showControls"
            @drop="onDrop('unused')" @dragover.prevent="dragOver($event, null)")
        .base-vars
            pivot-variable-display.row(v-for="v in variablesInScope('unused')" :key="v.name"
                draggable="true"
                @dragover.prevent="dragOver($event, v)"
                 @dragstart="startDrag($event, v)"
                :variable="v" :filter="getFilterForVariable(v)")
        .more-vars(v-if="openMoreVars")
            hr
            .tu-small Test variables
            pivot-variable-display.row(v-for="v in dynamicVars" :key="v.name"
                draggable="true"
                @dragstart="startDrag($event, v)"
                :variable="v" :filter="getFilterForVariable(v)")
        .more-vars-opener(v-if="useDynamicVars" @click="openMoreVars=!openMoreVars")
            i.fa.fa-1.fa-angle-down(v-if="!openMoreVars")
            i.fa.fa-1.fa-angle-up(v-if="openMoreVars")

    .columns.variable-container.row-first(
        v-if="showControls"
        @dragover.prevent="dragOver($event, null)"
        @drop="onDrop('columns')")
        pivot-variable-display.row(v-for="v in variablesInScope('columns')" :key="v.name"
            draggable="true"
            @dragover.prevent="dragOver($event, v)"
            @dragstart="startDrag($event, v)"
            :variable="v" :filter="getFilterForVariable(v)")

    .rows.variable-container.column-first(
        v-if="showControls"
        @dragover.prevent="dragOver($event, null)"
        @drop="onDrop('rows')")
        pivot-variable-display.column(v-for="v in variablesInScope('rows')" :key="v.name"
            draggable="true"
            @dragover.prevent="dragOver($event, v)"
            @dragstart="startDrag($event, v)"
            :variable="v" :filter="getFilterForVariable(v)")

    .result
        pivot-table-plotly(v-if="currentAggregator?.getConfig()?.showGraph"
            :columns="variablesInScope('columns')"
            :rows="variablesInScope('rows')"
            :aggregator="currentAggregator"
            :data="filteredData"
            :linkedData="linkedData"
        )
        pivot-table-data(v-if="!currentAggregator?.getConfig()?.showGraph"
           :columns="variablesInScope('columns')"
           :rows="variablesInScope('rows')"
           :aggregator="currentAggregator"
           :data="filteredData"
           :linkedData="linkedData"
           )
</template>

<style lang="css" scoped>
.pivottable {
    display: grid;
    min-height: max-content;
    min-width: max-content;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content 1fr;
}
.result {
    grid-area: 3 / 2
}
.variable-container {
    min-height: 2em;
    margin: 2px;
    border: 1px solid lightgray;
}


.row-first .variable {
    display: inline-block;
}

.aggregator {
    min-width: 10em;
    grid-column: 1;
    grid-row: 1/3;
}
.unused-variables {
    grid-column: 2;
    grid-row: 1;
}
.unused-variables-popup {
    display: grid;
    grid-template-columns: 1fr min-content;
}
.more-vars-opener {
    padding: 1ex;
    grid-column: 2;
    grid-row: 1;
}

.columns{
    grid-column: 2;
    grid-row: 2
}
.rows {
    grid-column: 1;
    grid-row: 3;
}
</style>
