/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetExecutionExecution = {
    id: number;
    name: string;
    time: string;
    status?: GetExecutionExecution.status;
};

export namespace GetExecutionExecution {

    export enum status {
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RUNNING = 'RUNNING',
        INTERRUPTED = 'INTERRUPTED',
    }


}

