export default [
    {
        url: /^$/,
        level: 0,
        text: 'Welcome! Please Create a Project to get started by clicking "Create Project"',
    },
    {
        url: /^project/,
        level: 1,
        text: "Great! Now let's edit your project name and confirm your entry.",
    },
    {
        url: /^project/,
        level: 2,
        text: "It is time to create our first test. Click on the Create Test button!",
    },
    {
        url: /^testcase/i,
        level: 3,
        text: "Great! Now let's edit your testcase name and confirm your entry.",
    },
    {
        url: /^testcase/i,
        level: 4,
        text: "Let's start now by editing our test and begin the automation! Press Edit!",
    },
]
