import axios from "axios"

const baseUrl = `/api/v1`
let refreshTimeout = 0
let semaphore = Promise.resolve()
setAuthHeader()

export function setAuthToken(tokenObj) {
    const _idToken = tokenObj?.access_token

    if (_idToken) {
        localStorage.setItem('_idToken', _idToken)
        localStorage.setItem("_refreshToken", tokenObj.refresh_token)
        localStorage.setItem("_expires", Date.now() + tokenObj.expires_in * 1000)
        setAuthHeader()
    } else {
        delete axios.defaults.headers.common["Authorization"]
        delete axios.defaults.headers.common["Auth-Server"]
        localStorage.removeItem("_idToken")
        localStorage.removeItem("_refreshToken")
    }
}

function setAuthHeader() {
    clearTimeout(refreshTimeout)
    const authToken = localStorage.getItem('_idToken')
    const expires = parseInt(localStorage.getItem("_expires"))
    const authServer = "WordPressOAuth"

    if (authToken && expires > Date.now()) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`
        axios.defaults.headers.common["Auth-Server"] = authServer
        refreshTimeout = setTimeout(refreshAuthToken, expires - Date.now() - 10000)
    }
}

export async function refreshAuthToken() {
    await semaphore
    const refreshToken = localStorage.getItem('_refreshToken')
    localStorage.removeItem('_refreshToken')
    if (refreshToken) {
        semaphore = resolveAuthToken(refreshToken, "REFRESH_TOKEN")
        await semaphore
    }
}

export function isAuthorized() {
    return !!localStorage.getItem('_idToken')
}

export async function resolveAuthToken(code, grantType = "AUTHORIZATION_CODE") {
    let redirectUri = window.location.origin + "/"
    try {
        const response= await axios.post(`${baseUrl}/auth/token`, { code: code, grantType: grantType, redirectUri: redirectUri })
        setAuthToken(response.data)
    } catch(e) {
        if (e.response && e.response.status === 503) {
            throw Error("We are experiencing technical difficulties. Please try again later.")
        }
        await loginRedirect()
    }
}

axios.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (originalRequest) {
            if (originalRequest._retryAuth || originalRequest.url.match(/^\/auth/)) await loginRedirect()
            originalRequest._retryAuth = true
            if (error.response && error.response.status === 401) {
                if (localStorage.getItem("_refreshToken")) {
                    await refreshAuthToken().catch(loginRedirect)
                    originalRequest.headers["Authorization"] = `Bearer ${localStorage.getItem("_idToken")}`
                    return axios(originalRequest)
                } else {
                    await loginRedirect()
                }
            }
        }
        throw error
    }
)

export function loginRedirect() {
    setAuthToken(null)
    if (!sessionStorage.getItem('intendedPath'))
        sessionStorage.setItem("intendedPath", location.pathname)
    location.href = "/autologin?redirect_uri=" + encodeURIComponent(location.origin + "/")
    return new Promise((done, reject) =>
        setTimeout(() => reject(Error("Failed to redirect to /autologin")), 10000)
    )
}
