import { ActionDTO, ActionInput, Configuration, ProjectDTO, TestCaseDTO } from "@/types/gen"
import {
    getActions,
    getTestCase,
    saveConfig,
} from "@/services/ProjectService"
import { actionVisibleInBranch, END_ACTION_ID } from "./actionUtils"

export class TestcaseService {

    isLoaded = false
    testCase?: TestCaseDTO
    actions: ActionDTO[] = []
    predecessor?: TestcaseService

    async loadTestCase(testCaseId: number, root=true) {
        const response = await Promise.all([
            getTestCase(testCaseId),
            getActions(testCaseId)
        ])
        this.testCase = response[0]
        this.actions = response[1]
        if (this.testCase?.predecessorId) {
            const predecessor = new TestcaseService()
            await predecessor.loadTestCase(this.testCase.predecessorId, false)
            this.predecessor = predecessor
        } else {
            this.predecessor = undefined
        }
        this.isLoaded = true
    }

    firstAction() : ActionDTO | undefined {
        let first: ActionDTO | undefined
        if (this.predecessor) {
            first = this.predecessor.firstAction()
        }
        if (!first) {
            first = this.actions[0]
        }
        return first
    }

    getTestCase(force = true) : TestCaseDTO {
        if (force && !this.isLoaded)
            throw new Error("Test case data could not be loaded")
        return this.testCase!!
    }

    getActions() : ActionDTO[] {
        return this.actions
    }

    replaceAction(action: ActionDTO) {
        const actionIdx = this.actions.findIndex((ac) => ac.id === action.id)
        if (actionIdx > -1 ) {
            this.actions[actionIdx] = action
        } else {
            this.predecessor?.replaceAction(action)
        }

        if (actionIdx > END_ACTION_ID) {
            this.actions[actionIdx] = action
        }
    }

    getConfig() : Configuration {
        let config= this.getTestCase().config!
        if (!config.overridePredecessor && this.predecessor) {
            config = this.predecessor.getConfig()
        }
        return config
    }

    async saveConfig() {
        await saveConfig(this.getTestCase().id, this.getTestCase().config)
    }

    setVariables(data:any[]) {
        this.testCase!.config!.variables = data
    }

    getActionById(id: number) : ActionDTO {
        let actionObj = this.actions.find((a) => a.id == id)
        if (!actionObj && this.predecessor) {
            actionObj = this.predecessor.getActionById(id)
        }
        if (!actionObj) {
            actionObj = { id: -1 } as ActionDTO
        }
        return actionObj
    }

    getIndex(actionId: number, fromStart: boolean) : number {
        let index = this.actions.findIndex((a) => a.id == actionId)
        if (fromStart && this.predecessor) {
            if (index < 0) {
                return this.predecessor.getIndex(actionId, true)
            } else {
                index = index + this.predecessor.totalActionCount()
            }
        }
        return index
    }

    totalActionCount() : number {
        return this.actions.length + (this.predecessor?.totalActionCount() || 0)
    }

    removeAction(actionId: number) : ActionDTO | undefined {
        let action
        const index = this.actions.findIndex(a => a.id == actionId)
        if (index >= 0) {
            this.actions.splice(index, 1)
            action= this.actions[index]
        } else if (this.predecessor) {
            action = this.predecessor.removeAction(actionId)
            if (!action)
                action = this.actions[0]
        }
        return action
    }

    getDirectParent(actionId: number) : TestcaseService | undefined {
        const action = this.actions.find(a => a.id == actionId)
        if (action)
            return this
        if (this.predecessor)
            return this.predecessor.getDirectParent(actionId)
        return
    }

    getAllHashTagNames(branch: string) : string[] {
        const tagNames = this.getAllHashTagActionInputs(branch).map(input => input.text || '')

        return [... new Set(tagNames)]
    }

    getAllHashTagActionInputs(branch: string) : ActionInput[] {
        return this.actions
                .filter(action => actionVisibleInBranch(action, branch))
                .flatMap(action => action.inputs)
                .filter(input => input.type == ActionInput.type.TAG && input.text && input.text[0] == '#')
    }
}
