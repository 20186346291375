<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed, watch } from "vue"
import {
    getProject,
    getTests,
    getExecution,
    notifyExecution,
    saveExecution,
    deleteExecution,
    saveRecording, getExecutionRecordings, getRecording
} from "@/services/ProjectService"
import { prepareFlattenedTreeOfDependency } from "@/services/TestcaseHelperFunction.js"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import VHeader from "@/common/components/layout/VHeader.vue"
import RecordingCellWithHoverPanel from "@/common/pivottable/RecordingCellWithHoverPanel.vue";
import ExecutionTime from "@/common/components/ExecutionTime.vue"
import VEditorOverlay from "@/common/components/layout/VEditorOverlay.vue"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import TestNameHeader from "@/modules/project/components/TestNameHeader.vue"
import { useRoute, useRouter } from "vue-router"
import { formatDateInterval, formatDateTime } from "@/common/util"
import type {ExecutionDTO, FrameDTO, ProjectDTO, RecordingDTO, TestCaseDTO, AnnotationDTO} from "@/types/gen"
import NotificationResults from "@/modules/execution/components/NotificationResults.vue"
import { getScreenCoordinates } from "@/services/DomHelperFunctions"
import { ExecUpdater } from "@/services/RecordingService"

const route = useRoute()
const router = useRouter()

const emit = defineEmits<{
    (event: "updateStatus", data: { testcaseRunning: boolean }): void
}>()

const project = ref<ProjectDTO>()
const execution = ref<ExecutionDTO>()
const recordings = ref<RecordingDTO[]>([])
const tests = ref<TestCaseDTO[]>([])
const testRecordings = ref<any[]>([])
const frameBorderClass = ref("")
const bgClass = ref("")
const isLatestRecordingBaseline = ref(false)

const failedOnly = ref(true)
const collapsedItems = ref<any>({})
const maxNumberOfRecords = ref(0)
const finishLoadData = ref(false)

const execUpdater = ref<any>(null)
const loadedTests = ref<TestCaseDTO[]>()

onMounted(async () => {
    collapsedItems.value = JSON.parse(localStorage.getItem("collapsedItems") || "{}")
    await reload()
})

onUnmounted(() => {
    if (execUpdater.value) execUpdater.value.destroy()
})

watch(
    () => execution.value,
    () => startExecUpdater()
)

function startExecUpdater() {
    if (execUpdater.value) execUpdater.value.destroy()
    execUpdater.value = new ExecUpdater()

    execUpdater.value.setExecUpdateCallback(async (exec: any, execRecordings: any) => {
        execution.value = exec
        recordings.value = await Promise.all(
            execRecordings
                .map(r => {
                    if (r.status != 'FAILED')
                        return r

                    return getRecording(r.id, r.lastFrameCreationTime)
                })
        )
    })

    execUpdater.value.setFinishedCallback(() => {
        reload()
    })

    execUpdater.value.keepUpdated(execution.value)
}

const flattenedDependencyTree = computed(() => {
    let recordingsSize = 0

    if (!loadedTests.value) return

    tests.value = loadedTests.value
        .map(t => {
            const testRecordings = recordings.value.filter(r => r.testCaseId == t.id) || []
            recordingsSize = Math.max(testRecordings.length, recordingsSize)
            const lastRecording = latestRecording(testRecordings)
            return {
                ...t,
                recordings: testRecordings,
                lastRecording: {
                    ...lastRecording,
                    failedFrame: errorFrame(lastRecording)
                }
            }
        })

    maxNumberOfRecords.value = recordingsSize
    return prepareFlattenedTreeOfDependency(tests.value, collapsedItems.value)
})

const flattenedDependencyTreeFiltered = computed(() => {
    flattenedDependencyTree.value
        .forEach((item: any) => {

            if (getItemByTestcaseId(item.testcase.predecessorId)?.isCollapsed) {
                    item.isCollapsed = true
                    item.isHidden = true
                    return
            }

            if (failedOnly.value) {
                if (!item.isHidden && item.testcase.lastRecording?.status == 'FAILED') {
                    while (item) {
                        item.isHidden = false
                        item = getItemByTestcaseId(item.testcase.predecessorId);
                    }
                } else {
                    item.isHidden = true
                }
            } else {
                item.isHidden = false
            }
        })

    return flattenedDependencyTree.value.filter((item: any) => !item.isHidden )
})

function getItemByTestcaseId(testcaseId: any) {
    return flattenedDependencyTree.value.find((item: any) => item.testcase.id == testcaseId)
}

function countItemsByPredecessor(item: any): any {
    return getItemsByPredecessor(item).length
}

function getItemsByPredecessor(item: any): any {
    let subitems = getItemsByPredecessorId(item.testcase.id)
    return subitems.reduce((items, subitem) => {
        if (subitem.testcase.id === item.testcase.id) {
            return items
        } else {
            return items.concat(getItemsByPredecessor(subitem));
        }
    }, subitems)
}

function getItemsByPredecessorId(testcaseId: any) {
    return flattenedDependencyTree.value.filter((item: any) => item.testcase.predecessorId == testcaseId)
}

const containsBaseline = computed(() => {
    return testRecordings.value ? testRecordings.value.filter((r) => r.latestRecording.isBaseline).length : false
})

const execStartTime = computed(() => {
    let startTime = 0
    execRecordings.value.forEach((r: any) => {
        const recStartTime = new Date(r.startTime).getTime()
        startTime = !startTime ? recStartTime : Math.min(recStartTime, startTime)
    })
    return startTime
})

const execEndTime = computed(() => {
    let endTime = 0
    execRecordings.value.forEach((r: any) => {
        endTime = Math.max(new Date(r.endTime).getTime(), endTime)
    })
    return endTime
})

const execTotalTime = computed(() => execEndTime.value - execStartTime.value)

const execRunTime = computed(() => {
    let runTime = 0
    execRecordings.value.forEach((r: any) => {
        runTime += new Date(r.endTime).getTime() - new Date(r.startTime).getTime()
    })
    return runTime
})

const execRecordings = computed(() => {
    return recordings.value
})

async function reload() {
    const executionId = parseInt(route.params.executionId as string)
    execution.value = await getExecution(executionId)

    const response = await getExecutionRecordings(execution.value.id)
    loadedTests.value = response.linkedEntities.testCases

    const recordingsWithFrames = await Promise.all(
        response.recordings
        .map(r => {
            if (r.status != 'FAILED')
                return r

            return getRecording(r.id, r.lastFrameCreationTime)
        })
    )

    recordings.value = recordingsWithFrames
    document.title = execution.value.name!

    if (execution.value?.status == 'RUNNING') {
        failedOnly.value = false
    }

    await loadProject()

    finishLoadData.value = true
}

async function loadProject() {
    project.value = await getProject(execution.value!.projectId)
}

function latestRecording(recordings: any[]) {
    if (recordings.length < 1) {
        return null
    }
    return recordings[0]
}

function errorFrame(recording: RecordingDTO): FrameDTO | undefined {
    if (recording?.status === 'FAILED') {
        const lastFrame = recording.frames[recording.frames.length - 1]
        if ((lastFrame?.errorMessage?.length || 0) > 0) {
            return lastFrame
        }

        return recording.frames
            .find(f => (f?.errorMessage?.length || 0) > 0)
    }

    return undefined
}

function runTest(testcase: any) {
    router.push("/run/" + testcase.id)
}

function goToTest(testcase: any) {
    router.push(`/testcase/${testcase.id}`)
}

function saveAllRecordingsAsBaseline() {
    isLatestRecordingBaseline.value = !isLatestRecordingBaseline.value
    testRecordings.value.forEach((item) =>
        saveRecording(item.latestRecording.id, { isBaseline: isLatestRecordingBaseline.value })
    )
}
async function doSaveExecution({ id, name }: ExecutionDTO) {
    await saveExecution(id, { name: name! })
}

async function doNotifyExecution() {
    await notifyExecution(execution.value!.id)
}

async function doDeleteExecution() {
    if (project.value) {
        await deleteExecution(execution.value!)
        router.push(`/project/${project.value.id}`)
    }
}

function colorClassChanged({ frameBorderClass: newFrameBorderClass, bgClass: newBgClass }: any) {
    frameBorderClass.value = newFrameBorderClass
    bgClass.value = newBgClass
}

async function recordingUpdated(testcase: any, { recording, finished }: any) {
    if (finished) emit("updateStatus", { testcaseRunning: false })
    if (recording) {
        const testToUpdate = testRecordings.value.find((test) => test.recordingId == recording.id)
        testToUpdate.latestRecording = testToUpdate.latestRecording || {}
        Object.assign(testToUpdate.latestRecording, recording)
    } else {
        await reload()
    }
}

function goBack() {
    if (project.value) {
        router.push(`/project/${project.value.id}`)
    }
}

function toggleFailedOnlyFilter() {
    failedOnly.value = !failedOnly.value
}

function getEndTime(testcase: any) {
    if (testcase.recordings.length < 1) {
        return ''
    }

    let endTime = testcase.recordings[0].startTime
    let endTimeObj = new Date(endTime)

    testcase
        .recordings
        .forEach((r: RecordingDTO) => {
            if (endTimeObj < new Date(r.endTime!!)) {
                endTimeObj = new Date(r.endTime!!)
                endTime = r.endTime
            }
        })

    return endTime
}

function lastFrame(recording: RecordingDTO): FrameDTO | null {
    if (recording.frames.length < 1) {
        return null
    }
    return recording.frames[recording.frames.length - 1]
}

function getSpanArray(testcase: any) {
    return new Array(Math.max(maxNumberOfRecords.value - testcase.recordings.length, 0))
}

function openViewerWithFrame(event: MouseEvent, recordingId: number, frameIndex?: number) {
    const newPath = `/execution/${execution?.value?.id}/view/${recordingId}`
    
    const point = getScreenCoordinates(event)
    sessionStorage.setItem('animate', JSON.stringify([point.x, point.y]))
    
    router.push({ 
        path: newPath,
        query: { n: frameIndex }
     })
}

function lastPlayedTag(testFlatten: any): string | null  {
    const tagText = testFlatten.testcase?.lastRecording?.meta.lastPlayedTag
    if (tagText?.length < 1 || tagText?.indexOf("#") == 0) {
        return null
    }

    return tagText
}

function dropDownItems(testcase: any) {
    return [{
            iconClass: ["fas", "fa-edit"],
            text: "Go to Editor",
            callback: () => runTest(testcase),
        },
        {
            iconClass: ["fas", "fa-edit"],
            text: "Open test case",
            callback: () => goToTest(testcase)
        }]
}

const getLastRecording = (testFlatten: any) => testFlatten.testcase?.lastRecording
const getStatus = (testFlatten: any) => getLastRecording(testFlatten)?.status
const getFailedFrame = (testFlatten: any) => getLastRecording(testFlatten)?.failedFrame
const getAnnotations = (testFlatten: any) => getLastRecording(testFlatten)?.annotations
const canShow = (testFlatten: any) => !failedOnly.value || getStatus(testFlatten) == 'FAILED'

const numberOfTestsRunning = computed(() => flattenedDependencyTree.value.filter(t => getStatus(t) != 'PASSED' && getStatus(t) != 'FAILED').length)

</script>

<template lang="pug">
.execution-page-view.avoid-print-break
    v-editor-overlay(:componentToExecute="'viewer'" @reload="reload")
    detailed-view(:tabNames="['Recordings', 'Info']" :frameBorderClass="frameBorderClass" :bgClass="bgClass")
        template(#navigate-back)
            v-navigate-back(@goBack="goBack")
        template(#breadcrumb)
            v-breadcrumb(
                v-if="project && execution"
                :projectId="project.id"
                :projectName="project.name"
                :executionName="execution.name"
                :simpleMode="true"
            )
        template(#title)
            h4
                v-editable-text(
                    v-if="execution"
                    v-model="execution.name"
                    @update:modelValue="doSaveExecution(execution)"
                )
        template(#button-group)
            v-action-button.invisible-in-print(:mainIconClass="['fas', 'fa-sign-out-alt']" :mainDisabled="!project")
                a.dropdown-item(@click="reload")
                    .dropdown-item-icon
                        i.fas.fa-sync-alt
                    span Reload
                a.dropdown-item(@click="saveAllRecordingsAsBaseline")
                    .dropdown-item-icon
                        i.fas.fa-star(v-if="isLatestRecordingBaseline")
                        i.far.fa-star(v-else)
                    span Set As Baseline
                a.dropdown-item(@click="doNotifyExecution")
                    .dropdown-item-icon
                        i.fas.fa-paper-plane
                    span Send manual notifications
                a.dropdown-item(@click="doDeleteExecution" :class="{ disabled: containsBaseline }")
                    .dropdown-item-icon
                        i.fas.fa-trash
                    span Delete
        template(#tab0)
            .executions.tu-tab-area-left-margin
                .container-block.recording-list.pt-4.pb-5(v-if="finishLoadData")
                    div
                        div.execution-run.passed(v-if="execution.status == 'PASSED'")
                            span.normal-text Your Execution
                            span.passed Passed!
                        div.execution-run.failed(v-if="execution.status == 'FAILED'")
                            span.normal-text Your Execution
                            span.failed Failed!
                        div.testcases-size
                            span Number of tests:
                            b {{ flattenedDependencyTree.length }}
                        div
                            span Failed tests:
                            b {{ flattenedDependencyTree.filter(t => getStatus(t) == 'FAILED').length }}
                        div
                            span Passed tests:
                            b {{ flattenedDependencyTree.filter(t => getStatus(t) == 'PASSED').length }}
                        div(v-if="numberOfTestsRunning > 0")
                            span Running tests:
                            b {{ numberOfTestsRunning }}
                    .main(v-if="tests.length > 0")
                        h5.filter-input
                            .input-group(@click="toggleFailedOnlyFilter()")
                                input.form-control(type="checkbox" :checked="failedOnly")
                                label(for="checkbox") &nbsp; Only show failed records
                        template(v-if="flattenedDependencyTreeFiltered.length > 0" v-for="(testFlatten, index) in flattenedDependencyTreeFiltered", :key="testFlatten.testcase.id")
                            test-name-header(
                                :testFlatten="testFlatten",
                                :project="project"
                                :countItemsByPredecessor="countItemsByPredecessor(testFlatten)",
                                :dropDownItems="dropDownItems(testFlatten.testcase)",
                                :parentTest="getItemByTestcaseId(testFlatten.testcase.predecessorId)"
                            )
                            .span-block
                            template(v-if="canShow(testFlatten) && testFlatten.testcase.recordings.length > 0")
                                .result-row(v-for="(recording, ind) in testFlatten.testcase.recordings", style="min-width: 13px")
                                    recording-cell-with-hover-panel(
                                        :recording="recording"
                                        :testCaseName="testFlatten.testcase.name"
                                        :executionName="execution.name"
                                        :height="30"
                                        :width="ind == 0 ? 30 : 5"
                                        @click.prevent="openViewerWithFrame($event, recording.id)"
                                    )
                                .result-row(v-for="ind in getSpanArray(testFlatten.testcase)", style="min-width: 13px")
                                    .mock-div
                            .span-block
                            div(v-if="canShow(testFlatten)")
                                execution-time(
                                    v-if="testFlatten.testcase.recordings.length > 0"
                                    fontSize="medium"
                                    marginRight="medium"
                                    :recording="getLastRecording(testFlatten)"
                                    :hideDate="true"
                                )
                                template(v-if="getStatus(testFlatten) == 'FAILED'")
                                    div
                                        i.fas.fa-quote-right &nbsp;
                                        span {{ getFailedFrame(testFlatten)?.errorMessage }}
                                    div(v-if="lastPlayedTag(testFlatten)")
                                        i.fas.fa-tag &nbsp;
                                        span {{ lastPlayedTag(testFlatten) }}
                            .span-block
                            template(v-if="getStatus(testFlatten) == 'FAILED'")
                                img.frame-preview(@click="openViewerWithFrame($event, getLastRecording(testFlatten)?.id)" :src="getFailedFrame(testFlatten)?.thumbnailUrl")
                            .span-block
                            .annotations-block(v-if="getAnnotations(testFlatten)?.length > 0")
                                div(v-for="annotation in getAnnotations(testFlatten)")
                                    i.far.fa-comment-dots &nbsp;
                                    span.annotation(@click="openViewerWithFrame($event, annotation.recordingId, annotation.content.frameIndex)") {{ annotation?.content?.text }}
        template(#tab1)
            .tu-tab-area.bg-very-light-grey1.pt-4.pl-4.pr-4(
                :class="[frameBorderClass, { 'tu-border-top': frameBorderClass }, { 'tu-border-bottom': frameBorderClass }, { 'pb-2': !frameBorderClass }]"
            )
                h4 Start time: {{ formatDateTime(execStartTime) }}
                h4 Total time: {{ formatDateInterval(execTotalTime) }}
                h4 Server time: {{ formatDateInterval(execRunTime) }}
                h4 Number of actions: {{ execution?.meta?.activeActionsCount }}

                notification-results.mt-3(
                    :notifications="execution.meta.notificationResults")
</template>

<style lang="css" scoped>
.frame-preview {
    width: 100px;
    cursor: pointer;
    padding: 2px;
}
.annotations-block {
    width: 200px;
}
.annotations-block .annotation {
    cursor: pointer;
}
.span-block {
    width: 1em;
}
.divider {
    background-color: var(--black);
}
.executions {
    margin-top: 10px;
    min-height: max-content;
}
.container-block {
    min-height: max-content;
    min-width: max-content;
}
.main {
    display: grid;
    grid-template-columns: max-content repeat(1000, max-content);
    grid-gap: 0;
    min-width: max-content;
    min-height: max-content;
}
.result-row {
    display: grid;
    grid-template-columns: repeat(1000, max-content);
    place-self: flex-start;
    margin-bottom: auto;
    position: relative;
}
.filter-input {
    padding-top: 1rem;
}
.execution-run {
    border: 1px solid var(--light-grey);
    padding: 5px;
    display: inline-block;
    border-left: solid 10px var(--status-color);
}
.normal-text {
    --status-color: var(--black)
}
.execution-run > span {
    color: var(--status-color)
}
.testcases-size {
    padding-top: 1rem;
}
</style>
