<script lang="ts" setup>
import VActionButton from "@/common/components/form/VActionButton.vue"

interface Props {
    deviceConnected?: boolean
    deviceStatus?: any
    isCurrentAction?: boolean
    actionDisabled?: boolean
    canEdit?: any
    isInPredecessor?: boolean,
    showInlineButton?: boolean
}

defineProps<Props>()
const emit = defineEmits(["delete", "jump", "splitTestCase", "inlineTestCase", "disableAction", "enableAction", "openPredecessor", "openActionInEditor", "complain"])

function removeAction() {
    emit("delete")
}

function jumpAction() {
    emit("jump")
}

function disableAction() {
    emit("disableAction")
}

function enableAction() {
    emit("enableAction")
}

function doComplain() {
    emit("complain")
}

</script>

<template lang="pug">
.action-context-menu
    v-action-button
        template(v-if="!deviceStatus || !deviceStatus.isDummy")
            a.dropdown-item(v-if="deviceConnected", @click="jumpAction", :class="{ disabled: isCurrentAction }")
                .dropdown-item-icon
                    i.fas.fa-map-marker-alt
                span Jump here
            a.dropdown-item(v-if="!actionDisabled && !isInPredecessor", @click="$emit('splitTestCase')")
                .dropdown-item-icon
                    i.fas.fa-object-ungroup
                span Split test here
            a.dropdown-item(v-if="!deviceConnected", @click="$emit('openPredecessor')")
                .dropdown-item-icon
                    i.fas.fa-edit
                span Open Predecessor
            a.dropdown-item(v-if="showInlineButton", @click="$emit('inlineTestCase')")
                .dropdown-item-icon
                    i.fas.fa-object-group
                span Inline test case
            a.dropdown-item(v-if="!actionDisabled && canEdit", @click="disableAction")
                .dropdown-item-icon
                    i.fas.fa-ban
                span Disable
            a.dropdown-item(v-if="actionDisabled && canEdit", @click="enableAction")
                .dropdown-item-icon
                    i.far.fa-circle
                span Enable
            a.dropdown-item(v-if="canEdit", @click="removeAction")
                .dropdown-item-icon
                    i.fas.fa-trash
                span Delete
        template(v-else)
            a.dropdown-item(@click="$emit('openActionInEditor')")
                .dropdown-item-icon
                    i.fas.fa-edit
                span {{ isInPredecessor ? "Edit in Predecessor" : "Edit in editor" }}
            a.dropdown-item(v-if="isCurrentAction", @click="doComplain()")
                .dropdown-item-icon
                    i.fas.fa-exclamation-triangle
                span Image Detection Wrong
</template>

<style lang="css" scoped>
.dropdown-item.disabled {
    opacity: 0.5;
}
</style>
