/* eslint-disable no-restricted-globals */

const workercode = () => {
    let timerInterval = null
    let time = 0

    self.onmessage = function ({ data: { action, intervalValue } }) {
        if (action === "clearInterval") {
            clearInterval(timerInterval)
            timerInterval = null
            time = 0
            self.close()
        }
        if (action === "setInterval") {
            timerInterval = setInterval(() => {
                time += intervalValue
                self.postMessage({ time })
            }, intervalValue)
        }
    }
}

let code = workercode.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))

const blob = new Blob([code], { type: "application/javascript" })
const worker_script = URL.createObjectURL(blob)

export default worker_script;
