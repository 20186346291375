<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"

interface Props {
    isSwipe: boolean
    path?: string
}

const props = defineProps<Props>()

const repeatcount = ref("1")

</script>

<template lang="pug">
svg(v-if="path"
    :key="path"
)
    image.cursor(
        x="-50"
        y="-50"
        href="@/assets/images/cursor/ripple.green.png"
        width="100"
        height="100"
        opacity="0"
    )
        animateMotion(
            :path="path",
            dur="1s",
            begin="0s",
            fill="freeze",
            keyPoints="0; 0; 1; 1",
            keyTimes="0; 0.25; 0.75; 1",
            calcMode="linear",
            :repeatCount="repeatcount"
        )

        animateTransform(
            v-if="isSwipe",
            attributeName="transform",
            type="scale",
            additive="sum",
            values="0 0; 1 1; 1 1; 2 2",
            keyTimes="0; 0.25; 0.75; 1",
            begin="0s",
            dur="1s",
            :repeatCount="repeatcount"
        )
        animateTransform(
            v-else,
            attributeName="transform",
            type="scale",
            additive="sum",
            values="0 0; 3 3",
            keyTimes="0; 1",
            begin="0s",
            dur="1s",
            :repeatCount="repeatcount"
        )

        animate(
            v-if="isSwipe",
            attributeName="opacity",
            values="0; 1; 1; 0"
            begin="0s",
            dur="1s",
            :repeatCount="repeatcount"
        )
        animate(
            v-else,
            attributeName="opacity",
            values="1; 0"
            begin="0s",
            dur="1s",
            :repeatCount="repeatcount"
        )
</template>

<style scoped>
.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: radial-gradient(ellipse at center, rgba(255, 113, 12, 0) 60%, rgba(255, 113, 12, 1) 51.5%);
    opacity: 0;
}
</style>
