/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ActivityLogDTO = {
    id: number;
    username: string;
    projectId: number;
    message: string;
    time: string;
    activityType: ActivityLogDTO.activityType;
    resourceId?: number;
};

export namespace ActivityLogDTO {

    export enum activityType {
        TESTCASE_CREATED = 'TESTCASE_CREATED',
        TESTCASE_DELETED = 'TESTCASE_DELETED',
        TESTCASE_EDITED = 'TESTCASE_EDITED',
        TESTCASE_CONFIGURED = 'TESTCASE_CONFIGURED',
        TESTCASE_MOVED = 'TESTCASE_MOVED',
        NOTIFICATION_SENT = 'NOTIFICATION_SENT',
        RECORDING_DELETED = 'RECORDING_DELETED',
        RECORDINGS_BULK_DELETE = 'RECORDINGS_BULK_DELETE',
        SCHEDULE_CREATED = 'SCHEDULE_CREATED',
        SCHEDULE_UPDATED = 'SCHEDULE_UPDATED',
        SCHEDULE_DELETED = 'SCHEDULE_DELETED',
        SCHEDULE_RUN = 'SCHEDULE_RUN',
    }


}

