<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VDeleteButton from "@/common/components/form/VDeleteButton.vue"
import VActionButton from "../form/VActionButton.vue";
import type { AnnotationDTO } from "@/types/gen";

interface Props {
    annotation: AnnotationDTO
}
const props = defineProps<Props>()

const emit = defineEmits<{
    (event: "deleteAnnotation", data: AnnotationDTO): void,
    (event: "updateAnnotation", data: AnnotationDTO): void,
    (event: "retakeAnnotation", data: AnnotationDTO): void
}>()

const annotationContent = ref(props.annotation.content.text)
const annotationElement = ref<HTMLElement>()

const dropDownItems = computed(() => {
    return [
        {
            iconClass: ["fas", "fa-image"],
            text: "Retake Annotation",
            callback: () => {
                emit('retakeAnnotation', props.annotation)
            },
        },
        {
            iconClass: ["fas", "fa-trash"],
            text: "Delete Annotation",
            callback: () => {
                emit('deleteAnnotation', props.annotation)
            },
        },
    ]
})

async function doSaveAnnotation(annotationText: string) {
    props.annotation.content.text = annotationText
    emit('updateAnnotation', props.annotation)
}

</script>

<template lang="pug">
.annotation-content(
    ref="annotationElement"
    :class="{ 'm-0': true }"
)
    v-editable-text(
        v-model="annotationContent", 
        noIcon=false,
        @update:modelValue="doSaveAnnotation"
        )
    div
        v-action-button.invisible-in-print(
                :secondIconClass="['fas', 'fa-edit']",
                :thirdIconClass="['fas', 'fa-sign-out-alt']",
                :dropDownItems="dropDownItems"
            )
</template>

<style lang="css" scoped>
.annotation-content ::v-slotted(.link) {
    cursor: pointer;
    color: var(--plum);
    font-weight: bold;
    text-decoration: none;
}
.annotation-content {
    transform: rotate(-2deg);
    background-color: var(--bright-orange);
    width: max-content;
    box-shadow: 3px 5px 5px #4444;
    display: grid;
    grid-auto-flow: column;
    user-select: none;
}
.annotation-content > div {
    padding: 10px;
}
.annotation-context-menu {
    background-color: white;
    cursor: pointer;
}
.annotation-content:hover {
    transform: rotate(-1.5deg) scale(1.05);
}
.dropdown-toggle {
    background-color: var(--white);
}
.dropdown-toggle::after {
    content: none;    
}
</style>
