import { useStore } from "@/store/useStore"
import { Directive } from "vue"

const state = new WeakMap()
let clickTimer:any

const movableDirective: Directive = {
    beforeMount: (el: HTMLElement) => {
        const store = useStore()

        el.addEventListener("mousedown", dragMouseDown)
        const data = {
            dragStartTime: 0,
            target: null as HTMLElement | null,
            clientX: 0,
            clientY: 0,
        }

        state.set(el, { dragFunc: dragMouseDown })

        function dragMouseDown(event: MouseEvent) {
            data.dragStartTime = Date.now()
            data.target = event.target as HTMLElement
            event.preventDefault()
            // get the mouse cursor position at startup:
            data.clientX = event.clientX
            data.clientY = event.clientY
            clickTimer = setTimeout(() => {
                store.setDragged(true)
            }, 300)
            document.addEventListener("mousemove", elementDrag)
            document.addEventListener("mouseup", closeDragElement)
        }

        function elementDrag(event: MouseEvent) {
            event.preventDefault()
            event.stopPropagation()
            // set the element's new position:
            const moveX = event.clientX - data.clientX
            const moveY = event.clientY - data.clientY
            data.clientX = event.clientX
            data.clientY = event.clientY
            el.style.left = el.offsetLeft + moveX + "px"
            el.style.top = el.offsetTop + moveY + "px"
            el.style.position = "absolute"
            el.dataset.movedLeft = el.style.left
            el.dataset.movedTop = el.style.top
        }

        function closeDragElement(event: MouseEvent) {
            event.preventDefault()
            event.stopPropagation()
            document.removeEventListener("mousemove", elementDrag)
            document.removeEventListener("mouseup", closeDragElement)
            const dragTime = Date.now() - data.dragStartTime
            const target = data.target
            clearTimeout(clickTimer)
            setTimeout(() => {
                store.setDragged(false)
                if (dragTime < 300) target?.click()
            }, 100)
        }
    },
    beforeUnmount: (el) => {
        const data = state.get(el)
        if (data.dragFunc) el.removeEventListener("mousedown", data.dragFunc)
    },
}

export default movableDirective
