import { PivotVariable } from "@/common/pivottable/PivotVariables"
import { computed, ComputedRef, Ref, watchEffect } from "vue"

export class PivotTree {
    variable: PivotVariable
    value: string
    size: number = 0
    data: any[] = []

    constructor(variable: PivotVariable, value: string) {
        this.variable = variable
        this.value = value
    }

    push(row: any) {
        this.data.push(row)
        this.size ++
    }
}

export class PivotHeader {
    key: string
    x: number = 0
    y1: number = 0
    y2: number = 1
    html: string
    link?: string
    style: any = {}

    constructor(key:string, html?:string, link?:string, options:any = {}) {
        this.key = key
        this.html = html ?? key
        this.link = link
        this.style = {
            'no-warp' : true,
            'tu-cursor-pointer':!!link,
            ...options
        }
    }
}

export class PivotCellData {
    x: number = 1
    y: number = 1
    isSummary: boolean
    data: any[] = []

    constructor(x:number, y:number, data:any[], isSummary=false) {
        this.x = x
        this.y = y
        this.data = data
        this.isSummary = isSummary
    }
}

export class LinkedDataHelper {

    entities = new Map<string, {index: number, entity: any}>()

    constructor(linkedEntities: Ref<any>, field: string) {
        watchEffect(() => {
            const data = linkedEntities.value[field] ?? []
            let index=0
            if (data) {
                for (let entity of data) {
                    this.entities.set( (entity['id']!.toString()), {
                        index, entity
                    })
                    index ++
                }
            }
        })
    }

    sort(data : PivotHeader[]) {
        data.sort((a:PivotHeader, b:PivotHeader) =>
            (this.entities.get(a.key.toString())?.index || 0) -
            (this.entities.get(b.key.toString())?.index || 0))
    }

    getName(key: string) {
        return this.entities.get(key)?.entity?.name ?? key
    }

    getEntity(key: string) {
        return this.entities.get(key)?.entity
    }
}

export class PivotDataFilter {

    active = false
    levels = new Map<string, { enabled: boolean, header: PivotHeader}>()
    updateCallback : (() => PivotHeader[])

    constructor(callback: () => PivotHeader[]) {
        this.updateCallback = callback
    }

    update() {
        const headers = this.updateCallback()
        for (let header of headers) {
            if (!this.levels.has(header.key)) {
                this.levels.set(header.key, { enabled: true, header })
            } else {
                this.levels.get(header.key)!.header = header
            }
        }
        this.active = true
    }

    filter(variable: PivotVariable, data : any[]) : any[] {
        if (!this.active) return data
        return data.filter(row => this.levels.get(variable.extractKey(row))?.enabled ?? true)
    }
}