<script lang="ts" setup>
import { computed } from "vue"

interface Props {
    testcase?: any
    noMargin?: boolean
    showCancel?: boolean
    currentActionObj?: any
}

const props = defineProps<Props>()

const webBrowser = computed(() => {
    if (!props.testcase?.config) return false
    const systemName = props.testcase.config.systemName
    return systemName ? systemName.includes("Website") : true
})

const showHelperText = computed(() => {
    if (props.currentActionObj && props.currentActionObj.inputs && props.currentActionObj.inputs.length > 0) {
        const helpText = getHelpText(props.currentActionObj.inputs[0].type)
        const helperTextObj: any = {
            TEXT: helpText,
            MARKER: "Add Tag. (Include # for jump labels)",
            CONTROL: helpText,
        }
        return helperTextObj[props.currentActionObj.type]
    }
    return undefined
})

function getHelpText(inputType: any) {
    const jumpHint = "Add jump label (without #)"
    if (inputType == "SCROLL") {
        return (webBrowser.value)
            ? "Enter the number of pixels to scroll by"
            : "Enter a number to rotate the mouse wheel to"
    }
    const helpTextObj: any = {
        ENTER: "Enter text and press enter",
        PASSWORD: "Enter password and press enter",
        WAIT: "Enter waiting time in seconds and press enter",
        NAVIGATE_URL: "Enter URL and press enter",
        FIND_IMAGE: " ",
        EVAL: "Enter text to put into clipboard",
        COMPARE_EQUAL: "Enter text to compare the clipboard with",
        JUMP: jumpHint,
        JUMP_IF_TRUE: jumpHint,
        JUMP_IF_FALSE: jumpHint,
    }
    return helpTextObj[inputType]
}
</script>

<template lang="pug">
.help-text-content(
    :class="{ 'm-0': noMargin }",
    v-if="!currentActionObj || currentActionObj.type == 'POINTER' || showHelperText"
)
    span.text-pos {{ showHelperText }}
    div
        slot
    .cancel(v-if="showCancel", @click.prevent.stop="$emit('cancel')")
        | &times;
</template>

<style lang="css" scoped>
.help-text-content ::v-slotted(.link) {
    cursor: pointer;
    color: var(--plum);
    font-weight: bold;
    text-decoration: none;
}
.help-text-content {
    transform: rotate(-2deg);
    background-color: var(--bright-orange);
    width: max-content;
    box-shadow: 3px 5px 5px #4444;
    display: grid;
    grid-auto-flow: column;
    user-select: none;
}
.help-text-content > div {
    padding: 10px;
}
.cancel {
    background-color: white;
    cursor: pointer;
}
.help-text-content:hover {
    transform: rotate(-1.5deg) scale(1.05);
}
.text-pos {
    margin-left: 5px;
    margin-top: 12px;
}
</style>
