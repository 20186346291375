/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetExecutionExecution } from './GetExecutionExecution';

export type GetExecutionRecording = {
    id: number;
    testCaseId: number;
    startTime?: string;
    endTime?: string;
    isBaseline: boolean;
    recordingStatus: GetExecutionRecording.recordingStatus;
    executionProgress: number;
    isInteractive: boolean;
    execution?: GetExecutionExecution;
};

export namespace GetExecutionRecording {

    export enum recordingStatus {
        RUNNING = 'RUNNING',
        FAILED = 'FAILED',
        PASSED = 'PASSED',
    }


}

