<script lang="ts" setup>
import {
    ref
} from "vue"
import { computed } from "vue"
import CursorPointer from "@/common/components/overlays/CursorPointer.vue"
import PointerAnimation from "@/common/components/overlays/PointerAnimation.vue";
import type { ActionInput } from "@/types/gen";

interface Props {
    frame: any
    scaleCoeff: any
    showHandles?: any
    clicks?: any
    swipe?: any
    ocr?: any
    dragging?: any
    isEditor?: any
    strokeGray?: any
    isHighlighted?: any
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "mousedownHandle", evt:UIEvent, correctionCorner: any): void
    (event: "mousedownCursorPointer", evt:UIEvent, step: ActionInput): void
}>()
defineExpose({

})

const currentFrame = ref<any>(null)
const ocrRect = computed(() => {
    const pos0 = toAbsolute(props.ocr[0])
    const pos1 = toAbsolute(props.ocr[1])
    return new DOMRect(
        Math.min(pos0[0], pos1[0]) * props.scaleCoeff,
        Math.min(pos0[1], pos1[1]) * props.scaleCoeff,
        Math.abs(pos0[0] - pos1[0]) * props.scaleCoeff,
        Math.abs(pos0[1] - pos1[1]) * props.scaleCoeff,
    )
})

function mousedownHandle(evt: UIEvent, correctionCorner: any) {
    (evt as any)['noClick'] = true
    emit("mousedownHandle", evt, correctionCorner)
}

function mousedownCursorPointer(evt: UIEvent, click: any) {
    emit("mousedownCursorPointer", evt, click)
}

function toAbsolute(click: any) {
    if (click.isAbsolute) {
        return [click.x, click.y]
    }
    return [click.x * (props.frame.x1 - props.frame.x0) + props.frame.x0,
            (click.y * (props.frame.y1 - props.frame.y0) + props.frame.y0)]
}

function getAbsolute(click: any) {
    const coord= toAbsolute(click)
    return coord[0] * props.scaleCoeff + ',' + coord[1] * props.scaleCoeff
}

function getPath(input: any[]) {
    return input
        .map((step: any) => {
            switch (step.type) {
                case "CLICK":
                    return "M" + getAbsolute(step)
                case "RIGHT_CLICK":
                    return "M" + getAbsolute(step)
                case "DOUBLE_CLICK":
                    return "M" + getAbsolute(step)
                case "HOVER":
                    return "M" + getAbsolute(step)
                case "SWIPE":
                    return "L" + getAbsolute(step)
                case "OCR":
                    return getAbsolute(step)
                default:
                    return ""
            }
        })
        .join(" ")
}

</script>

<template lang="pug">
pointer-animation(
    v-if="swipe || clicks"
    :style="{ top: 0 }"
    :path="getPath(swipe|| clicks)",
    :isSwipe="(swipe || clicks).length > 1"
)
rect.frame.stroke-black.hide-in-iframe(
    v-if="frame"
    :class="{'stroke-gray' : strokeGray, 'highlighted': isHighlighted}",
    :x="Math.min(frame.x1, frame.x0) * scaleCoeff",
    :y="Math.min(frame.y1, frame.y0) * scaleCoeff",
    :width="Math.abs(frame.x1 - frame.x0) * scaleCoeff",
    :height="Math.abs(frame.y1 - frame.y0) * scaleCoeff"
)
rect.frame.stroke-green(
    v-if="ocr && ocr.length > 1"
    :x="ocrRect.x",
    :y="ocrRect.y",
    :width="ocrRect.width",
    :height="ocrRect.height"
)
g(v-if="showHandles && frame")
    circle.handle(
        :cx="frame.x0 * scaleCoeff",
        :cy="frame.y0 * scaleCoeff",
        r=8,
        @mousedown="mousedownHandle($event, { x: 0, y: 0 })"
    )
    circle.handle(
        :cx="frame.x1 * scaleCoeff",
        :cy="frame.y0 * scaleCoeff",
        r=8,
        @mousedown="mousedownHandle($event, { x: 1, y: 0 })"
    )
    circle.handle(
        :cx="frame.x0 * scaleCoeff",
        :cy="frame.y1 * scaleCoeff",
        r=8,
        @mousedown="mousedownHandle($event, { x: 0, y: 1 })"
    )
    circle.handle(
        :cx="frame.x1 * scaleCoeff",
        :cy="frame.y1 * scaleCoeff",
        r=8,
        @mousedown="mousedownHandle($event, { x: 1, y: 1 })"
    )
line.swipe(
    v-if="!swipe && !ocr && clicks && clicks[1]",
    :x1="clicks[0].x * scaleCoeff",
    :y1="clicks[0].y * scaleCoeff",
    :x2="clicks[1].x * scaleCoeff",
    :y2="clicks[1].y * scaleCoeff"
    marker-end="url(#arrowhead)"
)
path.swipe(
    v-if="swipe && swipe.length > 1"
    :d="getPath(swipe)",
    marker-end="url(#arrowhead)"
)
template(v-if="swipe || clicks")
    cursor-pointer(
        v-for="click in (swipe || clicks)"
        :size="12"
        :type="click.type"
        :dragging="dragging"
        :transform="'translate(' + getAbsolute(click) + ')'"
        @mousedown="mousedownCursorPointer($event, click)"
    )
</template>

<style lang="css" scoped>
rect.frame {
    fill: none;
    stroke-width: 2px;
    stroke-dasharray: 5, 3;
    filter: drop-shadow(2px 2px 2px rgb(255 255 255 / 1));
}

.stroke-black {
    stroke: black;
}

.stroke-gray {
    stroke: gray;
    stroke-dasharray: 7 5 !important;
}

.stroke-green {
    stroke: #94D904;
}

.highlighted {
    stroke: orangered;
    stroke-width: 3px !important;
}

circle.handle {
    stroke: black;
    fill: #94D904;
    filter: drop-shadow(0 0 5px rgb(255, 255, 255))
}
.handle:hover {
    fill: #FDA404 !important;
}
.handle:hover {
    cursor: crosshair;
}

.matcher path {
    fill: #ffffff88;
}
.swipe {
    stroke-width: 5px;
    stroke: #94d907;
    fill: none;
}
</style>
