<script lang="ts" setup>
import { computed, ref, useSlots } from "vue"
import { useRouter } from "vue-router"

interface Props {
    items: any[]
    recordingKey?: string
    noRecording?: boolean
    cardHeaderStyle?: any
    keyProp?: string
}

const props = defineProps<Props>()
const slots = useSlots()
const router = useRouter()

const hasItems = computed(() => !!props.items.length)

function borderClass(recording: any) {
    const status = recording ? recording.status.toLowerCase() : "norun"
    return `tu-border-${status}`
}

function hasSlot(slotName: string) {
    return !!slots[slotName]
}

function showRecording(item: any) {
    if (props.noRecording) return false
    const latestRecording = props.recordingKey ? item[props.recordingKey] : item
    return !!latestRecording
}

function performClick(recordingId: number) {
    let newPath = actualBaseRoute() + "/view/" + recordingId
    router.push(newPath)
}

function actualBaseRoute() {
    let current = router.currentRoute.value.fullPath

    // matches {url}?tab pattern
    let basePath = current.match(/(\/.*)(?=(\?tab))/g)
    if (!basePath) {
        // matches {url} pattern
        return current
    }

    return basePath
}

function showOverlayComponent() {
    let projectDetector = /\/project\//i
    let detectProject = location.href.match(projectDetector)
    return detectProject == null
}

function getRecordingId(item: any) {
    return item.recordingId || item.id
}
</script>

<template lang="pug">
.item-list.m-0.p-0.avoid-print-break
    .tu-frame.avoid-print-break.mb-1.mb-4(
        v-for="(item, index) in items",
        :key="item[keyProp || 'id']",
        :class="[borderClass(recordingKey ? item[recordingKey] : item)]"
    )
        .card-header.bg-white.pl-0.pr-1.text-color(
            @click.prevent="performClick(getRecordingId(item))",
            :class="{ 'card-header-time': hasSlot('timeItem') }",
            :style="cardHeaderStyle"
        )
            div(style="margin-top: 3px")
                slot(name="progressBar", :listItem="item", :index="index")
                slot(name="progressBarIcons", :listItem="item", :index="index")
            div
                slot(name="moveItem", :listItem="item", :index="index")
                slot(name="deleteButton", :listItem="item", :index="index")

            span.names
                slot.tu-d-grid.tu-align-items-center(name="itemName", :listItem="item", :index="index")

                span
                    slot(name="timeItem", :listItem="item", :index="index")
                    slot(name="execTime", :listItem="item", :index="index")
                    slot(name="timeDiffItem", :listItem="item", :index="index")

            slot(name="actionButton", :listItem="item", :index="index")

        .card-body(v-if="showRecording(item)")
            slot(name="recordingPreview", :listItem="item", :index="index")
</template>

<style lang="css" scoped>
.card-header {
    display: grid;
    grid-template-columns: minmax(115px, 16%) min-content 1.2fr max-content;
    grid-template-rows: min-content;
    grid-column-gap: 5px;
}
.card-header-time {
    grid-template-columns: minmax(115px, 16%) max-content 1fr max-content;
    grid-template-rows: min-content;
}
.text-color {
    color: var(--black);
    text-decoration: none;
}
.action-button {
    display: grid;
    grid-template-columns: max-content max-content;
}
.bg-clr {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: rgba(0, 0, 0, 0);
}
.card-body {
    padding: 1px;
}
.dropdown-item.disabled {
    opacity: 0.5;
}
.progress {
    align-self: center;
    height: 35px;
    margin-right: 10px;
}
.progress > .progress-bar {
    width: 100px;
}
.w-0 {
    width: 0% !important;
}

.tu-border-passed:hover {
    border-color: var(--green) !important;
}

.tu-border-failed:hover, .tu-border-interrupted:hover {
    border-color: var(--dark-red) !important;
}

.span-sm {
    margin-left: 3px;
}

.test-name {
    min-height: 21px;
    margin-top: 0px;
}
</style>
