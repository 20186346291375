<script lang="ts" setup>
import { nextTick, ref, watch } from "vue"

interface Props {
    modelValue?: number
    isTimeOutSet: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data?: number): void
    (event: "leaveFocus", data: Event): void
}>()
defineExpose({
    showTimeout,
})

const secured = ref(true)
const v = ref(props.modelValue)
const timer = ref<number | null>(null)

const el = ref<HTMLDivElement>()

watch(
    () => props.modelValue,
    (newVal) => {
        v.value = newVal
    }
)

function update() {
    emit("update:modelValue", v.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}

async function showTimeout() {
    secure(false)
    await nextTick()
    el.value?.querySelector("input")?.focus()
}

function secure(isSecured: boolean, timeout = 200) {
    if (timer.value) clearTimeout(timer.value)
    if (isSecured) {
        timer.value = setTimeout(() => {
            secured.value = true
        }, timeout) as unknown as number
    } else {
        secured.value = false
    }
}
</script>

<template lang="pug">
div(ref="el")
    .input-group-sm(v-if="!secured", style="display: inline-flex")
        input.form-control.input-size.mr-2(
            v-model="v",
            type="number",
            @change="update",
            @keydown.enter="leaveFocus",
            @blur="secure(true)",
            @keydown.stop=""
        )
        i.fas.fa-history.mt-2
    div(v-if="secured && isTimeOutSet")
        i.fas.fa-history(@click="showTimeout")
</template>

<style lang="css" scoped>
.input-size {
    width: 70px;
    text-align: center;
}
</style>
