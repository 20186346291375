function flattenDependencyTree(testcaseDependencyTree, depth, testcases) {
    const flattenedDependencyTree = []
    for (let item of testcaseDependencyTree) {
        const testcase = testcases[item["index"]]
        const flatItem = {
            index: item["index"],
            depth, testcase,
            search: [testcase.name.toLowerCase()]
        }
        flattenedDependencyTree.push(flatItem)
        if (item["children"]) {
            const children = flattenDependencyTree(item["children"], depth + 1, testcases)
            for (let c of children) {
                flattenedDependencyTree.push(c)
                flatItem.search.push(c.search[0])
                c.search.push(flatItem.search[0])
            }
        }
    }
    return flattenedDependencyTree
}
function flattenEndingDependencyTree(flattenedDependencyTree) {
    let pattern = "pattern"

    flattenedDependencyTree.forEach((actualElement, index) => {
        actualElement[pattern] = ""
        for (let indexDepth = 0; indexDepth < actualElement["depth"]; indexDepth++) {
            if (index != flattenedDependencyTree.length - 1) {
                let nextElement = flattenedDependencyTree[index + 1]
                actualElement[pattern] += indexDepth < nextElement.depth ? "O" : "C"
            } else {
                actualElement[pattern] += "C"
            }
        }
    })
}

function buildTestsTree(testcases) {
    const nodes = testcases.map((testCase, index) => ({
        id: testCase.id,
        predecessorId: testCase.predecessorId,
        index,
        children: []
    }));

    const rootNodes = [];

    nodes.forEach(currentNode => {
        if (currentNode.predecessorId) {
            if (currentNode.predecessorId === currentNode.id) {
                rootNodes.push(currentNode);
            } else {
                const predecessorNode = nodes.find(node => node.id === currentNode.predecessorId);
                if (predecessorNode) {
                    predecessorNode.children.push(currentNode);
                } else {
                    rootNodes.push(currentNode)
                }
            }
        } else {
            rootNodes.push(currentNode);
        }
    });
    return rootNodes
}

// Main entry point
function prepareFlattenedTreeOfDependency(testcases, collapsedItems = {}) {
    let treeOfTestcases = buildTestsTree(testcases)

    let flattenedDependencyTree = flattenDependencyTree(treeOfTestcases, 0, testcases)
    flattenEndingDependencyTree(flattenedDependencyTree)
    prepareCollapsedItems(flattenedDependencyTree, collapsedItems)

    return flattenedDependencyTree
}

function prepareCollapsedItems(flattenedDependencyTree, collapsedItems) {
    let predecessorIds = flattenedDependencyTree.map((item) => item.testcase.predecessorId).filter((id) => id)

    for (let item of flattenedDependencyTree) {
        item.testcase.isPredecessor = predecessorIds.includes(item.testcase.id)
        item.isCollapsed = !!collapsedItems[item.testcase.id]
    }

    Object.keys(collapsedItems).forEach((predecessorId) => {
        if (!predecessorIds.includes(parseInt(predecessorId))) delete collapsedItems[predecessorId]
    })
}

function listOfReorderedTestcase(testcases) {
    let flattenedTestcases = prepareFlattenedTreeOfDependency(testcases)
    return flattenedTestcases.map((flattenedTestcase) => {
        let prefixBullets = (String.fromCodePoint(0x2022) + " ").repeat(flattenedTestcase.depth)
        let testcase = {...flattenedTestcase.testcase}
        testcase.name = prefixBullets + testcase.name
        return testcase
    })
}

export {prepareFlattenedTreeOfDependency, listOfReorderedTestcase}
