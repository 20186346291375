<script lang="ts" setup>
import VHelpText from "@/common/components/layout/VHelpText.vue"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"

const emit = defineEmits(["play", "delete"])

const { dragged } = storeToRefs(useStore())

function play() {
    if (!dragged.value) emit("play")
}

function cancel() {
    if (!dragged.value) emit("delete")
}
</script>

<template lang="pug">
div(v-movable, style="visibility: visible; z-index: 10000")
    v-help-text(:showCancel="true", @cancel="cancel")
        .link(@click="play") [play it]
</template>

<style lang="css" scoped></style>
