/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationTargetConfig = {
    channel: NotificationTargetConfig.channel;
    trigger: NotificationTargetConfig.trigger;
    hook: string;
};

export namespace NotificationTargetConfig {

    export enum channel {
        EMAIL = 'EMAIL',
        SLACK = 'SLACK',
        MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
        DISCORD = 'DISCORD',
    }

    export enum trigger {
        ALL = 'ALL',
        FAILED = 'FAILED',
        PASSED = 'PASSED',
        MANUAL = 'MANUAL',
        DISABLED = 'DISABLED',
    }


}

