
import { DynamicPivotVariable, PivotVariable } from "@/common/pivottable/PivotVariables"
import { formatDateInterval } from "@/common/util"
import { ref } from "vue"

export class PivotAggregator {
    config = new AggregatorConfig()

    aggregate(data: any[]): any {
        return ""
    }

    getStyle(data: any[]): string {
        return ""
    }

    getConfig(): AggregatorConfig {
        return this.config
    }
}

export class AggregatorConfig {
    showGraph? : boolean
    aggregatedVariable?: PivotVariable
}

export class CustomAggregator extends PivotAggregator {
    type: string
    constructor(type: string) {
        super()
        this.type = type
    }
}

export class FieldAggregator extends PivotAggregator {
    field : string

    constructor(field:string) {
        super()
        this.field = field
    }
}

export class RawLastElementAggregator extends PivotAggregator {
    aggregate(data: any[]): any {
        return data.length ? (data[0] ?? "null") : ""
    }
}

export class TotalAggregator extends PivotAggregator {
    constructor() {
        super()
        this.config.showGraph = false
    }
    aggregate(data: any[]): any {
        return data.length
    }
}

export class SumAggregator extends FieldAggregator {

    aggregate(data: any[]): any {
        return data.reduce((sum:number, row:any) => sum + row[this.field], 0)
    }
}

export class SumSecondsAggregator extends SumAggregator {
    aggregate(data: any[]):any {
        if (!data.length) return ""
        const sum = super.aggregate(data)
        return formatDateInterval(sum * 1000)
    }
}

export class SumMinutesAggregator extends SumAggregator {
    constructor(field = "duration") {
        super(field)
        this.config.showGraph = false
    }

    aggregate(data: any[]):any {
        if (!data.length) return ""
        const sum = super.aggregate(data)
        return (sum / 60).toFixed(2)
    }
}


export class RecordingStatusAggregator extends FieldAggregator {

    constructor(field = "status") {
        super(field)
    }

    aggregate(data: any[]): any {
        if (!data.length) return ""
        const success = data.filter((x:any) => x[this.field] == "PASSED").length
        const failed = data.filter((x:any) => x[this.field] == "FAILED").length
        return Math.round(success/(data.length)*100)+"%"
    }

    getStyle(data: any[]) {
        const textAggregate = this.aggregate(data)
        const value = parseInt(textAggregate.replace('%','')) / 100.0
        const rgb = [value * 148 + (1-value) * 224, value* 217 + (1-value) * 7, value* 4 + (1-value) * 7]
        return `background-color: rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
    }
}

export class VariableValueAggregator extends PivotAggregator {

    constructor() {
        super()
        this.config.showGraph = false
        this.config.aggregatedVariable =  new DynamicPivotVariable("CLIPBOARD", "runtimeVariables")
    }

    aggregate(data: any[]): any {
        const list = []
        for (let row of data) {
            const value = this.config.aggregatedVariable?.extractKey(row)
            if (value != null) {
                list.push(parseFloat(value))
            }
        }
        const sum = list.reduce((sum:number, row:any) => sum + row, 0)
        return sum / list.length
    }
}