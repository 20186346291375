<script lang="ts" setup>
import { nextTick, ref, watch } from "vue"
import { DeviceError, informServerAboutError } from "@/services/BackendService"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"
import useEventListener from "@/composables/useEventListener"

const store = useStore()
const { clearError } = store
const { error } = storeToRefs(store)

const roboContent = ref<HTMLElement>()
const prevClientWidth = ref(0)

watch(error, async (value) => {
    if (!value) return
    await nextTick()
    let el = roboContent.value
    if (!el) return
    el.style.left = document.body.clientWidth - el.offsetWidth - 40 + "px"
    prevClientWidth.value = document.body.clientWidth
    if (value.name != "autoDisconnectMessage") await informServerAboutError(format(value))
})

useEventListener(window, "resize", resize)

function resize() {
    let el = roboContent.value
    if (!el) return
    const clientWidthCoeff = prevClientWidth.value / document.body.clientWidth
    prevClientWidth.value = document.body.clientWidth
    const left = el.style.left
    el.style.left = Number(left.substring(0, left.length - 2)) / clientWidthCoeff + 'px'
}

function close() {
    clearError()
}

function header(error: any) {
    if (!error) return "Error"
    if (error instanceof DeviceError) {
        if (error.level == "ERROR") return "Device error"
        return "Warning!"
    }
    if (error instanceof Event) return "Event"
    if (error.response && error.response.status) return `Server error ${error.response.status}`
    if (error.response && error.response.data) return `Server error ${error.response.data.status}`
    if (error.header) return error.header
    if (error.message) return error.message
    return "Error"
}

function format(error: any) {
    const message = formatErrorMessage(error)
    if (error.traceId) {
        return `${message} [#${error.traceId}]`
    }

    return message
}

function formatErrorMessage(error: any) {
    if (!error) return "Error"
    if (error instanceof DeviceError) {
        return error.message
    }
    if (error instanceof Event) {
        return error.type
    }
    if (error.response && error.response.status == 404) {
        let errorData = error.response.data
        let additionalInfo = ""
        if (errorData.message) additionalInfo = errorData.message
        return "Invalid request made to: " + error.request.responseURL + "\n" + additionalInfo
    }
    if (error.response && error.response.status == 403) {
        return "Access forbidden to " + error.config.url + " " + (error.response?.data?.message || "")
    }
    if (error.response && error.response.status == 504) {
        let additionalInfo: string = error.response.data.message ? error.response.data.message : ""
        return "Request timed out. Call to " + error.request.responseURL + "\n" + additionalInfo + "failed."
    }
    if (error.response && error.response.data) {
        let errorData = error.response.data
        if (errorData.message) return errorData.message
    }
    if (error.config && error.config.url) {
        return "Error accessing " + error.config.url
    }
    if (error.message) return error.message
    return error.toString()
}

function resolutions(error: any) {
    return error.resolutions || []
}

function resolve(resolution: any) {
    resolution.callback()
    close()
}

function errorTimestamp() {
    return "UTC " + new Date().toISOString()
}

function errorURL() {
    return window.location.href.toString()
}
</script>

<template lang="pug">
.robo-content(ref="roboContent", v-if="error", v-movable)
    .modal-backdrop(v-if="error", @click.prevent.stop="close", @mousemove.prevent.stop)
    .robo-container
        .pr-3
            .error-bubble
                .msg-header
                .msg-side.d-flex.justify-content-center
                    i.mt-4.fas.fa-exclamation-triangle(style="font-size: 1.2em !important")
                .msg-center(@mousedown.stop)
                    .header {{ header(error) }}
                    .body {{ format(error) }}
                .msg-footer(@mousedown.stop)
                    .developer-information {{ errorTimestamp() }} <br> {{ errorURL() }}
                    button.btn.btn-secondary(v-if="!error.resolutions" type="button", data-dismiss="modal", @click.prevent.stop="close") Close
                    button.btn.btn-secondary(v-for="res in resolutions(error)" type="button", data-dismiss="modal", @click.prevent.stop="resolve(res)") {{ res.label }}

        .robot
            span.d-block.text-right(style="width: 100%; cursor: pointer", @click.prevent.stop="close")
                i.fas.fa-times(style="font-size: 1.2em !important")
            img(src="@/assets/robot_reading.png")
</template>

<style lang="css" scoped>
.robo-content {
    position: fixed;
    bottom: 5vh;
    z-index: 9998;
}

.robo-container img {
    width: 100px;
    display: block;
}

.robo-container {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.robo-container .robot {
    z-index: 1000;
}

.error-bubble {
    position: relative;
    border-radius: 0.5em;
    min-width: 200px;
    min-height: 40vh;
    max-width: 50vw;
    z-index: 2000;
}

.error-bubble:before {
    content: "";
    display: block;
    position: absolute;
    transform: rotate(90deg);
    left: 100%;
    top: 70px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: gray;
    z-index: -100;
}

.error-bubble:after {
    content: "";
    display: block;
    position: absolute;
    transform: rotate(90deg);
    left: 100%;
    top: 71px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
    z-index: -100;
}

.error-bubble {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: 15px 1fr max-content;
    grid-template-areas:
        "head head"
        "side center"
        "side foot";
}

.msg-header {
    grid-area: head;
    background-color: #ba0707;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.msg-side {
    grid-area: side;
    background-color: black;
    border-bottom-left-radius: 0.5em;
    color: white;
}

.msg-center {
    grid-area: center;
    background-color: white;
    padding: 20px;
    padding-top: 0;
    border-right: 1px solid gray;
    word-break: break-word;
    max-height: 85vh;
    overflow-y: auto;
}

.msg-center > .header {
    font-size: 1.3em;
    margin-top: 25px;
    margin-bottom: 10px;
}

.msg-center > .body {
    font-size: 0.9em;
}

.msg-footer {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-area: foot;
    padding: 8px 20px 8px 20px;
    border: 1px solid gray;
    border-top: 1px solid #e9ecef;
    border-left: none;
    border-bottom-right-radius: 0.5em;
    font-size: 9px;
    background: white;
    color: gray;
}

.msg-footer > button {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    padding-top: 3px;
    padding-bottom: 3px;
    align-self: center;
}

.msg-footer > button:hover {
    background-color: black;
    border-color: black;
}

.developer-information {
    word-break: break-word;
}

.modal-backdrop {
    z-index: 500;
    opacity: 0.01;
}
</style>
