/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExecutionMeta } from './ExecutionMeta';
import type { GetExecutionProject } from './GetExecutionProject';
import type { GetExecutionRecording } from './GetExecutionRecording';

export type GetExecutionResponse = {
    id: number;
    name?: string;
    time?: string;
    status?: GetExecutionResponse.status;
    project?: GetExecutionProject;
    recordings?: Array<GetExecutionRecording>;
    meta?: ExecutionMeta;
};

export namespace GetExecutionResponse {

    export enum status {
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RUNNING = 'RUNNING',
        INTERRUPTED = 'INTERRUPTED',
    }


}

