import dayjs from "@/plugins/dayjs"

export * from "./dom"

export function formatDate(value: dayjs.ConfigType, format = "YYYY-MM-DD") {
    const today = dayjs(new Date()).format(format)
    const formatted = dayjs(value).format(format)
    if (today == formatted) return dayjs(value).fromNow(false)
    return formatted
}

export function formatDateTime(value: dayjs.ConfigType, format = "YYYY-MM-DD HH:mm") {
    return dayjs(value).format(format)
}

export function dateDiff(value: dayjs.ConfigType, end: dayjs.ConfigType) {
    const interval = dayjs(end).diff(dayjs(value))
    return formatDateInterval(interval)
}

export function formatDateInterval(interval: number) {
    const oneHour = 1000 * 60 * 60
    const hours = Math.floor(interval / oneHour)
    const minFormatted = dayjs.duration(interval - hours * oneHour).format("mm:ss")
    if (!hours) return minFormatted
    return hours.toString().padStart(2, "0") + ":" + minFormatted
}

export function isObject<T extends object>(arg: any): arg is T {
    return arg && typeof arg === "object" && arg.constructor === Object
}
