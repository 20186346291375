import { useStore } from "@/store/useStore"
import { defineStore } from "pinia"
import { ref } from "vue"
import {DeviceError} from "@/services/BackendService";

import bingUrl from '@/assets/bing1.mp3';

export const useAutoDisconnectStore = defineStore("useAutoDisconnectStore", () => {
    const timeoutBeforeError = ref(900 * 1000)
    const timeoutBeforeDisconnect = ref(60 * 1000)
    const timerBeforeError = ref<number | undefined>(undefined)
    const timerBeforeDisconnect = ref<number | undefined>(undefined)
    const setDisconnectTimeoutFunc = ref(() => setDisconnectTimeout())
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const callback = ref<CallableFunction>(() => {})

    const store = useStore()

    function openDisconnectTimeout(providedCallback: CallableFunction) {
        callback.value = providedCallback
        if (window.EDITOR_LIFETIME) {
            timeoutBeforeError.value = window.EDITOR_LIFETIME * 60 * 1000
        }
        setDisconnectTimeout()
    }

    function closeDisconnectTimeout() {
        clearDisconnectTimeout()
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        callback.value = () => {}
    }

    function setDisconnectTimeout() {
        clearDisconnectTimeout()
        timerBeforeError.value = setTimeout(
            () => {
                document.body.addEventListener("mousemove", setDisconnectTimeoutFunc.value)
                setBeforeDisconnectTimeout(timeoutBeforeDisconnect.value)
            },
            timeoutBeforeError.value
        ) as unknown as number
        document.body.addEventListener("mousedown", setDisconnectTimeoutFunc.value)
    }

    function clearDisconnectTimeout() {
        store.clearError()
        clearTimeout(timerBeforeError.value)
        clearTimeout(timerBeforeDisconnect.value)
        timerBeforeError.value = undefined
        timerBeforeDisconnect.value = undefined
        document.body.removeEventListener("mousedown", setDisconnectTimeoutFunc.value)
        document.body.removeEventListener("mousemove", setDisconnectTimeoutFunc.value)
    }

    async function setBeforeDisconnectTimeout(timeLeftBeforeDisconnect: number) {
        if (timeLeftBeforeDisconnect > 0) {
            clearTimeout(timerBeforeDisconnect.value)
            timerBeforeDisconnect.value = setTimeout(
                () => setBeforeDisconnectTimeout(timeLeftBeforeDisconnect - 5000),
                5000
            ) as unknown as number
            const audio = new Audio(bingUrl)
            await audio.play().catch()
            showError({
                message: `Your device will be disconnected in ${timeLeftBeforeDisconnect / 1000} seconds`,
            })
        } else {
            await callback.value()
            closeDisconnectTimeout()
            showError({
                header: "Your device was disconnected",
                message: "You were inactive in the editor for several minutes, that's why your device has been disconnected."
            })
        }
    }

    function showError(e: any) {
        if (e) e.name = "autoDisconnectMessage"
        store.showError(e)
    }

    return {
        openDisconnectTimeout,
        closeDisconnectTimeout,
    }
})
