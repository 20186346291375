<script lang="ts" setup>
import { computed } from "vue"

interface Props {
    recording: any
    noProgressLeftRadius?: boolean
}

const props = defineProps<Props>()

const executionStatusLabel = computed(() => {
    let progressLabels: any = {
        NO_RUN: "No Play",
        RUNNING: "Playing",
        PASSED: "Passed",
        FAILED: "Failed",
        INTERRUPTED: "Interrupted",
    }
    return props.recording ? progressLabels[props.recording.status] : "No Play"
})

const executionStatusClass = computed(() => {
    let progressClasses: any = {
        NO_RUN: "tu-bg-norun",
        RUNNING: "tu-bg-running",
        PASSED: "tu-bg-passed",
        FAILED: "tu-bg-failed",
        INTERRUPTED: "tu-bg-interrupted",
    }
    return props.recording ? progressClasses[props.recording.status] : "tu-bg-norun"
})

const executionStatusIconClass = computed(() => {
    const progressClasses: any = {
        "tu-bg-norun": ["fa", "fa-minus-circle"],
        "tu-bg-running": ["fas", "fa-sync-alt"],
        "tu-bg-passed": ["fa", "fa-check-circle"],
        "tu-bg-failed": ["fa", "fa-times-circle"],
        "tu-bg-interrupted": ["fa", "fa-times-circle"],
    }
    return progressClasses[executionStatusClass.value]
})

const executionProgress = computed(() => {
    return props.recording && props.recording.status == "RUNNING"
        ? props.recording.executionProgress * 100
        : 100
})
</script>

<template lang="pug">
.progress.position-relative(:class="{ 'tu-border-left-radius-0': noProgressLeftRadius }")
    .progress-bar(:class="executionStatusClass", :style="{ width: `${executionProgress}%` }", role="progressbar")
    span.d-flex.justify-content-center.align-self-center.position-absolute.w-100
        i.d-inline(:class="executionStatusIconClass", style="margin-top: 2px")
        h5 &nbsp;&nbsp;{{ executionStatusLabel }}
</template>

<style lang="css" scoped>
.progress {
    height: 35px;
    margin-right: 10px;
}

.progress span {
    font-family: var(--tu-font-family-primary) !important;
    color: white;
    pointer-events: none;
}

.tu-border-passed:hover .progress-bar {
    background-color: var(--green) !important;
}

.tu-border-failed:hover .progress-bar, .tu-border-interrupted:hover .progress-bar {
    background-color: var(--dark-red) !important;
}
</style>
