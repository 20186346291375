<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue"
import {getTestCaseRecordings} from "@/services/ProjectService"
import { recordingService } from "@/services/RecordingService"
import { formatDate } from "@/common/util"
import { AxiosResponse } from "axios";
import { storeToRefs } from "pinia"
import { useStore } from "@/store/useStore"
import { RecordingDTO } from "@/types/gen";

interface Props {
    mode: string
    recording?: any | null
    showActions: boolean
    activeRec: any
    frameIndex: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "saveBaseline"): void
    (event: "iframeCode"): void
    (event: "deleteRecording"): void
    (event: "nextFrame"): void
    (event: "prevFrame"): void
    (event: "firstFrame"): void
    (event: "nextIssue"): void
    (event: "prevIssue"): void
    (event: "firstFrame"): void
    (event: "playRec"): void
    (event: "stopRec"): void
    (event: "setCompareMode", data: any): void
    (event: "setAnimate", data: any): void
    (event: "showComp", data: any): void
    (event: "retakeRecording"): void
    (event: "update:showActions", data: boolean): void
}>()

defineExpose({
})

const rec = ref(props.recording)
const showMore = ref(false)
const recordingsWithLinkedEntities = ref<any>({})
const isFirst = ref<boolean>(true)
const compareRec = ref<any>({})
const animateRec = ref(true)
const playing = ref(false)

const { drawAnnotation } = storeToRefs(useStore())

const hasFramesToPlay = computed(() => {
    return props.recording && props.frameIndex < props.recording.frames.length - 1
})

watch(
    () => props.recording,
    async (val) => {
        rec.value = val
        if (rec.value && rec.value.testCaseId) {
            await updateRecordings(rec.value.testCaseId)
            if (isFirst.value) {
                isFirst.value = false
                await changeModeOnLoad()
            }
        }
    },
    { immediate: true }
)

watch(
    () => props.frameIndex,
    () => {
        if (!hasFramesToPlay.value) stop()
    }
)

function openMore() {
    showMore.value = !showMore.value
}

async function changeMode(mode: string, selectedRec: any= null, on_load = false) {
    if (mode !== "compare") {
        if (mode != "view") stop()
        compareRec.value = {}
        emit("setCompareMode", {mode})
    } else {
        compareRec.value = selectedRec || recordingsWithLinkedEntities.value?.recordings?.find((el: any) => {
            return el.status === "PASSED" && Date.parse(el.endTime) < Date.parse(rec.value.endTime)
        })
        emit("setCompareMode", {mode, rec: compareRec.value, on_load})
     }
}

async function changeModeOnLoad() {
    const mode = props.recording.status=="FAILED" ? "compare" : "view"
    changeMode(mode, null, true)
}

function saveBaseline() {
    emit("saveBaseline")
}

function iframeCode() {
    emit("iframeCode")
}

function deleteRecording() {
    emit("deleteRecording")
}

function nextFrame() {
    emit("nextFrame")
}

function prevFrame() {
    emit("prevFrame")
}

function firstFrame() {
    emit("firstFrame")
}

function nextIssue() {
    emit("nextIssue")
}

function prevIssue() {
    emit("prevIssue")
}

async function play() {
    if (!hasFramesToPlay.value) {
        emit("firstFrame")
        await nextTick()
        if (!hasFramesToPlay.value) return
    }
    playing.value = true
    emit("playRec")
}

function stop() {
    playing.value = false
    emit("stopRec")
}

async function updateRecordings(testCaseId: any) {
    recordingsWithLinkedEntities.value = await getTestCaseRecordings(testCaseId, 0, 300)
    recordingsWithLinkedEntities.value.recordings.unshift(
        {
            id: -22,
            status: "SYNTHETIC",
            interactive: true

        } as any as RecordingDTO)
}

function resolveName(recording: any) {
    if (!recording || !Object.keys(recording).length) return "None Selected"
    else if (recording.status == "SYNTHETIC") return "Synthetic"
    else return recordingService.resolveName(recording)
}

function toggleAnimate() {
    setAnimate(!animateRec.value)
}

function setAnimate(value: boolean) {
    animateRec.value = value
    emit("setAnimate", animateRec.value)
}

function showComp(val: any) {
    if(compareRec.value == null) {
        throw {header: "Error Opening compare recording", message: "We have not yet been able to pass this test, therefore we have no fitting recording to compare this to."}
    }
    if (!Object.keys(compareRec.value).length) return
    setAnimate(false)
    emit("showComp", val)
}

function forClass(r: any) {
    return {
        "tu-failed": r.status === "FAILED",
        "tu-passed": r.status === "PASSED",
        selected: r.id === compareRec.value?.id,
    }
}

function retakeRecording() {
    emit("retakeRecording")
}

function toggleShowActions(e: Event) {
    const checked = (e.target as HTMLInputElement).checked
    emit("update:showActions", checked)
}
</script>

<template lang="pug">
.run-header
    .navigation
        ul.nav.nav-tabs
            li.nav-item
                a.nav-link(:class="{ active: mode == 'view' }" @click="changeMode('view')") View
            li.nav-item
                a.nav-link(:class="{ active: mode == 'edit' }" @click="changeMode('edit')") Edit
            li.nav-item
                a.nav-link(:class="{ active: mode == 'data' }" @click="changeMode('data')") Data
            li.nav-item
                a.nav-link(:class="{ active: mode == 'compare' }" @click="changeMode('compare')") Compare
        .done-button.pr-1
            slot
    .tabs
        template(v-if="mode == 'view'")
            .tab-content.tab-pane
                .button-container(v-if="!playing" @click="play")
                    i.fas.fa-play
                    .span-sm Play
                .button-container.playing(v-else @click="stop")
                    i.-bigger.fas.fa-pause-circle
                    .span-sm Stop
                .button-container(@click="prevFrame")
                    i.fas.fa-backward
                    .span-sm Previous Frame
                .button-container(@click="nextFrame")
                    i.fas.fa-forward
                    .span-sm Next Frame
                .button-container(@click="prevIssue")
                    i.fas.fa-caret-square-left
                    .span-sm Previous issue
                .button-container(@click="nextIssue")
                    i.fa.fa-caret-square-right
                    .span-sm Next issue
                .button-container
                    label.bottom-label
                        input(type="checkbox" :checked="showActions" @change="toggleShowActions")
                        .span-sm.text-center Show Actions
                .button-container(
                    :style="drawAnnotation ? { 'background-color': 'green' } : {}",
                    @click="drawAnnotation = !drawAnnotation"
                )
                    .icon-large.fa.fa-comment
                    .span-sm Annotate

        template(v-if="mode == 'edit'")
            .tab-content.tab-pane
                .button-container(@click="deleteRecording" :class="{ disabled: recording?.isBaseline }")
                    i.fas.fa-trash
                    .span-sm Delete
                .button-container(v-if="recording")
                    label.bottom-label
                        input(type="checkbox" v-model="recording.isBaseline" @change="saveBaseline")
                        .span-sm.text-center Set as Baseline
                .button-container(@click="iframeCode")
                    i.fas.fa-chalkboard
                    .span-sm Make Iframe
                .button-container(@click="retakeRecording")
                    i.fas.fa-edit
                    .span-sm Go to Editor
        template(v-if="mode == 'data'")
            .tab-content.tab-pane

        template(v-if="mode == 'compare'")
            .tab-content.tab-pane
                .button-container
                    .dropdown.dropdown-pos
                        .button-container.moreKeys(@click="openMore")
                            .span-sm Compare With Recording
                            i.fas.fa-chevron-down
                        .dropdown-menu(v-if="showMore")
                            .button-container.padding-reduce.move-left(
                                v-for="(r, i) in recordingsWithLinkedEntities.recordings",
                                @click="changeMode('compare', r); openMore()"
                            )
                                .compare-item-con(v-if="r.status != 'RUNNING'")
                                    .dot.compare-item-status(v-if="!r.isBaseline" :class="forClass(r)")
                                    i.fas.fa-download(v-else)
                                    .span-sm.compare-item-text(:class="forClass(r)")
                                        | {{ r.startTime && formatDate(r.startTime) }} -
                                        | {{ resolveName(r) }}
                .button-container.wide(style="padding-top: 8px" @click="showComp(false)" :class="{ activeRec: activeRec }")
                    .span-sm.compare-with currently viewing:<br>
                        template(v-if="rec")
                            | {{ formatDate(rec.startTime) }}<br>
                        | {{ resolveName(rec) }}
                .button-container.wide(style="padding-top: 8px" @click="showComp(true)" :class="{ activeRec: !activeRec }")
                    .span-sm.compare-with comparing with:<br>
                        template(v-if="compareRec")
                            | {{ formatDate(compareRec.startTime) }}<br>
                        | {{ resolveName(compareRec) }}
                .button-container(@click="toggleAnimate")
                    label.bottom-label
                        input.mr-1(type="checkbox" v-model="animateRec" @click="toggleAnimate")
                        .span-sm Animate Comparison
</template>

<style lang="css" scoped>
.run-header {
    grid-column: 1/4;
}
.tabs {
    display: grid;
    overflow: hidden;
}
li.nav-item {
    cursor: pointer;
    user-select: none;
}
.navigation {
    display: grid;
    grid-template-columns: auto max-content;
}
.done-button {
    display: grid;
    align-items: center;
    border-bottom: 1px solid var(--tu-border-lightgray);
}
li.nav-item:nth-child(5) {
    flex: 1;
    text-align: right;
}
.tab-content {
    height: 70px;
    max-width: 100vw;
    border-bottom: 1px solid var(--tu-border-lightgray);
}
.tab-pane {
    display: grid;
    grid-template-columns: repeat(15, max-content);
}
.button-container {
    display: grid;
    justify-items: center;
    width: 90px;
    padding-top: 14px;
    cursor: pointer;
    user-select: none;
}
.button-container:hover {
    background-color: #dcdcdc;
}
.button-container:hover:active {
    background-color: #ccc;
}
.button-container:nth-child(2) {
    border-right: 1px solid var(--tu-border-lightgray);
}
.button-container.playing {
    background-color: #dc3545;
    color: white;
}
.button-container.playing:hover {
    background-color: #c82333;
}
.button-container.playing:hover:active {
    background-color: #ad1f2d;
}
.button-container .span-sm {
    text-align: center;
}
.padding-reduce {
    padding-top: 3px;
    font-size: 17px;
    padding-left: 5px;
    width: 80px;
    white-space: nowrap;
}
.dropdown-menu {
    display: block;
    min-width: 160px;
    background-color: white;
    position: absolute;
    left: 0px;
    max-height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    font-weight: bold;
}
.move-left {
    margin-left: 5px;
    width: 100%;
}

.compare-item-con {
    display: grid;
    place-items: center;
    grid-template-columns: 35px auto;
    width: 100%;
    padding: 5px;
}

.dropdown-pos {
    position: absolute;
    margin-top: -20px;
}

.wide {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.selected {
    font-weight: bold;
}
.dot {
    height: 20px;
    width: 20px;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
}
.compare-item-text {
    grid-column: 2/3;
    justify-self: left;
    color: black;
}
.compare-item-status {
    grid-column: 1/2;
}

.moreKeys {
    margin-top: 10px;
    padding-top: 0;
}

.activeRec {
    background-color: #ccc;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
