import { PromiseResolvedCallback } from "@/types"

export function disableScrolling() {
    document.body.style.overflow = "hidden"
}

export function enableScrolling() {
    document.body.style.overflow = ""
}

export async function blockScreen(func: PromiseResolvedCallback) {
    document.body.classList.add("no-pointer-events")
    try {
        const res = await func()
        return res
    } finally {
        document.body.classList.remove("no-pointer-events")
    }
}
