import { getVariables, saveVariables, saveConfig } from "@/services/ProjectService"

const testup_base = "/api/v1"

function getSettingsDefault() {
    return {
        AQUA_URL: window.PLUGIN_AQUA_URL,
        AQUA_USER: "",
        AQUA_PASSWORD: "",
    }
}
let aquaConfig = { loading: true }

export function getMatchImageLink(matcherId) {
    return `${testup_base}/matcher/${matcherId}/image.png`
}
function setOption(config, name, value) {
    config.options = config.options.filter((x) => x.name != name)
    if (value) {
        config.options.push({ name: name, value: value })
    }
}

export async function setAquaLink(testCase, aquaTestCaseId) {
    setOption(testCase.config, "aquaTestCaseId", aquaTestCaseId)
    /*
    const name =
        (aquaTestCaseId ? aquaTestCaseId + " " : "") +
        testCase.name.replace(/^TC[0-9]+ /,'')
    testCase.name = name
 */
    return await saveConfig(testCase.id, testCase.config)
}

async function reloadAquaConfig() {
    const vars = await getVariables("AQUA")
    aquaConfig = { ...getSettingsDefault() }
    for (let variable of vars) {
        aquaConfig[variable.name] = variable.value
    }
    return aquaConfig
}

export async function saveAquaConfig(config) {
    await saveVariables(
        Object.keys(config).map((key) => ({
            name: key,
            value: config[key],
        }))
    )
    return reloadAquaConfig()
}

export async function getAquaConfig() {
    return aquaConfig.loading ? reloadAquaConfig() : aquaConfig
}

export function getImportFromAquaId(config) {
    for (let option of config.options) {
        if (option.name == "aquaTestCaseId") return option.value
    }
    return null
}
