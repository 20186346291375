import { LinkedDataHelper, PivotHeader } from "@/common/pivottable/PivotDataHelpers"
import { getAction } from "@/services/ProjectService"
import type { ActionDTO, LinkedEntitiesDTO } from "@/types/gen"
import { ref, Ref, reactive, ReactiveEffect, ComputedRef, computed, watchEffect } from "vue"
import { userActionVerb } from "@/services/actionUtils"

export class PivotVariable {
    name: string
    key: string
    index: number = 0
    role: string = "unused"

    constructor(name: string, key?:string) {
        this.name = name
        this.key = key ?? name
    }

    isColumn() {
        return this.role == "columns"
    }

    extractKey(row: any): string {
        return row[this.key]?.toString() ?? 'null'
    }

    formatHtml(key: any) : string{
        return key
    }

    sortedHeaders(data : any[]) : PivotHeader[] {
        const headerMap = new Map<string, PivotHeader>()
        for (let row of data) {
            const key = this.extractKey(row)
            if (!headerMap.has(key)) {
                const header = new PivotHeader(key, this.formatHtml(key), this.getLink(key), this.getStyle())
                headerMap.set(key, header)
            }
        }

        const headers = [...headerMap.values()]
        this.sort(headers)
        return headers
    }

    sort(headers : PivotHeader[]) {
        headers.sort((a:PivotHeader, b:PivotHeader) => a.html < b.html ? -1 : a.key == b.key ? 0 : 1)
    }

    setRole(role: string) {
        this.role = role
        return this
    }

    getLink(key: any) : string | undefined {
        return
    }

    getStyle() : any {
        return {}
    }
}

export class PivotTimeVariable extends PivotVariable {

    constructor(name: string, key?:string) {
        super(name, key)
    }

    extractKey(row:any): string {
        return row[this.key]?.toString()?.replace(/(T..:..:..).*/, '$1') ?? 'WAITING'
    }

    formatHtml(key: any) : string{
        return key.replace(/T/, ' ')
    }

    sortedHeaders(data: any[]) {
        let values = super.sortedHeaders(data)
        if (this.isColumn()) values= values.reverse()
        return values
    }
}

export class PivotDateVariable extends PivotTimeVariable {

    constructor(name: string, key?:string) {
        super(name, key)
    }

    extractKey(row:any): string {
        return row[this.key]?.toString()?.replace(/T.*$/,'') ?? 'WAITING'
    }
}

export class TestcaseVariable extends PivotVariable {

    linkedData: LinkedDataHelper

    constructor(key:string, linkedData: Ref<any>) {
        super("Test", key)
        this.linkedData = new LinkedDataHelper(linkedData, 'testCases')
    }

    setOptions(options: any): this {
        return super.setOptions(options)
    }

    sort(headers : PivotHeader[]) {
        this.linkedData.sort(headers)
    }

    getLink(id: any) : string{
        return `/testcase/${id}`
    }

    formatHtml(key: any): string {
        let testCase = this.linkedData.getEntity(key)
        if (testCase) {
            let name = testCase.name
            while (testCase && !this.isColumn() && testCase.predecessorId) {
                // add dots to the test case name to indicate number of predecessors
                name = "· " + name
                testCase = this.linkedData.getEntity(testCase?.predecessorId.toString())
            }
            return name ?? key
        }
        return key
    }

    getStyle() {
        return {
            'header-rotated': this.isColumn()
        }
    }
}

export class ExecutionVariable extends PivotVariable {

    linkedData: LinkedDataHelper

    constructor(key:string, linkedData: Ref<any>) {
        super("Execution", key)
        this.linkedData = new LinkedDataHelper(linkedData, 'executions')
        linkedData.value = {executions: []}
    }

    sort(headers : PivotHeader[]) {
        headers.sort((a:PivotHeader, b:PivotHeader) =>
            (this.linkedData.entities.get(a.key.toString())?.entity?.startTime || 0) -
            (this.linkedData.entities.get(b.key.toString())?.entity?.startTime || 0))
    }

    getLink(id:any) : string{
        return `/execution/${id}`
    }

    formatHtml(key: string) {
        return this.linkedData.getName(key)
    }

    getStyle() {
        return {
            'header-rotated': this.isColumn()
        }
    }
}

export class ProjectVariable extends PivotVariable {

    linkedData: LinkedDataHelper

    constructor(key:string, linkedData: Ref<any>) {
        super("Project", key)
        this.linkedData = new LinkedDataHelper(linkedData, 'projects')
    }

    getLink(id: any) : string{
        return `/project/${id}`
    }

    formatHtml(key:any) {
        return this.linkedData.getName(key)
    }
}

export class BilledVariable extends PivotVariable {
    constructor(name: string) {
        super(name)
    }

    extractKey(row: any): string {
        return row?.isInteractive ? 'free' :
            row.error ? 'error' : 'billed'
    }
}

export class DynamicPivotVariable extends PivotVariable {

    metaKey: string

    constructor(name: string, metaKey: string) {
        super(name, ".meta." + metaKey + "."+name)
        this.metaKey = metaKey
    }

    extractKey(row: any): string {
        return row?.meta?.[this.metaKey].find((x:any) => x.name == this.name)?.['value']?.toString() ?? ""
    }

}

export class LastPlayedActionVariable extends PivotVariable {

    cache= new Map<string, ActionDTO>()

    constructor() {
        super("Last Action", "lastPlayedActionId")
    }

    sortedHeaders(data: any[]): PivotHeader[] {
        const headers = super.sortedHeaders(data)
        const actions = Promise.all(headers.map(async (header:PivotHeader) => {
            if (header.key) {
                if (!this.cache.has(header.key)) {
                    const action= await (getAction(header.key).catch(() =>({inputs:[]})))
                    this.cache.set(header.key, action)
                }
                const action = this.cache.get(header.key)
                if (action) {
                    header.html = (action.index+1) + ". " + userActionVerb(action)
                }
            }
        }))
        return headers
    }

    extractKey(row: any): string {
        return row?.meta?.lastPlayedActionId?.toString() ?? ""
    }
}
