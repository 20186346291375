<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { getTags } from "@/services/ProjectService"
import type { ProjectDTO, TagDTO, TestCaseDTO } from "@/types/gen"

interface Props {
    project: ProjectDTO
    tests: TestCaseDTO[]
}

const props = defineProps<Props>()

const tags = ref<TagDTO[]>([])
const groupByTests = ref(true)
const testArrWithTags = ref<any>({})

onMounted(async () => {
    await reloadTags()
})

const resultRows = computed(() => {
    if (groupByTests.value) {
        let resultArr = []
        for (let tag of tags.value) {
            const rowFound = resultArr.find((row) => row.testcase.id == tag.testId)
            if (rowFound) {
                rowFound.tags.push(tag)
                continue
            }
            const testFound = props.tests.find((test: any) => test.id == tag.testId)
            if (testFound) {
                resultArr.push({
                    testcase: testFound,
                    tags: [tag],
                })
            }
        }
        testArrWithTags.value = resultArr
        return resultArr
    }
    //map testcases with tags
    else {
        let resultArr = []
        for (let testRow of testArrWithTags.value) {
            for (let tagRow of testRow.tags) {
                resultArr.push({
                    testId: testRow.testcase.id,
                    testcaseName: testRow.testcase.name,
                    tagText: tagRow.tag,
                })
            }
        }
        //grouping by tag
        resultArr = resultArr.reduce(function (acc: any, obj) {
            let key = obj["tagText"] as string
            console.warn("key", key)

            if (!acc[key]) {
                acc[key] = []
            }
            acc[key].push(obj)
            return acc
        }, {})
        return resultArr
    }
})

const sortedRows = computed(() => {
    let objectArray = []
    if (groupByTests.value) {
        return resultRows.value.slice(0).sort((a: any, b: any) => {
            if (a.testcase.name < b.testcase.name) {
                return -1
            }
            if (a.testcase.name > b.testcase.name) {
                return 1
            }
            if (groupByTests.value) return 0
            if (a.tags[0].tag < b.tags[0].tag) {
                return -1
            }
            if (a.tags[0].tag > b.tags[0].tag) {
                return 1
            }
            return 0
        })
    } else {
        objectArray = Object.entries(resultRows.value)
        objectArray = sortTags(objectArray)
        return objectArray
    }
})

async function reloadTags() {
    tags.value = await getTags(props.project.id)
}

function toggleMode() {
    groupByTests.value = !groupByTests.value
}

function sortTests(rows: any[]) {
    return rows.slice(0).sort((a, b) => {
        if (a.testcaseName < b.testcaseName) {
            return -1
        }
        if (a.testcaseName > b.testcaseName) {
            return 1
        }
        return 0
    })
}

function sortTags(rows: any[]) {
    return rows.slice(0).sort((a, b) => {
        if (a[0] < b[0]) {
            return -1
        }
        if (a[0] > b[0]) {
            return 1
        }
        return 0
    })
}
</script>

<template lang="pug">
.tags-component
    .tu-small.mt-3
        .data-table
            .table-header(:class="{ 'testcase-col-second': !groupByTests }") Test case
                .switch-order-btn(v-if="!groupByTests", @click="toggleMode") [Sort by Test Cases]
            .table-header(:class="{ 'tags-col-first': !groupByTests }") Tags
                .switch-order-btn(v-if="groupByTests", @click="toggleMode") [Sort by Tags]
            template(v-for="row in sortedRows")
                .testcase-col(:class="{ 'testcase-col-second': !groupByTests, 'align-items-center': !groupByTests }")
                    router-link.tu-link(v-if="groupByTests", :to="`/testcase/${row.testcase.id}`") {{ row.testcase.name }}
                    div(v-else, v-for="test in sortTests(row[1])")
                        router-link.tu-link(:to="`/testcase/${test.testId}`") {{ test.testcaseName }}
                .tags-col(:class="{ 'tags-col-first': !groupByTests }")
                    div
                        div(
                            style="transform: scale(0.4); margin-left: -10px",
                            :style="{ 'margin-top': groupByTests ? '-4px' : '-2px' }"
                        )
                            svg(height="27", width="80", style="margin-left: -10px")
                                polygon(
                                    points="1,1 1,25 60,25 75,15 60,1",
                                    style="fill: black; stroke: black; stroke-width: 4"
                                )
                            svg(height="10", width="10", style="margin-left: -75px")
                                circle(cx="5", cy="5", r="5", stroke="black", stroke-width="3", fill="white")
                    div
                        div(v-if="groupByTests", v-for="tag in row.tags")
                            div {{ tag.tag }}
                        div(v-else) {{ row[0] }}
</template>

<style lang="css" scoped>
.data-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    grid-gap: 8px;
    width: 100%;
    margin-top: 25px;
}

.data-table > * {
    background-color: var(--very-light-grey);
}

.table-header {
    font-size: 0.8rem;
    font-weight: 500;
    background-color: white;
    display: inline-block;
}

.testcase-col,
.tags-col {
    display: grid;
    padding: 10px;
}

.tags-col {
    display: grid;
    grid-template-columns: 40px 1fr;
    padding-left: 0px;
}

.tags-col-first {
    grid-column: 1 / 2;
}

.testcase-col-second {
    grid-column: 2 / 3;
}

.switch-order-btn {
    font-size: 10px;
    font-weight: initial;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
}

.divider {
    background-color: var(--black);
}
</style>
