<script lang="ts" setup>
import useEventListener from "@/composables/useEventListener"
import { ref } from "vue"
import { useRouter } from "vue-router"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"

interface Props {
    backUrl?: string
}

const props = defineProps<Props>()

const router = useRouter()

const wordpressBase = ref(WORDPRESS_BASE)
const showMenu = ref(false)

useEventListener(document, "click", closeMenu)

function toggleMenu() {
    showMenu.value = !showMenu.value
}

function closeMenu() {
    if (showMenu.value) showMenu.value = false
}

function goBack() {
    if (props.backUrl) router.push(props.backUrl)
    else history.back()
}
</script>

<template lang="pug">
.header-component
    .header.pl-4.pr-4
        v-navigate-back.mr-2(@goBack="goBack")
        span.bcrumb Synchronize with aqua
        slot(name="breadcrumb")
            span.bcrumb &nbsp;
        .documentation.invisible-in-print
            a.link(:href="`${wordpressBase}/documentation`", target="_blank") Documentation
    .gradient-bar
</template>

<style lang="css" scoped>
.header {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    align-items: center;
    justify-items: center;
    height: 70px;
}

.bcrumb {
    justify-self: start;
}

.link {
    color: var(--black);
}

.link:hover {
    color: rgb(17, 140, 24);
}

.logo,
.bcrumb,
.documentation {
    padding: 5px;
}

.logo img {
    width: 50px;
}

.gradient-bar {
    background-color: var(--black);
    background-image: linear-gradient(160deg, rgb(17, 140, 24) 0%, rgb(242, 116, 5) 100%);
    height: 5px;
}

.login {
    margin-left: 20px;
    margin-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.header-component {
    background-color: white;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
</style>
