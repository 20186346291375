<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from "vue"
import { getBillingRecords } from "@/services/ProjectService"
import VActionButton from "@/common/components/form/VActionButton.vue"
import PivotTable from "@/common/pivottable/PivotTable.vue"
import * as pivotAggregators from "@/common/pivottable/PivotDataAggregators";
import * as pivotVariables from "@/common/pivottable//PivotVariables";
import type { LinkedEntitiesDTO } from "@/types/gen";
import { anyTypeAnnotation } from "@babel/types";
import { useStore } from "@/store/useStore";


const years = computed(() => [2021, 2022, 2023, 2024])
const months = computed(() => [0,1,2,3,4,5,6,7,8,9,10,11])
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const year = ref<number>(new Date().getFullYear())
const month = ref<number>(new Date().getMonth())
const userId = ref<string>()
const isLoading = ref(true)
const showPivotControls = ref(false)
const billingRecords = ref<any[]>([])
const linkedData = ref<LinkedEntitiesDTO>({} as LinkedEntitiesDTO)

const variables = ref([
    new pivotVariables.PivotDateVariable("Date", "startTime").setRole("rows"),
    new pivotVariables.ProjectVariable("projectId", linkedData),
    new pivotVariables.TestcaseVariable("testCaseId", linkedData),
    new pivotVariables.PivotVariable("Schedule","scheduleId"),
    new pivotVariables.PivotVariable("TestResult", "status"),
    new pivotVariables.PivotVariable("DeviceType", "systemName"),
    new pivotVariables.BilledVariable("Billed").setRole("columns"),
    new pivotVariables.PivotVariable("Id","recordingId"),
])

const aggregators = ref([
    {
        name: "Minutes",
        aggregator: new pivotAggregators.SumMinutesAggregator("durationSeconds")
    },
        {
        name: "Total time",
        aggregator: new pivotAggregators.SumSecondsAggregator("durationSeconds")
    }, {
        name: "Count",
        aggregator: new pivotAggregators.TotalAggregator()
    }, {
        name: "PASSED rate",
        aggregator: new pivotAggregators.RecordingStatusAggregator()
    }, {
        name: "Raw last value",
        aggregator: new pivotAggregators.RawLastElementAggregator()
    },
])

onMounted(async () => {
    if (useStore().getUserData().admin) {
        userId.value = useStore().getUserData().id
    }
    await loadData()
})

async function loadData() {
    isLoading.value = true
    try {
        const data = await getBillingRecords(year.value, month.value+1, userId.value)
        billingRecords.value = data.records ?? []
        linkedData.value = data.linkedEntities ?? {}
    } finally {
        isLoading.value = false
    }
}

</script>

<template lang="pug">
.usage-analyzer
    .form.date-selection
        select.form-control(v-model="year" @change="loadData")
            option(v-for="y in years" :valye="y") {{ y }}
        select.form-control(v-model="month" @change="loadData")
            option(v-for="m in months" :value="m") {{ monthNames[m] }}
        div
            input.form-control(v-if="userId!==undefined" v-model="userId" @change="loadData")
        .empty
        div
            v-action-button.invisible-in-print(
                main="Configure",
                :mainIconClass="['fas', 'fa-cog']"
                @main="showPivotControls = !showPivotControls")
    div(v-if="isLoading")
        .spinner(style="top: 5em; left: 10em")
            .spinner-item
            .spinner-item
            .spinner-item
    pivot-table(v-show="!isLoading"
        :showControls="showPivotControls"
        :variables="variables"
        :aggregators="aggregators"
        :data="billingRecords"
        )
</template>

<style lang="css" scoped>
.usage-analyzer {
    display: grid;
    grid-template-rows: min-content 1fr;
    row-gap: 1em;
    margin-top: 2ex;
}
.date-selection {
    display: grid;
    width: 100%;
    grid-template-columns: 10em 10em 10em 1fr max-content;
    column-gap: 1ex;
}
</style>
