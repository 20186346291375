<script lang="ts" setup>
import { computed, ref, useSlots, watch } from "vue"
import VDeleteButton from "@/common/components/form/VDeleteButton.vue"
import VOneLevelMenu from "./VOneLevelMenu.vue";

interface Props {
    dropDownItems: any[],
    show?: boolean
}

const props = defineProps<Props>()
const slots = useSlots()

const showMenu = ref(false)
const itemToClick = ref<any>(null)

const emit = defineEmits<{
  (event: "showMenuChanged", data: boolean): void
}>()

watch(showMenu, (newValue) => {
    emit("showMenuChanged", newValue)
})

function toggleMenu() {
    showMenu.value = !showMenu.value
    if (!showMenu.value) itemToClick.value = null
}

function clickItem(item: any) {
    if (!item.confirm) {
        item.callback()
        showMenu.value = false
    } else {
        itemToClick.value = item
    }
}

function clickConfirmed(confirmed: boolean) {
    if (confirmed) {
      itemToClick.value.callback()
    }
    itemToClick.value = null
    showMenu.value = false
}

</script>

<template lang="pug">
.multilevel-menu
    button.btn.tu-btn(
            @click.stop="toggleMenu",
            @click.prevent.stop=""
        )
        i.fas.fa-ellipsis-v
    .multilevel-menu-container
      .modal-backdrop(v-if="showMenu", @click.prevent.stop="toggleMenu")
      .multi-level-navigation(v-if="showMenu")
        v-one-level-menu(
          v-if="!itemToClick"
          :dropDownItems="dropDownItems"
          @clickItem="clickItem"
        )
        .delete-button.ml-2.mr-2(v-else)
          v-delete-button(
              :confirmModeOnInit="true",
              @itemDeleted="clickConfirmed(true)",
              @itemDeletionCanceled="clickConfirmed(false)"
          )
</template>

<style lang="css" scoped>
.multilevel-menu-container {
  position: relative;
}
.multi-level-navigation {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
  right: 1.8em;
  top: 0em;
  position: absolute;
  z-index: 501;
}
.modal-backdrop {
    z-index: 500;
    opacity: 0.05;
}
</style>
