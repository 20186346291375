<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import { listProjects, createProject, getUserTeams } from "@/services/ProjectService"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import VHeader from "@/common/components/layout/VHeader.vue"
import { useStore } from "@/store/useStore"
import HomeProfile from "@/modules/home/components/HomeProfile.vue"
import UsageAnalyzer from "@/modules/home/components/UsageAnalyzer.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import type { GetProjectsProject, TestupTeam } from "@/types/gen/"

const router = useRouter()
const route = useRoute()

const projectsLoaded = ref(false)
const projects = ref<GetProjectsProject[]>([])
const teamIds = ref<TestupTeam[]>([])
const selectedTeam = ref<TestupTeam>()
const loading = ref(true)

const { incUserLevel } = useStore()

onMounted(async () => {
    await refresh()
    document.title = "testup.io"
})

const hasProjects = computed(() => {
    return !!projects.value.length
})

const version = computed(() => {
    return TESTUP_VERSION
})

const filteredProjects = computed(() => {
    const selectedTeamId = selectedTeam?.value?.team_id

    if (!selectedTeamId) {
        return projects.value
    }

    return projects.value.filter(project => project.teamIds.includes(selectedTeamId))
})

async function refresh() {
    projects.value = await listProjects()
    teamIds.value = await getListOfTeamIds()

    loading.value = false
    projectsLoaded.value = true
}

async function getListOfTeamIds() {
    const userTeams = (await getUserTeams()).filter(team => !!team.team_id)

    const projectTeamIds = userTeams.map(team => team.team_id)

    const uniqueTeamsIdsFromProjects = [...new Set(projects.value
        .flatMap(project => project.teamIds)
        .filter(teamId => !projectTeamIds.includes(teamId)))]

    uniqueTeamsIdsFromProjects
        .map(teamId => { return { team_id: teamId, team_name: teamId.toString(), role: "" } as TestupTeam })
        .forEach(team => userTeams.push(team))

    return userTeams
}

async function doCreateProject() {
    incUserLevel(1, route)
    const project = {
        name: "Project name",
    }
    const newProject = await createProject(project)
    router.push(`/project/${newProject.id}`)
}

function ownership(project: GetProjectsProject) {
    return  `You${project.owned_by_me ? '': " don't"} own this project`
}

function isEnabled(team: any): boolean {
    return selectedTeam.value === team
}

function selectProjectsByTeam(team: TestupTeam) {
    if (selectedTeam.value !== team) {
        selectedTeam.value = team
    } else {
        selectedTeam.value = undefined
    }
}

</script>

<template lang="pug">
.start-view.avoid-print-break
    detailed-view(:tabNames="['Projects', 'Usage', 'Profile']")
        template(#title)
            h4 Projects
        template(#breadcrumb)
            v-breadcrumb(:emptyMode="true", :simpleMode="true")
        template(#button-group)
            a.dropdown-item(@click="refresh")
                .dropdown-item-icon
                    i.fas.fa-sync-alt
                span Reload
        template.bg(#tab0)
            .non-grid-tab-content
                .spinner-container(v-if="loading")
                    .spinner
                        .spinner-item
                        .spinner-item
                        .spinner-item
                .teams
                    .tu-toggle-button.team-selector(v-for="team in teamIds" @click="selectProjectsByTeam(team)" :class="{enabled: isEnabled(team)}")
                        h5 {{ team.team_name }}
                .project-list.pt-5.pb-3.tu-tab-area.avoid-print-break
                    .tu-card.avoid-print-break(@click="doCreateProject")
                        .card-header.set-height.reverse-on-hover
                            i.fas.fa-folder-plus.fa-7x
                            h5 Create Project
                    .tu-card(v-if="loading" v-for="opac in [.8, .5, .3]" :style="'opacity: '+opac")
                        .card-header.set-height
                    router-link.d-block.avoid-print-break(
                        v-if="!loading",
                        v-for="p in filteredProjects",
                        :key="p.id",
                        :to="`/project/${p.id}`"
                    )
                        .tu-card.avoid-print-break(style="position: relative")
                            .card-header.avoid-print-break.set-width.reverse-on-hover
                                .project-decoration
                                    i.fas.fa-home(:class="{active: p.owned_by_me}" :title="ownership(p)")
                                    br
                                    i.fa.fa-link.active(v-if="p.is_shared" title="This project is shared with a team")
                                i.fas.fa-folder-open.fa-7x
                                h5 {{ p.name }}
                .tu-small.text-right.version {{ version }}
        template(#tab1)
            usage-analyzer
        template(#tab2)
            home-profile
</template>

<style lang="css" scoped>
.start-view {
    display: grid;
    grid-template-columns: min-content 1fr;
    overflow-y: hidden;
    overflow-x: hidden;
}
.card {
    margin-bottom: 1ex;
    display: grid;
}
.card-header {
    display: grid;
    grid-template-columns: auto;
    width: 200px;
    height: 200px;
    text-align: center;
    background-color: white;
    border-radius: 3px;
}
.project-list a {
    color: var(--black);
    text-decoration: none;
    display: grid;
    width: 200px;
    height: 200px;
}

.project-list {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-template-rows: repeat(auto-fit, 200px);
    grid-gap: 1rem;
    place-items: center;
    padding-top: calc(var(--header-gap) / 2) !important;
    background-color: var(--very-light-grey);
}

.fa-7x {
    padding-top: 10px;
    font-size: 90px !important;
    color: var(--bright-green) !important;
}

.project-decoration {
    position: absolute;
    right: 10px;
}
.project-decoration i {
    font-size: 15px !important;
    color: lightgray;
}
.active {
    color: var(--bright-green) !important;
}

.card-header:hover {
    background-color: var(--dark-grey);
}
.version {
    margin-top: 50px;
}
.set-height {
    max-height: 200px;
    margin-bottom: -10px;
}
.set-width {
    width: 200px;
    overflow: hidden !important;
    word-break: break-word;
}
.bg {
    background-color: var(--very-light-grey);
}

.non-grid-tab-content {
    overflow-x: hidden;
    background-color: var(--very-light-grey);
    margin-left: calc(-1 * var(--header-gap));
    margin-right: calc(-1 * var(--header-gap));
    padding-left: calc(var(--header-gap));
    padding-right: 10px;
}

.teams {
    padding-top: 1em;
}
.team-selector {
    margin-right: 15px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
}
</style>
