<script lang="ts" setup>
import { computed, ref, useSlots, watch } from "vue"
import VDeleteButton from "@/common/components/form/VDeleteButton.vue"

interface Props {
    main?: string | null
    dropright?: string
    second?: string
    third?: string
    expandOnInit?: boolean
    hideMenuButton?: boolean
    mainDisabled?: boolean
    mainHidden?: boolean
    mainClass?: string | string[] | null
    mainHtml?: string
    mainHref?: string
    secondDisabled?: boolean
    secondClass?: string | string[]
    secondHtml?: string
    thirdHtml?: string
    mainIconClass?: string | string[]
    secondIconClass?: string | string[]
    thirdIconClass?: string | string[]
    dropDownItems?: any[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "showMenuChanged", data: boolean): void
    (event: "main"): void
    (event: "second"): void
    (event: "third"): void
}>()
const slots = useSlots()

const showMenu = ref(!!props.expandOnInit)
const itemToClick = ref<any>(null)

const dropDownItemsFiltered = computed(() => {
    return (props.dropDownItems ?? []).filter((item) => !item.hidden)
})

watch(showMenu, (newValue) => {
    emit("showMenuChanged", newValue)
})

function toggleMenu() {
    showMenu.value = !showMenu.value
    if (!showMenu.value) itemToClick.value = null
}

function clickItem(item: any) {
    if (!item.confirm) {
        item.callback()
        showMenu.value = false
    } else {
        itemToClick.value = item
    }
}

function clickConfirmed(confirmed: boolean) {
    if (confirmed) itemToClick.value.callback()
    itemToClick.value = null
    showMenu.value = false
}

function hasSlot() {
    return !!slots.default
}
</script>

<template lang="pug">
.content
    template(v-if="(main || mainHtml) && !mainHidden")
        button.btn.mr-2(
            v-if="!mainHref",
            :class="mainClass || ['tu-btn']",
            :disabled="mainDisabled",
            @click.prevent.stop="$emit('main')"
        )
            template(v-if="main")
                i.mr-2(v-if="mainIconClass", :class="mainIconClass")
                span {{ main }}
            span(v-else, v-html="mainHtml")
        a.btn.mr-2(v-else, :class="mainClass || ['tu-btn']", :disabled="mainDisabled", :href="mainHref" :target="mainHref.startsWith('http') ? '_blank' : ''")
            template(v-if="main")
                i.mr-2(v-if="mainIconClass", :class="mainIconClass")
                span {{ main }}
            span(v-else, v-html="mainHtml")
    button.btn.mr-2.tu-toggle-button(
        v-if="second || secondHtml",
        :class="secondClass || ['tu-btn']",
        :disabled="secondDisabled",
        @click.prevent.stop="$emit('second')"
    )
        template(v-if="second")
            i.mr-2(v-if="secondIconClass", :class="secondIconClass")
            span {{ second }}
        span(v-else, v-html="secondHtml")
    button.btn.mr-2.tu-toggle-button(v-if="third || thirdHtml", :class="['tu-btn']", @click.prevent.stop="$emit('third')")
        i.mr-2(v-if="thirdIconClass", :class="thirdIconClass")
        span {{ third }}
    .modal-backdrop(v-if="showMenu", @click.prevent.stop="toggleMenu")
    .btn-group(v-if="hasSlot() || dropDownItems", :class="dropright ? 'dropright' : 'dropleft'")
        button.btn.tu-btn.dropdown-toggle.hide-in-iframe(
            @click.stop="toggleMenu",
            :class="{ 'd-none': hideMenuButton }",
            @click.prevent.stop=""
        )
            i.fas.fa-ellipsis-v
        .dropdown-menu.mt-1(v-if="dropDownItems", :class="{ show: showMenu }", @click.prevent.stop="")
            template(v-if="!itemToClick")
                a.dropdown-item(
                    v-for="item in dropDownItemsFiltered",
                    :key="item.text",
                    :class="{ disabled: item.disabled }",
                    @click="clickItem(item)"
                )
                    .dropdown-item-icon
                        i(:class="item.iconClass")
                    span {{ item.text }}
            .delete-button.ml-2.mr-2(v-else)
                v-delete-button(
                    :confirmModeOnInit="true",
                    @itemDeleted="clickConfirmed(true)",
                    @itemDeletionCanceled="clickConfirmed(false)"
                )
        .dropdown-menu.mt-1(v-else, :class="{ show: showMenu }", @click.prevent.stop="showMenu = false")
            slot
</template>

<style lang="css" scoped>
.content {
    display: inline;
}
.action-buttons {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-gap: 15px;
    padding: 10px;
}
.modal-backdrop {
    z-index: 500;
    opacity: 0.05;
}
</style>
