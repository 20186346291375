<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import VUrl from "@/common/components/form/VUrl.vue"

interface Props {
    schedule: any
}

const props = defineProps<Props>()
const emit = defineEmits(["saveSchedule"])

const triggerOptions = {
    ALL: 'All runs',
    FAILED: 'Failed runs',
    PASSED: 'Passed',
    MANUAL: 'Manual',
    DISABLED: 'Disabled',
}

const channelOptions = {
    EMAIL: 'Email',
    SLACK: 'Slack',
    MICROSOFT_TEAMS: 'Microsoft Teams',
    DISCORD: 'Discord',
}

const placeholders = {
    EMAIL: 'test@email.com',
    SLACK: 'https://hooks.slack.com/services/XXX/XXXX',
    MICROSOFT_TEAMS: 'https://XXX.webhook.office.com/webhookb2/XXX@XXX/IncomingWebhook/XXX/XXX',
    DISCORD: 'https://discord.com/api/webhooks/XXX/XXXX',
}

async function saveSchedule() {
    emit("saveSchedule")
}

function updateScheduleProperty(property: string, newValue: string) {
    props.schedule[property] = newValue
    saveSchedule()
}

function addNotification() {
    props.schedule.notificationConfig.targets.push({
        channel: "EMAIL",
        trigger: "ALL",
        hook: ""
    })
    saveSchedule()
}

function deleteNotification(targetIndex: number) {
    props.schedule.notificationConfig.targets.splice(targetIndex, 1)
    saveSchedule()
}

</script>

<template lang="pug">
.timings
    .notifications-section
        div
            v-action-button.invisible-in-print(
                main="Add Notification",
                :mainIconClass="['fas', 'fa-plus-circle']",
                @main="addNotification"
            )

        .notifications-table
            .notifications-table-title Trigger
            .notifications-table-title Channel
            .notifications-table-title Webhook/E-mail
            .notifications-table-title &nbsp;
            template(v-for="(target, targetIndex) in schedule.notificationConfig.targets" :key="targetIndex")
                div(style="margin-bottom: 7px")
                    select.select.custom-select.select-trigger(v-model="target.trigger" @change="saveSchedule")
                        option(v-for="(trigger, triggerKey) in triggerOptions" :value="triggerKey" :key="triggerKey") {{ trigger }}
                div
                    select.select.custom-select.select-channel(v-model="target.channel" @change="saveSchedule" :disabled="target.trigger=='DISABLED'")
                        option(v-for="(channel, channelKey) in channelOptions" :value="channelKey" :key="channelKey") {{ channel }}
                div(style="padding-left: 15px")
                    .input-group.input-group-sm(v-if="target.channel=='EMAIL'")
                        .input-group-prepend
                            span.input-group-text
                                i.fas.fa-envelope
                        input.form-control(
                            :placeholder="placeholders[target.channel]",
                            :disabled="target.trigger=='DISABLED'"
                            v-model="target.hook"
                            @change="saveSchedule"
                            type="email"
                        )
                    v-url(v-else
                        :placeholder="placeholders[target.channel]",
                        :disabled="target.trigger=='DISABLED'"
                        v-model="target.hook"
                        @change="saveSchedule"
                    )
                div.delIcons
                    .delBtn(@click="deleteNotification(targetIndex)")
                        i.fas.fa-trash
</template>

<style lang="css" scoped>
.notifications {
    margin-top: 20px;
}

.notifications-section {
    margin-top: 20px;
}

.notifications-table {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr max-content;
    gap: 10px;
    width: 80vw;
    margin-top: 10px;
    margin-bottom: 10px;
}
.notifications-table-title {
    margin-left: 15px;
}
.delIcons{
    display: grid;
    grid-auto-flow: column;
    padding-right: 6px;
    margin-left: 10px;
}
.delBtn {
    cursor: pointer;
    width: fit-content;
    padding-left: 3px;
    padding-right: 3px;
}

label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-select {
    margin-left: 15px;
    margin-right: 15px;
}

.checkbox-label {
    margin-left: 15px;
}
</style>
