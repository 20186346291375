<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { getTestCase, getActions, getLatestPassedRecording, getRecording } from "@/services/ProjectService"
import { getMatchImageLink, setAquaLink } from "@/common/aqua/TestupWrapper"
import {
    getAquaBase,
    getProjects,
    getTestExecutions,
    getTestSteps,
    getTestCase as aquaGetTestCase,
    deleteTestCase,
    getFolders,
    AquaTestCaseBuilder,
    AquaExecutionBuilder,
} from "@/common/aqua/AquaService"
import HeaderComponent from "@/common/aqua/HeaderComponent.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import SelectAquaFolder from "@/common/aqua/SelectAquaFolder.vue"
import AquaSettings from "@/common/aqua/AquaSettings.vue"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import { useRoute, useRouter } from "vue-router"
import SynchronizeExecutions from "@/common/aqua/SynchronizeExecutions.vue"
import type { ActionDTO, TestCaseDTO } from "@/types/gen"

const route = useRoute()
const router = useRouter()

const wordpressBase = ref(WORDPRESS_BASE)

const step = ref(1)
const aquaUrl = ref("")
const progress = ref({
    step: 0,
    total: 0,
})
const testup = ref<any>({
    testCaseId: 0,
    testCase: {},
    actions: [],
})
const aqua = ref<any>({
    status: "loading",
    formattedId: false,
    testCase: {},
    steps: [],
    executions: [], // deprecated
})

const detailedView = ref<InstanceType<typeof DetailedView>>()
const selectFolder = ref<InstanceType<typeof SelectAquaFolder>>()

onMounted(async () => {
    await reload()
})

const tags = computed(() => {
    return testup.value.actions
        .filter((a: any) => a.type == "MARKER" && !a.inputs[0].text.match(/^#/))
        .map((a: any) => a.inputs[0].text)
})

const testupTestUrl = computed(() => {
    return "/testCase/" + testup.value.testCase.id
})

const aquaTestUrl = computed(() => {
    return aquaUrl.value + "/TestCase/" + aqua.value.testCase.Id
})

const aquaTestCaseMenu = computed(() => {
    return [
        {
            iconClass: ["fas", "fa-link"],
            text: "Set requirements",
            callback: () => router.push(`/ext/aqua/req/${aqua.value.testCase.Id}?testupId=${testup.value.testCaseId}`),
            disabled: !aqua.value.formattedId,
        },
        {
            iconClass: ["fas", "fa-trash"],
            text: "Delete in aqua",
            callback: () => deleteInAqua(aqua.value.testCase.Id),
            disabled: !aqua.value.formattedId,
        },
        {
            iconClass: ["fas", "fa-sync-alt"],
            text: "Reload",
            callback: reload,
        },
    ]
})

watch(
    () => route.params.testCaseId,
    (value) => {
        if (value) reload()
    }
)

async function reload() {
    testup.value.testCaseId = route.params.testCaseId
    step.value = 1
    aqua.value.status = "loading"
    aqua.value.formattedId = false
    aqua.value.testCase = {}
    aqua.value.steps = []
    aqua.value.executions = []
    testup.value.testCase = await getTestCase(testup.value.testCaseId)
    await loadActions(testup.value.testCase)
    const options = testup.value.testCase.config.options
    const aquaTestCaseId = options.find((x: any) => x.name == "aquaTestCaseId")
    aquaUrl.value = await getAquaBase()
    if (!aquaUrl.value) {
        aqua.value.status = "nolink"
    } else if (aquaTestCaseId && aquaTestCaseId.value) {
        aqua.value.formattedId = aquaTestCaseId.value
        aqua.value.testCase = (await aquaGetTestCase(aquaTestCaseId.value)).data
        if (aqua.value.testCase.Id) {
            await getTestSteps(aqua.value.testCase.Id).then((x: any) => (aqua.value.steps = x.data)),
            aqua.value.status = "loaded"
        } else {
            aqua.value.status = "deleted"
        }
    } else {
        aqua.value.status = "notsync"
    }
    step.value = 1
}

async function loadActions(testCase: TestCaseDTO): Promise<any> {
    let predessorActions = [] as ActionDTO[]
    if (testCase.predecessorId) {
        const predecessor = await getTestCase(testCase.predecessorId)
        predessorActions = await loadActions(predecessor)
    }
    const actions = await getActions(testCase.id)
    const allActions = predessorActions.concat(actions)
    testup.value.actions = allActions
    return allActions
}

function exit() {
    router.push(testupTestUrl.value)
}

async function startSync() {
    selectFolder.value?.openDialog()
}

function setProgress(progress:any) {
    if (progress) {
        step.value = 0
        progress.value = progress
    } else {
        step.value = 1
    }
}

async function selectProjectAndFolder(data: any) {
    const {project, folder} = data
    try {
        const builder = new AquaTestCaseBuilder()
        setProgress(builder.progress)
        builder.setName(testup.value.testCase.name)
        builder.setProject(project.Id)
        builder.setFolder(folder.Id)
        await fillTestSteps(builder, testup.value.actions)
        const result = await builder.save()
        await setAquaLink(testup.value.testCase, result.formattedId)
        aqua.value.formattedId = result.formattedId
    } finally {
        await reload()
    }
}

async function synchronizeTest() {
    step.value = 0
    try {
        const builder = new AquaTestCaseBuilder()
        progress.value = builder.progress
        builder.setName(testup.value.testCase.name)
        await fillTestSteps(builder, testup.value.actions)
        await builder.synchronize(aqua.value.testCase, aqua.value.steps)
    } finally {
        await reload()
    }
}

async function deleteInAqua(aquaId: number) {
    await deleteTestCase(aquaId)
    await setAquaLink(testup.value.testCase, "")
    await reload()
}

async function fillTestSteps(builder: any, actions: any[]) {
    let baselineFrames = []
    const baselineRec = await getLatestPassedRecording(testup.value.testCase.id)
    baselineFrames = baselineRec.frames
    let refFrame = null
    for (let action of testup.value.actions) {
        if (action.disabled) continue
        if (action.type == "MARKER" && !action.inputs[0].text.match(/^#/)) {
            refFrame = baselineFrames.find((x: any) => x.actionId == action.id)
            if (refFrame) {
                builder.stepBuilder.setExpectedImage(refFrame.imageUrl)
            }
            builder.addTestStep(action.inputs[0].text)
        } else {
            builder.stepBuilder.fillInstructionFromAction(action)
        }
    }
    builder.stepBuilder.setExpectedImage(refFrame && refFrame.imageUrl)
}

function addScreenshots(stepBuilder: any, frame: any, baselineRecording: any) {
    stepBuilder.setObservedImage(frame.imageUrl)
    if (!frame.success) {
        if (baselineRecording) {
            const referenceFrame = baselineRecording.frames.find((x: any) => x.actionId == frame.actionId)
            if (referenceFrame) {
                stepBuilder.setExpectedImage(frame.imageUrl)
            }
        }
    }
}

function configure() {
    switchTab(2)
}

function switchTab(tab: number) {
    if (!detailedView.value) return

    detailedView.value.updateTab(tab)
}

async function savedSettings() {
    switchTab(0)
    await reload()
}

function goBack() {
    if (testupTestUrl.value) router.push(testupTestUrl.value)
    else history.back()
}
</script>

<template lang="pug">
.sync-test-case-view
    select-aqua-folder(ref="selectFolder" @select="selectProjectAndFolder")
    detailed-view(
        ref="detailedView",
        :tabNames="['Test steps', 'Executions', 'Settings']"
    )
        template(#navigate-back)
            .navigate-back
                v-navigate-back(@goBack="goBack")
        template(#breadcrumb)
            .header
                span.bcrumb Synchronize with aqua
        template(#button-group)
            .documentation.invisible-in-print
                a.link(:href="`${wordpressBase}/documentation`", target="_blank") Documentation
        template.bg(#tab0)
            .main.mb-4.container
                div(v-if="step == 0")
                    p Uploading data {{ progress.step }} / {{ progress.total }}
                div(v-if="step == 1")
                    .test-case-compare
                        img.logo(src="@/assets/images/testup.webp", @click="exit")
                        svg.logo(width="100", height="80", viewBox="0 0 122.88 61.49")
                            polygon(
                                style="fill: lightgray",
                                points="122.88,30.75 90.9,0 90.9,19.79 31.98,19.79 31.98,0 0,30.75 31.98,61.49 31.98,41.7 90.9,41.7 90.9,61.49 122.88,30.75"
                            )
                        img.logo(src="@/assets/images/aqua.png")

                        div(style="grid-column: 1/4; min-height: 1em")

                        .system-group
                            a.tu-link(v-if="testup.testCase.id", :href="testupTestUrl")
                                h4 {{ testup.testCase.name }}
                            div
                                ol
                                    li(v-for="tag in tags") {{ tag }}

                        div
                        .system-group(v-if="aqua.status")
                            v-action-button(:dropDownItems="aquaTestCaseMenu", style="float: right")
                            div(v-if="aqua.status == 'nolink'")
                                p The aqua connection is not yet configured
                                .sync-action.btn(@click="configure")
                                    i.fas.fa-paper-plane
                                    span &nbsp; Configure aqua connection
                            div(v-if="aqua.status == 'notsync'")
                                p The testcase does not yet exist in aqua
                                .sync-action.btn(@click="startSync")
                                    i.fas.fa-paper-plane
                                    span &nbsp; Create in aqua
                            div(v-if="aqua.status == 'deleted'")
                                p The referenced testcase does not exist
                                .sync-action.btn(@click="startSync")
                                    i.fas.fa-paper-plane
                                    span &nbsp; Recreate in aqua

                            .aqua-title(v-if="aqua.testCase.Id")
                                a.tu-link(v-if="aqua.testCase.Id", :href="aquaTestUrl", target="_blank")
                                    h4 {{ aqua.testCase.FormattedId }} {{ aqua.testCase.Name }}
                            ol
                                li(v-for="step in aqua.steps") {{ step.Name }}


                            div(v-if="aqua.status == 'loaded'")
                                .sync-action.btn(@click="synchronizeTest")
                                    i.fas.fa-paper-plane
                                    span &nbsp; Synchronize test steps
                            div(v-if="aqua.status == 'loading'")
                                span Loading ....

                    hr
                div(v-if="step == 4")
                    h2 Upload complete
        template.bg(#tab1)
            synchronize-executions(v-if="testup.testCase.id && aqua.testCase.Id"
                :testupTest="testup.testCase" :testupActions="testup.actions"
                :aquaTest="aqua.testCase"
                :aquaUrl="aquaUrl")
        template.bg(#tab2)
            aqua-settings(@saved="savedSettings")
</template>

<style lang="css" scoped>
h1,
h2 {
    text-align: center;
}
.sync-test-case-view {
    display: grid;
    grid-template-columns: max-content;
    height: 100vh;
}
.main {
    margin-top: 50px;
}
.aqua-title {
    display: grid;
    grid-template-columns: auto min-content;
}
.test-case-compare {
    display: grid;
    grid-template-columns: 1fr 1em 1fr;
}
.system-group {
    background-color: #f1f1f1;
    padding: 2em;
    justify-self: stretch;
}
.logo {
    height: 80px;
    place-self: center;
}
.tu-link {
    color: black;
}
.sync-action {
    background-color: white;
    padding: 1em;
    text-align: center;
    width: 100%;
}
.sync-action:hover {
    color: white;
    background-color: dimgray;
}
.header {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    align-items: center;
    justify-items: center;
    margin-bottom: 10px;
}

.navigate-back {
    margin-bottom: 10px;
}
.link {
    color: var(--black);
}

.link:hover {
    color: rgb(17, 140, 24);
}

.logo,
.bcrumb,
.documentation {
    padding: 5px;
}

.bcrumb {
    justify-self: start;
    padding-left: 0;
}
</style>
