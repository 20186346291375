<script lang="ts" setup>
import { ref, watch } from "vue"

interface Props {
    modelValue: string
    placeholder?: string
    disabled?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: string): void
    (event: "leaveFocus", data: Event): void
}>()

const url = ref(props.modelValue)

watch(
    () => props.modelValue,
    () => {
        url.value = props.modelValue
    }
)

function update() {
    if (!!url.value && !url.value.startsWith("$PREDECESSOR_URL") && !/^[a-zA-Z]+:\/\//.test(url.value)) {
        url.value = "http://" + url.value
    }
    emit("update:modelValue", url.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}
</script>

<template lang="pug">
.input-group.input-group-sm
    .input-group-prepend
        span.input-group-text
            i.fas.fa-globe-americas
    input.form-control(
        :placeholder="placeholder || 'http://example.com'",
        v-model.trim="url",
        type="url",
        :disabled="!!disabled",
        @change="update",
        @keydown.enter="leaveFocus",
        @keydown.stop=""
    )
</template>
