/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BillingRecord = {
    id?: number;
    createdTime?: string;
    startTime?: string;
    endTime?: string;
    durationSeconds?: number;
    error?: string;
    projectOwner?: string;
    projectId: number;
    testCaseId: number;
    recordingId: number;
    scheduleId?: number;
    executionId?: number;
    startingUserId?: string;
    deviceId?: string;
    recordingStatus: BillingRecord.recordingStatus;
    recordingResult?: BillingRecord.recordingResult;
    isInteractive: boolean;
    systemName?: string;
};

export namespace BillingRecord {

    export enum recordingStatus {
        NEW = 'NEW',
        SELECTED = 'SELECTED',
        SUBMITTED = 'SUBMITTED',
        RUNNING = 'RUNNING',
        DEVICE_DISCONNECTED = 'DEVICE_DISCONNECTED',
        STOPPED = 'STOPPED',
        PASSED = 'PASSED',
        FAILED = 'FAILED',
    }

    export enum recordingResult {
        PASSED = 'PASSED',
        FAILED = 'FAILED',
    }


}

