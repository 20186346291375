/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TeamProjectPermissions = {
    team_id: number;
    permission: TeamProjectPermissions.permission;
};

export namespace TeamProjectPermissions {

    export enum permission {
        NONE = 'NONE',
        VIEW_IFRAME = 'VIEW_IFRAME',
        EDIT = 'EDIT',
        READONLY = 'READONLY',
    }


}

