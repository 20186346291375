<script lang="ts" setup>
import { computed, ref, useSlots, watch } from "vue"
import VDeleteButton from "@/common/components/form/VDeleteButton.vue"

interface Props {
    dropDownItems: any[],
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (event: "clickItem", item: any): void
}>()

function clickItem(item: any) {
  emit('clickItem', item)
}
</script>

<template lang="pug">
ul.menu-single-level
    li(
        v-for="item in dropDownItems" 
        :class="{'has-sub': !!item.nextLevel}"
        @click.prevent.stop="clickItem(item)"
      ) 
        i(:class="item.iconClass")
        span {{ item.text }}
        v-one-level-menu(
            v-if="!!item.nextLevel",
            :dropDownItems="item.nextLevel",
            @clickItem="clickItem"
        )
</template>

<style lang="css" scoped>
.multi-level-navigation ul,
.multi-level-navigation ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.multi-level-navigation ul li {
    padding: 10px;
    cursor: pointer;
}

.multi-level-navigation ul li i {
    width: 1.4em;
}

.menu-single-level {
    background: var(--white);
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    color: var(--black);
}

.multi-level-navigation ul {
  position: relative;
  z-index: 501;
  float: left;
}

.multi-level-navigation ul li {
  float: left;
  min-height: 0.05em;
  line-height: 1em;
  vertical-align: middle;
  position: relative;
}

.multi-level-navigation ul li.hover,
.multi-level-navigation ul li:hover {
  position: relative;
  z-index: 510;
  background: #f8f9fa;
}

.multi-level-navigation ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 520;
  width: 100%;
}

.multi-level-navigation ul ul li { float: none; }

.multi-level-navigation ul ul ul {
  top: 0;
  right: 0;
}

.multi-level-navigation ul li:hover > ul { visibility: visible; }

.multi-level-navigation ul ul {
  top: 0;
  left: 99%;
}

.multi-level-navigation ul li { float: none; }

.multi-level-navigation ul ul { margin-top: 0.05em; }

.multi-level-navigation {
  width: 16em;
  zoom: 1;
}

.multi-level-navigation:before {
  content: '';
  display: block;
}

.multi-level-navigation:after {
  content: '';
  display: table;
  clear: both;
}


.multi-level-navigation > ul { width: 16em; }

.multi-level-navigation ul ul { width: 16em; }

.multi-level-navigation li { position: relative; }

.multi-level-navigation ul li.has-sub:after {
  content: '>';
  position: absolute;
  right: 0.5em;
}

.multi-level-navigation.align-right { float: right; }

.multi-level-navigation.align-right li { text-align: right; }


.multi-level-navigation.align-right ul li.has-sub > a:after { content: none; }

.multi-level-navigation.align-right ul ul {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 598;
  width: 100%;
}
</style>
