export function getScreenCoordinates(event: MouseEvent | any) {
    let screenX, screenY
    if (!event.touches) {
        screenX = event.clientX
        screenY = event.clientY
    } else {
        if (event.touches.length != 0) {
            screenX = event.touches[0].clientX
            screenY = event.touches[0].clientY
        } else {
            screenX = event.changedTouches[0].clientX
            screenY = event.changedTouches[0].clientY
        }
    }
    return new DOMPoint(screenX, screenY)
}

export function getMatrix(image: HTMLImageElement) {
  const matrix = new DOMMatrix()
  if (!image) return matrix
  const rect = image.getBoundingClientRect()
  const zoom = Math.min(
      image.clientWidth / image.naturalWidth,
    image.clientHeight / image.naturalHeight)
  matrix.translateSelf(
    rect.left + (image.clientWidth - image.naturalWidth * zoom)/2,
    rect.top + (image.clientHeight - image.naturalHeight * zoom)/2)
  matrix.scaleSelf(zoom, zoom)
  return matrix
}

export function getImageCoords(image: HTMLImageElement, point: DOMPoint) {
    return getMatrix(image).inverse().transformPoint(point)
}

export function highlightRowsAndCols(p:DOMPoint, elt: HTMLElement) {
    let node : HTMLElement | null= elt;
    while (node) {
      p.x += node.scrollLeft
      p.y += node.scrollTop
      node = node.parentElement
    }
    for (let div of elt.childNodes) {
      if (div instanceof HTMLElement) {
        const noX = div.classList.contains("no-x")
        const noY = div.classList.contains("no-y")
        if (p.y > div.offsetTop && p.y < div.offsetTop + div.offsetHeight && !noY||
            p.x > div.offsetLeft && p.x < div.offsetLeft + div.offsetWidth && !noX) {
          div.classList.add("highlighted")
        } else {
          div.classList.remove("highlighted")
        }
      }
    }
  }

  export function updateHighlight(event: MouseEvent) {
    const p = getScreenCoordinates(event)
    const elt = event.currentTarget as HTMLElement
    highlightRowsAndCols(p, elt)
  }

export function getTypeOfClick(event: PointerEvent) : string {
  if (event.detail == 1) {
    if (event.button == 2)
      return "RIGHT_CLICK"
    else
      return "CLICK"
  }
  if (event.detail == 2) {
      return "DOUBLE_CLICK"
  }
  if (event.detail == 3) {
      return "RIGHT_CLICK"
  }
  return "CLICK"
}
