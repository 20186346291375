/**
 *
 * @param {*} first
 * @param {*} second
 * @param {string | null} key
 * @returns
 */
function levJoin(first, second, key = null) {
    // phase 1 : create DP table of weights

    function valueOf(item) {
        return key ? item[key] : item
    }

    let DPtable = []
    let innerArr = [0]

    let first_len = first.length
    let second_len = second.length

    for (let i = 0; i <= first_len; i++) {
        //rows
        for (let j = 1; j <= second_len; j++) {
            //cols
            if (i) {
                if (valueOf(first[i - 1]) == valueOf(second[j - 1])) {
                    innerArr.push(DPtable[i - 1][j - 1])
                } else {
                    innerArr.push(Math.min(DPtable[i - 1][j] + 1, innerArr[j - 1]) + 1)
                }
            } else {
                innerArr.push(j)
            }
        }
        DPtable.push(innerArr)
        innerArr = [i + 1]
    }

    // phase 2: create joined array from table
    let i = first_len
    let j = second_len

    let result = []

    while (i || j) {
        if (i && j) {
            if (DPtable[i - 1][j - 1] <= DPtable[i - 1][j] && DPtable[i - 1][j - 1] <= DPtable[i][j - 1]) {
                i--
                j--
                result.unshift(second[j])
                if (valueOf(first[i]) != valueOf(second[j])) result.unshift(first[i])
            } else if (DPtable[i - 1][j] <= DPtable[i][j - 1]) {
                i--
                result.unshift(first[i])
            } else {
                j--
                result.unshift(second[j])
            }
        } else if (j) {
            j--
            result.unshift(second[j])
        } else {
            i--
            result.unshift(first[i])
        }
    }
    return result
}


/**
 *
 * @param {*} inArr
 * @param {*} levArr
 * @param {string | null} key
 * @returns
 */
function augArr(inArr, levArr, key = null) {
    // phase 3: create augmented arrays by repeating elements when they do not match the levenstein arr

    let retArr = []
    let k = 0
    for (let i = 0; i < Object.keys(levArr).length; i++) {
        retArr.push(inArr[k])
        if (
            key
                ? levArr[i + 1] !== undefined && inArr[k + 1] !== undefined && levArr[i + 1][key] === inArr[k + 1][key]
                : levArr[i + 1] === inArr[k + 1]
        ) {
            k++
        } else {
            inArr[k] = { ...inArr[k], notPlayed: true }
        }
    }
    return !Array.isArray(inArr) ? Object.fromEntries(Object.entries(retArr)) : retArr
}

export { levJoin, augArr }
