<script lang="ts" setup>
import { computed } from "vue"

interface Props {
    currentIndex: number
    maxIndex: number
    status: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "currentFrame", data: number): void
}>()

const rangeBackground = computed(() => {
    const coeff = props.maxIndex ? 100 / props.maxIndex : 0
    return (
        "linear-gradient(to right," +
        decideColor.value +
        props.currentIndex * coeff +
        "%, #D0CECE " +
        props.currentIndex * coeff +
        "%, #D0CECE 100%)"
    )
})

const decideColor = computed(() => {
    if (props.status == "RUNNING") {
        return "#FFA500 0%, #FFA500 "
    } else if (props.status == "FAILED") {
        return "#C12C28 0%, #C12C28 "
    }
    return "#94d904 0%, #94d904 "
})

function slideChanged(event: Event) {
    emit("currentFrame", parseFloat((event.target as HTMLInputElement).value))
}
</script>

<template lang="pug">
.viewslider
    .slidecontainer
        input#myRange.slider(
            type="range"
            min="0"
            :max="maxIndex"
            step="1"
            :value="currentIndex"
            @input="slideChanged"
            :style="{ background: rangeBackground }"
        )
</template>

<style lang="css" scoped>
.viewslider {
    display: grid;
    place-items: center;
    text-align: center;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

p.current-total-number {
    width: 100%;
    text-align: left;
}

.slidecontainer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #4472c3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
    transform: scale(1.05);
    width: 95.5%;
}

.slider::-moz-focus-outer {
    border: 0;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 75px;
    width: 45px;
    border-radius: 7px;
    background: #afabab;
    cursor: pointer;
    margin-top: -0px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.slider::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 75px;
    width: 45px;
    border-radius: 7px;
    background: #afabab;
    cursor: pointer;
}

.slider::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 75px;
    width: 45px;
    border-radius: 7px;
    background: #afabab;
    cursor: pointer;
}

span.index {
    font-size: 18px;
}
</style>
