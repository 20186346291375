<template lang="pug">
.virtual-keyboard(:class="{ visible: showKeyboard }")
    .virtual-keyboard-container(:class="{ 'control-active': controlMode, 'alt-active': altMode }")
        i.icon-large.fas.fa-play.virtual-keyboard-play-button.tu-cursor-pointer(@click="play()")
        i.icon-large.fas.fa-times.virtual-keyboard-close-icon.tu-cursor-pointer(@click="closeKeyboard()")
        .virtual-keyboard__contents(@click.stop)
            .virtual-keyboard-main
            .control-arrows
                .virtual-keyboard-control
                .virtual-keyboard-arrows
</template>

<script setup lang="ts">
import { onMounted, shallowRef, ref, computed } from "vue"
import Keyboard from "simple-keyboard"
import "simple-keyboard/build/css/index.css"

const emit = defineEmits<{
    (event: "keypress", key: any): void
    (event: "play"): void
    (event: "text", key: string): void
    (event: "closeKeyboard"): void
}>()
defineProps<{
    showKeyboard: boolean
}>()

const controlMode = ref<any>(false)
const altMode = ref<any>(false)

const play = () => {
    emit("text", inputVirtualKeyboard.value)
    closeKeyboard()
    emit("play")
}

const enterText = () => {
    emit("text", inputVirtualKeyboard.value)
    closeKeyboard()
}

const closeKeyboard = () => {
    emit("closeKeyboard")
    keyboard.value.setOptions({ layoutName: "default" })
    controlMode.value = false
    altMode.value = false
}

const keyboard = shallowRef(null as any)
const inputVirtualKeyboard = ref("")

const isShiftMode = computed(() => keyboard.value.options.layoutName == "shift")

const handleShift = () => {
    const currentLayout = keyboard.value.options.layoutName
    const toggledLayout = currentLayout === "default" ? "shift" : "default"
    keyboard.value.setOptions({ layoutName: toggledLayout })
}

const handleControl = () => {
    controlMode.value = !controlMode.value
}

const handleAlt = () => {
    altMode.value = !altMode.value
}

const keySequencePressed = (key: any, shiftMode = false) => {
    let resultArr = [{ key: key, action: "DOWNUP" }]
    if (shiftMode) {
        resultArr.unshift({ key: "SHIFT", action: "DOWN" })
        resultArr.push({ key: "SHIFT", action: "UP" })
    }
    if (controlMode.value) {
        resultArr.unshift({ key: "CONTROL", action: "DOWN" })
        resultArr.push({ key: "CONTROL", action: "UP" })
    }
    if (altMode.value) {
        resultArr.unshift({ key: "ALT", action: "DOWN" })
        resultArr.push({ key: "ALT", action: "UP" })
    }
    emit("keypress", resultArr)
}

const onKeyPress = (button: string) => {
    const sequenceArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i",
        "j", "k", "l", "m", "n", "o", "p", "q", "r",
        "s", "t", "u", "v", "w", "x", "y", "z",
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "0",
        "-", "=", "[", "]", ";", "'", ",", ".", "/", "`", "\\",
        "~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+",
        "{", "}", "|", ":", '"', "<", ">", "?"]
    const sequenceArrShift = ["A", "B", "C", "D", "E", "F", "G", "H", "I",
        "J", "K", "L", "M", "N", "O", "P", "Q", "R",
        "S", "T", "U", "V", "W", "X", "Y", "Z"]
    const sequenceMap: any = {
        "{f1}": "F1",
        "{f2}": "F2",
        "{f3}": "F3",
        "{f4}": "F4",
        "{f5}": "F5",
        "{f6}": "F6",
        "{f7}": "F7",
        "{f8}": "F8",
        "{f9}": "F9",
        "{f10}": "F10",
        "{f11}": "F11",
        "{f12}": "F12",
        "{enter}": "ENTER",
        "{backspace}": "BACK_SPACE",
        "{tab}": "TAB",
        "{arrowup}": "UP",
        "{arrowdown}": "DOWN",
        "{arrowleft}": "LEFT",
        "{arrowright}": "RIGHT",
        "{pageup}": "PAGE_UP",
        "{pagedown}": "PAGE_DOWN",
        "{home}": "HOME",
        "{end}": "END",
        "{space}": "SPACE",
        "{escape}": "ESCAPE",
        "{print}": "PRINT",
        "{insert}": "INSERT",
        "{delete}": "DELETE"
    }
    if (["{shift}", "{shiftleft}", "{shiftright}"].includes(button)) {
        handleShift()
    }
    else if (["{controlleft}", "{controlright}"].includes(button)) {
        handleControl()
    }
    else if (["{altleft}", "{altright}"].includes(button)) {
        handleAlt()
    }
    else if (sequenceArr.includes(button)) {
        keySequencePressed(button)
    }
    else if (sequenceArrShift.includes(button)) {
        const buttonLowercase = button.toLocaleLowerCase()
        keySequencePressed(buttonLowercase, true)
    }
    else if (sequenceMap[button]) {
        keySequencePressed(sequenceMap[button], isShiftMode.value)
    } else {
        switch (button) {
            case "{command}":
                break
            default:
                break
        }
    }
}

const commonKeyboardOptions = {
    onKeyPress: onKeyPress,
    theme: "hg-theme-default",
    physicalKeyboardHighlight: true,
    syncInstanceInputs: true,
    mergeDisplay: true,
    debug: false,
}

const formatDOM = (dom: Element) => {
    dom.innerHTML = ""
}

onMounted(() => {
    document.querySelectorAll(".virtual-keyboard-main").forEach((dom) => formatDOM(dom))
    document.querySelectorAll(".virtual-keyboard-control").forEach((dom) => formatDOM(dom))
    document.querySelectorAll(".virtual-keyboard-arrows").forEach((dom) => formatDOM(dom))

    keyboard.value = new Keyboard(".virtual-keyboard-main", {
        ...commonKeyboardOptions,
        /**
         * Layout by:
         * Sterling Butters (https://github.com/SterlingButters)
         */
        layout: {
            default: [
                "{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}",
                "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                "{tab} q w e r t y u i o p [ ] \\",
                "{capslock} a s d f g h j k l ; ' {enter}",
                "{shiftleft} z x c v b n m , . / {shiftright}",
                "{controlleft} {command} {altleft} {space} {altright} {command} {print} {controlright}",
            ],
            shift: [
                "{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}",
                "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
                "{tab} Q W E R T Y U I O P { } |",
                '{capslock} A S D F G H J K L : " {enter}',
                "{shiftleft} Z X C V B N M < > ? {shiftright}",
                "{controlleft} {command} {altleft} {space} {altright} {command} {print} {controlright}",
            ],
        },
        display: {
            "{escape}": "Esc",
            "{f1}": "F1",
            "{f2}": "F2",
            "{f3}": "F3",
            "{f4}": "F4",
            "{f5}": "F5",
            "{f6}": "F6",
            "{f7}": "F7",
            "{f8}": "F8",
            "{f9}": "F9",
            "{f10}": "F10",
            "{f11}": "F11",
            "{f12}": "F12",
            "{tab}": "Tab",
            "{backspace}": "Backspace",
            "{enter}": "Enter",
            "{capslock}": "Caps lock",
            "{shiftleft}": "Shift",
            "{shiftright}": "Shift",
            "{controlleft}": "Ctrl",
            "{controlright}": "Ctrl",
            "{altleft}": "Alt",
            "{altright}": "Alt",
            "{print}": "Prnt",
            "{command}": " ♥ ",
        },
    })
    new Keyboard(".virtual-keyboard-control", {
        ...commonKeyboardOptions,
        layout: {
            default: ["{insert} {home} {pageup}", "{delete} {end} {pagedown}"],
        },
        display: {
            "{insert}": "Insert",
            "{home}": "Home",
            "{pageup}": "PgUp",
            "{delete}": "Del",
            "{end}": "End",
            "{pagedown}": "PgDn",
        },
    })
    new Keyboard(".virtual-keyboard-arrows", {
        ...commonKeyboardOptions,
        layout: {
            default: ["{arrowup}", "{arrowleft} {arrowdown} {arrowright}"],
        },
    })
})
</script>

<style>
.virtual-keyboard {
    position: fixed;
    top: 120px;
    left: 10px;
    bottom: 0;

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 10;
}

.virtual-keyboard.visible {
    display: flex;
}

.virtual-keyboard-container {
    background-color: #ececec;
    padding: 10px;
}

.virtual-keyboard-container1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0004;
}
.virtual-keyboard-close-icon.icon-large {
    position: absolute;
    right: 20px;
    top: 18px;
    font-size: 1.5rem !important;
}
.virtual-keyboard-play-button {
    position: relative;
    right: 80px;
}
.virtual-keyboard-play-button::after {
    content: "PLAY";
    font-family: sans-serif;
    font-size: 100%;
    font-weight: 400;
    line-height: 1.15;
    margin: 0;
    display: inline-flex;
    float: right;
    padding: 0px 10px;
}

.virtual-keyboard-input {
    width: 638px;
    height: 35px;
    background-color: #ffffff;
    color: rgb(184, 184, 184);
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;
    text-align: left;
    margin: 0px 0 10px 0;
    padding: 0 0 0 10px;
    border-radius: 2px;
    border-bottom: 2px solid #94d904;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    display: inline-block;
    margin: 5px;
}

.virtual-keyboard__contents {
    display: flex;
    background-color: #0004;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    z-index: 20;
}

.simple-keyboard.hg-theme-default {
    display: inline-block;
}
.virtual-keyboard-main.simple-keyboard,
.virtual-keyboard-arrows.simple-keyboard,
.virtual-keyboard-control.simple-keyboard {
    background-color: transparent;
}
.virtual-keyboard-main.simple-keyboard .hg-row:first-child,
.virtual-keyboard-control.simple-keyboard .hg-row:first-child {
    margin-bottom: 10px;
}
.simple-keyboard .hg-button.selectedButton {
    background-color: rgba(5, 25, 70, 0.53);
    color: white;
}
.simple-keyboard .hg-button.emptySpace {
    pointer-events: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.virtual-keyboard-arrows .hg-row,
.virtual-keyboard-control .hg-row {
    justify-content: space-around;
}
.virtual-keyboard-arrows .hg-button {
    width: 30%;
    flex-grow: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}
.virtual-keyboard-control .hg-button {
    width: 35%;
    flex-grow: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}
.control-arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    background-color: #ececec;
}
.hg-button.hg-functionBtn.hg-button-space {
    width: 350px;
}
.hg-button {
    height: 40px;
    display: block !important;
    background-color: rgb(255, 255, 255) !important;
    text-align: left !important;
    padding-left: 8px !important;
    line-height: 29px !important;
    border-radius: 2px !important;
    float: left !important;
    margin-left: 2px !important;
    margin-bottom: 2px !important;
    cursor: pointer !important;
    transition: box-shadow 0.7s ease !important;
    box-shadow: 0px 2px 0px 1px rgb(219, 219, 219) !important;
    margin-top: 3px !important;
    margin-right: 1px !important;
}

.hg-layout-shift .hg-button-shiftleft,
.hg-layout-shift .hg-button-shiftright,
.control-active .hg-button-controlleft,
.control-active .hg-button-controlright,
.alt-active .hg-button-altleft,
.alt-active .hg-button-altright {
    background-color: #94d904 !important;
}

/* .hg-button.hg-standardBtn:not(:last-child), */
.hg-button-capslock,
.hg-button-command
/* .hg-button-controlleft,
.hg-button-controlright,
.hg-button-altleft,
.hg-button-altright, */
/* .hg-button-f1,
.hg-button-f2,
.hg-button-f3,
.hg-button-f4,
.hg-button-f5,
.hg-button-f6,
.hg-button-f7,
.hg-button-f8,
.hg-button-f9,
.hg-button-f10,
.hg-button-f11,
.hg-button-f12, */
/* .hg-button-escape */
/* .hg-button-insert,
.hg-button-delete,
.hg-button-print, */
/* .hg-button-shiftleft,
.hg-button-shiftright, */
/* .hg-button[data-skbtn="\\"], */
/* .hg-button[data-skbtn="{tab}"], */
/* .hg-button[data-skbtn="`"] */
/* .hg-button[data-skbtn="1"],
.hg-button[data-skbtn="2"],
.hg-button[data-skbtn="3"],
.hg-button[data-skbtn="4"],
.hg-button[data-skbtn="5"],
.hg-button[data-skbtn="6"],
.hg-button[data-skbtn="7"],
.hg-button[data-skbtn="8"],
.hg-button[data-skbtn="9"],
.hg-button[data-skbtn="0"], */
/* .hg-button[data-skbtn="-"],
.hg-button[data-skbtn="="], */
/* .hg-button[data-skbtn="["],
.hg-button[data-skbtn="]"],
.hg-button[data-skbtn=";"],
.hg-button[data-skbtn="'"],
.hg-button[data-skbtn=","],
.hg-button[data-skbtn="."],
.hg-button[data-skbtn="/"] */ {
    background: #b5b5b5 !important;
    cursor: not-allowed !important;
}

@media screen and (max-width: 960px) {
    .hg-button.hg-functionBtn.hg-button-space {
        width: 200px;
    }
    .simple-keyboard .hg-button {
        height: 30px;
    }
    .simple-keyboard .hg-button span {
        font-size: 10px;
    }
}
@media screen and (max-width: 600px) {
    .hg-button.hg-functionBtn.hg-button-space {
        width: 180px;
    }
    .simple-keyboard .hg-button {
        height: 25px;
        padding: 2px;
    }
    .simple-keyboard .hg-button.hg-standardBtn:not(:last-child),
    .simple-keyboard .hg-button.hg-functionBtn:not(:last-child) {
        margin-right: 2px;
    }
    .simple-keyboard .hg-row:not(:last-child) {
        margin-bottom: 2px;
    }
    .virtual-keyboard-main.simple-keyboard .hg-row:first-child,
    .virtual-keyboard-control.simple-keyboard .hg-row:first-child {
        margin-bottom: 5px;
    }
    .simple-keyboard .hg-button.hg-functionBtn span {
        font-size: 6px;
    }
}
</style>
