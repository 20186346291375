import { defineStore } from "pinia"
import { ref, Ref } from "vue"
import { RouteLocationNormalizedLoaded } from "vue-router"
import roboText from "@/assets/roboText.js"
import { PromiseResolvedCallback } from "@/types"
import type { CurrentUserResponse, ImageMatchResult } from "@/types/gen"

export const useStore = defineStore("useStore", () => {
    const sidebarShown = ref(false)
    const dragged = ref(false)
    const glassVisible = ref(false)
    const glassPosition = ref(new DOMPoint())
    const showRobot = location.hostname != "testup-qa"
    const testupLevel = ref(0)
    const msgText = ref("")
    const error = ref<any>(null)
    const lock = ref(Promise.resolve())
    const lockMatcher = ref(Promise.resolve())
    const currentUser = ref<CurrentUserResponse>()
    const drawAnnotation = ref(false)
    const highlightedCandidate = ref<ImageMatchResult | undefined>()

    function setUserData(userData: CurrentUserResponse) {
        currentUser.value = userData
    }

    function getUserData() : CurrentUserResponse {
        return currentUser.value!
    }

    function setDragged(state: boolean) {
        dragged.value = state
    }

    function toggleSidebar() {
        sidebarShown.value = !sidebarShown.value
    }

    function loadText(route: RouteLocationNormalizedLoaded) {
        if (!showRobot) return
        const url = route.path.substring(1)
        const item = roboText.find((item) => item.level == testupLevel.value && item.url.test(url))
        msgText.value = item ? item.text : ""
    }

    function incUserLevel(targetLevel: number, route: RouteLocationNormalizedLoaded) {
        testupLevel.value = Math.max(targetLevel, testupLevel.value)
        localStorage.setItem("testup_level", testupLevel.value.toString())
        loadText(route)
    }

    function runLocked(func: any) {
        lock.value = lock.value.then(func).catch(showError)
    }

    function runLockedMatcher(func: PromiseResolvedCallback) {
        lockMatcher.value = lockMatcher.value.then(func).catch(showError)
    }

    function clearError() {
        error.value = null
    }

    function showError(e: any) {
        console.error(e)
        error.value = e
    }

    function setHighlightedCandidate(candidate: ImageMatchResult | undefined) {
        highlightedCandidate.value = candidate    
    }

    function getHighlightedCandidateRef() : Ref<ImageMatchResult | undefined> {
        return highlightedCandidate
    }

    return {
        showRobot,
        dragged,
        error,
        testupLevel,
        msgText,
        lock,
        sidebarShown,
        drawAnnotation,
        glassVisible,
        glassPosition,
        setUserData, getUserData,
        setDragged,
        loadText,
        incUserLevel,
        runLocked,
        runLockedMatcher,
        showError,
        clearError,
        toggleSidebar,
        setHighlightedCandidate,
        getHighlightedCandidateRef,
    }
})
