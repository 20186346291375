<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from "vue"

interface Props {
    modelValue: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: string): void
    (event: "leaveFocus", data: Event): void
}>()

const secured = ref(false)
const hidden = ref(true)
const v = ref(props.modelValue)
const timer = ref<number | null>(null)

const el = ref<HTMLDivElement>()

onMounted(() => {
    if (props.modelValue) {
        hidden.value = true
        secured.value = true
    }
})

watch(
    () => props.modelValue,
    (newVal) => {
        v.value = newVal
    }
)

function update() {
    emit("update:modelValue", v.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}

async function reset() {
    v.value = ""
    secure(false)
    await nextTick()
    hide(true)
}

function hide(isHidden: boolean) {
    secure(false)
    el.value?.querySelector("input")?.focus()
    hidden.value = isHidden
}

function secure(isSecured: boolean, timeout = 200) {
    if (timer.value) clearTimeout(timer.value)
    if (isSecured) {
        timer.value = setTimeout(() => {
            secured.value = true
        }, timeout) as unknown as number
    } else {
        secured.value = false
    }
}
</script>

<template lang="pug">
div(ref="el")
    .input-group.input-group-sm(v-if="!secured")
        input.form-control(
            v-model="v",
            @change="update",
            @keydown.enter="leaveFocus",
            @blur="secure(true)",
            @keydown.stop="",
            :class="{ obfuscated: hidden }"
        )
        .input-group-append(v-if="hidden")
            span.input-group-text(@click="hide(false)")
                i.fas.fa-eye
        .input-group-append(v-if="!hidden")
            span.input-group-text(@click="hide(true)")
                i.fas.fa-glasses
    div(v-if="secured")
        span *****
        button.btn(@click="reset") Reset
        span *****
</template>

<style lang="css" scoped>
span.input-group-text {
    cursor: pointer;
    user-select: none;
}
.obfuscated {
    -webkit-text-security: disc;
    -moz-text-security: disc;
    text-security: disc;
}
</style>
