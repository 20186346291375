<script lang="ts" setup>
import {computed, onMounted, ref, watch} from "vue"
import {getLatestPassedRecording, getRecording, getTestCaseRecordings} from "@/services/ProjectService";
import dayjs from "@/plugins/dayjs";
import VTestStatus from "@/common/components/form/VTestStatus.vue"
import {AquaExecutionBuilder, getTestExecutions} from "@/common/aqua/AquaService";


interface Props {
    testupTest: any
    testupActions: any
    aquaTest: any
    aquaUrl: string
}

const emit = defineEmits<{
}>()

const props = defineProps<Props>()

const testupTestUrl = computed(() => {
    return "/testCase/" + props.testupTest.id
})

const aquaTestUrl = computed(() => {
    return props.aquaUrl + "/TestCase/" + props.aquaTest.Id
})

const aquaExecutions = ref<any[]>([])
const valueSets = ref<any>({})
const step = ref<number>(0)

const testupId = computed(() => props.testupTest.id || 0 )

onMounted(async () => { await reload() })

async function reload() {
    const recordings = (await getTestCaseRecordings(props.testupTest.id)).recordings
    for (let record of recordings) {
        const setName = valueSetName(record)
        if (!valueSets.value[setName]) {
            valueSets.value[setName] = { testup: record, selected: false}
        }
    }
    const executions = (await getTestExecutions(props.aquaTest.Id, 10)).data
    if (executions.Message)
        throw { header:executions.Type, message: executions.FurtherInfo || executions.Message };
    for (let exec of executions) {
        const setName = exec.ValueSetName || "None"
        const entry = valueSets.value[setName]
        if (entry && !entry.aqua) {
            entry.builder = null
            valueSets.value[setName].aqua = exec
        }
    }
}

async function createExecutions() {
    try {
        const names = valueSetNames()
        step.value = 1
        for (let index of names) {
            console.log("Synching value set: " + index)
            const entry = valueSets.value[index]
            if (entry.selected) {
                await createExecution(entry)
            }
        }
    } finally {
        step.value = 0
        await reload()
    }
}

async function createExecution(entry: any) {
    const recordingId = entry.testup.id
    const builder = new AquaExecutionBuilder(props.aquaTest)
    entry.progress =  builder.progress

    let baselineRecording = await getLatestPassedRecording(props.testupTest.id)

    const recording = await getRecording(recordingId)
    for (let variable of (recording?.meta?.variables || [])) {
        if (variable.name == "_Wertesatz") builder.setValueSetName(variable.value)
    }
    builder.setDuration(recording.startTime, recording.endTime)
    for (let frame of recording.frames) {
        const inputs = frame.actionInputs
        if (inputs.length && inputs[0].type == "TAG") {
            addScreenshots(builder.stepBuilder, frame, baselineRecording)
            builder.addTestStep(inputs[0].text)
        } else if (frame.actionId! > 0) {
            const action = props.testupActions.find((a: any) => a.id == frame.actionId)
            builder.stepBuilder.fillInstructionFromAction(action)
        }
        if (!frame.success) {
            builder.stepBuilder.status = "Failed"
        }
    }
    addScreenshots(builder.stepBuilder, recording.frames[recording.frames.length - 1], baselineRecording)
    await builder.save()
    entry.selected = false
    entry.aqua = null
}

function addScreenshots(stepBuilder: any, frame: any, baselineRecording: any) {
    stepBuilder.setObservedImage(frame.imageUrl)
    if (!frame.success) {
        if (baselineRecording) {
            const referenceFrame = baselineRecording.frames.find((x: any) => x.actionId == frame.actionId)
            if (referenceFrame) {
                stepBuilder.setExpectedImage(frame.imageUrl)
            }
        }
    }
}


const numSelected = computed(() =>
    Object.values(valueSets.value).reduce((a:any,b:any) => a + (b.selected ? 1 : 0), 0)
)

function valueSetNames() {
    return Object.keys(valueSets.value).sort()
}

function valueSetName(recording: any) {
    return recording?.meta?.variables?.find((x:any) => x.name == "_Wertesatz")?.value || "None"
}

function formatTime(time: any) {
    return dayjs(new Date(time)).format("YYYY-MM-DD HH:mm")
}

function testupViewUrl(recording: any) {
    return testupTestUrl.value + `/view/${recording.id}`
}

function aquaExecutionUrl(execution: any) {
    return props.aquaUrl + "/ViewExecution/" + execution.Id
}

function convertRecording(execution: any) {
    return {
        status: execution.Status == "Pass" ? "PASSED" : "FAILED",
    }
}
</script>

<template lang="pug">
.execution-compare.mt-3
    img.logo(src="@/assets/images/valueset.svg")
    img.logo(src="@/assets/images/testup.webp", @click="exit")
    svg.logo(width="100", height="80", viewBox="0 0 122.88 61.49")
        polygon(
            style="fill: lightgray",
            points="122.88,30.75 90.9,0 90.9,19.79 31.98,19.79 31.98,0 0,30.75 31.98,61.49 31.98,41.7 90.9,41.7 90.9,61.49 122.88,30.75"
        )
    img.logo(src="@/assets/images/aqua.png")
    template(v-for="name in valueSetNames()" v-if="step==1")
        .full-row {{ name }}
        div
        div(:set="progress = valueSets[name].progress")
            span(v-if="progress") {{ progress.step }} / {{ progress.total }}
    template(v-for="name in valueSetNames()" v-if="step==0")
        label.full-row
            input(type="checkbox" v-model="valueSets[name].selected")
            span &nbsp; {{ name }}
        div
        div(:set="recording = valueSets[name].testup")
            .recording-box(v-if="recording", :data-status="recording.status")
                v-test-status.mb-2(:recording="recording")
                a.tu-link(:href="testupViewUrl(recording)")
                    i.far.fa-calendar
                    span &nbsp; {{ formatTime(recording.startTime) }}
        div
        div(:set="execution = valueSets[name].aqua")
           .recording-box(v-if="execution" :data-status="execution.Status")
                v-test-status.mb-2(:recording="convertRecording(execution)")
                a.tu-link(:href="aquaExecutionUrl(execution)", target="_blank") {{ execution.FormattedId }}
                    br
                    i.far.fa-calendar
                      span &nbsp; {{ execution.ExecutionDate.Text }}
    .full-row
        | {{ numSelected }} executions selected for synchronization
    div
    div(v-if="step==0")
        .sync-action.btn.mb-3(
            @click="createExecutions()"
        )
            i.fas.fa-paper-plane
            span &nbsp; Publish executions

    //   div(v-for="recording in testupRecordings") {{ recording.id }} {{ valueSetName(recording) }}
</template>

<style lang="css" scoped>
.execution-compare {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;
}
.full-row {
    grid-column: 1/5;
    background-color: lightgrey;
    padding: 5px;
    margin-top: 5px;
    height: min-content;
}
.logo {
    height: 80px;
    place-self: center;
}
.recording-box {
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--bright-green);
    padding: 5px;
}
.recording-box[data-status="Failed"],
.recording-box[data-status="FAILED"] {
    border: 1px solid var(--red);
}
.btn {
    border: 1px solid gray;
}
</style>
