<script lang="ts" setup>
import { computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import ErrorDialog from "@/ErrorDialog.vue"
import useEventListener from "@/composables/useEventListener"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"

const route = useRoute()
const store = useStore()
const { showRobot, testupLevel, msgText, error } = storeToRefs(store)
const { showError } = store

onMounted(() => {
    if (window.location.hostname == "app.testup.io" || route.query.production) {
        //
    }
})

useEventListener(window, "unhandledrejection", (e) => showError(e.reason))

const activeLayout = computed(() => (route.meta.layout ?? "default") + "-layout")
</script>

<template lang="pug">
error-dialog(v-model:error="error")
component(:is="activeLayout")

.robo-content-app(v-if="showRobot && msgText && error")
    .robo-container
        .pr-3
            .speech-bubble.d-flex.align-items-center.justify-content-center {{ msgText }}
        div
            span.d-block.text-right(style="width: 100%; cursor: pointer", @click="showRobot = false") X
            img(src="@/assets/robot_talking.png")
            .progress.robot-progress
                .progress-bar(:style="{ width: `${testupLevel * 20}%` }")
</template>

<style lang="css" scoped>
.robo-content-app {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 40px;
    margin-bottom: 30px;
}

.robo-container img {
    width: 100px;
    display: block;
}

.robo-container {
    display: grid;
    grid-template-columns: max-content 1fr;
}

.robot-progress {
    height: 12px;
    margin-top: 10px;
    background-color: #a9b0bb;
}

.robot-progress .progress-bar {
    background-color: #94d907 !important;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.speech-bubble {
    position: relative;
    background: #94d907;
    border-radius: 0.7em;
    min-width: 200px;
    min-height: 100px;
    max-width: 350px;
    padding: 15px;
    color: white;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    right: 0;
    top: 43px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #94d907;
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -20px;
}
</style>
