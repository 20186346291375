/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PlayActionStatus = {
    status: PlayActionStatus.status;
};

export namespace PlayActionStatus {

    export enum status {
        SUCCESS = 'SUCCESS',
        RESTART_REQUIRED = 'RESTART_REQUIRED',
    }


}

