/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExecutionMeta } from './ExecutionMeta';
import type { RecordingDTO } from './RecordingDTO';

export type ExecutionDTO = {
    id: number;
    projectId: number;
    name?: string;
    time?: string;
    status?: ExecutionDTO.status;
    meta?: ExecutionMeta;
    scheduleId?: number;
    recordings?: Array<RecordingDTO>;
};

export namespace ExecutionDTO {

    export enum status {
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RUNNING = 'RUNNING',
        INTERRUPTED = 'INTERRUPTED',
    }


}

