<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { getUser } from "@/services/ProjectService"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DataTable from "@/common/components/DataTable.vue"
import type { CurrentUserResponse } from "@/types/gen";

const wordpressBase = WORDPRESS_BASE
const user = ref<CurrentUserResponse>()
const includedInPlan = ref(500)
const planDetails = ref<any[]>([])
const usageDetails = ref<any[]>([])
const APIKeys = ref<any[]>([])

onMounted(async () => {
    const response = await getUser()
    user.value = response

    planDetails.value.push({
        plan: user.value.email,
        parallelTests: "2 parallel Tests",
        includedRuntime: `${includedInPlan.value} min per month`,
        extraRuntime: "0.00€ per minute",
    })

    APIKeys.value.push(user.value)
})

const usageDetailsRows = computed(() => {
    return usageDetails.value.map((row) => {
        const runtime = Math.ceil(row.headless / 60)
        return {
            startOfPeriod: row.date,
            runtime: `${runtime} min`,
            includedInPlan: `${includedInPlan.value} min`,
            remaining: `${includedInPlan.value - runtime} min`,
        }
    })
})

async function regenerate() {
    user.value = await getUser(true)
    APIKeys.value = []
    APIKeys.value.push(user.value)
}
</script>

<template lang="pug">
.profile.tu-tab-area
    data-table.mt-4(
        title="Plan Details",
        :columnNames="['Current Plan', 'Parallel Tests', 'Included Runtime', 'Extra Runtime']",
        :dataKeys="['plan', 'parallelTests', 'includedRuntime', 'extraRuntime']",
        :dataRows="planDetails"
    )
    .button-row.mt-5
        div
        v-action-button(
            main="My Account",
            :mainIconClass="['fas', 'fa-user']",
            :mainHref="`${wordpressBase}/my-account`"
        )
    hr.divider
    data-table.mt-4(
        title="Usage Details",
        :columnNames="['Start of period', 'Runtime', 'Included in Plan', 'Remaining']",
        :dataKeys="['startOfPeriod', 'runtime', 'includedInPlan', 'remaining']",
        :dataRows="usageDetailsRows"
    )
    .button-row.mt-5
        div
        v-action-button(
            main="Membership",
            :mainIconClass="['fas', 'fa-list-alt']",
            :mainHref="`${wordpressBase}/en/my-account/members-area`"
        )
    hr.divider
    data-table.mt-4.mb-5(
        title="API Keys",
        :columnNames="['User', 'Key']",
        :dataKeys="['email', 'apiKey']",
        :dataRows="APIKeys"
        :hidden="['apiKey']"
    )
    .button-row.mt-5.mb-5
        div
        v-action-button(main="Regenerate", :mainIconClass="['fas', 'fa-sync-alt']", @main="regenerate")
</template>

<style lang="css" scoped>
.button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.divider {
    background-color: var(--black);
}
</style>
