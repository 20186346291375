<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import {
    getUser,
    getUserTeams,
    getProjectTeamsPermissions,
    updateProjectTeamsPermissions,
} from "@/services/ProjectService"
import VActionButton from "@/common/components/form/VActionButton.vue"

import type {ProjectDTO, TeamProjectPermissions, WpTeam} from "@/types/gen";

interface Props {
    userData: any
    project: ProjectDTO
}

const props = defineProps<Props>()

const user = ref(props.userData)
const userTeams = ref<WpTeam[]>([])
const projectMembers = ref<ProjectMember[]>([])

interface UserTeamWithProjectPermissions {
    teamName: string
    teamId: number
    projectPermission: TeamProjectPermissions.permission
}
interface ProjectMember {
    who: string
    permissions: string
    isOwner: boolean
}

const userTeamsWithProjectPermissions = ref<UserTeamWithProjectPermissions[]>([])

onMounted(async () => {
    await reload()
})

const isOwnerOrAdmin = computed(() => {
    if (!user.value) return false

    return isOwner.value || user.value.isAdmin
})

const isOwner = computed(() => {
    if (!user.value || !props.project) return false
    return user.value.id === props.project.createdBy
})

const isAdminAnywhere = computed(() => userTeams.value.filter(x => x.role != "member").length)

watch(props.userData, async (newValue) => {
    user.value = newValue
    await reload()
})

async function reload() {
    if (!user.value) user.value = await getUser()

    userTeams.value = await getUserTeams()

    const projectTeamPermissions: TeamProjectPermissions[] = await getProjectTeamsPermissions(props.project.id)
    userTeamsWithProjectPermissions.value = enrichUserTeamsWithProjectPermissions(projectTeamPermissions)

    projectMembers.value = [{
        who: user.value.email,
        permissions: resolveProjectPermissionLevel(),
        isOwner: isOwner.value,
    } as ProjectMember]
}

function resolveProjectPermissionLevel() {
    if (isOwner.value) {
        return "Owner"
    } else if (user.value.isAdmin) {
        return "Admin"
    }
    return "Member"
}

function isShared(team: UserTeamWithProjectPermissions) {
    return team.projectPermission != "NONE"
}

function enrichUserTeamsWithProjectPermissions(projectTeamPermissions: TeamProjectPermissions[]): UserTeamWithProjectPermissions[] {
    const projectPermissionByTeamId = associateBy("team_id", projectTeamPermissions)
    return userTeams.value.map((team) => {
        const permission = projectPermissionByTeamId[team.team_id] ? projectPermissionByTeamId[team.team_id].permission : "NONE"
        return {
            teamName: team.team_name,
            teamId: team.team_id,
            role: team.role,
            projectPermission: permission,
        } as UserTeamWithProjectPermissions
    })
}

function associateBy(key: string, list: any[]): any {
    return list.reduce((map, obj) => {
        map[obj.team_id] = obj
        return map
    }, {})
}

async function updateTeams() {
    await updateProjectTeamsPermissions(
        props.project.id,
        userTeamsWithProjectPermissions.value.map((teamAndPermission) => {
            return {
                team_id: teamAndPermission.teamId,
                permission: teamAndPermission.projectPermission,
            }
        })
    )
}
</script>

<template lang="pug">
.profile
    .data-table-component.mt-4
        .tu-small
            th Teams Permissions
            table.data-table
                tr
                    th(v-for="columnName in ['Who', 'Permissions']") {{ columnName }}
                tr(v-for="projectMember in projectMembers")
                    td.pr-3 {{ projectMember['who'] }}
                    td.pr-3 {{ projectMember['permissions'] }}
                    td.pr-3
                        i.fas.fa-home.decoration.active(v-if="projectMember.isOwner" title="You own this project")
                        i.fas.fa-home.decoration(v-if="!projectMember.isOwner" title="You don't own this project")
                tr(v-for="team in userTeamsWithProjectPermissions")
                    td.pr-3 {{ team.teamName }}
                    td.pr-3
                        select.custom-select(
                            v-model="team.projectPermission",
                            v-if="isOwnerOrAdmin",
                            @change="updateTeams",
                            style="width: 100px"
                        )
                            option(value="NONE") None
                            option(value="VIEW_IFRAME", v-if="team.teamId === 0") View iframe
                            option(value="READONLY", v-if="team.teamId !== 0") View
                            option(value="EDIT", v-if="team.teamId !== 0") Edit
                        span(v-else) {{ team.projectPermission }}
                    td
                        i.fas.fa-link.decoration.active(v-if="isShared(team)" title="This project is shared with the team")
                        i.fas.fa-link.decoration(v-if="!isShared(team)" title="This project is not shared with the team")

    .button-row.mt-5(v-if="isAdminAnywhere")
        div
        v-action-button(
            main="Manage Teams",
            :mainIconClass="['fas', 'fa-user-plus']",
            mainHref="https://testup.io/en/my-account/teams/"
        )
</template>

<style lang="css" scoped>
.button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.divider {
    color: var(--black);
}

.data-table {
    width: 100%;
}

th,
td {
    height: 30px;
    width: 25%;
}

.decoration {
    color: lightgray;
}

.decoration.active {
    color: var(--bright-green);
}
</style>
