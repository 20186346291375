import {
    deleteRecording,
    getRecording,
    getExecution,
    getExecutionRecordings,
    stopRecording
} from "@/services/ProjectService"
import { TestcaseService } from "@/services/TestcaseService"
import type { ActionDTO, ExecutionDTO, RecordingDTO } from "@/types/gen"

export const recordingService = {
    async stopRecordings(recordings: RecordingDTO[]) {
        const running = recordingService.getRunningRecordings(recordings)
        const promises = running.map(async (r) => await recordingService.stopRecording(recordings, r))
        await Promise.all(promises)
    },
    async stopRecording(recordings:RecordingDTO[], r:RecordingDTO) {
        await stopRecording(r)
        recordings.splice(recordings.indexOf(r), 1)
    },
    async deleteRecording(recordings:RecordingDTO[], r:RecordingDTO) {
        await deleteRecording(r)
        recordings.splice(recordings.indexOf(r), 1)
    },
    updateRecording(recordings:RecordingDTO[], recording:RecordingDTO) {
        const recordingToUpdate = recordings.find((r) => r.id == recording.id)
        if (recordingToUpdate)
            Object.assign(recordingToUpdate, recording)
    },
    getRunningRecordings(recordings:RecordingDTO[]) {
        return recordings.filter((r) => recordingService.isRunning(r))
    },
    hasRunningRecordings(recordings: RecordingDTO[]) {
        return !!recordingService.getRunningRecordings(recordings).length
    },
    isRunning(r) {
        return r.status == "RUNNING"
    },
    resolveName(r) {
        if (r.executionId) return r.executionName // todo make getExecution(id) call and use response
        if (r.interactive) return "Interactive editing"
        return "Started by play"
    },
}

export class RecordingUpdater {
    list: Array<RecordingDTO> = []
    updateActive = false
    updaterActive = false
    isAllFrames = false
    finishedCallback:Function = () => {}
    recordingUpdateCallback:Function = () => {}
    timer:any = 0
    lastFrameCreatedTime: string | null = null

    constructor(isAllFrames = false) {
        this.isAllFrames = isAllFrames
    }

    setFinishedCallback(callback: Function) {
        this.finishedCallback = callback
    }

    setRecordingUpdateCallback(callback:Function) {
        this.recordingUpdateCallback = callback
    }

    keepUpdated(recording: RecordingDTO) {
        if (recordingService.isRunning(recording) && !this.list.includes(recording)) {
            this.list.push(recording)
        }

        if (this.list.length && !this.updaterActive) {
            this.updaterActive = true
            this._routineUpdate()
        }
        return recording
    }

    destroy() {
        clearTimeout(this.timer)
        this.updaterActive = false
        this.list = []
    }

    _routineUpdate() {
        if (!this.updaterActive) return

        this.timer = setTimeout(async () => {
            const newList = []
            for (let r of this.list) {
                if (!this.updaterActive) return
                let updated_recording
                try {
                    const lastFrameCreationTime = this.isAllFrames ? null : (r.lastFrameCreationTime || this.lastFrameCreatedTime)
                    if (lastFrameCreationTime) {
                        this.lastFrameCreatedTime = lastFrameCreationTime
                    }
                    updated_recording = await getRecording(r.id, lastFrameCreationTime)
                } catch (e) {
                    if (e.response) {
                        if (e.response.status == 404) {
                            console.error(e)
                        } else {
                            this.destroy()
                            throw(e)
                        }
                    } else {
                        console.error(e)
                        updated_recording = "NETWORK_ERROR"
                    }
                }
                if (updated_recording == "NETWORK_ERROR") continue

                if (!this.updaterActive) return

                if (updated_recording) {
                    newList.push(Object.assign(r, updated_recording))
                }
                if (this.recordingUpdateCallback) {
                    this.recordingUpdateCallback(r, updated_recording)
                }
            }
            this.list = newList.filter((r) => recordingService.isRunning(r))

            if (this.list.length != 0) {
                this._routineUpdate()
            } else {
                if (this.finishedCallback) this.finishedCallback()
                this.updaterActive = false
            }
        }, 1000)
    }
}

export class ExecUpdater {
    list: Array<ExecutionDTO> = []
    updaterActive = false
    finishedCallback:Function = () => {}
    execUpdateCallback:Function = () => {}
    timer: any = 0

    constructor() {
    }

    setFinishedCallback(callback: Function) {
        this.finishedCallback = callback
    }

    setExecUpdateCallback(callback: Function) {
        this.execUpdateCallback = callback
    }

    keepUpdated(execution: ExecutionDTO) {
        if (this.isRunning(execution) && !this.list.includes(execution)) {
            this.list.push(execution)
        }

        if (this.list.length && !this.updaterActive) {
            this.updaterActive = true
            this._routineUpdate()
        }
        return execution
    }

    isRunning(exec) {
        return exec.status == 'RUNNING'
    }

    destroy() {
        clearTimeout(this.timer);
        this.updaterActive = false
        this.list = []
    }

    _routineUpdate() {

        if (!this.updaterActive) return

        this.timer = setTimeout(async () => {

            const newList = []
            for (let e of this.list) {
                if (!this.updaterActive) return
                let updated_exec
                try {
                    updated_exec = await getExecution(e.id)
                } catch(error: any) {
                    if (error.response) {
                        this.destroy()
                        return Promise.reject(error)
                    }
                    console.error(error)
                    continue
                }
                if (!this.updaterActive) return

                if (updated_exec) {
                    if (updated_exec.status == 'INTERRUPTED') {
                        if (this.finishedCallback) this.finishedCallback()
                        this.updaterActive = false
                        return
                    }
                    else {
                        newList.push(updated_exec)
                    }
                }
                if (this.execUpdateCallback) {
                    const eRecordings = (await getExecutionRecordings(e.id)).recordings
                    this.execUpdateCallback(e, eRecordings)
                }
            }
            this.list = newList.filter(r => this.isRunning(r))

            if (this.list.length != 0) {
                this._routineUpdate()
            } else {
                if (this.finishedCallback) this.finishedCallback()
                this.updaterActive = false
            }

        }, 1000)

    }
}

export function syntheticRecording(recording:RecordingDTO, testCaseService: TestcaseService) {
    let newRec = { ...recording }
    newRec.frames = newRec.frames
    .filter(frame => frame.actionId)
    .map(frame => {
        const action = testCaseService.getActionById(frame.actionId!!)
        return { ...frame,
            actionInputs:[],
            anchorMatchResult:null,
            imageUrl: action.imageUrl
        }
    })
    return newRec
}

export function getVariableFromRecording(recording: RecordingDTO | undefined, name: string) {
    for (let variable of recording?.meta?.variables || []) {
        if (variable.name == name) {
            return variable.value
        }
    }
    return null
}