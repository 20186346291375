<script lang="ts" setup>
import type {LinkedEntitiesDTO, RecordingDTO, ScheduleDTO} from '@/types/gen';
import { getScheduleRecordings } from '@/services/ProjectService';
import { onMounted, reactive, ref, computed } from 'vue';
import VActionButton from "@/common/components/form/VActionButton.vue"
import PivotTable from "@/common/pivottable/PivotTable.vue"
import * as pivotAggregators from "@/common/pivottable/PivotDataAggregators";
import * as pivotVariables from "@/common/pivottable//PivotVariables";

interface Props {
    schedule: ScheduleDTO
}

const props = defineProps<Props>()

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const isLoading = ref(true)
const showPivotControls = ref(false)
const numExecutions = ref(5)
const recordings = ref<RecordingDTO[]>([])
const linkedData = ref<LinkedEntitiesDTO>({} as LinkedEntitiesDTO)

const variables = ([
    new pivotVariables.TestcaseVariable("testCaseId", linkedData).setRole("rows"),
    new pivotVariables.ExecutionVariable( "executionId", linkedData).setRole("columns"),
    new pivotVariables.PivotVariable("TestResult", "status"),
    new pivotVariables.PivotDateVariable("Date", "startTime"),
    new pivotVariables.PivotTimeVariable("Time", "startTime"),
    new pivotVariables.LastPlayedActionVariable()
])

const aggregators = ref([
    {
        name: "All recordings",
        aggregator: new pivotAggregators.CustomAggregator("all-recordings")
    }, {
        name: "PASSED rate",
        aggregator: new pivotAggregators.RecordingStatusAggregator("status")
    }, {
        name: "Count",
        aggregator: new pivotAggregators.TotalAggregator()
    }, {
        name: "Variable value",
        aggregator: new pivotAggregators.VariableValueAggregator()
    }, {
        name: "Raw last value",
        aggregator: new pivotAggregators.RawLastElementAggregator()
    }
])

onMounted(async () => {
    await loadData()
})

async function loadData() {
    isLoading.value = true
    try {
        const recordingsWithLinkedEntities = await getScheduleRecordings(props.schedule.id, numExecutions.value)
        recordings.value = recordingsWithLinkedEntities.recordings ?? []
        linkedData.value = recordingsWithLinkedEntities.linkedEntities ?? {}
    } finally {
        isLoading.value = false
    }
}

async function loadMore() {
    numExecutions.value = numExecutions.value * 2
    await loadData()
}

</script>

<template lang="pug">
.recordings
    .data-selection
        div {{ isLoading ? '---' : recordings.length }} recordings loaded
        v-action-button.invisible-in-print(
            main="Load more"
            :mainIconClass="['fas', 'fa-caret-right']"
            @main="loadMore"
        )
        .empty
        div
            v-action-button.invisible-in-print(
                main="Configure",
                :mainIconClass="['fas', 'fa-cog']"
                @main="showPivotControls = !showPivotControls")

    div(v-if="isLoading")
        .spinner(style="top: 5em; left: 10em")
            .spinner-item
            .spinner-item
            .spinner-item
    pivot-table(v-show="!isLoading"
            :showControls="showPivotControls"
            :variables="variables"
            :useDynamicVars="true"
            :aggregators="aggregators"
            :data="recordings"
            :linkedData="linkedData"
            )

</template>

<style lang="css" scoped>
.recordings {
    display: grid;
    grid-template-rows: min-content 1fr;
    margin-top: 1ex;
}
.data-selection {
    display: grid;
    width: 100%;
    grid-template-columns: max-content max-content 1fr max-content;
    column-gap: 1ex;
    place-items: center;
}
</style>
