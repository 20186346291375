<script lang="ts" setup>
import { nextTick, onMounted, ref } from "vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { formatDateTime } from "@/common/util"

interface Props {
    schedule: any
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "updateSchedule", data: any): void
    (event: "saveSchedule"): void
}>()

const APIKeys = ref([])
const selectedRunOption = ref("")
const selectedStartHours = ref("")
const selectedEndHours = ref("")
const selectedAtHours = ref("")
const selectedOptionDays = ref("")
const selectedWeekDays = ref("")
const firstCronSeconds = ref("0")
const secondCronMinutes = ref("")
const thirdCronHours = ref("")
const fourthCronDayOfMonth = ref("?")
const fifthCronMonth = ref("*")
const sixthCronDayOfWeek = ref("")
const seventhCronYear = ref("*")
const finalCron = ref("")
const showError = ref(false)
const weekDays = ref(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"])
const hoursInUTC = ref([
    "0:00h UTC",
    "1:00h UTC",
    "2:00h UTC",
    "3:00h UTC",
    "4:00h UTC",
    "5:00h UTC",
    "6:00h UTC",
    "7:00h UTC",
    "8:00h UTC",
    "9:00h UTC",
    "10:00h UTC",
    "11:00h UTC",
    "12:00h UTC",
    "13:00h UTC",
    "14:00h UTC",
    "15:00h UTC",
    "16:00h UTC",
    "17:00h UTC",
    "18:00h UTC",
    "19:00h UTC",
    "20:00h UTC",
    "21:00h UTC",
    "22:00h UTC",
    "23:00h UTC",
    "24:00h UTC"
])
const runOptions = ref(["Every 15 minutes", "Every Hour", "Every Day", "Every Week"])

onMounted(() => {
    if (!props.schedule.cronTiming) {
        selectedRunOption.value = "Every Hour"
        secondCronMinutes.value = "0"
        selectedStartHours.value = defineUTCHourFromNumber("8")
        selectedEndHours.value = defineUTCHourFromNumber("22")
        thirdCronHours.value = "8-22"
        selectedOptionDays.value = "Every Day"
        sixthCronDayOfWeek.value = "*"
    } else {
        const cronArray = props.schedule.cronTiming.split(" ")
        firstCronSeconds.value = cronArray[0]
        secondCronMinutes.value = cronArray[1]
        thirdCronHours.value = cronArray[2]
        fourthCronDayOfMonth.value = cronArray[3]
        fifthCronMonth.value = cronArray[4]
        sixthCronDayOfWeek.value = cronArray[5]
        seventhCronYear.value = cronArray[6]

        if (secondCronMinutes.value === "0") {
            selectedRunOption.value = "Every Hour"

            if (
                !thirdCronHours.value.includes("-") &&
                (!sixthCronDayOfWeek.value.includes(",") || !sixthCronDayOfWeek.value.includes("*"))
            ) {
                selectedRunOption.value = "Every Day"
                selectedAtHours.value = defineUTCHourFromNumber(thirdCronHours.value)
            }

            if (
                !thirdCronHours.value.includes("-") &&
                weekDays.value.indexOf(defineWeekDayFromCronDay(sixthCronDayOfWeek.value)!) > -1
            ) {
                selectedRunOption.value = "Every Week"
                selectedWeekDays.value = defineWeekDayFromCronDay(sixthCronDayOfWeek.value)!
            }
        } else if (secondCronMinutes.value === "0/15") {
            selectedRunOption.value = "Every 15 minutes"
        }

        if (thirdCronHours.value.includes("-")) {
            selectedStartHours.value = defineUTCHourFromNumber(
                thirdCronHours.value.substring(0, thirdCronHours.value.indexOf("-"))
            )
            selectedEndHours.value = defineUTCHourFromNumber(
                thirdCronHours.value.substring(thirdCronHours.value.indexOf("-") + 1, thirdCronHours.value.length)
            )
        } else {
            selectedStartHours.value = ""
            selectedEndHours.value = ""
            selectedAtHours.value = defineUTCHourFromNumber(thirdCronHours.value)
        }

        if (sixthCronDayOfWeek.value === "MON,TUE,WED,THU,FRI") {
            selectedOptionDays.value = "Weekdays"
        } else if (sixthCronDayOfWeek.value === "*") {
            selectedOptionDays.value = "Every Day"
        }
    }
})

async function updateRunOption() {
    if (selectedRunOption.value === "Every 15 minutes") {
        secondCronMinutes.value = "0/15"
        selectedStartHours.value = defineUTCHourFromNumber("8")
        selectedEndHours.value = defineUTCHourFromNumber("22")
        thirdCronHours.value = "8-22"
        selectedOptionDays.value = "Every Day"
        sixthCronDayOfWeek.value = "*"
    } else if (selectedRunOption.value === "Every Day") {
        secondCronMinutes.value = "0"
        selectedAtHours.value = defineUTCHourFromNumber("8")
        thirdCronHours.value = "8"
        selectedOptionDays.value = "Every Day"
        sixthCronDayOfWeek.value = "*"
    } else if (selectedRunOption.value === "Every Week") {
        secondCronMinutes.value = "0"
        selectedAtHours.value = defineUTCHourFromNumber("8")
        thirdCronHours.value = "8"
        selectedWeekDays.value = "Monday"
        sixthCronDayOfWeek.value = "MON"
    } else if (selectedRunOption.value === "Every Hour") {
        secondCronMinutes.value = "0"
        selectedStartHours.value = defineUTCHourFromNumber("8")
        selectedEndHours.value = defineUTCHourFromNumber("22")
        thirdCronHours.value = "8-22"
        selectedOptionDays.value = "Every Day"
        sixthCronDayOfWeek.value = "*"
    }

    await buildFinalCroneAndUpdateSchedule()
}

function getSelectedStartOursFirstPart() {
    return selectedStartHours.value.substring(0, selectedStartHours.value.indexOf(":"))
}

function getSelectedEndOursFirstPart() {
    return selectedEndHours.value.substring(0, selectedEndHours.value.indexOf(":"))
}

async function updateStartHours() {
    if (parseInt(getSelectedStartOursFirstPart()) > parseInt(getSelectedEndOursFirstPart())) {
        showError.value = true
        return
    } else {
        showError.value = false
    }

    thirdCronHours.value = thirdCronHours.value.replace(
        thirdCronHours.value.substring(0, thirdCronHours.value.indexOf("-")),
        getSelectedStartOursFirstPart()
    )

    await buildFinalCroneAndUpdateSchedule()
}

async function updateEndHours() {
    if (parseInt(getSelectedStartOursFirstPart()) > parseInt(getSelectedEndOursFirstPart())) {
        showError.value = true
        return
    } else {
        showError.value = false
    }

    thirdCronHours.value = thirdCronHours.value.replace(
        thirdCronHours.value.substring(thirdCronHours.value.indexOf("-") + 1, thirdCronHours.value.length),
        getSelectedEndOursFirstPart()
    )

    await buildFinalCroneAndUpdateSchedule()
}

async function updateAtHours() {
    thirdCronHours.value = selectedAtHours.value.substring(0, selectedAtHours.value.indexOf(":"))
    await buildFinalCroneAndUpdateSchedule()
}

async function updateDays() {
    if (selectedOptionDays.value === "Weekdays") {
        sixthCronDayOfWeek.value = "MON,TUE,WED,THU,FRI"
    }

    if (selectedOptionDays.value === "Every Day") {
        sixthCronDayOfWeek.value = "*"
    }

    await buildFinalCroneAndUpdateSchedule()
}

async function updateWeekDays() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    sixthCronDayOfWeek.value = defineCronDayFromWeekDay(selectedWeekDays.value)!

    await buildFinalCroneAndUpdateSchedule()
}

async function buildFinalCroneAndUpdateSchedule() {
    const schedule = { ...props.schedule }
    schedule.cronTiming = [
        firstCronSeconds.value,
        secondCronMinutes.value,
        thirdCronHours.value,
        fourthCronDayOfMonth.value,
        fifthCronMonth.value,
        sixthCronDayOfWeek.value,
        seventhCronYear.value,
    ].join(" ")
    schedule.threadLimit = parseInt(schedule.threadLimit)
    emit("updateSchedule", schedule)
    nextTick(() => {
        emit("saveSchedule")
    })
}

function defineUTCHourFromNumber(hour: string) {
    return hour + ":00h UTC"
}

function defineWeekDayFromCronDay(cronDay: string) {
    switch (cronDay) {
        case "MON":
            return "Monday"
        case "TUE":
            return "Tuesday"
        case "WED":
            return "Wednesday"
        case "THU":
            return "Thursday"
        case "FRI":
            return "Friday"
        default:
            return
    }
}

function defineCronDayFromWeekDay(weekDay: string) {
    switch (weekDay) {
        case "Monday":
            return "MON"
        case "Tuesday":
            return "TUE"
        case "Wednesday":
            return "WED"
        case "Thursday":
            return "THU"
        case "Friday":
            return "FRI"
        default:
            return
    }
}
</script>

<template lang="pug">
.timings
    .row.run-checkbox
        label.checkbox-label.ml-0
            input(type="checkbox", v-model="schedule.runAutomatically", @change="buildFinalCroneAndUpdateSchedule()")
            .span &nbsp;&nbsp;&nbsp;Run automatically
    .row
        label.run Run
            select.custom-select(v-model="selectedRunOption", @change="updateRunOption")
                option(v-for="runOption in runOptions", :key="runOption") {{ runOption }}

        label(v-if="selectedRunOption === 'Every 15 minutes' || selectedRunOption === 'Every Hour'") between
            select.custom-select(v-model="selectedStartHours", @change="updateStartHours")
                option(v-for="startHour in hoursInUTC", :key="startHour") {{ startHour }}

        label(v-if="selectedRunOption === 'Every 15 minutes' || selectedRunOption === 'Every Hour'") and
            select.custom-select(v-model="selectedEndHours", @change="updateEndHours")
                option(v-for="endHour in hoursInUTC", :key="endHour") {{ endHour }}

        label(v-if="selectedRunOption === 'Every Day' || selectedRunOption === 'Every Week'") at
            select.custom-select(v-model="selectedAtHours", @change="updateAtHours")
                option(v-for="hourAt in hoursInUTC", :key="hourAt") {{ hourAt }}

        label(v-if="selectedRunOption != 'Every Week'") on
            select.custom-select(v-model="selectedOptionDays", @change="updateDays")
                option Weekdays
                option Every Day

        label(v-if="selectedRunOption === 'Every Week'") on
            select.custom-select(v-model="selectedWeekDays", @change="updateWeekDays")
                option(v-for="weekDay in weekDays", :key="weekDay") {{ weekDay }}
    .row.mt-4
        label Thread limit
            select.custom-select(v-model="schedule.threadLimit", @change="buildFinalCroneAndUpdateSchedule")
                option(value="0") unlimited
                option(value="1") 1
                option(value="2") 2

    .row(v-if="showError")
        p.error Start time can't be higher than end time

    .row.next-play(v-if="schedule.nextRun && schedule.runAutomatically")
        p next play on: <br> {{ formatDateTime(schedule.nextRun) }} (local time)
</template>

<style lang="css" scoped>
.button-row {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.divider {
    background-color: var(--black);
}

.run-checkbox {
    margin-bottom: 25px;
    margin-top: 25px;
}

label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-select {
    margin-left: 15px;
    margin-right: 15px;
}

.checkbox-label {
    margin-left: 15px;
}

.next-play {
    margin-top: 50px;
}

.error {
    color: red;
    margin-top: 25px;
}
</style>
