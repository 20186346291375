<template lang="pug">
.content.scrollable-content(style="margin: 5px 0 0 3px")
    flow-controller(
        v-if="showFlowController()",
        :deviceStatus="deviceStatus",
        :canPlay="canPlay",
        :currentActionExact="currentActionExact",
        :mode="mode",
        @play="$emit('play', actions)",
        @stop="$emit('stop')"
    )
    div.tu-sticky-header(style="display: grid; grid-template-columns: 25px auto")
        .opened-triangle(v-if="showChildren", @click="togglePredecessorActions") &#9660;
        .closed-triangle(v-if="!showChildren", @click="togglePredecessorActions") &#9654;
        .card-header.tu-card-header
            .context
                div(style="display: grid")
                    .span-sm Predecessor
                    h5 {{ predecessorTestcase.config && predecessorTestcase.name }}
                action-context-menu(
                    @openPredecessor="$emit('openTestCaseInEditor', {testCaseId: predecessorTestcase.id})",
                    @inlineTestCase="$emit('inlineTestCase')",
                    :showInlineButton="canBeInlined"
                    style="margin-right: 5px"
                )
    .indent(v-show="showChildren")
        action-predecessor(
            ref="predecessor",
            v-if="testCaseService.predecessor",
            :testCaseService="testCaseService.predecessor"
            :deviceStatus="deviceStatus",
            :currentActionExact="currentActionExact",
            :isInPredecessor="isInPredecessor",
            :mode="mode",
            :failedActions="failedActions",
            :cutClipboards="cutClipboards",
            @openTestCaseInEditor="openTestCaseInEditor",
            @play="$emit('play', a)",
            @playAll="playSecond",
            @stop="$emit('stop')",
            @jump="jump",
            @playtohere="playtohereAction",
            @openSelf="openSelf",
            @complain="complainAction",
            @update="update",
            @pasteAction="pasteAction",
            @setMode="setMode"
        )
        action-list(
            :actions="testCaseService.actions",
            :deviceStatus="deviceStatus",
            :currentActionExact="currentActionExact",
            :mode="mode",
            :failedActions="failedActions",
            :isInPredecessor="true",
            :testCaseService="testCaseService",
            :cutClipboards="cutClipboards",
            @play="$emit('play', a)",
            @playAll="playFirst",
            @stop="$emit('stop')",
            @jump="jump",
            @playtohere="playtohereAction",
            @openTestCaseInEditor="openTestCaseInEditor",
            @complain="complainAction",
            @openSelf="openSelf",
            @update="update",
            @pasteAction="pasteAction"
            @setMode="setMode"
        )
            slot
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { canPlayPredecessor } from "@/services/DeviceHelperFunctions"
import { getActions, getTestCase } from "@/services/ProjectService"
import ActionList from "@/common/components/action/ActionList.vue"
import ActionContextMenu from "@/common/components/action/ActionContextMenu.vue"
import FlowController from "@/common/components/FlowController.vue"
import { ActionDTO } from "@/types/gen"

export default defineComponent({
    props: [
        "testCaseService",
        "currentActionExact", "deviceStatus", "mode", "failedActions", "canBeInlined", "cutClipboards"],
    emits: ["playAll", "jump", "playtohere", "openTestCaseInEditor", "complain", "play", "stop", "openSelf", "update", "pasteAction", "setMode"],
    data() {
        return {
            showChildren: false,
            showMenu: !!this.expandOnInit,
            actions: [] as ActionDTO[],
            predecessorTestcase: {},
            isInPredecessor: false,
            openedOnce: false
        }
    },
    name: "action-predecessor",
    computed: {
        canPlay() {
            if (this.deviceStatus.isDummy) {
                return true
            } else {
                return canPlayPredecessor(this.deviceStatus)
            }
        },
    },
    async created() {
        this.predecessorTestcase = this.testCaseService.getTestCase()
        this.actions = this.testCaseService.actions
    },
    methods: {
        openTestCaseInEditor(data: any) {
            this.$emit('openTestCaseInEditor', data)
        },
        showFlowController() {
            return this.isCurrentActionInPredecessor() && !this.showChildren
        },
        isCurrentActionInPredecessor() {
            if (this.containsCurrentAction(this.actions)) {
                return true
            }
            if (this.$refs.predecessor && this.containsCurrentAction((this.$refs.predecessor as any).actions)) {
                return true
            }
            return false
        },
        openSelf() {
            if (!this.openedOnce) {
                this.showChildren = true
                this.openedOnce = true
                this.$emit('openSelf')
            }
        },
        togglePredecessorActions() {
            this.showChildren = !this.showChildren
        },
        toggleMenu() {
            this.showMenu = !this.showMenu
        },
        playFirst(actions: any[]) {
            this.$emit("playAll", actions)
        },
        playSecond(actions: any[]) {
            this.$emit("playAll", actions.concat(this.actions))
        },
        containsCurrentAction(actions: any[]) {
            return actions.find((a) => a.id == this.deviceStatus.currentAction)
        },
        jump(action: any) {
            this.$emit("jump", action)
        },
        playtohereAction(payload: any) {
            this.$emit("playtohere", payload)
        },
        complainAction(action: ActionDTO) {
            this.$emit('complain', action)
        },
        update(event: any) {
            this.$emit('update', event)
        },
        pasteAction(event: any) {
            this.$emit('pasteAction', event)
        },
        setMode(mode: string) {
            this.$emit('setMode', mode)
        }
    },
    components: {
        ActionList,
        ActionContextMenu,
        FlowController,
    },
})
</script>

<style lang="css" scoped>
.opened-triangle,
.closed-triangle {
    cursor: pointer;
    user-select: none;
    margin: 25px 0 0 5px;
}
.indent {
    margin-left: 20px;
}
.context {
    display: grid;
    grid-template-columns: 1fr max-content;
}
</style>
