import axios from "axios"
import mqtt from 'mqtt'
import {nextTick} from "vue"

import CryptoJS from 'crypto-js'
import worker_script from "./KeepAliveService"
import type { ActionDTO, ActionMatcher, FrameDTO, ImageMatchResult, PlayActionStatus, RecordingDTO, PromptExecutedAction } from "@/types/gen"

export class DeviceError extends Error {
    level: string
    resolutions: string[]
    traceId: string
    timerWorker: Worker | null
    constructor(g: any, level:string, resolutions: string[]) {
        super(g.errorMessage)
        this.level = level || "ERROR"
        this.resolutions = resolutions
        this.traceId = g.traceId
        this.timerWorker = null
    }
}

export abstract class DeviceService {
    deviceStatus: DeviceState
    errorHandler = (e: any) => {}
    testCaseId = 0
    configId = 0
    deviceId = ""

    constructor() {
        this.deviceStatus = new DeviceState()
    }

    connectDevice(variables:any = null) {}
    disconnectDevice(statys: string | null) {}
    stopAllActions() {}
    activateAction(action: ActionDTO) {}
    playFromStartTo(action?: number) {}
    async resetDevice() { return false }

    async play(fromActionId: number|null = null, toActionId: number|null = null, numberActionsToPlay: number|null= null) : Promise<PlayActionStatus>{
        return { status: 'RESTART_REQUIRED' } as PlayActionStatus
    }

    setMatcher(matcher: ActionMatcher, actionId: number) {
        this.deviceStatus.nextFrame = matcher
        this.deviceStatus.actionStatus = "matched"
        this.deviceStatus.candidates = []
        return axios.put(`/api/v1/action/${actionId}/set-matcher`, matcher)
    }

    setMatcherFromClick(matcher: any, actionId: number) {
        return axios.put(`/api/v1/action/${actionId}/set-matcher-from-click`, matcher)
    }

    getBranch() {

    }
}

export class RealDeviceService extends DeviceService {
    keepAliveIntervalId = 0
    backendState : any | null = null
    encryptionEnabled = false
    encryptionSecret : string | null = null
    mqttTransportParams = {
        deviceFeedTopic: "",
        brokerExternalGateway: ""
    }
    mqttClient?: mqtt.MqttClient
    timerWorker: Worker | null = null

    constructor() {
        super()
    }
    connectToMqtt() {
        let options = this.extractMqttOptions(this.mqttTransportParams.brokerExternalGateway)
        try {
            console.log("MQTT client is connecting, options:", options)
            this.mqttClient = mqtt.connect(options as mqtt.IClientOptions)
        } catch (error) {
            console.log('mqtt.connect error', error)
            this.errorHandler(error)
        }
        const device = this

        let promise = Promise.resolve()

        function executeInSequence(asyncFun: any) {
            const myPromise = promise
                .then(asyncFun)
                .then(() => nextTick())
                .catch((e) => device.errorHandler(e))
                .finally(() => {
                    if (promise === myPromise) promise = Promise.resolve()
                })
            promise = myPromise
        }

        this.mqttClient!!
            .on('connect', () => {
                console.log('MQTT client connected')
                this.subscribeToMqttTopic(this.mqttTransportParams.deviceFeedTopic, device);
                this.startKeepAliveInterval()
            })
            .on('offline', () => {
                console.log("MQTT client went offline")
            })
            .on('close', () => {
                console.log("MQTT client closed")
            })
            .on('reconnect', () => {
                console.log('MQTT client reconnected')
                this.startKeepAliveInterval()
            })
            .on('disconnect', packet => {
                console.log('MQTT client disconnected by broker, reason:', packet.reasonCode)
                this.clearKeepAliveInterval()
            })
            .on('error', error => {
                console.log('MQTT client error', error)
                this.closeConnectionAndRemoveDeviceData()
                this.errorHandler(error)
            })
            .on('message', (topic, message) => {
                executeInSequence(async () => await this.receiveMessage(message))
            })
    }


    subscribeToMqttTopic(topic, device) {
        this.mqttClient.subscribe(topic, {qos: 1}, function (err, granted) {
            if (!err) {
                console.log(`Subscribed to MQTT topic[${topic}]`)
            } else {
                console.error(`Unable to subscribe to MQTT topic[${topic}]`)
                device.closeConnectionAndRemoveDeviceData()
                device.deviceStatus.connectionFailed = true
                device.errorHandler(err)
            }
        })
    }

    extractMqttOptions(brokerUrl: string) {
        if(brokerUrl.charAt(0) == '/') { //relative url
            brokerUrl = (window.location.origin+brokerUrl).replace("http","ws")
        }
        let parsedUrl = new URL(brokerUrl)
        return {
            clean: true,
            protocol: parsedUrl.protocol.replace(":", ""),
            hostname: parsedUrl.hostname,
            port: parseInt(parsedUrl.port),
            path: parsedUrl.pathname,
            clientId: Math.random().toString(36).substr(2, 9),
            keepalive: 30
        }
    }

    async connectDevice(variables = null) {
        this.deviceStatus.connecting = true
        this.deviceStatus.disconnecting = false
        this.deviceStatus.connected = false
        this.deviceStatus.disconnectedAfterConnected = false
        this.deviceStatus.currentAction = -4
        this.deviceStatus.error = ""

        if (!this.deviceId) {
            let urlParams = {testCaseId: this.testCaseId} as any
            if (variables) urlParams.variables = variables

            console.log(`connecting to a new device`)

            try {
                const deviceCreatedResponse = (await axios.post(`/api/v1/device/connect-device`, urlParams)).data
                this.deviceId = deviceCreatedResponse.deviceId
                this.encryptionEnabled = deviceCreatedResponse.encryptionConfig.enabled
                if (this.encryptionEnabled == true) {
                    this.encryptionSecret = deviceCreatedResponse.encryptionConfig.secret
                }

                sessionStorage.setItem(`device-${this.configId}`, this.deviceId)
                sessionStorage.setItem(`encryptionEnabled-${this.configId}`, this.encryptionEnabled.toString())
                sessionStorage.setItem(`encryptionSecret-${this.configId}`, this.encryptionSecret!!)
                sessionStorage.setItem(`deviceFeedTopic-${this.configId}`, deviceCreatedResponse.deviceFeedTopic)
                sessionStorage.setItem(`brokerExternalGateway-${this.configId}`, deviceCreatedResponse.brokerExternalGateway)
            } catch (e) {
                console.error(`failed to connect to the device`)
                this.deviceStatus.connecting = false
                throw(e)
            }
        }

        this.encryptionEnabled = sessionStorage.getItem(`encryptionEnabled-${this.configId}`) == "true"
        this.encryptionSecret = sessionStorage.getItem(`encryptionSecret-${this.configId}`)
        this.mqttTransportParams.deviceFeedTopic = sessionStorage.getItem(`deviceFeedTopic-${this.configId}`)!!
        this.mqttTransportParams.brokerExternalGateway = sessionStorage.getItem(`brokerExternalGateway-${this.configId}`)!!

        this.connectToMqtt()
    }

    async disconnectDeviceAndDeleteRecording() {
        return this.disconnectDevice(null)
    }

    async disconnectDevice(status: string | null) {
        this.deviceStatus.disconnecting = true
        this.closeConnectionAndRemoveDeviceData()
        try {
            if (this.deviceId) await axios.post(`/api/v1/device/disconnect-device`, {
                deviceId: this.deviceId,
                success: status
            })
        } finally {
            this.deviceStatus.disconnecting = false
        }
    }

    async resetDevice() {
        await this.disconnectDeviceAndDeleteRecording()
        this.deviceId = ""
        await this.connectDevice()
        return true
    }

    closeConnectionAndRemoveDeviceData() {
        this.disconnectFromDeviceUpdates()
        this.removeDeviceInfoFromSessionStorage()
        this.backendState = null
        this.deviceStatus.reset()
    }

    disconnectFromDeviceUpdates() {
        this.unsubscribeToDeviceFeed()
        this.clearKeepAliveInterval()
    }

    unsubscribeToDeviceFeed() {
        this.mqttClient && this.mqttClient.end(true)
    }

    clearKeepAliveInterval() {
        if (this.timerWorker) {
            this.timerWorker.postMessage({action: "clearInterval"})
            this.timerWorker = null
        }
    }

    startKeepAliveInterval() {
        if (!this.timerWorker) {
            this.timerWorker = new Worker(worker_script)
            this.timerWorker.onmessage = ({data: {time}}) => {
                this.keepAlive()
            }
            this.timerWorker.postMessage({action: "setInterval", intervalValue: 30_000})
        }
    }

    removeDeviceInfoFromSessionStorage() {
        sessionStorage.removeItem(`device-${this.configId}`)
        sessionStorage.removeItem(`deviceFeedTopic-${this.configId}`)
        sessionStorage.removeItem(`brokerExternalGateway-${this.configId}`)
        sessionStorage.removeItem(`encryptionEnabled-${this.configId}`)
        sessionStorage.removeItem(`encryptionSecret-${this.configId}`)
    }


    async receiveMessage(message) {
        let jsonMessage = ""
        if (this.encryptionEnabled == true) {
            let key = CryptoJS.enc.Base64.parse(this.encryptionSecret)
            const decoder = new TextDecoder('UTF-8')

            const toString = (bytes) => {
                const array = new Uint8Array(bytes)
                return decoder.decode(array)
            }

            let str = toString(message)
            let decrypt = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
            jsonMessage = decrypt.toString(CryptoJS.enc.Utf8)
        } else {
            jsonMessage = message
        }

        const g = JSON.parse(jsonMessage)

        await this.processStateUpdate(g);
    }

    async processStateUpdate(newState: DeviceState) {
        if (newState.error && (!this.backendState || !this.backendState.error || this.backendState.error.id !== newState.error.id)) {
            console.log("state changed[error]: ", JSON.stringify(newState.error));
            this.errorHandler(new DeviceError({errorMessage: newState.error.message, traceId: newState.error.traceId}));
        }
        if (newState.connectionState &&
            (!this.backendState || newState.connectionState !== this.backendState.connectionState)) {
            console.log("state changed[connection]: ", JSON.stringify(newState.connectionState));
            switch (newState.connectionState) {
                case "CONNECTED":
                    this.deviceStatus.connecting = false
                    this.deviceStatus.connected = true

                    let isFirstUpdate = !this.backendState
                    if (isFirstUpdate) {
                        await this.synchronizeState()
                    }
                    break
                case "DISCONNECTED":
                    this.closeConnectionAndRemoveDeviceData()
                    this.deviceStatus.disconnectedAfterConnected = true
                    break
                case "CONNECTION_FAILED":
                    this.closeConnectionAndRemoveDeviceData()
                    this.deviceStatus.connectionFailed = true
                    break
            }
        }
        if (this.deviceStatus.connected) {
            if (newState.imageUrl && (!this.backendState || newState.imageUrl !== this.backendState.imageUrl)) {
                console.log("state changed[image]: ", JSON.stringify(newState.imageUrl))
                this.deviceStatus.imageUrl = newState.imageUrl
            }
            // TODO remove this part after the next deployment
            if ((newState.clipboard || newState.clipboard === '') && (!this.backendState || newState.clipboard !== this.backendState.clipboard)) {
                if (typeof newState.clipboard === 'string') {
                    console.log("state changed[clipboard]: ", JSON.stringify(newState.clipboard))
                    this.deviceStatus.clipboard = newState.clipboard
                }
            }
            if (newState.runtimeVariables) {
                if (typeof newState.runtimeVariables === 'object') {
                    if (JSON.stringify(newState.runtimeVariables) !== JSON.stringify(this.deviceStatus.runtimeVariables)) {
                        console.log(`state changed[runtimeVariables]:`, JSON.stringify(newState.runtimeVariables))
                        this.deviceStatus.runtimeVariables = newState.runtimeVariables
                    }
                    for (let key in newState.runtimeVariables) {
                        const value = newState.runtimeVariables[key]
                        if (!this.deviceStatus.runtimeVariables || !this.deviceStatus.runtimeVariables[key] || this.deviceStatus.runtimeVariables[key] != value) {
                            if (key === 'CLIPBOARD') {
                                this.deviceStatus.clipboard = value
                            }
                        }
                    }
                }
            }
            if (newState.action) {
                if (!this.backendState || JSON.stringify(newState.action) !== JSON.stringify(this.backendState.action)) {
                    console.log("state changed[action]: ", JSON.stringify(newState.action))
                    const actionHasChanged =
                        !this.backendState ||
                        !this.backendState.action ||
                        this.backendState.action.id !== newState.action.id ||
                        (!this.backendState.action.id && !newState.action.id)

                    if (actionHasChanged) {
                        this.deviceStatus.actionStatus = "unknown"
                        this.deviceStatus.nextFrame = null
                        this.deviceStatus.candidates = null
                    }
                    this.deviceStatus.currentAction = newState.action.id
                    this.deviceStatus.currentActionMatcherId = newState.action.matcherId
                    if (this.deviceStatus.currentAction === this.deviceStatus.playingTo) {
                        this.setPlayingTo(null)
                    }
                }
            }

            const actionAnchorAreaMatch = newState.actionAnchorAreaMatch
            if (actionAnchorAreaMatch && (!this.backendState || JSON.stringify(actionAnchorAreaMatch) !== JSON.stringify(this.backendState.actionAnchorAreaMatch))) {
                if (actionAnchorAreaMatch.actionMatcherId === this.deviceStatus.currentActionMatcherId ||
                    !actionAnchorAreaMatch.actionMatcherId) { // for backward compatibility
                    console.log("state changed[match]: ", JSON.stringify(actionAnchorAreaMatch))
                    if (actionAnchorAreaMatch.matched) {
                        this.deviceStatus.nextFrame = actionAnchorAreaMatch.matchCoordinates
                        this.deviceStatus.actionStatus = "matched"
                        this.deviceStatus.candidates = []
                    } else {
                        this.deviceStatus.nextFrame = null
                        this.deviceStatus.actionStatus = "mismatched"
                        if (actionAnchorAreaMatch.candidates) {
                            this.deviceStatus.candidates = actionAnchorAreaMatch.candidates
                        } else {
                            this.deviceStatus.candidates = []
                        }
                    }
                } else {
                    console.log("state changed[match]: skipping as match corresponds to another matcher", JSON.stringify(newState.actionAnchorAreaMatch))
                }
            }
            if (this.backendState && newState.playing !== this.backendState.playing) {
                if (this.backendState) {
                    console.log("state changed[playing]: ", JSON.stringify(newState.playing))
                    this.deviceStatus.playing = newState.playing
                    if (!this.deviceStatus.playing) {
                        this.deviceStatus.playingTo = null
                    }
                }
            }

            if (this.backendState) {
                if (newState.promptResponse) {
                    this.deviceStatus.promptsPerAction.set(this.deviceStatus.currentAction, newState.promptResponse)
                } else {
                    this.deviceStatus.promptsPerAction.delete(this.deviceStatus.currentAction)
                }
            }
        }
        this.backendState = newState
    }

    async synchronizeState() {
        if (this.deviceStatus.sendOnceConnectedCurrentActionId) {
            await this.activateAction({id: this.deviceStatus.sendOnceConnectedCurrentActionId})
            this.deviceStatus.sendOnceConnectedCurrentActionId = null
        }
        if (this.deviceStatus.sendOnceConnectedPlayRequest) {
            const {from, to, n} = this.deviceStatus.sendOnceConnectedPlayRequest
            this.play(from, to, n).catch(this.errorHandler)
            this.deviceStatus.sendOnceConnectedPlayRequest = null
        }
    }

    activateAction(action: ActionDTO) {
        if (this.deviceStatus.currentAction != action.id) this.deviceStatus.nextFrame= null
        this.deviceStatus.currentAction = action.id
        this.deviceStatus.currentActionMatcherId = action.matcherId
        this.deviceStatus.actionStatus = "unknown"
        if (this.deviceStatus.currentAction === this.deviceStatus.playingTo) {
            this.setPlayingTo(null)
        }
        if (this.deviceStatus.connected) {
            return axios.post(`/api/v1/device/activate-action`, {deviceId: this.deviceId, actionId: action.id})
        } else {
            this.deviceStatus.sendOnceConnectedCurrentActionId = action.id
        }
    }

    async play(fromActionId: number|null = null, toActionId: number|null = null, numberActionsToPlay: number|null= null) : Promise<PlayActionStatus> {
        if (toActionId) this.setPlayingTo(toActionId)

        const playRequest = {
            deviceId: this.deviceId,
            from: fromActionId,
            to: toActionId,
            n: numberActionsToPlay
        }

        this.deviceStatus.playing = true

        if (this.deviceStatus.connected) {
            return axios.post(`/api/v1/device/play`, playRequest).then(x => x.data)
        } else {
            this.deviceStatus.sendOnceConnectedPlayRequest = playRequest
            return { status: "SUCCESS"} as PlayActionStatus
        }
    }

    async playFromStartTo(playToActionId: number) {
        return this.play(null, playToActionId)
    }

    stopPlaying() {
        this.deviceStatus.playing = false
        this.deviceStatus.playingTo = null

        if (this.deviceStatus.connected) {
            return axios.post(`/api/v1/device/stop`, {deviceId: this.deviceId})
        } else {
            this.deviceStatus.sendOnceConnectedPlayRequest = null
        }
    }

    keepAlive() {
        return axios.post(`/api/v1/device/keep-alive`, {deviceId: this.deviceId})
    }

    async stopAllActions() {
        if (this.deviceStatus.playing) {
            await this.stopPlaying()
        }
    }

    setPlayingTo(playingTo: number) {
        this.deviceStatus.playingTo = playingTo
    }
}

export class PendingDeviceService extends DeviceService {
    constructor() {
        super()
        this.deviceStatus.currentAction = -4
        this.deviceStatus.connected = true
        this.deviceStatus.isDummy = true
    }
}

export class DummyDeviceService extends PendingDeviceService {
    frame?: FrameDTO
    recording: RecordingDTO
    imageUrl?: string

    constructor(recording: RecordingDTO, imageUrl?: string) {
        super()
        this.recording = recording
        this.imageUrl = imageUrl
    }

    setRuntimeVariables(runtimeVariables: any) {
        this.deviceStatus.runtimeVariables = runtimeVariables
    }

    setCurrentAction(currentAction: number) {
        this.deviceStatus.currentAction = currentAction
    }

    activateAction(action: ActionDTO) {
        const index = this.recording.frames
                                .findIndex(f => action.matcherId === f.meta?.matcherId 
                                             && f.imageUrl == this.imageUrl);

        this.activateFrameByIndex(index)

        this.deviceStatus.currentAction = action.id

        if (action.id === this.frame?.actionId) {
            this.deviceStatus.nextFrame = this.frame.meta?.anchorMatchResult
            this.deviceStatus.candidates = this.frame.meta?.candidates
            this.deviceStatus.currentActionMatcherId = this.frame.meta?.matcherId
        }
    }

    playFromStartTo(playingTo: number) {
        this.setCurrentAction(playingTo)
    }

    private activateFrame(frame: FrameDTO, previousFrames: FrameDTO[]) {
        this.frame = frame
        this.deviceStatus.imageUrl = this.imageUrl || frame.imageUrl

        const runtimeVariablesObj = {}
        previousFrames.forEach(fr => {
            const frameRuntimeVariables = fr?.meta?.runtimeVariables ?? {}
            Object.assign(runtimeVariablesObj, frameRuntimeVariables)
        })

        this.deviceStatus.runtimeVariables = runtimeVariablesObj
        this.deviceStatus.nextFrame = frame.meta?.anchorMatchResult
        this.deviceStatus.candidates = frame.meta?.candidates

        this.deviceStatus.promptsPerAction.set(this.deviceStatus.currentAction, [])

        this.fillPromptsPerActionByCurrentFrame(previousFrames)

        const promptResponse = this.frame?.meta?.promptResponse
        if (promptResponse && promptResponse.length > 0) {
            this.deviceStatus.promptsPerAction
            .get(this.deviceStatus.currentAction)!
            .push(promptResponse[0])
        }
    }

    private fillPromptsPerActionByCurrentFrame(previousFrames: FrameDTO[]) {
        const actionIds = previousFrames
            .filter(fr => fr.actionId)
            .map(fr => fr.actionId)

        let currentActionId = actionIds[0]
        if (!currentActionId)
            return

        previousFrames
            .filter(fr => fr.actionId)
            .forEach(fr => {
                const frameActionId = fr.actionId!!
                if (!this.deviceStatus.promptsPerAction.get(frameActionId) || currentActionId != frameActionId) {
                    this.deviceStatus.promptsPerAction.set(frameActionId, [])
                }

                if (fr?.meta?.promptResponse != null && fr?.meta?.promptResponse[0]) {
                    this.deviceStatus.promptsPerAction.get(frameActionId)
                    ?.push(fr?.meta?.promptResponse[0])
                }
                if (currentActionId != frameActionId) {
                    currentActionId = frameActionId
                }
            })
    }


    getPrompts(): PromptExecutedAction[] | undefined {
        return this.deviceStatus.promptsPerAction.get(this.deviceStatus.currentAction)
    }

    activateFrameByIndex(index: number) {
        const frame = this.recording.frames[index]
        if (index == 0) {
            this.deviceStatus.promptsPerAction.clear()
        }

        this.deviceStatus.nextFrame = null
        this.deviceStatus.candidates = undefined
        this.deviceStatus.currentActionMatcherId = null

        if (frame) {
            this.activateFrame(frame!, this.recording.frames.slice(0, index))

            this.setCurrentAction(this.findCurrentActionId(index))
            
        }
    }

    private findCurrentActionId(index: number) {
        const frame = this.recording.frames[index]
        if (frame.actionId) {
            return frame.actionId
        } else {
            return this.recording.frames
                .slice(index)
                .find(fr => !!fr.actionId)?.actionId || -4
        }
    }

    fakeCurrentAction() {
        return {
            matcherId: this.frame?.meta?.matcherId,
            inputs: this.frame?.actionInputs
        }
    }

    connectDevice() {
        return 1
    }
}

export class DeviceState {
    connecting = false
    connected = false
    disconnecting = false
    disconnectedAfterConnected = false
    connectionFailed = false
    time = Date.now()
    playing = false
    error = ""
    nextFrame: any = null
    mismatch = null
    currentAction = -4
    playingTo: number | null = null
    currentActionMatcherId = null
    imageUrl? = ""
    retakeAnchorArea = false
    candidates? : ImageMatchResult[] = []
    clipboard = ""
    runtimeVariables = {}
    findImage = false
    actionStatus = "" // todo rename to anchorAreaMatchStatus
    isDummy = false

    sendOnceConnectedCurrentActionId = null
    sendOnceConnectedPlayRequest: any = null

    promptResponse : PromptExecutedAction[] | null = null

    promptsPerAction = new Map<number, PromptExecutedAction[]>()

    reset() {
        this.connecting = false
        this.connected = false
        this.disconnecting = false
        this.disconnectedAfterConnected = false
        this.connectionFailed = false
        this.playing = false
        this.error = ""
        this.nextFrame = null
        this.mismatch = null
        this.currentAction = -4
        this.currentActionMatcherId = null
        this.imageUrl = undefined
        this.retakeAnchorArea = false
        this.candidates = []
        this.clipboard = ""
        this.findImage = false
        this.actionStatus = ""
        this.runtimeVariables = {}

        this.sendOnceConnectedCurrentActionId = null
        this.sendOnceConnectedPlayRequest = null
    }
}

export function informServerAboutError(errorMessage: string) {
    return axios.post(`/api/v1/logger`, {message: errorMessage}).catch(console.error)
}
