<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
    getProject,
    deleteProject,
    saveProject,
    runTestcases,
    stopAllProjectTestcases,
    createTestCase,
    duplicateProject,
    getTests,
    deleteTestCase,
    duplicateTestCase,
    moveTestCase,
} from "@/services/ProjectService"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import ProjectExecutions from "@/modules/project/components/ProjectExecutions.vue"
import VEditorOverlay from "@/common/components/layout/VEditorOverlay.vue"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import { useStore } from "@/store/useStore"
import ProjectTags from "@/modules/project/components/ProjectTags.vue"
import ProjectSettings from "@/modules/project/components/ProjectSettings.vue"
import ProjectEventsList from "./components/ProjectEventsList.vue"
import { blockScreen } from "@/common/util"
import type { TestCaseCreateRequest, ProjectDTO, TestCaseDTO } from "@/types/gen"

const route = useRoute()
const router = useRouter()

const project = ref<ProjectDTO>()
const tests = ref<TestCaseDTO[]>([])
const testsLoaded = ref(false)
const configId = ref<any>(null)
const frameBorderClass = ref("")
const bgClass = ref("")
const openModal = ref(true)
const isProjectRunning = ref(false)

const projectExecutions = ref<InstanceType<typeof ProjectExecutions>>()
const detailedView = ref<InstanceType<typeof DetailedView>>()

const { incUserLevel } = useStore()

onMounted(async () => {
    await reload()
})

const hasTests = computed(() => !!tests.value.length)

const dropDownItems = computed(() => [
    {
        iconClass: ["far", "fa-clone"],
        text: "Duplicate Project",
        callback: doDuplicateProject,
    },
    {
        iconClass: ["fas", "fa-sync-alt"],
        text: "Reload",
        callback: reload,
    },
    {
        iconClass: ["fas", "fa-trash"],
        text: "Delete Project",
        confirm: true,
        disabled: !project.value,
        callback: doDeleteProject,
    },
])

watch(
    () => route.params.projectId,
    async (value) => {
        if (value != null) await reload()
    }
)

async function reload() {
    const projectId = parseInt(route.params.projectId as string)
    const getProjectPromise = getProject(projectId)
    const getTestsPromise = getTests({ id: projectId })
    project.value = await getProjectPromise
    tests.value = await getTestsPromise
    testsLoaded.value = true
    document.title = project.value.name
}

async function doDuplicateProject() {
    blockScreen(async () => {
        const projectId = parseInt(route.params.projectId as string)
        const newProject = await duplicateProject(projectId)
        router.push(`/project/${newProject.id}`)
        await reload()
    })
}

function runTest(testcase: any) {
    router.push(`/run/${testcase.id}`)
}

function goBack() {
    router.replace("/")
}

async function doDeleteProject() {
    if (project.value) {
        await deleteProject(project.value)
        router.replace("/")
    }
}

async function doSaveProject() {
    if (project.value) {
        if (project.value.name != "Project name") incUserLevel(2, route)
        project.value = await saveProject(project.value)
        document.title = project.value.name
    }
}

async function doCreateTestCase() {
    if (project.value) {
        incUserLevel(3, route)
        const createTestCasePayload = {
            projectId: project.value.id,
            name: "Name of testcase"
        } as TestCaseCreateRequest
        const newTestcase = await createTestCase(createTestCasePayload)
        router.push(`/testcase/${newTestcase.id}`)
    }
}

async function runAllProjectTestcases() {
    await runTestcases(project.value!, "Started by play-all")
    await reload()

    // switch tab to recordings view, placing it after restart prevent visual problems
    switchTab(0)
    await projectExecutions.value?.reload()
}

async function doStopAllProjectTestcases() {
    await stopAllProjectTestcases(project.value!)
}

function colorClassChanged(data: { frameBorderClass: string; bgClass: string }) {
    frameBorderClass.value = data.frameBorderClass
    bgClass.value = data.bgClass
}

function switchTab(tab: number) {
    if (!detailedView.value) return

    detailedView.value.updateTab(tab)
}

async function deleteTest({testcase, withDescendants}: any) {
    tests.value.splice(tests.value.indexOf(testcase), 1)
    await deleteTestCase(testcase, withDescendants)
    await reload()
}

async function duplicateTest({testcase, withDescendants}: any) {
    await duplicateTestCase(testcase.id, withDescendants)
    await reload()
}

async function moveToProject({testcase, project}: any) {
    tests.value.splice(tests.value.indexOf(testcase), 1)
    await moveTestCase(testcase.id, project.id, true)
    await reload()
}
</script>

<template lang="pug">
.project-view
    v-editor-overlay(:componentToExecute="'viewer'", @reload="reload")
    detailed-view(
        ref="detailedView",
        :tabNames="['Executions', 'Project settings', 'Tags', 'Events']",
        :frameBorderClass="frameBorderClass",
        :bgClass="bgClass"
    )
        template(#navigate-back)
            v-navigate-back(@goBack="goBack")
        template(#breadcrumb)
            v-breadcrumb(:projectName="project?.name", :simpleMode="true")
        template(#title)
            h4
                v-editable-text(v-if="project" v-model="project.name", @update:modelValue="doSaveProject")
        template(#button-group)
            v-action-button.invisible-in-print(
                :main="hasTests ? (!isProjectRunning ? 'Play All' : 'Stop All') : null",
                :mainClass="!isProjectRunning ? null : 'btn-danger'",
                :mainIconClass="!isProjectRunning ? ['fas', 'fa-play'] : ['fas', 'fa-stop-circle']",
                :dropDownItems="dropDownItems",
                @main="!isProjectRunning ? runAllProjectTestcases() : doStopAllProjectTestcases()"
            )
        template(#tab0)
            project-executions(
                v-if="project && testsLoaded",
                v-model:isRunning="isProjectRunning",
                :project="project",
                :tests="tests",
                @colorClassChanged="colorClassChanged",
                @createTestCase="doCreateTestCase",
                @runTest="runTest",
                @reloadView="reload",
                @deleteTestCase="deleteTest",
                @duplicateTestCase="duplicateTest",
                @moveToProject="moveToProject",
                ref="projectExecutions"
            )
            .spinner-container(v-if="!testsLoaded")
                .spinner
                    .spinner-item
                    .spinner-item
                    .spinner-item
        template(#tab1)
            project-settings.tu-tab-area(:project="project")
        template(#tab2)
            project-tags.tu-tab-area(v-if="project && testsLoaded", :project="project", :tests="tests")
        template(#tab3)
            project-events-list(
                :project="project"
            )
</template>

<style lang="css" scoped>
.project-view {
    height: var(--project-view-height);
    /* width: var(--project-view-width); */
    display: grid;
    grid-template-columns: min-content 1fr;
    overflow-y: hidden;
    overflow-x: hidden;
}
</style>
