import { onMounted, onUnmounted } from "vue"

export default function useEventListener<K extends keyof WindowEventMap>(
    target: Element | typeof window | Document | null,
    event: K,
    listener: (this: Window, ev: WindowEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
) {
    onMounted(() => {
        if (!target) return
        target.addEventListener(event, listener as EventListenerOrEventListenerObject, options)
    })

    onUnmounted(() => {
        if (!target) return
        target.removeEventListener(event, listener as EventListenerOrEventListenerObject, options)
    })
}
