<script lang="ts" setup>
import {
    computed,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    onBeforeUpdate,
    onUpdated,
    ref,
    watch,
} from "vue"
import { useStore } from "@/store/useStore"
import { storeToRefs } from "pinia"
import { setMatcherNoDevice, addAnnotation, updateAnnotation, deleteAnnotation } from "@/services/ProjectService"
import VHelpText from "@/common/components/layout/VHelpText.vue"
import ScreenArea from "@/common/components/viewer/ScreenArea.vue"
import { DummyDeviceService } from "@/services/BackendService"
import ViewerDisplayTag from "@/common/components/viewer/ViewerDisplayTag.vue"
import type { AnnotationDTO, AnnotationUpstreamDTO, FrameDTO, MatcherOnArea, RecordingDTO } from "@/types/gen"

interface Props {
    mode?: string
    device: DummyDeviceService
    recording: RecordingDTO
    initialFrame: any
    autoplay: boolean
    navigation: any
    lastFrame?: any
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "currentFrame", data: number): void
    (event: "resetActions"): void
    (event: "setCurrentFrame", index: number): void
}>()
defineExpose({
    next,
    prev,
    first,
    setCurrentFrame,
})

const currentFrame = ref<FrameDTO>()
const candidates = ref<any>(null)
const mismatchImgUrl = ref<string | null>(null)
const currentIndex = ref(props.initialFrame || 0)
const maxIndex = ref(0)
const end = ref(props.lastFrame || 0)
const firstIndex = ref(props.initialFrame || 0)
const timer = ref<number | null>(null)
const showErrorPopup = ref(true)
const actiontext = ref<any[]>([])
const frameTimeOut = ref(1000)
const currentEditableAnnotation = ref<AnnotationDTO | null>(null)

const frameImage = ref<HTMLImageElement>()
const modalContent = ref<HTMLDivElement>()
const modalTitle = ref<HTMLElement>()
const mismatchImage = ref<HTMLImageElement>()

const { dragged, drawAnnotation } = storeToRefs(useStore())

const highlightedCandidate = useStore().getHighlightedCandidateRef()
const cropContainerStyle = ref<any>()
const cropStyle = ref<any>()

onBeforeMount(() => {
    const parsedStartValue = parseInt(props.initialFrame)
    const parsedEndValue = parseInt(end.value)
    firstIndex.value = currentIndex.value =
        parsedStartValue || parsedStartValue == 0 ? parsedStartValue : getIndexOfTaggedFrame(1, props.initialFrame)
    end.value = parsedEndValue || parsedEndValue == 0 ? parsedEndValue : getIndexOfTaggedFrame(-1, end.value)
    maxIndex.value = props.recording.frames.length - 1
    setCurrentFrame(currentIndex.value)
    if (props.autoplay) {
        playRecording()
    }
})

onBeforeUnmount(() => {
    if (timer.value) clearInterval(timer.value)
})

onBeforeUpdate(() => {
    if (!props.recording.frames[currentIndex.value]) return
    if (
        props.recording.frames[currentIndex.value]?.actionInputs?.length &&
        props.recording.frames[currentIndex.value]?.actionInputs[0].type === "TAG"
    ) {
        actiontext.value[0] = {text: props.recording.frames[currentIndex.value].actionInputs[0].text}
    } else {
        actiontext.value = []
    }
    frameTimeOut.value = actiontext.value[0] ? Math.max(1000, 1000 + actiontext.value[0].text.length * 50) : 1000
    if (timer.value) {
        stop()
        playRecording()
    }
})

const prevActive = computed(() => {
    return currentIndex.value > 0
})

const nextActive = computed(() => {
    return currentIndex.value < maxIndex.value
})

const showErrorOverlay = computed(() => {
    return currentFrame.value && currentFrame.value.errorMessage && showErrorPopup.value
        && props.mode != "edit" && props.mode != "compare"
})

const candidatesShown = computed(() => {
    return candidates.value && props.mode=='edit'
})

watch(
    () => props.recording,
    () => {
        setCurrentFrame(currentIndex.value)
    }
)

watch(currentIndex, (newVal, oldVal) => {
    emit("currentFrame", newVal as unknown as number)
})

watch(
    () => props.initialFrame,
    (n) => {
        setCurrentFrame(n)
    }
)

watch(
    () => highlightedCandidate.value,
    (candidate) => {
        const currentFrameImageUrl = currentFrame.value!!.imageUrl
        if (candidate) {
            const mismatchImg = mismatchImage.value
            if (!mismatchImg) return

            const frameWidth = props.device.frame?.meta?.anchorMatchResult?.originWidth!
            const mismatchImgPadding = getImgPadding(mismatchImg)

            const scaleX = mismatchImg.naturalWidth / (mismatchImg.offsetWidth - mismatchImgPadding)
            const scaleY = mismatchImg.naturalHeight / (mismatchImg.offsetHeight - mismatchImgPadding)
            const { x0, y0 } = candidate;

            cropStyle.value = {
                width: `${frameWidth / scaleX}px`,
                left: `-${x0 / scaleX}px`,
                top: `-${y0 / scaleY}px`
            };

            cropContainerStyle.value = {
                width: `${mismatchImg.offsetWidth - mismatchImgPadding}px`,
                height: `${mismatchImg.offsetHeight - mismatchImgPadding}px`,
            }
        }
    }
)

function getImgPadding(img: HTMLImageElement | undefined): number {
    try {
        return parseFloat(window.getComputedStyle(img!!).paddingLeft) * 2
    } catch (e) {
        return 0
    }
}

function getIndexOfTaggedFrame(index: number, tag: string) {
    for (const frame of props.recording.frames) {
        if (
            frame.actionInputs.length > 0 &&
            frame.actionInputs[0].type != "MARKER" &&
            frame.actionInputs[0].text == tag
        )
            return index
        index++
    }
    throw new Error("No such tag found")
}

const frameImageUrl= computed(() => props.device.deviceStatus.imageUrl)

async function setCurrentFrame(index = 0) {
    if (!(props.recording && props.recording.frames && props.recording.frames.length)) {
        return false
    }
    if (props.recording.frames[index]) {
        currentIndex.value = index
        currentFrame.value = props.recording.frames[index]

        props.device.activateFrameByIndex(index)
        await nextTick()

        if (currentFrame.value.mismatchUrl) {
            mismatchImgUrl.value = currentFrame.value.mismatchUrl
        }
        if (end.value >= props.recording.frames.length - 1) {
            maxIndex.value = props.recording.frames.length - 1
        } else if (end.value > 0) {
            maxIndex.value = end.value
        } else {
            maxIndex.value = props.recording.frames.length - 1
        }

        cacheImage(index + 1)
        cacheImage(index + 2)
    }
    showErrorPopup.value = true
    return
}

function prev() {
    setCurrentFrame(currentIndex.value - 1)
}

function next() {
    setCurrentFrame(currentIndex.value + 1)
}

function first() {
    setCurrentFrame(0)
}

function cacheImage(index: number) {
    if (props.recording.frames[index]) {
        new Image().src = props.recording.frames[index].imageUrl
    }
}

function getFrameClickPos(frame: any) {
    const retclick = frame.actionInputs
        .filter((elem: any) => ["CLICK", "RIGHT_CLICK", "DOUBLE_CLICK", "HOVER"].includes(elem.type))
        .map((elem: any) => ({ x: elem.x, y: elem.y, type: elem.type }))
    const retswipe = frame.actionInputs
        .filter((elem: any) => elem.type === "SWIPE")
        .map((elem: any) => ({ x: elem.x, y: elem.y, type: elem.type }))
    let ret = []
    if (retswipe.length) {
        ret = [retclick[0], retswipe[0]]
    } else {
        ret = retclick
    }
    return ret
}

function playRecording() {
    timer.value = setInterval(() => {
        if (!props.autoplay) {
            stop()
            return
        }
        if (currentIndex.value === maxIndex.value) {
            setCurrentFrame(firstIndex.value)
        } else {
            next()
        }
    }, frameTimeOut.value) as unknown as number
}

function stop() {
    if (timer.value) clearInterval(timer.value)
}

function closeError() {
    if (!dragged.value) showErrorPopup.value = false
}


async function useCandidate(candidateFrame: any) {
    if (dragged.value) return
    const frame = {
        x0: candidateFrame.x,
        x1: candidateFrame.right,
        y0: candidateFrame.y,
        y1: candidateFrame.bottom,
        imageUrl: frameImageUrl.value
    } as MatcherOnArea
    await setMatcherNoDevice(frame, currentFrame.value.actionId)
    emit("resetActions")
}

const currentAnnotations = computed(() =>
    props.recording?.annotations.filter(a => a.content?.imageUrl == currentFrame.value?.imageUrl)
)

async function annotateRect(rect: DOMRect, callback:Function) {
    drawAnnotation.value = false
    const annotation = {content: {
        x0: rect.x,
        x1: rect.right,
        y0: rect.y,
        y1: rect.bottom,
        imageUrl: currentFrame.value?.imageUrl!!,
        frameIndex: props.recording.frames.indexOf(currentFrame.value!!),
        text: "annotation"
    }} as AnnotationUpstreamDTO

    if (!currentEditableAnnotation.value) {
        const savedAnnotation = await addAnnotation(props.recording, annotation)
        props.recording.annotations.push(savedAnnotation as AnnotationDTO)
    } else {
        const content = currentEditableAnnotation.value.content
        content.x0 = annotation.content.x0
        content.x1 = annotation.content.x1
        content.y0 = annotation.content.y0
        content.y1 = annotation.content.y1
        await updateAnnotation(currentEditableAnnotation.value)
        currentEditableAnnotation.value = null
    }

    callback()
}

async function doDeleteAnnotation(annotation: AnnotationDTO) {
    await deleteAnnotation(annotation)
    props.recording.annotations = props.recording.annotations.filter(a => a.id != annotation.id)
}

async function doUpdateAnnotation(annotation: AnnotationDTO) {
    await updateAnnotation(annotation)
}

async function doRetakeAnnotation(annotation: AnnotationDTO) {
    currentEditableAnnotation.value = annotation
    drawAnnotation.value = true
}

function resolveErrorMessage(currentFrame: any) {
    if (highlightedCandidate.value) {
        return "Best match"
    }
    if (currentFrame.traceId) {
        return `${currentFrame.errorMessage} [#${currentFrame.traceId}]`
    }
    return currentFrame.errorMessage
}
</script>

<template lang="pug">
.viewer-component-wrapper
    #overlay-text.alert-danger(v-if="showErrorOverlay", v-movable)
        .modal-header
            p.modal-title {{ resolveErrorMessage(currentFrame) }}
            button.close(type="button", data-dismiss="modal", aria-label="Close", @click="closeError")
                span(aria-hidden="true") ×
        .modal-content(ref="modalContent")
            img.image-height(ref="mismatchImage" v-if="mismatchImgUrl", :src="mismatchImgUrl")
            div.image-container(v-if="mismatchImgUrl && highlightedCandidate")
                div.cropped-region(:style="cropContainerStyle")
                    img(:src="frameImageUrl", :style="cropStyle")
    .viewer-component-content
        screen-area.frame-img-container.magnifiable(
            :device="device.deviceStatus"
            :annotations="currentAnnotations"
            :magnify="false"
            :drawable="drawAnnotation"
            :allowEdit="false"
            :hideUseButton="mode=='compare'"
            :currentAction="device.fakeCurrentAction()"
            @updateRect="useCandidate"
            @newRect="annotateRect"
            @deleteAnnotation="doDeleteAnnotation"
            @updateAnnotation="doUpdateAnnotation"
            @retakeAnnotation="doRetakeAnnotation"
        )

        viewer-display-tag(:texts="actiontext", :closeButton="false")
        .frame-img-container.missing-image-text(v-if="currentFrame && !frameImageUrl")
            h2 Missing image
        .frame-img-container.missing-image-text(v-else-if="!currentFrame || currentFrame.notPlayed")
            h2 Action not played
        .left-box(v-if="!navigation && !drawAnnotation", @click="prev")
            .left-control
                a.control_prev ❮❮
        .right-box(v-if="!navigation && !drawAnnotation", @click="next")
            .right-control
                a.control_next ❯❯
</template>

<style lang="css" scoped>
.viewer-component-wrapper {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: inherit;
    overflow: hidden;
    grid-column: 1/4;
    grid-row: 1/2;

    .image-container {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .cropped-region {
        position: relative;
        overflow: hidden;
        margin: 5px;
    }
    
    .cropped-region > img {
        position: absolute;
    }
}

.viewer-component-content {
    height: inherit;
    width: 100%;
    display: grid;
    text-align: center;
    align-items: center;
    grid-column: 1/2;
    grid-row: 1/2;
    position: relative;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr 1fr;
    overflow: hidden;
}

.frame-img-container {
    position: relative;
    grid-row: 1;
    grid-column: 1/4;
    height: inherit;
    overflow: hidden;
}

.frame-img-container > svg {
    position: absolute;
    /*top: 0;*/
    left: 0;
    height: 100%;
    width: 100%;
}

.frame-img-container > img {
    object-fit: contain;
    overflow: hidden;
    max-width: 100%;
    height: inherit;
}

.frame-img-container.missing-image-text {
    display: grid;
    align-items: center;
    justify-items: center;
}

a.control_prev,
a.control_next {
    z-index: 999;
    display: block;
    padding: 12px 23px;
    background: #2a2a2a;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    opacity: 0.5;
    user-select: none;
    width: fit-content;
}

.left-control {
    justify-self: left;
}

.right-control {
    justify-self: right;
}

.left-box {
    opacity: 0.5;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    align-content: center;
    width: 100%;
    height: 100%;
}

.right-box {
    opacity: 0.5;
    grid-column: 3;
    grid-row: 1;
    display: grid;
    align-content: center;
    width: 100%;
    height: 100%;
}

.left-box:hover,
.right-box:hover {
    opacity: 0.9;
    -webkit-transition: all 0.2s ease;
    color: white;
    cursor: pointer;
}

a.control_prev.disabled,
a.control_next.disabled {
    opacity: 0.4;
    cursor: default;
}

a.control_prev {
    border-radius: 25px 0 0 25px;
    padding-right: 15px;
}

a.control_next {
    border-radius: 0 25px 25px 0;
    padding-left: 15px;
}

#overlay-text {
    height: auto;
    overflow: hidden;
    z-index: 10005;
    place-items: center;
    display: grid;
    grid-column: 1/2;
    grid-row: 1/2;
    filter: drop-shadow(2px 2px 2px gray);
    border-radius: 0.3rem;
    max-height: 75%;
    max-width: 75%;
}

.image-height {
    padding: 5px;
    object-fit: contain;
    max-height: 50vmin;
    overflow: hidden;
}

.modal-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
}

.modal-title {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 1.6vmin;
    min-width: 15ch;
}

#modal-3 .modal-header button.close {
    color: rgb(0, 0, 0);
}

.modal-content {
    overflow: hidden;
}
</style>