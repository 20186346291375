<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { getTestCase, getTestData, updateTestCase } from "@/common/aqua/AquaService"
import { computed } from "@vue/reactivity";
import { config } from "process";

interface Props {
    exportToAquaId: string,
    config: any
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "showMenuToggle"): void
    (event: "nameMissing", data: any): void
    (event: "exportItem"): void
}>()

const aquaTestData = ref<any>(null)
const aquaTestCaseId = computed(() => props.exportToAquaId.replace(/^TC/, ""))
const valueSetName = computed(() => getVariable("_Wertesatz"))
const hasValueSetName = computed(() => !!valueSetName.value)
const valueSetExists = computed(() => aquaTestData.value?.ValueSets?.find((x:any) => x.Name == valueSetName.value))

onMounted(async () => await loadItems())

async function loadItems() {
    const data = (await getTestData(aquaTestCaseId.value)).data as any
    if (data && data.ValueSets)
        aquaTestData.value = data
}

function getVariable(name: string) {
    const variable= props.config.variables.find((x:any) => x.name == name)
    return variable ? variable.value : null
}

function toggleMenu() {
    emit("showMenuToggle")
}

async function exportItem() {
    const testData = (await getTestData(aquaTestCaseId.value)).data as any
    if (testData.Message)
        throw new Error(testData.Message)
    testData.ValueSets = testData.ValueSets || []
    testData.Values = testData.Values || []
    testData.Variables = testData.Variables || []

    let row = testData.ValueSets.map((x:any) => x.Name).indexOf(valueSetName.value)
    if (row < 0) {
        console.log("Create value set", valueSetName.value)
        row = testData.ValueSets.length
        testData.ValueSets.push({ Name: valueSetName.value})
        const values: any[] = testData.Variables.map((x:any) => null)
        testData.Values.push(values)
    }
    for (let variable of props.config.variables ) {
        if (!variable.name.startsWith("_")) {
            let col = testData.Variables.map((x:any) => x.Name).indexOf(variable.name)
            if (col < 0) {
                col = testData.Variables.length
                console.log("create variable ", variable.name)
                testData.Variables.push({ Name: variable.name })
                for (let values of testData.Values) {
                    values.push(null)
                }
            }
            testData.Values[row][col] = variable.value
        }
    }
    updateTestCase(aquaTestCaseId.value, { TestData: testData })
    emit("exportItem")
}

function createName() {
    emit("nameMissing", "_Wertesatz")
}
</script>

<template lang="pug">
.modal.show()
    .modal-dialog.modal-dialog-centered
        .modal-content
            .modal-header
                h4.modal-title Exporting value set "{{ valueSetName }}"
                button.close(@click="toggleMenu") &times;
            .model-body(v-if="!hasValueSetName")
                p The variable "_Wertesatz" is not defined. Please set that variable to the value set's name.
            .modal-body(v-else-if="valueSetExists")
                p The current variables override the existing value set in aqua.
            .modal-body(v-else)
                p The variable set does not exist in aqua. A new set will be created.
            .modal-footer(v-if="!hasValueSetName")
                button.btn.btn-success(@click="createName") Set Name
            .modal-footer(v-else)
                button.btn.btn-success(@click="exportItem") Export
    .modal-backdrop.show(style="z-index: -1", @click="toggleMenu")

</template>

<style scoped>

.project-select {
    width: 100%;
}

.disabledOption {
    opacity: 0.4;
}

.disabled {
    pointer-events: none;
}
</style>
