/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ActionInput = {
    type: ActionInput.type;
    'x': number;
    'y': number;
    text?: string;
    wait: number;
    isAbsolute: boolean;
};

export namespace ActionInput {

    export enum type {
        NONE = 'NONE',
        CLICK = 'CLICK',
        SWIPE = 'SWIPE',
        ENTER = 'ENTER',
        WAIT = 'WAIT',
        KEY = 'KEY',
        PASSWORD = 'PASSWORD',
        NAVIGATE_BACK = 'NAVIGATE_BACK',
        NAVIGATE_URL = 'NAVIGATE_URL',
        NAVIGATE_NEXT_TAB = 'NAVIGATE_NEXT_TAB',
        DOUBLE_CLICK = 'DOUBLE_CLICK',
        RIGHT_CLICK = 'RIGHT_CLICK',
        CUT = 'CUT',
        COPY = 'COPY',
        PASTE = 'PASTE',
        SELECT_ALL = 'SELECT_ALL',
        TAG = 'TAG',
        LOAD = 'LOAD',
        COMPARE_EQUAL = 'COMPARE_EQUAL',
        COMPARE_GREATER = 'COMPARE_GREATER',
        COMPARE_LESS = 'COMPARE_LESS',
        JUMP = 'JUMP',
        JUMP_IF_TRUE = 'JUMP_IF_TRUE',
        JUMP_IF_FALSE = 'JUMP_IF_FALSE',
        FIND_IMAGE = 'FIND_IMAGE',
        HOVER = 'HOVER',
        EVAL = 'EVAL',
        SCROLL = 'SCROLL',
        KEY_SEQUENCE = 'KEY_SEQUENCE',
        OCR = 'OCR',
        PROMPT = 'PROMPT',
    }


}

