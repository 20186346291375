function canPlay(device, action) {
    if (device.actionStatus === "unknown" || device.actionStatus === "matched") return true
    return (
        !!device.nextFrame ||
        !action ||
        (!!action && !!action.type && (action.type == "TEXT" || action.type == "CONTROL" || action.type == "MARKER")) ||
        action.disabled
    )
}

function canPlayPredecessor(device) {
    if (device.actionStatus === "unknown" || device.actionStatus === "matched") return true
    return !!device.nextFrame
}

export { canPlay, canPlayPredecessor }
