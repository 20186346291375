<script lang="ts" setup>
const emit = defineEmits<{
    (event: "goBack"): void
}>()

function navBack() {
    emit("goBack")
}
</script>

<template lang="pug">
i.nav-back.fas.fa-arrow-left.fa-x7(@click="navBack")
</template>

<style scoped>
.nav-back {
    cursor: pointer;
    font-size: 25px !important;
    color: var(--bright-green);
}
</style>
