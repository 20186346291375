<script lang="ts" setup>
import { onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import RecordingPage from "@/modules/recording/RecordingPage.vue"

interface Props {
    componentToExecute: string
}

defineProps<Props>()
const emit = defineEmits<{
    (event: "reload"): void
}>()

const route = useRoute()
const router = useRouter()

const recordingWasChanged = ref(false)
const animationTime = ref(1.0)
const animationCenter = ref([])
const showModal = ref(false)

onMounted(() =>
    showModal.value = !!route.params.recordingId
)

watch(() => route.params.recordingId, (rid, old) => {
    document.body.style.overflow = rid ? "hidden" : ""
    showModal.value = true
    animate(!!rid, () => { showModal.value = !!rid })
})

function closeModal() {
    let new_path = route.path.replace(/\/(view\/)?[^/]*$/,'')
    router.replace(new_path)
    if (recordingWasChanged.value) emit("reload")
}

function recordingUpdated(val: boolean) {
    recordingWasChanged.value = val
}

function animate(isStart: boolean, finishCallback= () => {}) {
    if (isStart) {
        animationCenter.value = JSON.parse(sessionStorage.getItem('animate') || '[]')
        sessionStorage.removeItem('animate')
    }
    if (animationCenter.value.length) {
        let start=0, dt
        function callback(time: number) {
            if (start) {
                dt = (time - start) / (isStart ? 150 : 400)
            } else {
                dt = 0
                start = time
            }
            animationTime.value = Math.pow(isStart ? Math.min(1.0, dt) : Math.max(0.0, 1.0-dt), 4)
            if (dt < 1.0) requestAnimationFrame(callback)
            else finishCallback()
        }
        requestAnimationFrame(callback)
    } else {
        finishCallback()
    }
}

function getStyle() {
    let scale = animationTime.value
    const x = ((animationCenter.value[0] || 0) - window.innerWidth/2) * (1.0-scale)
    const y = ((animationCenter.value[1] || 0) - window.innerHeight/2) * (1.0-scale)
    return ` transform: translate(${x}px, ${y}px) scale(${scale}) `
}

</script>

<template lang="pug">
#modal-3(v-if="showModal")
    transition(name="modal")
        .modal-mask
            .modal-wrapper
                .modal-content(:style="getStyle()")
                    recording-page(
                        v-if="componentToExecute === 'viewer'",
                        @closeModal="closeModal",
                        @recordingUpdated="recordingUpdated"
                    )
</template>

<style lang="css" scoped>
.modal-wrapper {
    display: grid;
    grid-template-columns: 100vw;
}
.modal-header {
    padding: 0 0 0 0;
}

.modal-mask {
    z-index: 20;
    background-color: rgba(0,0,0,0.1) !important;
}
.modal-content {
    background-color: white;
    height: 100vh;
    width: 100vw;
}
</style>
