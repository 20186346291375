import type { ActionDTO } from "@/types/gen"

export function getActionInputType(a: ActionDTO) {
    if (a.type == "CONTROL" && a.inputs[0]) {
        return a.inputs[0].type
    }
    if (a.type == "MARKER" && a.inputs[0] && a.inputs[0].type == "TAG") return "TAG"
    if (a.type == "POINTER" && a.inputs.length == 0) return "CHECK"
    return "UNKNOWN"
}

export function autoplayAfterInsert(a: ActionDTO) {
    const inputType = getActionInputType(a)
    const autoplayTypes = ["FIND_IMAGE"]
    return autoplayTypes.includes(inputType)
}

export function replayAfterChange(a: ActionDTO) {
    const inputType = getActionInputType(a)
    const noReplayTypes = ["CHECK", "TAG"]
    return !noReplayTypes.includes(inputType)
}

export function tagExists(text: String, actions: ActionDTO[]) {
    for (let a of actions) {
        if (getActionInputType(a) == "TAG" && a.inputs[0].text == "#" + text) return true
    }
    return false
}

export function userActionVerb(action: ActionDTO) {
    const inputs = action.inputs
    if (action.type == "MARKER") {
        return inputs.length == 1 && inputs[0].type == "TAG" ? "TAG" : "WRONG_ACTION"
    }
    if (action.type == "POINTER") {
        if (inputs.length == 0) return "CHECK"
        else {
            if (inputs.length == 1 && inputs[0].type == "CLICK") return "CLICK"
            if (inputs[0].type == "RIGHT_CLICK") return "CLICK (R)"
            if (inputs[0].type == "DOUBLE_CLICK") return "CLICK (2x)"
            if (inputs[0].type == "HOVER") return "HOVER"
        }
        if (inputs[0].type == "OCR") return "OCR"
        return "SWIPE"
    } else if (action.type == "TEXT") {
        const textTypes = {
            KEY: "PRESS",
            ENTER: "ENTER",
            PASSWORD: "ENTER",
            WAIT: "WAIT",
            NAVIGATE_URL: "OPEN",
            SCROLL: "SCROLL",
            KEY_SEQUENCE: "PRESS",
        }
        if (inputs.length == 0) return ""
        return (textTypes)[inputs[0].type]
    } else if (action.type == "CONTROL") {
        const controlTypes = {
            LOAD: "LOAD",
            EVAL: "EVAL",
            COMPARE_EQUAL: "EQUALS",
            JUMP: "JUMP",
            JUMP_IF_TRUE: "JUMP (T)",
            JUMP_IF_FALSE: "JUMP (F)",
            FIND_IMAGE: "FIND",
        }
        if (inputs.length == 0) return ""
        return (controlTypes)[inputs[0].type]
    } else if (action.type == "PROMPT") {
        return [inputs[0].type]
    }
}

function isMatch(pattern: string, text: string) {
    if (pattern.includes("*")) {
        const re= new RegExp("^"+pattern.replaceAll(".","\.").replaceAll("*",".*")+"$")
        return !!re.exec(text)
    } else {
        return pattern==text
    }
}

export function actionVisibleInBranch(action: ActionDTO, branch: string) : boolean {
    let result= true
    if (action.branches) {
        const [pos, neg] = action.branches.split("!")
        result = !pos.length || !!pos.split(",").find(x => isMatch(x, branch))
        if (neg && result) {
            result = !neg.split(",").find(x => isMatch(x, branch))
        }
    }
    return result
}

export const END_ACTION_ID = -1
