/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeviceSystemConfigurationProperties = {
    id: string;
    name: string;
    displayName: string;
    resolutionWidth: number;
    resolutionHeight: number;
    default: boolean;
    deviceHandler: DeviceSystemConfigurationProperties.deviceHandler;
    type: DeviceSystemConfigurationProperties.type;
};

export namespace DeviceSystemConfigurationProperties {

    export enum deviceHandler {
        CHROME = 'CHROME',
        FIREFOX = 'FIREFOX',
        VNC = 'VNC',
        FIREFOX_VNC = 'FIREFOX_VNC',
        FIREFOX_WD_VNC = 'FIREFOX_WD_VNC',
        CHROME_VNC = 'CHROME_VNC',
        VNC_WINDOWS_DIRECT = 'VNC_WINDOWS_DIRECT',
        MOBILE_APPETIZE = 'MOBILE_APPETIZE',
        VNC_WINDOWS_DIRECT_PERSISTED_PREDECESSOR = 'VNC_WINDOWS_DIRECT_PERSISTED_PREDECESSOR',
        VNC_WDPP_DEDICATED_2019 = 'VNC_WDPP_DEDICATED_2019',
    }

    export enum type {
        MOBILE = 'MOBILE',
        DESKTOP = 'DESKTOP',
        WEBSITE = 'WEBSITE',
        PLAYGROUND = 'PLAYGROUND',
    }


}

