import { createApp } from "vue"
import App from "@/App.vue"
import { router } from "@/router"
import { createPinia } from "pinia"
import { useStore } from "@/store/useStore"
import { resolveAuthToken } from "@/services/AuthService"
import pluginInjector from "@/plugins/PluginService"
import "@/assets/css/variables.css"
import "@/assets/css/main.css"
import { getUser } from "@/services/ProjectService"
import movableDirective from "@/common/directives/movableDirective"
import registerLayouts from "@/plugins/registerLayouts"
import { VueCookieNext } from 'vue-cookie-next'
import VueCryptojs from 'vue-cryptojs'
import type { CurrentUserResponse } from "@/types/gen"

pluginInjector.addRoutes(router)

let authorized = false

// prettier-ignore
const app = createApp(App)
    .use(router)
    .use(createPinia())
    .use(registerLayouts)
    .use(VueCookieNext)
    .use(VueCryptojs)
    .directive("movable", movableDirective)

app.config.errorHandler = (err) => {
    useStore().showError(err)
}

let appMounted = false
let receivedCode: string | null = null
router.beforeEach(async (to) => {
    const code = new URLSearchParams(location.search).get("code")
    if (code && code != receivedCode) {
        receivedCode = code
        await resolveAuthToken(code)
            .catch((e) => {
                loadingFailed(e)
                throw e
            });
        const intendedPath = sessionStorage.getItem("intendedPath") || "/"
        sessionStorage.removeItem("intendedPath")
        authorized = true
        setInstallationData()
        mountApp()
        return intendedPath
    } else if (to.matched.some((record) => record.meta.auth)) {
        if (!authorized) await setInstallationData()
    }
    mountApp()
    return true
})

function setInstallationData() : Promise<any> {
    return getUser().then((userData: CurrentUserResponse) => {
        authorized = true
        useStore().setUserData(userData)
        if (userData?.installation?.settings) {
            window.PLUGIN_AQUA_URL = (userData.installation.settings["aqua-plugin-url"])
            window.OCR_ENABLED = (userData.installation.settings["ocr-enabled"] == "true")
            window.EDITOR_LIFETIME = userData.installation.settings["editor-lifetime"]
        }
    }).catch(loadingFailed)
}

function loadingFailed(e: Error) {
    console.error(e)
    const loader = document.getElementsByClassName("loader").item(0)
    if (loader) {
        loader.innerHTML = "Loading failed."
    }
}

function mountApp() {
    if (appMounted) return
    app.mount("#app")
    appMounted = true
}
