/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ActionCreateRequest = {
    testCaseId: number;
    beforeActionId?: number;
    type?: ActionCreateRequest.type;
};

export namespace ActionCreateRequest {

    export enum type {
        POINTER = 'POINTER',
        TEXT = 'TEXT',
        MARKER = 'MARKER',
        CONTROL = 'CONTROL',
        PROMPT = 'PROMPT',
    }


}

