<script lang="ts" setup>
import VActionButton from "@/common/components/form/VActionButton.vue"

interface Props {
    texts: any[]
    closeButton: boolean
}

defineProps<Props>()
const emit = defineEmits(["closeTag"])

function closeTag() {
    emit("closeTag")
}
</script>

<template lang="pug">
transition(name="fade")
    .tagContainer(v-if="texts.length" @click="closeTag")
        .close-tag(v-if="closeButton") X
        .tagBackdrop
            .text-view
                .tagText(v-for="text in texts") {{ text.text }}
                    template(v-if="text.button")
                        span.mt-3
                            v-action-button(
                                :main="text.button.main"
                                :mainClass="text.button.mainClass"
                                :mainIconClass="text.button.mainIconClass"
                                @main="text.button.callback()"
                            )
</template>

<style scoped>
.text-view {
    display: grid;
    grid-area: 1/1/2/2;
    align-content: center;
    padding: 3.2vmax;
}

.tagBackdrop {
    display: grid;
    grid-area: 1/1/2/2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.tagText {
    color: white;
    font-family: var(--tu-font-family-primary);
    text-shadow: 0px 0px 2px #000000;
    font-size: 3.2vmin;
    display: grid;
    align-content: center;
    padding-top: 15px;
}

.tagContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr;
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.close-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
}
</style>
