<script lang="ts" setup>
import { computed, nextTick, ref } from "vue"
import { canPlay } from "@/services/DeviceHelperFunctions"
import VHelpText from "@/common/components/layout/VHelpText.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import { useRouter } from "vue-router"
import ActionListItem from "@/common/components/action/ActionListItem.vue"
import ActionHover from "@/common/components/action/ActionHover.vue"
import FlowController from "@/common/components/FlowController.vue"
import { userActionVerb } from "@/services/actionUtils"
import type { ActionDTO } from "@/types/gen"
import { TestcaseService } from "@/services/TestcaseService";

interface Props {
    mode: string
    actions: any[]
    actionsLoaded?: boolean
    deviceStatus?: any
    currentActionExact?: any
    isRoot?: boolean
    justInserted?: number
    currentActionObj?: any
    failedActions: any[]
    notPlayed?: any[]
    isInPredecessor?: boolean
    cutClipboards?: any[],
    testCaseService: TestcaseService
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update", data: any): void
    (event: "delete", data: any): void
    (event: "retake", data: any): void
    (event: "playtohere", data: any): void
    (event: "openTestCaseInEditor", data: any): void
    (event: "jump", data: any): void
    (event: "splitTestCase", data: any): void
    (event: "setMode", data: string): void
    (event: "addEmptyTextAction"): void
    (event: "play", data?: any[]): void
    (event: "stop"): void
    (event: "complain", data: any): void
    (event: "closeSession", data: boolean): void
    (event: "pasteAction", data: any): void
    (event: "openSelf"): void
}>()

const router = useRouter()

const hoveredActionId = ref<number | null>(null)

const testcase = computed(() => props.testCaseService.getTestCase())

const isEnd = computed(() => props.deviceStatus.currentAction === -1)

const testPassed = computed(() => {
    return !hasFailed(props.actions[props.actions.length - 1])
})

function canEdit(action: any) {
    return !!action
}

function showAsPlayable(action: any) {
    if (props.deviceStatus.isDummy) {
        return !hasFailed(action)
    } else {
        return canPlay(props.deviceStatus, action)
    }
}

function saveAction(action: any) {
    emit("update", action)
}

function deleteAction(action: any) {
    emit("delete", action)
}

function retakeAction(action: any) {
    emit("retake", action)
}

function playtohereAction(action: any) {
    if (props.deviceStatus.isDummy) {
        jumpAction(action)
        return
    }
    emit("playtohere", action)
}

function openActionInEditor(action: any) {
    emit("openTestCaseInEditor", {
        testCaseId: testcase.value.id,
        playTo: action.id
    })
}

function jumpAction(action: any) {
    emit("jump", action)
}

function splitTestCase(action: any) {
    emit("splitTestCase", action)
}

function disableAction(action: any) {
    action.disabled = true
    emit("update", action)
}

function enableAction(action: ActionDTO) {
    action.disabled = false
    emit("update", action)
}

function complainAction(action: ActionDTO) {
    emit("complain", action)
}

async function activatedElem(element: HTMLElement) {
    await nextTick()
    const top = document.getElementById("side-panel")
    if (!top) return
    const totalHeight = top.offsetHeight
    let scrollVal = element.offsetTop - totalHeight / 2 + element.offsetHeight * 0.7
    top.scrollTop = scrollVal
}

function isCurrentAction(action: any) {
    return props.deviceStatus.currentAction == action.id
}

function setMode(mode: string) {
    emit("setMode", mode)
}

function showPlayToHere(a: any) {
    return a.id != -1 ? _showPlayToHere(a) && a.id != props.deviceStatus.currentAction : _showPlayToHere(a)
}

function _showPlayToHere(a: any) {
    return (
        !props.deviceStatus.playing &&
        a.id == hoveredActionId.value &&
        props.deviceStatus.playingTo == null
    )
}

function mouseenter(action: any) {
    hoveredActionId.value = action.id
}

function mouseleave(action: any) {
    hoveredActionId.value = null
}

function showJustInsertedHelp(a: any) {
    return (
        !props.deviceStatus.playing &&
        props.mode == "edit" &&
        a.id == props.deviceStatus.currentAction &&
        props.justInserted == props.deviceStatus.currentAction &&
        props.isRoot &&
        props.currentActionObj
    )
}

function hasFailed(action: any) {
    return (
        !!action &&
        !!props.failedActions &&
        !!props.failedActions.find((failedAction: any) => failedAction.actionId === action.id)
    )
}

function wasPlayed(action: any) {
    if (props.notPlayed) {
        return props.notPlayed.findIndex((el: any) => el.id === action.id) < 0
    } else {
        return true
    }
}

function actionAnnotations(action: any) {
    let res = {
        wasPlayed: wasPlayed(action),
        hasFailed: hasFailed(action),
    }
    if (res.hasFailed) emit("openSelf")
    return res
}
</script>

<template lang="pug">
.action-list-content
    div(v-for="(a, index) in actions", :key="a.id", @mouseenter="mouseenter(a)", @mouseleave="mouseleave(a)")
        .m-2(v-if="!deviceStatus.playing && mode.startsWith('insert') && a.id == deviceStatus.currentAction")
            v-help-text(v-if="!!cutClipboards?.length" :showCancel="true", @click="$emit('pasteAction')", @cancel="cutClipboards.length = 0")
                span.link {{ `[Paste ${cutClipboards.length} cut actions]` }}
            v-help-text(v-else :showCancel="true", @click="$emit('addEmptyTextAction')", @cancel="setMode('play')")
                span Draw anchor area or!{" "}
                span.link [press key]
        flow-controller(
            v-if="a.id == deviceStatus.currentAction && (deviceStatus.playing || !mode.startsWith('insert'))",
            :deviceStatus="deviceStatus",
            :isFirst="index == 0",
            :isRoot="isRoot",
            :canPlay="showAsPlayable(a)",
            :mode="mode",
            :justInserted="justInserted",
            :currentActionExact="currentActionExact",
            @play="$emit('play', actions)",
            @stop="$emit('stop')",
            @activated="activatedElem",
            @retakeImage="retakeAction(a)",
            @setMode="setMode"
        )
        action-hover(
            v-if="showPlayToHere(a) && actionAnnotations(a).wasPlayed",
            :deviceStatus="deviceStatus",
            @playToHere="playtohereAction(a)",
        )
        action-hover(
            v-else-if="a.id == deviceStatus.playingTo",
            :playingToHere="true",
        )
        action-list-item(
            :a="a",
            :verb="userActionVerb(a)",
            :deviceConnected="!!deviceStatus",
            :deviceStatus="deviceStatus",
            :isCurrentAction="isCurrentAction(a)",
            :canEdit="canEdit(a)",
            :actionAnnotations="actionAnnotations(a)",
            :justInserted="justInserted",
            :isInPredecessor="isInPredecessor",
            :canPlay="showAsPlayable(a)",
            :mode="mode",
            :actionIndex="index",
            :testCaseService="testCaseService",
            @delete="deleteAction(a)",
            @playtohere="playtohereAction(a)",
            @openActionInEditor="openActionInEditor(a)",
            @jump="jumpAction(a)",
            @splitTestCase="splitTestCase(a)",
            @disableAction="disableAction(a)",
            @enableAction="enableAction(a)",
            @saveAction="(e) => (e ? saveAction(e) : saveAction(a))",
            @play="$emit('play')",
            @complain="complainAction(a)"
        )
        .m-2(v-if="showJustInsertedHelp(a)")
            v-help-text(:showCancel="true", :currentActionObj="currentActionObj", :testcase="testcase.value", @cancel="deleteAction(a)")
                div(v-if="deviceStatus.retakeAnchorArea")
                    span Draw new anchor area
                div(v-else)
                    div(v-if="currentActionObj.type == 'POINTER'")
                        span Add click, swipe or!{" "}
                        span.link(v-if="!currentActionObj.inputs.length", @click="$emit('play')") [play as check]
                        span.link(v-else, @click="$emit('play')") [play it]
                    div(v-if="currentActionObj.type == 'CONTROL'")
                        span.link(v-if="currentActionObj.inputs[0].type=='FIND_IMAGE'", @click="$emit('play')") [play as find]

    .float-left(
        v-if="deviceStatus.currentAction > -1",
        @mouseenter="mouseenter({ id: -1 })",
        @mouseleave="mouseleave({ id: -1 })"
    )
        action-hover(v-if="showPlayToHere({ id: -1 }) && !isInPredecessor", @playToHere="playtohereAction({ id: -1 })", :deviceStatus="deviceStatus")
        action-hover(v-else-if="deviceStatus.playingTo == -1", :playingToHere="true", :deviceStatus="deviceStatus")
        .card-container
            div
            .card.bg-transparent.border-0(style="margin-bottom: -30px")
                .card-header.bg-transparent.border-0
    .float-left(
        v-if="isEnd && testPassed && deviceStatus.isDummy && notPlayed && notPlayed.length == 0",
        @mouseleave="mouseleave({ id: -1 })"
    )
        action-hover(:passed="true")
    div(
        v-if="deviceStatus.currentAction < 0 && (!mode.startsWith('insert')) && isRoot && deviceStatus.connected",
        @click="setMode('insert-default')",
        style="margin-top: 20px"
    )
        .insert-style
            .line
            span Append new action
            .line
    .float-right(v-if="!deviceStatus.isDummy && isRoot")
        .card-container
            div
            .card.bg-transparent.border-0(style="margin-bottom: -30px")
                .card-header.bg-transparent.border-0
                    div
                    span.text-right
                        v-action-button(
                            main="Done"
                            :mainIconClass="['fas', 'fa-sign-out-alt']"
                            :mainHidden="!isEnd"
                            @main="$emit('closeSession', true)"
                        )
                            a.dropdown-item(
                                v-if="!!deviceStatus",
                                @click="jumpAction({ id: -1 })",
                                :class="{ disabled: deviceStatus.currentAction == -1 }"
                            )
                                .dropdown-item-icon
                                    i.fas.fa-map-marker-alt
                                span Jump here
                            a.dropdown-item(v-if="isEnd", @click="$emit('closeSession', true)")
                                .dropdown-item-icon
                                    i.fas.fa-thumbs-up
                                span End with Success
                            a.dropdown-item(@click="$emit('closeSession', false)")
                                .dropdown-item-icon
                                    i.fas.fa-thumbs-down
                                span End as Failed
    div(v-if="isEnd")
        slot
</template>

<style lang="css" scoped>
.action-list-content {
    margin: 5px 0 0 3px;
}

.card {
    margin: 0.5ex 1ex 0.5ex 0.5em;
}

.card-container {
    width: 100%;
    display: grid;
    grid-template-columns: 14px 1fr;
}

.card-header {
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr) min-content min-content;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 110px;
    padding-left: 10px;
    padding-right: 5px;
}

.insert-style {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto max-content auto;
    line-height: 0.1em;
    color: var(--bright-orange);
    margin-left: 10px;
    grid-column: 11 / auto;
}

.line {
    border-bottom: 3px solid var(--bright-orange);
    margin-left: 5px;
    margin-right: 5px;
}
</style>
