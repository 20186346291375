<script lang="ts" setup>
import { computed, onMounted, onUpdated, ref } from "vue"
import { useRoute, useRouter } from "vue-router"

interface Props {
    tabNames: string[]
    openTab?: number
    hiddenTabs?: string[]
    frameBorderClass?: string
    bgClass?: string
}

const props = defineProps<Props>()
const route = useRoute()
const router = useRouter()

const activeTab = ref(props.openTab ?? 0)
const tabs = ref(props.tabNames.map((v) => v.replace(" ", "").toLowerCase()))
const tabString = ref(route.query.tab as string)

onMounted(() => {
    if (tabString.value) {
        tabString.value = tabString.value.replace(" ", "").toLowerCase()
        activeTab.value = tabs.value.indexOf(tabString.value)
    }
})

onUpdated(() => {
    if (!modalOverlay.value) {
        updatePath()
    }
})

const modalOverlay = computed(() => {
    return route.params.recordingId != null
})

defineExpose({
    updateTab,
})

function updateTab(tab: number) {
    activeTab.value = tab
}

function updatePath() {
    router.replace({ query: { tab: tabs.value[activeTab.value] } })
}
</script>

<template lang="pug">
.detailed-view
    .header.title-area.tu-title-area
        .titles(style="min-height:3.5em")
            .navigate-back
                slot(name="navigate-back")
            .breadcrumbs
                slot(name="breadcrumb")
            .title
                slot(name="title")
        .button-group
            slot(name="button-group")
    .tu-tab-titles(:class="frameBorderClass")
        ul.nav.nav-tabs(v-if="tabNames", :class="frameBorderClass")
            template(v-for="(tabName, index) in tabNames")
                li.flex-inline.nav-item(v-if="!hiddenTabs || !hiddenTabs[index]")
                    a.nav-link.tu-tab.bg-very-light-grey.no-border(
                        :class="[{ active: activeTab == index }, activeTab == index ? bgClass : '', activeTab == index ? frameBorderClass : '', '']",
                        @click="activeTab = index"
                    ) {{ tabName }}
    .tabs.scroll-event-hook
        template(v-if="activeTab == 0")
            slot(name="tab0")

        template(v-if="activeTab == 1")
            slot(name="tab1")

        template(v-if="activeTab == 2")
            slot(name="tab2")

        template(v-if="activeTab == 3")
            slot(name="tab3")

        template(v-if="activeTab == 4")
            slot(name="tab4")
</template>

<style lang="css" scoped>
.header {
    padding-top: 30px;
    padding-left: var(--header-gap);
    padding-right: var(--header-gap);
    display: grid;
    grid-template-columns: auto max-content;
}
.detailed-view {
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-column: var(--layout-body-col);
    grid-row: var(--layout-body-row);
    overflow-y: var(--detailed-view-overflow-y);
    overflow-x: var(--detailed-view-overflow-x);
    width: 100%;
    height: 100vh;
    align-content: start;
}
.detailed-view > .container {
    display: grid;
    grid-template-rows: min-content min-content auto;
}
.tabs > * {
}
li.nav-item {
    cursor: pointer;
    user-select: none;
}
.nav-tabs {
    padding-left: var(--header-gap);
    padding-right: var(--header-gap);
    border-bottom: none;
}
.nav-link {
    border-bottom: none;
}
a.nav-link.bg-very-light-grey {
    white-space: nowrap;
}

.tabs {
    padding-left: var(--header-gap);
    padding-right: var(--header-gap);
    height: 100%;
    display: grid;
    overflow: auto;
}
@media print {
    .tabs {
        overflow: revert;
    }
}
.titles {
    margin-left: calc(-1 * var(--header-gap));
    display: grid;
    grid-template-columns: var(--header-gap) max-content 1fr;
    grid-template-rows: min-content min-content;
}

.navigate-back {
    display: grid;
    grid-row: 1/3;
    grid-column: 1/2;
    place-items: center;
}
.breadcrumbs {
    display: grid;
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 0;
    margin: 0;
}
.title {
    display: grid;
    grid-row: 2/3;
    grid-column: 2/5;
    padding: 0;
    margin: 0;
}
.no-border {
    border: 0px solid !important;
}
</style>
