<script lang="ts" setup>
import { ref, watch } from "vue"

interface Props {
    modelValue: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: string): void
    (event: "leaveFocus", data: Event): void
    (event: "pressEnter", data: Event): void
}>()

const tag = ref(props.modelValue)
const tagInputError = ref<boolean>(false)
const tagInputErrorMessage = ref<string>('')

watch(
    () => props.modelValue,
    () => {
        tag.value = props.modelValue
    }
)

function update() {
    emit("update:modelValue", tag.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}
</script>

<template lang="pug">
div
    i.icon-large.fas.fa-terminal
.prompt-text.input-group.input-group-sm
    textarea.form-control(
        v-model="tag", 
        type="text",
         @change="update", 
         @keydown.enter.prevent="emit('pressEnter', $event)",
         @keydown.stop="")
</template>
<style>
</style>
