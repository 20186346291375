<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from "vue"
import { getAquaConfig, saveAquaConfig } from "@/common/aqua/TestupWrapper"
import { useRoute, useRouter } from "vue-router"

const emit = defineEmits<{
    (event: "saved"): void
}>()

const route = useRoute()
const router = useRouter()

const config = ref<any>({ loading: true })
const dirty = ref(false)

onMounted(async () => {
    config.value = await getAquaConfig()
    await nextTick()
    dirty.value = false
})

watch(config, touch, { deep: true })

async function save() {
    await saveAquaConfig(config.value)
    dirty.value = false
    emit("saved")
}

function touch() {
    dirty.value = true
}
</script>

<template lang="pug">
.settings.container
    .main
        h3.mb-4 aqua authorization
        .table(v-if="!config.loading")
            label Domain:
            input(v-model="config.AQUA_URL")
            label Username:
            input(v-model="config.AQUA_USER")
            label Password:
            input(v-model="config.AQUA_PASSWORD", type="password")
        button.btn.btn-primary(@click="save()", :class="{ disabled: !dirty }", :disabled="!dirty") Save settings
</template>

<style lang="css" scoped>
.table {
    display: grid;
    row-gap: 1ex;
    grid-template-columns: 10em auto;
}
.main {
    margin-top: 100px;
}
</style>
