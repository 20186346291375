<template lang="pug">
.device-screen.magnifiable
    screen-area(
        :device="device"
        :magnify="mode!='edit'"
        :drawable="isAnchorAreaDrawMode() || isAnchorAreaInsertMode()"
        :allowEdit="mode=='edit'"
        :currentAction="!mode.startsWith('insert') ? currentAction : null"
        @pointerstart="pointerStart"
        @newRect="setAnchorArea"
        @updateRect="useCandidate"
        @newSwipe="newSwipe"
        @click="setClick"
        @updateClick="$emit('saveAction', currentAction)"
        ref="clickableArea",
        :class="{ drawAnchorMode: isAnchorAreaDrawMode() || isAnchorAreaInsertMode() }"
    )
        template(#play-it)
            run-play-it(v-if="showPlayIt(device.nextFrame)"
                @play="$emit('play')"
                @delete="$emit('delete', currentAction)")
</template>

<script lang="ts" setup>
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue"
import RunPlayIt from "@/modules/run/components/RunPlayIt.vue"
import ScreenArea from "@/common/components/viewer/ScreenArea.vue"
import { getScreenCoordinates, getTypeOfClick } from "@/services/DomHelperFunctions";
import { DeviceState } from "@/services/BackendService"
import type { ActionDTO } from "@/types/gen"

interface Props {
    device: DeviceState,
    mode: string,
    currentAction?: ActionDTO,
    justInserted?: number
}
const props = defineProps<Props>()

type CallbackType = () => void
const emit = defineEmits<{
    (event: "setMatcher", data: any, data2?:any, data3?:any, callback?:Function): void
    (event: "saveAction", data: any): void
    (event: "play", data: any): void
    (event: "delete", data: any): void
    (event: "replay", data: any): void
}>()

const currentStep = ref<any>()
const correctionCorner = ref("")
const candidateFrame = ref<any[]>([])
const secondClick = ref(false)
const savedCurrentAction = ref<any>()
const moveInputMode = ref(false)
const startInputPos = ref<any>()
const startInputType = ref<any>()
const dragged = ref(false)

const clickableArea = ref<typeof ScreenArea>()

onMounted(() => {
    window.addEventListener("dblclick", registerSecondClick)
})

function pointerStart(event: UIEvent, matrix: SVGMatrix) {
    if (isClickMode()) {
        startInput(event, matrix)
    }
}

function mousedownRectCircle(correctionCorner: any) {
    correctionCorner.value = correctionCorner
    //TODO startRectCorrection()
}

function newSwipe(data: any) {
    emit("setMatcher", null, data.pos1, (action: any, match: any) => {
        action.inputs.push(
            Object.assign(getCoordsRelative(data.pos1, match), {
                type: data.type,
            })
        )
        action.inputs.push(Object.assign(getCoordsRelative(data.pos2, match),
            {
                type: "SWIPE"
            }))
        return true
    })
}

function registerSecondClick(event: UIEvent) {
    event.preventDefault()
    secondClick.value = true
}

function setClick(data: any) {
    let type = data.type
    secondClick.value = false
    emit("setMatcher", null, data.pos, (action: any, match: any) => {
        if (secondClick.value) {
            type = "DOUBLE_CLICK"
        }
        if (!props.device.retakeAnchorArea) {
            action.inputs = [Object.assign(getCoordsRelative(data.pos, match), { type })]
        }
        return true
    })
}

function setAnchorArea(rect: DOMRect, callback: Function) {
    if (props.device.retakeAnchorArea && !props.device.findImage) {
        props.device.findImage = false
    }
    props.device.retakeAnchorArea = false
    emit(
        "setMatcher",
        {
            x0: rect.x,
            x1: rect.right,
            y0: rect.y,
            y1: rect.bottom,
            imageUrl: props.device.imageUrl
        },
        null,
        callback
    )
}

function useCandidate(rect: DOMRect, callback: Function) {
    if (dragged.value) return
    emit(
        "setMatcher",
        {
            x0: rect.x,
            x1: rect.right,
            y0: rect.y,
            y1: rect.bottom,
            imageUrl: props.device.imageUrl
        },
        null,
        callback
    )
}

function startInput(event: any, matrix: SVGMatrix) {
    if (!props.currentAction) return
    savedCurrentAction.value = props.currentAction
    startInputPos.value = getCoordsRelative(matrix.inverse().transformPoint(getScreenCoordinates(event)))
    startInputType.value = getTypeOfClick(event)
    if (!currentStep.value) {
        savedCurrentAction.value.inputs = [
            Object.assign(startInputPos.value, { type: getTypeOfClick(event) }),
        ]
    }
    function moveInput(event: Event) {
        moveInputMode.value = true
        const pos = getCoordsRelative(matrix.inverse().transformPoint(getScreenCoordinates(event)))
        const frameWidth = props.device.nextFrame!.x1 - props.device.nextFrame!.x0
        const frameHeight = props.device.nextFrame!.y1 - props.device.nextFrame!.y0
        if (currentStep.value) {
            currentStep.value.x = pos.x
            currentStep.value.y = pos.y
        } else {
            const inputs = savedCurrentAction.value.inputs,
                last = inputs[inputs.length - 1]
            const xMove = Math.abs(pos.x - last.x) * frameWidth
            const yMove = Math.abs(pos.y - last.y) * frameHeight
            if (last.type != "SWIPE" && (xMove > 10 || yMove > 10)) {
                const newStep = Object.assign(pos, { type: "SWIPE" })
                inputs.push(newStep)
                currentStep.value = newStep
            }
        }
    }
    function stopInput(event: Event) {
        window.removeEventListener("mousemove", moveInput)
        window.removeEventListener("mouseup", stopInput)
        window.removeEventListener("touchmove", moveInput)
        window.removeEventListener("touchend", stopInput)

        console.log('move input mode', moveInputMode.value)
        if (!moveInputMode.value) {
            let input = savedCurrentAction.value.inputs[0]
            const pos = getCoordsRelative(matrix.inverse().transformPoint(getScreenCoordinates(event)))
            if (input && (input.type == "CLICK" || input.type == "DOUBLE_CLICK" || input.type == "RIGHT_CLICK")) {
                const samePos = !(pos.x - startInputPos.value.x) && !(pos.y - startInputPos.value.y)
                if (samePos && currentStep.value) {
                    let newInputType = "DOUBLE_CLICK"
                    if (startInputType.value == "RIGHT_CLICK") newInputType = "RIGHT_CLICK"
                    else if (input.type == "RIGHT_CLICK" && startInputType.value == "CLICK") newInputType = "CLICK"
                    input.type = newInputType
                }
            }
        }
        moveInputMode.value = false
        emit("saveAction", savedCurrentAction.value)
        savedCurrentAction.value = null
        startInputPos.value = null
        currentStep.value = null
    }

    window.addEventListener("mousemove", moveInput)
    window.addEventListener("mouseup", stopInput)
    window.addEventListener("touchmove", moveInput)
    window.addEventListener("touchend", stopInput)
}

function getCoordsRelative(pos: DOMPoint, match: any = undefined) {
    let frameDim = null
    if (match) frameDim = match.data
    else {
        frameDim = props.device.nextFrame
    }

    return {
        x: (pos.x - frameDim.x0) / (frameDim.x1 - frameDim.x0),
        y: (pos.y - frameDim.y0) / (frameDim.y1 - frameDim.y0),
    }
}

function isAnchorAreaInsertMode() {
    return props.mode.startsWith("insert")
}

function isAnchorAreaDrawMode() {
    return props.mode == "edit" && props.device.retakeAnchorArea
}

function isClickMode() {
    return props.mode == "edit" && props.device.nextFrame
}

const hasFrame= computed(() => props.currentAction && props.currentAction.matcherId && props.device.nextFrame)

function showPlayIt(frame: any) {
    return (
        hasFrame.value && props.mode == "edit" && props.device && !props.device.playing && props.justInserted
    )
}

function showUse(frame: any) {
    return frame && (props.mode == "edit" || props.mode == "play")
}

</script>

<style lang="css" scoped>
.device-screen {
    display: grid;
    overflow: hidden;
    text-align: center;
    place-items: center;
    height: 100%;
    position: relative
}
</style>
