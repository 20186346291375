import SyncTestCase from "@/common/aqua/SyncTestCase.vue"
import AquaSettings from "@/common/aqua/AquaSettings.vue"
import LinkRequirements from "@/common/aqua/LinkRequirements.vue"

class PluginInjector {
    addRoutes(router) {
        router.addRoute({
            path: "/ext/aqua/sync/:testCaseId",
            component: SyncTestCase,
            meta: {
                auth: true,
            },
        })
        router.addRoute({
            path: "/ext/aqua/req/:aquaId",
            component: LinkRequirements,
            meta: {
                auth: true,
            },
        })
    }

    testCaseContextMenu(menuList, route, router) {
        if (window.PLUGIN_AQUA_URL) {
            menuList.push({
                iconClass: ["fas", "fa-link"],
                text: "Synchronize with aqua",
                callback: () => {
                    const id = route.params.testcaseId
                    router.push(`/ext/aqua/sync/${id}`)
                },
            })
        }
        return menuList
    }
}

export default new PluginInjector()
