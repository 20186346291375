<script lang="ts" setup>
import { ActionInput } from "@/types/gen";
import { ref, watch } from "vue"

interface Props {
    modelValue: string,
    hashTagActions: ActionInput[],
    currentInput: ActionInput
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: string): void
    (event: "leaveFocus", data: Event): void
}>()

const tag = ref(props.modelValue)
const tagInputError = ref<boolean>(false)
const tagInputErrorMessage = ref<string>('')

watch(
    () => props.modelValue,
    () => {
        tag.value = props.modelValue
    }
)

watch(
    () => props.hashTagActions,
    () => {
        markWithErrorIfDuplicatedTagValue(props.modelValue)
    }
)

function update() {
    emit("update:modelValue", tag.value)
}

function leaveFocus(e: Event) {
    emit("leaveFocus", e)
}

function checkIfUniqueInput(e: Event) {
    const tagValue = e.target.value
    
    markWithErrorIfDuplicatedTagValue(tagValue)
}

function markWithErrorIfDuplicatedTagValue(tagValue: string) {
    tagInputError.value = false
    if (!props.hashTagActions.length || tagValue[0] != '#')
        return
    
    let tagNamesExcludedCurrent = props.hashTagActions
                                           .filter(h => h !== props.currentInput)
                                           .map(h => h.text)

    const index = tagNamesExcludedCurrent!!.indexOf(tagValue)    
    
    if (index > -1) {
        tagInputErrorMessage.value = 'Tag name duplication'
        tagInputError.value = true
    }    
}
</script>

<template lang="pug">
div
    svg(height="27", width="80", style="margin-left: -10px")
        polygon(points="1,1 1,25 60,25 75,15 60,1", style="fill: black; stroke: black; stroke-width: 4")
    svg(height="10", width="10", style="margin-left: -75px")
        circle(cx="5", cy="5", r="5", stroke="black", stroke-width="3", fill="white")
.input-group.input-group-sm
    input.form-control(v-model="tag", type="text", @change="update", @keydown.enter="leaveFocus", @keydown.stop="", @keyup="checkIfUniqueInput")
    span.errorMessage(v-if="tagInputError") {{tagInputErrorMessage}}
</template>
<style>
.errorMessage {
    position:absolute;
    right: 0px;
    top: -23px;
    background-color: var(--red);
    color: var(--very-light-grey);
    border-radius: 3px;
    padding: 3px;
}
</style>
