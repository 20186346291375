<script lang="ts" setup>
import { computed, ref } from "vue"
import type { ScheduleDTO } from "@/types/gen";

interface Props {
    schedule: ScheduleDTO
    tests: any[]
}

const props = defineProps<Props>()

const hiddenVariablesNames = computed(() => [])
const variables = ref(props.schedule.variables)

const emit = defineEmits<{
    (event: "saveSchedule"): void
}>()

function saveSchedule() {
    emit("saveSchedule")
}

function deleteVariable(variable: any) {
    variables.value.splice(variables.value.indexOf(variable), 1)
    saveSchedule()
}

function addOption() {
    variables.value.push({name: "", value: "", hidden: false})
}

</script>

<template lang="pug">
.schedule-variables
    .advanced-section-name.section-name
        span.tu-heading Advanced
        .advanced-section
            template(v-for="variable in variables")
                template(v-if="!hiddenVariablesNames.includes(variable.name)")
                    input.form-control(v-model="variable.name", @change="saveSchedule")
                    div
                        input.form-control.d-inline(v-model="variable.value", @change="saveSchedule")
                    div
                        .deleteBtn(style="cursor: pointer", @click="deleteVariable(variable)")
                            i.fas.fa-trash
            i.fas.fa-plus.mt-3(style="cursor: pointer", @click="addOption")
            .empty
</template>

<style lang="css" scoped>
.schedule-variables {
    margin-top: 2ex;
}
.advanced-section {
    display: grid;
    grid-template-columns: 20% 1fr 220px;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
}

.options-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
}
</style>