<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import {
    getTests,
    saveConfig,
    updateTestCase,
} from "@/services/ProjectService"
import {listOfReorderedTestcase} from "@/services/TestcaseHelperFunction.js"
import ConfigComponent from "@/modules/testcase/components/ConfigComponent.vue"
import {useStore} from "@/store/useStore"
import VActionButton from "@/common/components/form/VActionButton.vue"
import VUrl from "@/common/components/form/VUrl.vue"
import type {
TestCaseConfigurationDTO,
    TestCaseDTO,
    UpdateTestCaseRequest
} from "@/types/gen"

interface Props {
    modelValue: any
    testcase: any
    showUrlHelpMessage?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "update:modelValue", data: any): void
    (event: "runTest"): void
}>()

const config = ref(props.modelValue)
const editMode = ref(false)
const tests = ref<TestCaseDTO[]>([])
const selectedPredecessorId = ref<string | null>(
    props.testcase ? (props.testcase.predecessorId != null ? props.testcase.predecessorId : "") : ""
)
const isDisabled = computed(() => props.testcase.predecessorId && !getPredecessor()?.isFolder && !props.testcase.config.overridePredecessor)
const selectedDeviceType = ref()
const file = ref<File | null>(null)

const { runLocked } = useStore()


onMounted(async () => {
    await loadTestcases()
    setDefaultOptions()
})

function setDefaultOptions() {
    if (props.testcase.isFolder) {
        selectedDeviceType.value = 'Folder'
    } else if (!props.testcase.predecessorId) {
        selectedDeviceType.value = 'Test'
    } else {
        const predecessor= getPredecessor()
        if (predecessor?.isFolder)
            selectedDeviceType.value = 'Test'
        else
            selectedDeviceType.value= 'Successor'
    }
}

const testList = computed(() => {
    return tests.value.filter((item: any) => props.testcase.id !== item.id)
})

const reorderedTests = computed(() => {
    return listOfReorderedTestcase(tests.value)
})

const reorderedTestsWithoutCurrent = computed(() => {
    return reorderedTests.value.filter((test: TestCaseDTO) => test.id != props.testcase.id)
})

const reorderedTestsOnlyFolder = computed(() => {
    return reorderedTestsWithoutCurrent.value.filter((test: TestCaseDTO) => test.isFolder)
})

const reorderedTestsNonFolders = computed(() => {
    return reorderedTestsWithoutCurrent.value.filter((test: TestCaseDTO) => !test.isFolder)
})

function getPredecessor(testcase: TestCaseDTO = props.testcase) {
    return tests.value.find((item: any) => item.id === testcase.predecessorId)
}

function getActiveConfig(testcase?: TestCaseDTO) : TestCaseConfigurationDTO | null {
    if (!testcase) return null
    if (testcase.isFolder) return null
    if (testcase.config?.overridePredecessor) return testcase.config
    const predecessor = getPredecessor(testcase)
    return getActiveConfig(predecessor) || testcase.config
}

watch(
    () => props.testcase,
    async () => {
        await loadTestcases()
    }
)

function switchEditMode() {
    editMode.value = !editMode.value
}

async function loadTestcases() {
    if (props.testcase) {
        tests.value = await getTests(props.testcase.project)
    }
}


async function updatePredecessor() {
    let predecessorId: number | null
    if (!selectedPredecessorId.value) {
        predecessorId = null
    } else {
        predecessorId = parseInt(selectedPredecessorId.value!)
    }
    props.testcase.predecessorId = predecessorId
    changeTest()
}

async function changeConfig() {
    await runLocked(async () => await runLocked(() => saveConfig(props.testcase.id, config.value)))
    emit("update:modelValue", config.value)
}

async function changeTest() {
    await runLocked(async () => await updateTestCase(props.testcase.id, {
        predecessorId: {value: props.testcase.predecessorId},
        isFolder: {value: props.testcase.isFolder}
    } as UpdateTestCaseRequest))
}

function runTest() {
    emit("runTest")
}


async function setTestCaseType(type: string, subtype: string | null = null) {
    selectedDeviceType.value = type
    props.testcase.isFolder = type == 'Folder'
    config.value.overridePredecessor = false
    changeConfig()
    changeTest()
}

function getTestCaseButtonClass(type: string) {
    return type == selectedDeviceType.value ? ['tu-btn', 'btn-outline-secondary', 'tu-toggle-button', 'enabled'] : null
}

</script>

<template lang="pug">
.config-container.tu-tab-area
    span.tu-heading What would you like to create

    .testcase-type-buttons
        v-action-button.invisible-in-print(
            main="Folder",
            :mainIconClass="['fas', 'fa-folder']",
            :mainClass="getTestCaseButtonClass('Folder')"
            @main="setTestCaseType('Folder')",
            style=""
        )

        v-action-button.invisible-in-print(
            main="Test",
            :mainIconClass="['fas', 'fa-signal']",
            :mainClass="getTestCaseButtonClass('Test')"
            @main="setTestCaseType('Test')",
            style=""
        )

        v-action-button.invisible-in-print(
            main="Successor Test",
            :mainIconClass="['fas', 'fa-level-up-alt']",
            :mainClass="getTestCaseButtonClass('Successor')"
            @main="setTestCaseType('Successor')",
            style=""
        )

    .settings-container(v-if="selectedDeviceType == 'Successor'")
        .space
        .settings-section-name.section-name
            span.tu-heading Predecessor
        .settings-section
            .settings-section-folder
                select.custom-select(v-model="selectedPredecessorId", @change="updatePredecessor" style="max-width: 60%")
                    option(v-for="test in reorderedTestsNonFolders", :value="test.id", :key="test.id") {{ test.name }}
        .settings-section-name
            label.tu-heading(for="override") Override
        .settings-section
            label
                input.form-control(id="override" type="checkbox" checked v-model="testcase.config.overridePredecessor" @change="changeConfig")
                span
    .settings-container(v-else)
        .space
        .settings-section-name.section-names
            span.tu-heading Parent Folder
        .settings-section
            .settings-section-folder
                select.custom-select(v-model="selectedPredecessorId", @change="updatePredecessor" style="max-width: 60%")
                    option(value="") No Parent Folder
                    option(v-for="test in reorderedTestsOnlyFolder", :value="test.id", :key="test.id") {{ test.name }}

    .config-container(v-if="selectedDeviceType && selectedDeviceType != 'Folder'" :class="{disabledConfig: isDisabled}")
        config-component(
            :modelValue="getActiveConfig(testcase)"
            @update:modelValue="changeConfig"
            @runTest="runTest")
</template>

<style lang="css" scoped>
.header-container-with-button {
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 5px;
}
.deleteBtn {
    cursor: pointer;
    width: fit-content;
    padding-left: 6px;
    padding-right: 6px;
}

.disabledConfig {
    pointer-events: none;
    opacity: 0.4;
}

.config-container {
    margin-top: 20px;
}

.settings-container {
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-template-rows: repeat(3, max-content);
    grid-gap: 10px;
    padding-bottom: 50px;
}

.testcase-type-buttons {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 20px 10px;
    margin-top: 20px;
}

.space {
    grid-column: 1/3;
    height: 50px;
}

@media (max-width: 940px) {
    .settings-container {
        grid-template-columns: 1fr;
    }
    .space {
        grid-column: 1/2;
    }
}

.select-device-section {
    margin-top: 5px;
}

</style>
