<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
    getProject,
    getSchedule,
    saveSchedule,
    deleteSchedule,
    runSchedule,
    stopAllProjectTestcases,
    getTests,
} from "@/services/ProjectService"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import VHeader from "@/common/components/layout/VHeader.vue"
import VEditorOverlay from "@/common/components/layout/VEditorOverlay.vue"
import ScheduleTimings from "@/modules/schedule/components/ScheduleTimings.vue"
import ScheduleTests from "@/modules/schedule/components/ScheduleTests.vue"
import ScheduleVariables from "@/modules/schedule/components/ScheduleVariables.vue"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import { useStore } from "@/store/useStore"
import ScheduleNotifications from "@/modules/schedule/components/ScheduleNotifications.vue"
import ScheduleRecordings from "@/modules/schedule/components/ScheduleRecordings.vue"
import type { ProjectDTO, ScheduleDTO, TestCaseDTO } from "@/types/gen"

const route = useRoute()
const router = useRouter()

const project = ref<ProjectDTO>()
const schedule = ref<ScheduleDTO>()
const tests = ref<TestCaseDTO[]>([])
const testsLoaded = ref(false)
const frameBorderClass = ref("")
const bgClass = ref("")
const isProjectRunning = ref(false)

const store = useStore()
const { runLocked } = store

onMounted(async () => {
    await reload()
})

async function reload() {
    const scheduleId = parseInt(route.params.scheduleId as string)
    schedule.value = await getSchedule(scheduleId)
    // temporary fix
    schedule.value = {
        threadLimit: 0,
        ...schedule.value,
    }

    document.title = schedule.value.name ?? 'schedule'
    project.value = await getProject(schedule.value.projectId)
    tests.value = await getTests({ id: project.value.id })
    testsLoaded.value = true
}

async function doStopAllProjectTestcases() {
    isProjectRunning.value = false
    await stopAllProjectTestcases(project.value!)
}

async function doSaveSchedule() {
    if (schedule.value) {
        document.title = schedule.value.name ?? 'schedule'
        runLocked(async () => {
            const { nextRun } = await saveSchedule(schedule.value!)
            Object.assign(schedule.value ?? {}, { nextRun })
        })
    }
}

async function doDeleteSchedule() {
    if (schedule.value && project.value) {
        await deleteSchedule(schedule.value)
        router.push(`/project/${project.value.id}`)
    }
}

async function playCheckedTests() {
    await store.lock
    await runSchedule(schedule.value!, "Run by " + schedule?.value?.name)
    isProjectRunning.value = true
}

function updateSchedule(newSchedule: ScheduleDTO) {
    schedule.value = newSchedule
}

async function goBack() {
    if (project.value) {
        router.push(`/project/${project.value.id}`)
    }
}
</script>

<template lang="pug">
.schedule-view
    v-editor-overlay(:componentToExecute="'viewer'", @reload="reload")
    detailed-view(
        :tabNames="['Timing', 'Notifications', 'Runs', 'Tests', 'Data']",
        :frameBorderClass="frameBorderClass",
        :bgClass="bgClass"
    )
        template(#navigate-back)
            v-navigate-back(@goBack="goBack")
        template(#title)
            h4
                v-editable-text(v-if="schedule", v-model="schedule.name", @update:modelValue="doSaveSchedule")
        template(#breadcrumb)
            v-breadcrumb(
                v-if="project && schedule",
                :projectId="project.id",
                :projectName="project.name",
                :scheduleName="schedule.name",
                :simpleMode="true"
            )
        template(#button-group)
            v-action-button.invisible-in-print(
                v-if="schedule",
                :main="!isProjectRunning ? 'Play' : 'Stop'",
                :mainClass="!isProjectRunning ? null : 'btn-danger'",
                :mainIconClass="!isProjectRunning ? ['fas', 'fa-play'] : ['fas', 'fa-stop-circle']",
                @main="!isProjectRunning ? playCheckedTests() : doStopAllProjectTestcases()"
            )
                a.dropdown-item(@click="reload")
                    .dropdown-item-icon
                        i.fas.fa-sync-alt
                    span Reload
                a.dropdown-item(@click="doDeleteSchedule")
                    .dropdown-item-icon
                        i.fas.fa-trash
                    span Delete
        template(#tab0)
            schedule-timings(
                v-if="schedule",
                :schedule="schedule",
                @updateSchedule="updateSchedule",
                @saveSchedule="doSaveSchedule"
            )
        template(#tab1)
            schedule-notifications(v-if="schedule", :schedule="schedule", @saveSchedule="doSaveSchedule")
        template(#tab2)
            schedule-recordings(v-if="schedule" :schedule="schedule")
        template(#tab3)
            schedule-tests(
                v-if="schedule && testsLoaded",
                :schedule="schedule",
                :tests="tests",
                @updateSchedule="updateSchedule",
                @saveSchedule="doSaveSchedule"
            )
        template(#tab4)
            schedule-variables(
                v-if="schedule" 
                :schedule="schedule"
                @saveSchedule="doSaveSchedule"
                )
</template>

<style lang="css" scoped>
.schedule-view {
    height: var(--project-view-height);
    display: grid;
    grid-template-columns: min-content 1fr;
    overflow-y: hidden;
    overflow-x: hidden;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
