<script lang="ts" setup>
import { computed, ref } from "vue"
import ActionList from "@/common/components/action/ActionList.vue"
import ActionPredecessor from "@/common/components/action/ActionPredecessor.vue"
import type { ActionDTO } from "@/types/gen";
import { TestcaseService } from "@/services/TestcaseService";

interface Props {
    mode: string
    testCaseService: TestcaseService
    currentAction?: any
    device?: any
    noDeviceMode?: boolean
    currentActionExact?: any
    isRoot?: boolean
    justInserted?: number
    canEdit?: any
    currentActionObj?: any
    failedActions: any[]
    notPlayed?: any[]
    cutClipboards?: any[]
}

const emit = defineEmits(["update", "complain","actionClicked", "setMode", "pasteAction", "jump", "stop", "play", "playAll", "openTestCaseInEditor"])

const props = defineProps<Props>()

const _currentActionObj = computed(() =>
    props.testCaseService.getActionById(props.currentAction)
)

const testcase = computed(() => props.testCaseService.getTestCase())


function update($event: UIEvent) {
    emit('update', $event)
}

function complainAction(action: ActionDTO) {
    emit('complain', action)
}
</script>

<template lang="pug">
.action-list-main
    template(v-if="!noDeviceMode")
        .card-header.tu-card-header.ml-4.tu-sticky-header(v-if="testcase.config")
            .span-sm Recorded actions from
            h5.wrapText {{ testcase.name }}
        action-predecessor(
            ref="predecessor",
            v-if="testCaseService.predecessor",
            :testCaseService="testCaseService.predecessor",
            :deviceStatus="device.deviceStatus",
            :mode="mode",
            :failedActions="failedActions",
            :canBeInlined="true",
            :cutClipboards="cutClipboards",
            @complain="complainAction($event)"
            @play="$emit('play', $event)",
            @playAll="$emit('playAll', $event)",
            @stop="$emit('stop', $event)",
            @jump="$emit('jump', $event)",
            @inlineTestCase="$emit('inlineTestCase')",
            @playtohere="$emit('playtohere', $event)",
            @openTestCaseInEditor="$emit('openTestCaseInEditor', $event)"
            @delete="$emit('delete', $event)",
            @setMode="$emit('setMode', $event)",
            @update="update",
            @pasteAction="$emit('pasteAction', $event)",
        )
        action-list(
            :mode="mode",
            :actions="testCaseService.actions",
            :deviceStatus="device.deviceStatus",
            :actionsLoaded="testCaseService.isLoaded",
            :isRoot="isRoot",
            :justInserted="justInserted",
            :currentActionObj="currentActionObj",
            :failedActions="failedActions",
            :cutClipboards="cutClipboards",
            :testCaseService="testCaseService",
            @play="$emit('play', $event)",
            @stop="$emit('stop', $event)",
            @update="update",
            @pasteAction="$emit('pasteAction', $event)",
            @delete="$emit('delete', $event)",
            @retake="$emit('retake', $event)",
            @playtohere="$emit('playtohere', $event)",
            @jump="$emit('jump', $event)",
            @splitTestCase="$emit('splitTestCase', $event)",
            @setMode="$emit('setMode', $event)",
            @closeSession="$emit('closeSession', $event)",
            @addEmptyTextAction="$emit('addEmptyTextAction', $event)"
        )

    template(v-if="noDeviceMode")
        action-predecessor(
            ref="predecessor",
            v-if="testCaseService.predecessor",
            :testCaseService="testCaseService.predecessor"
            :deviceStatus="device.deviceStatus",
            :currentActionExact="currentActionExact",
            :mode="mode",
            :failedActions="failedActions",
            @play="$emit('play')",
            @update="$emit('update', $event)",
            @jump="$emit('actionClicked', $event)",
            @complain="complainAction($event)"
            @openTestCaseInEditor="$emit('openTestCaseInEditor', $event)"
        )
        action-list(
            :mode="mode",
            :actions="testCaseService.actions",
            :deviceStatus="device.deviceStatus",
            :currentActionExact="currentActionExact",
            :actionsLoaded="testCaseService.isLoaded",
            :isRoot="isRoot",
            :justInserted="justInserted",
            :currentActionObj="_currentActionObj",
            :failedActions="failedActions",
            :notPlayed="notPlayed",
            :testCaseService="testCaseService",
            @play="$emit('play')",
            @update="$emit('update', $event)",
            @jump="$emit('actionClicked', $event)",
            @complain="$emit('complain', $event)",
            @openTestCaseInEditor="$emit('openTestCaseInEditor', $event)",
            @cutClipboards="cutClipboards"
        )
</template>

<style lang="css" scoped>
.wrapText {
    overflow: hidden;
    word-break: break-all;
}
</style>
