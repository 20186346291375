<script lang="ts" setup>
import { computed, watch } from "vue"
import VHelpText from "@/common/components/layout/VHelpText.vue"

interface Props {
    mode: string
    device: any
    action: any
    ignoreGlobalCreate?: boolean
    hasActions: boolean
    cutClipboards?: any[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "setMode", data: string): void
    (event: "addEmptyTextAction"): void
    (event: "pasteAction", data: any): void
}>()

const isCurrentAction = computed(() => {
    return props.device.currentAction == props.action.id
})

const isGlobalCreate = computed(() => {
    return props.mode.startsWith("insert") && !props.ignoreGlobalCreate
})

watch(
    () => props.mode,
    (newValue) => {
        if (!newValue && isCurrentAction.value) {
            emit("setMode", "edit")
        }
    }
)

function setMode(mode: string) {
    emit("setMode", mode)
}
</script>

<template lang="pug">
.action-edit-content
    .div(:class="{ 'global-create': isGlobalCreate, 'action-details': !isGlobalCreate }")
        div(v-if="isGlobalCreate && device.currentAction < 0")
            .m-2(v-if="mode.startsWith('insert')")
                v-help-text(v-if="!hasActions", :showCancel="true", @cancel="setMode('play')")
                    span Click or draw on the device screen
                template(v-else)
                    v-help-text(v-if="!!cutClipboards?.length" :showCancel="true", @click="$emit('pasteAction', null)", @cancel="cutClipboards.length = 0")
                        span.link {{ `[Paste ${cutClipboards.length} cut actions]` }}
                    v-help-text(v-else
                        :showCancel="true",
                        @click="$emit('addEmptyTextAction')",
                        @cancel="setMode('play')"
                    )
                        span Click, draw or&nbsp;
                        span.link [press key]
</template>

<style lang="css" scoped>
.action-edit-content {
    /*height: 100%;*/
    /*padding: 2ex;*/
    padding-left: 1.5ex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
}
.insert-cancel {
    height: 45px;
    margin-bottom: 5px;
    margin-top: -3px;
}
</style>
